import { HttpClientModule } from '@angular/common/http';
import { Inject, InjectionToken, NgModule, Optional } from '@angular/core';

import { ApiExecutorService } from './api-executor.service';
import { DefaultServiceLocator } from './default-service-locator.service';
import { ExecutorService } from './executor.service';
import { HttpCommunicationService } from './http-communication.service';
import { ApiConfiguration } from './model/api-configuration';
import { SharedApiExecutorService } from './shared-api-executor.service';

export const ApiServices = {
    ddvMW: new InjectionToken<ApiExecutorService>('ddvMWService'),
    monitorMW: new InjectionToken<SharedApiExecutorService>('monitorMWService'),
    ddvApi: new InjectionToken<ApiExecutorService>('ddvApiService'),
    monitorApi: new InjectionToken<SharedApiExecutorService>('monitorApiService'),
    trebek: new InjectionToken<ApiExecutorService>('trebekApiService'),
    usage: new InjectionToken<SharedApiExecutorService>('usageApiService'),
    flags: new InjectionToken<SharedApiExecutorService>('flagsApiService'),
    wauth: new InjectionToken<SharedApiExecutorService>('wauthApiService'),
    xtlk: new InjectionToken<ApiExecutorService>('xtlkApiService'),
    reportRunner: new InjectionToken<ApiExecutorService>('reportRunnerApiService'),
    fmp: new InjectionToken<ApiExecutorService>('fmpApiService'),
    nqs: new InjectionToken<ApiExecutorService>('nqsApiService'),
};

@NgModule({
    imports: [
        HttpClientModule,
    ],
    providers: [
        ExecutorService,
        DefaultServiceLocator,
        HttpCommunicationService,
        {
            provide: ApiServices.ddvMW,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): ApiExecutorService => {
                return new ApiExecutorService('ddvMWLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.monitorMW,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): SharedApiExecutorService => {
                return new SharedApiExecutorService('monitorMWLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.ddvApi,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): ApiExecutorService => {
                return new ApiExecutorService('ddvApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.monitorApi,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): SharedApiExecutorService => {
                return new SharedApiExecutorService('monitorApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.trebek,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): ApiExecutorService => {
                return new ApiExecutorService('trebekApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.usage,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): SharedApiExecutorService => {
                return new SharedApiExecutorService('usageApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.flags,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): SharedApiExecutorService => {
                return new SharedApiExecutorService('flagsApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.wauth,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): SharedApiExecutorService => {
                return new SharedApiExecutorService('wauthApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.xtlk,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): ApiExecutorService => {
                return new ApiExecutorService('xtlkApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.reportRunner,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): ApiExecutorService => {
                return new ApiExecutorService('reportRunnerApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.fmp,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): ApiExecutorService => {
                return new ApiExecutorService('fmpLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: ApiServices.nqs,
            useFactory: (executor: ExecutorService, config: ApiConfiguration | null): ApiExecutorService => {
                return new ApiExecutorService('nqsApiLocation', executor, config);
            },
            deps: [ExecutorService, [new Inject('apiConfig'), new Optional()]],
        },
    ],
})
export class HttpModule { }
