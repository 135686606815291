import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { UserService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { ManagerService } from '@ddv/layout';
import {
    BoardWidgetDefinitionIds,
    Client,
    MANAGE_WIDGET_WS_ID,
    WIDGET_LIFECYCLE_EVENT,
    CompareMode,
    DashboardPreference,
    toPrioritizedFilterParams,
    DatasetDefinitionDetails,
    DatasetFetchRuntimeParams,
    Query,
    UserPreferences,
    FieldMetadata,
    Fund,
    Action,
    ActionState,
    DatasetMetadata,
    HSColumnDefinition,
    MetadataLookup,
    WidgetFilterParams,
    crosstalkFieldMetadatas,
    crosstalkColumns, QueryTypeName,
} from '@ddv/models';
import { ClientsService, FundsService } from '@ddv/reference-data';
import { UsageTracker, UsageTrackingService } from '@ddv/usage-tracking';
import { deepCompare, deepClone } from '@ddv/utils';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, skipWhile, switchMap, take } from 'rxjs/operators';

interface MetadataQueryResponse {
    actions: Action[];
    metadata: FieldMetadata[];
    supportsDateRange: boolean;
    supportsGranularity: boolean;
}

@Injectable()
export class MetadataService {
    public readonly metadataState: Observable<DatasetMetadata>;
    public readonly actionsState: Observable<ActionState>;

    private readonly metadataStateSubject: Subject<DatasetMetadata> = new BehaviorSubject<DatasetMetadata>({ metadata: new Map() });
    private clientCode: string = '';
    private isMultiClient: boolean = false;
    private dashboardClientsList: string[] = [];
    private manageWidgetClientsList: string[] = [];
    private fullClientsList: string = '';
    private fundOptions: Fund[] = [];
    private clientOptions: Client[] = [];
    private dashboardQueryParams: DashboardPreference | undefined;
    private userPreferences: UserPreferences | undefined;
    private readonly metadataDetails: Map<number, MetadataDetails> = new Map();
    private readonly actionsStateSubject: Subject<ActionState> = new BehaviorSubject({});

    constructor(
        private readonly currentStateService: CurrentStateService,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly fundsService: FundsService,
        private readonly userService: UserService,
        private readonly usageTracking: UsageTrackingService,
        private readonly managerService: ManagerService,
        private readonly queryParamsService: QueryParamsService,
        private readonly clientsService: ClientsService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
        @Inject(ApiServices.nqs) private readonly nqsApiExecutor: ApiExecutorService,
    ) {
        this.currentStateService.clientCode$.subscribe({
            next: (clientCode) => this.clientCode = clientCode,
        });
        this.metadataState = this.metadataStateSubject.asObservable();
        this.actionsState = this.actionsStateSubject.asObservable();

        this.currentStateService.isMultiClient$.pipe(switchMap((isMultiClient) => {
            this.isMultiClient = isMultiClient;
            if (this.isMultiClient) {
                return combineLatest([
                    this.queryParamsService.dashboardQueryParams,
                    this.queryParamsService.widgetQueryParams,
                    this.clientsService.clients(),
                ]);
            } else {
                return combineLatest([of({}), of({}), this.fundsService.funds()]);
            }
        })).subscribe({
            next: ([dashboardQueryParams, widgetQueryParams, list]) => {
                if (this.isMultiClient) {
                    if (dashboardQueryParams && Object.keys(dashboardQueryParams).length) {
                        this.dashboardClientsList = (dashboardQueryParams as DashboardPreference).clients?.map((cl) => cl.clientId) ?? [];
                    }
                    if (widgetQueryParams && Object.keys(widgetQueryParams).length) {
                        (widgetQueryParams as WidgetFilterParams).widgetFilters.forEach((widgetFilters, _) => {
                            if (widgetFilters.clients) {
                                this.manageWidgetClientsList = widgetFilters.clients.map((client) => client.clientId);
                            }
                        });
                    }
                    this.fullClientsList = list.join(',');
                    this.clientOptions = (list as string[]).map((clientCode) => ({ clientId: clientCode, clientName: clientCode }));
                } else {
                    if (list.some((datum) => datum instanceof Fund)) {
                        this.fundOptions = list as Fund[];
                    }
                }
            },
        });

        this.queryParamsService.dashboardQueryParams.subscribe({
            next: (dashboardQueryParams) => this.dashboardQueryParams = dashboardQueryParams,
        });

        this.userService.userPreferences$.subscribe({
            next: (userPreferences) => this.userPreferences = userPreferences,
        });
    }

    // this is called only by ApplicationBaseWidget.fetchMetaData
    getMetadata(context: BoardWidgetDefinitionIds): void {
        this.fetchMetadataUniqueMetadata(context).subscribe({
            next: (metadata: MetadataLookup) => {
                Object.entries(metadata).forEach(([key, value]) => {
                    value.name = key;
                });

                if (context.widgetId) {
                    this.addMetadataToState(context.widgetId, metadata);
                }
            },
        });
    }

    addMetadataToState(widgetId: number, metadata: MetadataLookup): void {
        const currentState = (this.metadataStateSubject as BehaviorSubject<DatasetMetadata>).value;
        if (!deepCompare(currentState.metadata.get(widgetId), metadata)) {
            const newMetadata = deepClone(currentState.metadata);
            newMetadata.set(widgetId, deepClone(metadata));

            const newState: DatasetMetadata = { ...currentState, lastChangedWidgetId: widgetId, metadata: newMetadata };
            this.metadataStateSubject.next(newState);
        }
    }

    removeMetadata(widgetId: number): void {
        const currentState = (this.metadataStateSubject as BehaviorSubject<DatasetMetadata>).value;
        const newMetadata: Map<number, MetadataLookup> = new Map();
        // eslint-disable-next-line guard-for-in
        for (const key in currentState.metadata) {
            const keyNumber = Number(key);
            if (keyNumber !== widgetId) {
                const metadata = currentState.metadata.get(keyNumber);
                if (metadata) {
                    newMetadata.set(keyNumber,metadata);
                }
            }
        }

        if (this.metadataDetails.get(widgetId)) {
            this.metadataDetails.delete(widgetId);
        }

        const newState: DatasetMetadata = { ...currentState, lastChangedWidgetId: undefined, metadata: newMetadata };
        this.metadataStateSubject.next(newState);
    }

    fetchMetadataUniqueMetadata(context: BoardWidgetDefinitionIds): Observable<MetadataLookup> {
        const widgetId = context.widgetId;
        if (!widgetId) {
            return throwError(() => new Error('cannot fetch metadata without a widget id'));
        }

        return this.fetchAndMapMetadataForDatasetDefinition(context)
            .pipe(map((metadata: MetadataLookup) => {
                const details = this.metadataDetails.get(widgetId);
                if (details) {
                    details.metadata = of(metadata);
                }
                return metadata;
            }));
    }

    fetchMetadataAllMetadata(contexts: BoardWidgetDefinitionIds[]): Observable<Record<number, MetadataLookup>> {
        const fetchObservables = contexts.map((context) => {
            return this.fetchAndMapMetadataForDatasetDefinition(context);
        });

        return forkJoin(fetchObservables)
            .pipe(map((metadatas: MetadataLookup[]) => {
                return metadatas.reduce((hash, metadata, index): Record<number, MetadataLookup> => {
                    const widgetId = contexts[index].widgetId;
                    if (!widgetId) {
                        return hash;
                    }

                    const details = this.metadataDetails.get(widgetId);
                    if (details) {
                        details.metadata = of(metadata);
                    }
                    hash[widgetId] = metadata;
                    return hash;
                }, {} as Record<number, MetadataLookup>);
            }));
    }

    private fetchAndMapMetadataForDatasetDefinition(context: BoardWidgetDefinitionIds): Observable<MetadataLookup> {
        if (!context.definitionId) {
            return throwError(() => new Error('cannot fetch metadata for definition with a definition id'));
        }

        return this.datasetDefinitionsService.fetchDatasetDefinitionDetails(context.definitionId)
            .pipe(mergeMap((datasetDefinition) => {
                return this.getMetadataDetails(datasetDefinition, context);
            }));
    }

    private getMetadataDetails(
        datasetDefinition: DatasetDefinitionDetails,
        context: BoardWidgetDefinitionIds,
    ): Observable<MetadataLookup> {
        return this.getQueryParams(context).pipe(switchMap((params): Observable<MetadataLookup> => {
            const widgetId = context.widgetId;
            let query: Query;
            try {
                query = datasetDefinition.populateQueryTemplate(params, this.fundOptions);
                if (datasetDefinition.conversableType) {
                    query.conversationType = datasetDefinition.conversableType;
                    if (query.selectedColumns) {
                        query.selectedColumns = [...query.selectedColumns, ...crosstalkColumns];
                    }
                }
            } catch (error) {
                const message = {
                    action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                    exception: { message: (error as Error).message },
                };

                if (widgetId) {
                    this.managerService.sendMessageToExistingWidget(widgetId, message);
                }

                return throwError(() => error);
            }

            const dashboardId = this.managerService.getCurrentDashboardId();
            if (!dashboardId || !widgetId) {
                return throwError(() => new Error('cannot getMetadataDetails without a dashboardId and a widgetId'));
            }

            if (this.shouldFetchMetadata(datasetDefinition, dashboardId, widgetId)) {
                const metadataEndpoint = datasetDefinition.metadataEndpoint(this.clientCode);
                const usage: UsageTracker = this.usageTracking.startTracking('DDV.Dataset.MetaData', context.toRecord());
                const clientCodeAndRoute = splitEndpointIntoClientAndRoute(metadataEndpoint);

                if (this.isMultiClient) {
                    clientCodeAndRoute.route += `?clients=${dashboardId === MANAGE_WIDGET_WS_ID ?
                        (this.manageWidgetClientsList.length ? this.manageWidgetClientsList.join(',') : this.fullClientsList) :
                        (this.dashboardClientsList.length ? this.dashboardClientsList.join(',') : this.fullClientsList)}`;
                }

                const metadataRequest: Observable<MetadataLookup> =
                    this.fetchMetadata(widgetId, datasetDefinition, clientCodeAndRoute, query, usage);

                const metadataDetails = {
                    dsd: datasetDefinition,
                    metadata: metadataRequest,
                    clients: this.isMultiClient ?
                        dashboardId === MANAGE_WIDGET_WS_ID ?
                            this.manageWidgetClientsList :
                            this.dashboardClientsList :
                        [],
                };
                this.metadataDetails.set(widgetId, metadataDetails);
            }

            const lookup = this.metadataDetails.get(widgetId)?.metadata;
            if (!lookup) {
                return throwError(() => new Error(`metadataDetails map does not have ${widgetId}`));
            }
            return lookup;
        }));
    }

    private fetchMetadata(
        widgetId: number,
        datasetDefinition: DatasetDefinitionDetails,
        clientCodeAndRoute: { clientCode: string, route: string },
        query: Query,
        usage: UsageTracker,
    ): Observable<MetadataLookup> {
        const apiExecutor = requestMetadataFromNQS(datasetDefinition.queryType.name) ?
            this.nqsApiExecutor :
            this.trebekApiExecutor;
        return apiExecutor.invokeServiceWithBody<MetadataQueryResponse>(
            clientCodeAndRoute.clientCode,
            clientCodeAndRoute.route,
            'POST',
            query,
            { usageTracker: usage })
            .pipe(
                map((response: MetadataQueryResponse): MetadataLookup => {
                    this.updateActionsState(datasetDefinition.id!, response.actions);
                    usage.succeeded();

                    response.metadata = this.mapUdfNumberToDecimal(response.metadata);

                    if (datasetDefinition.conversableType) {
                        response.metadata.push(...crosstalkFieldMetadatas);
                    }
                    return response.metadata.reduce((hash: MetadataLookup, row: FieldMetadata) => {
                        if (row.name) {
                            hash[row.name] = new HSColumnDefinition(row);
                        }
                        return hash;
                    }, {});
                }),
                catchError((error: HttpErrorResponse) => {
                    const response = error;
                    usage.failed(response.error || response.message);
                    console.error(response);
                    const message = {
                        action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                        exception: { message: (response.error || response.message) },
                    };

                    this.managerService.sendMessageToExistingWidget(widgetId, message);
                    if (response.url?.indexOf('tfl') !== -1 && response.status === 403) {
                        Object.assign(error, { message: 'You are not entitled to this action' });
                    }
                    return throwError(() => error);
                }),
            );
    }

    private getQueryParams(context: BoardWidgetDefinitionIds): Observable<DatasetFetchRuntimeParams> {
        return combineLatest([this.queryParamsService.widgetQueryParams, this.managerService.activeWidgets])
            .pipe(skipWhile(([, widgets]) => !widgets.some((w) => w.id === context.widgetId)))
            .pipe(skipWhile(([widgetQueryParams, widgets]) => {
                return !widgets.find((w) => w.id === context.widgetId)?.getExtraPreferences()?.isSubscribedToDashboardFilters &&
                    (!!context.widgetId && !widgetQueryParams?.widgetFilters?.has(context.widgetId));
            }))
            .pipe(take(1))
            .pipe(map(([widgetQueryParams, widgets]) => {
                const widget = widgets.find((w) => w.id === context.widgetId)?.getExtraPreferences();
                const widgetQueryParam = widget?.id ? widgetQueryParams?.widgetFilters.get(widget?.id) : undefined;
                const widgetComparing = widgetQueryParam?.comparing;
                const dashboardComparing = this.dashboardQueryParams?.comparing;
                const mode = widgetComparing ?? dashboardComparing;
                const inCompareMode = widgetQueryParam?.isComparing ?? this.dashboardQueryParams?.isComparing;
                const preference = widget?.isSubscribedToDashboardFilters && !inCompareMode ? this.dashboardQueryParams : widgetQueryParam;

                const hsDefaults = toPrioritizedFilterParams(
                    this.fundOptions,
                    this.clientOptions,
                    this.managerService.getExtraParametersForWorkspace(),
                    this.userPreferences);
                const fundCodes = (preference?.funds?.length ? preference : hsDefaults).funds?.map((fund) => fund.fundId) ?? [];

                const dateFrom = mode && mode === CompareMode.COMPARED ?
                    preference?.compareDates?.dateFrom ?? this.dashboardQueryParams?.compareDates?.dateFrom :
                    preference?.startDate ?? hsDefaults.startDate;

                const dateTo = mode && mode === CompareMode.COMPARED ?
                    preference?.compareDates?.dateTo ?? this.dashboardQueryParams?.compareDates?.dateTo :
                    preference?.endDate ?? hsDefaults.endDate;

                const dateRange = {
                    from: dateFrom ?? '',
                    to: dateTo ?? '',
                };

                if (this.isMultiClient) {
                    const clientCodeList = preference?.clients;
                    return new DatasetFetchRuntimeParams('', 'daily', dateRange, fundCodes, true, true, clientCodeList);
                }

                return new DatasetFetchRuntimeParams('', 'daily', dateRange, fundCodes, true);
            }));
    }

    private shouldFetchMetadata(datasetDefinition: DatasetDefinitionDetails, dashboardId: string, widgetId: number): boolean {
        const metadataDetails = this.metadataDetails.get(widgetId);
        return !metadataDetails || metadataDetails && !deepCompare(metadataDetails.dsd, datasetDefinition) ||
            (this.isMultiClient &&
            ((dashboardId !== MANAGE_WIDGET_WS_ID && !deepCompare(metadataDetails.clients, this.dashboardClientsList)) ||
            (dashboardId === MANAGE_WIDGET_WS_ID && !deepCompare(metadataDetails.clients, this.manageWidgetClientsList))));
    }

    private updateActionsState(datasetId: number, actions: Action[]): void {
        const currentActionState = (this.actionsStateSubject as BehaviorSubject<ActionState>).value;
        const updatedActionState = { ...currentActionState, [datasetId]: actions };
        this.actionsStateSubject.next(updatedActionState);
    }

    private mapUdfNumberToDecimal(metadata: FieldMetadata[]): FieldMetadata[] {
        metadata.filter((field) => field.name?.toLowerCase().startsWith('udf_') && field.datatype === 'number')
            .forEach((numberField) => numberField.datatype = 'decimal');
        return metadata;
    }
}

interface MetadataDetails {
    dsd: DatasetDefinitionDetails;
    metadata: Observable<MetadataLookup>;
    clients: string[];
}

export function splitEndpointIntoClientAndRoute(endpoint: string): { clientCode: string, route: string } {
    const queryEndpointParts = endpoint.split('/');
    if (queryEndpointParts.shift() === '') {
        queryEndpointParts.shift();
    }
    const clientCode = queryEndpointParts.shift() ?? '';
    const route = queryEndpointParts.join('/');
    return { clientCode, route };
}

function requestMetadataFromNQS(queryTypeName: string): boolean {
    return queryTypeName === 'Transactions' ||
        queryTypeName === 'Positions' ||
        queryTypeName === 'Recon' ||
        queryTypeName === 'Portfolio' ||
        queryTypeName === QueryTypeName.NET_SETTLEMENT_DETAILS;
}
