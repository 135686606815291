<ag-grid-angular
    *ngIf="gridConfiguration"
    [columnDefs]="columnDefinitions"
    [components]="frameworkComponents"
    [enableGroupEdit]="enableGroupEdit"
    [gridOptions]="gridOptions"
    [rowData]="gridConfiguration.rowData"
    [sideBar]="sideBar"
    [statusBar]="statusBar"
    [suppressAggFuncInHeader]="suppressAggFuncInHeader"
    [suppressScrollOnNewData]="suppressScrollOnNewData"
    [alwaysAggregateAtRootLevel]="true"
    [suppressFieldDotNotation]="true"
    [tooltipMouseTrack]="true"
    (cellClicked)="cellClickedHandler($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    (columnVisible)="onColumnsVisibilityChanged($event)"
    (columnEverythingChanged)="onColumnEverythingChanged($event)"
    (columnMoved)="onColumnMoved($event)"
    (columnPivotModeChanged)="onColumnPivotModeChanged($event)"
    (columnPinned)="onColumnPinned($event)"
    (columnValueChanged)="onValueColumnChanged($event)"
    (columnRowGroupChanged)="onRowGroupColumnChanged($event)"
    (columnPivotChanged)="onColumnPivotChanged()"
    (displayedColumnsChanged)="onDisplayedColumnsChanged()"
    (dragStopped)="onDragStopped($event)"
    (filterChanged)="onGridFilterChanged($event)"
    (filterModified)="onFilterModified($event)"
    (gridColumnsChanged)="onGridColumnsChanged()"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onResize()"
    (modelUpdated)="onModelUpdated()"
    (rowGroupOpened)="onRowGroupOpened($event)"
    (rowSelected)="onRowSelected($event)"
    (rowClicked)="onRowClicked($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (virtualColumnsChanged)="onVirtualColumnsChanged()">
</ag-grid-angular>
