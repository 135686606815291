import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { CustomCellRendererParams } from '@ddv/data-grid';
import { UserDefinedField, UserDefinedFieldType } from '@ddv/models';
import { DropdownOption } from '@hs/ui-core-common';
import { CommonDate, FuzzyDate } from '@hs/ui-core-date';

@Component({
    selector: 'app-user-defined-field-cell-renderer',
    templateUrl: './user-defined-field-cell-renderer.component.html',
    styleUrls: ['./user-defined-field-cell-renderer.component.scss'],
})
export class UserDefinedFieldCellRendererComponent implements ICellRendererAngularComp {
    cellValue: string | number | undefined;
    dropdownOptions: DropdownOption[] = [];
    dropdownCellValue: DropdownOption | undefined;
    dateCellValue: CommonDate | undefined;
    booleanCellValue = false;

    protected params: CustomCellRendererParams | undefined;
    protected type: UserDefinedFieldType | undefined;
    protected fuzzyDates: FuzzyDate[] = [];
    protected canEditUdf = false;

    agInit(params: CustomCellRendererParams): void {
        this.params = params;
        this.initializeCell();
        this.setCellValue();
    }

    refresh(params: CustomCellRendererParams): boolean {
        this.params = params;
        this.initializeCell();
        this.setCellValue();
        return true;
    }

    updateDropdownValue(e: DropdownOption): void {
        const field = this.params?.colDef?.field;
        if (!field) {
            return;
        }

        const newData = { ...this.params?.data[field] };
        newData.value = e?.value || null;
        const colId = this.params?.colDef?.colId;
        if (colId) {
            this.params?.node.setDataValue(colId, newData);
        }
    }

    updateDateValue(e: CommonDate | FuzzyDate | undefined): void {
        const field = this.params?.colDef?.field;
        if (!field) {
            return;
        }

        const newData = { ...this.params?.data[field] };
        if (e instanceof FuzzyDate) {
            newData.value = CommonDate.fromISOFormat(e.value).toDashFormat();
        } else if (e instanceof CommonDate) {
            newData.value = CommonDate.fromYearMonthDay(e.year, e.month, e.day).toDashFormat();
        }

        const colId = this.params?.colDef?.colId;
        if (colId) {
            this.params?.node.setDataValue(colId, newData);
        }
    }

    updateBooleanValue(e: boolean): void {
        const field = this.params?.colDef?.field;
        if (!field) {
            return;
        }

        const newData = { ...this.params?.data[field] };
        newData.value = e;
        const colId = this.params?.colDef?.colId;
        if (colId) {
            this.params?.node.setDataValue(colId, newData);
        }
    }

    private initializeCell(): void {
        this.canEditUdf = !!this.params?.canEditUdf;
        this.type = this.params?.userDefinedFieldType;
        switch (this.type) {
            case 'choice':
                this.dropdownOptions = this.params?.choices?.map((c: string) => ({ text: c, value: c })) ?? [];
                break;
            case 'date':
                this.fuzzyDates = this.params?.fuzzyDates || [];
        }
    }

    private setCellValue(): void {
        const udfType = this.params?.userDefinedFieldType;
        if (udfType === 'decimal') {
            if (this.params?.node.isRowPinned()) {
                const colId = this.params?.colDef?.colId;
                if (colId) {
                    this.cellValue = this.params?.node.aggData[colId];
                }
                return;
            }
            if (this.params?.columnApi.isPivotMode() || !!this.params?.node.allChildrenCount) {
                this.cellValue = this.params.value;
                return;
            }
        }

        const field = this.params?.colDef?.field;
        if (field) {
            const cellData: UserDefinedField = this.params?.data?.[field];
            if (cellData) {
                const { value } = cellData;
                switch (udfType) {
                    case 'choice':
                        this.dropdownCellValue = value ? { text: value as string, value: value as string } : undefined;
                        break;
                    case 'date':
                        this.dateCellValue = value ? CommonDate.fromDashFormat(value as string) : undefined;
                        break;
                    case 'decimal':
                        this.cellValue = Number(value ?? 0);
                        break;
                    case 'string':
                        this.cellValue = String(value != null ? value : '');
                        break;
                    case 'boolean':
                        this.booleanCellValue = !!value;
                }
            }
        }
    }
}
