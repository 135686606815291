import { Injectable } from '@angular/core';
import { FieldMetadataGroup, HSColumnDefinition, MetadataLookup, UserFacingFieldMetadata } from '@ddv/models';
import { escapeRegex } from '@ddv/utils';

@Injectable()
export class FieldMetadataService {
    convertToTreeByHierarchy(fieldMetadataById: MetadataLookup): FieldMetadataGroup[] {
        const treeRoot: FieldMetadataGroup = this.createGroup('ROOT');
        Object.entries(fieldMetadataById).forEach(([_, fieldMetadata]: [string, HSColumnDefinition]) => {
            let path: string[];

            if (!fieldMetadata.hierarchy) {
                path = [fieldMetadata.displayName];
            } else {
                path = fieldMetadata.hierarchy.split('|');
            }

            let branch = treeRoot;
            for (const branchName of path) {
                let nextBranch = branch.children.find((child) => child.displayName === branchName);
                if (!nextBranch) {
                    nextBranch = this.createGroup(branchName);
                    branch.children.push(nextBranch);
                }

                branch = nextBranch;
            }

            branch.displayData.push(fieldMetadata);
        });

        return this.sortChildBranches(treeRoot);
    }

    sortChildBranches(branch: FieldMetadataGroup): FieldMetadataGroup[] {
        for (const childBranch of branch.children) {
            this.sortChildBranches(childBranch);
        }

        branch.displayData.sort(sortFieldMetadataAlphaOnDisplayName);
        return branch.children.sort(sortFieldMetadataAlphaOnDisplayName);
    }

    filterTree(branches: FieldMetadataGroup[] | undefined, searchTerm: string = ''): void {
        if (!branches) {
            return;
        }

        branches.forEach((branch) => {
            let aChildBranchIsVisible;
            if (branch.children.length) {
                this.filterTree(branch.children, searchTerm);
                aChildBranchIsVisible = branch.children.some((item) => !item.hidden);
            }

            let aLeafIsVisible: boolean | undefined;
            if (branch.displayData.length) {
                branch.displayData.forEach((item) => {
                    const regex = new RegExp(escapeRegex(searchTerm), 'gi');
                    item.hidden = !regex.test(item.displayName!);
                    aLeafIsVisible = aLeafIsVisible || !item.hidden;
                });
            }

            branch.hidden = (!branch.displayData.length || !aLeafIsVisible)
                && (!branch.children.length || !aChildBranchIsVisible);
        });
    }

    private createGroup(displayName: string): FieldMetadataGroup {
        return {
            displayName,
            children: [],
            displayData: [],
        };
    }
}

function sortFieldMetadataAlphaOnDisplayName(
    branchA: UserFacingFieldMetadata | FieldMetadataGroup,
    branchB: UserFacingFieldMetadata | FieldMetadataGroup,
): number {
    if (branchA.displayName! < branchB.displayName!) {
        return -1;
    }

    if (branchA.displayName! > branchB.displayName!) {
        return 1;
    }

    return 0;
}
