export interface QueryType {
    id: number;
    name: string;
    dataEndpoint: string;
    metadataEndpoint: string;
}
// this is useful in things like constructors where you need some value for QueryType such that you
// dont have to make the type `| undefined` and you trust that by the time the property is
// actually accessed it will get a real queryType (like from an API call)
export const dummyQueryType: QueryType = { id: -1, name: '', dataEndpoint: '', metadataEndpoint: '' };

export interface QueryPeriodType {
    id: number;
    name: string;
}

export const enum QueryPeriodTypeName {
    RANGE_WITH_ACTIVE_DATE = 'Range with Active Date',
    RANGE = 'Range',
    POINT_IN_TIME = 'Point in Time',
    NO_CALENDAR = 'No Calendar',
}

export const enum QueryTypeName {
    AD_HOC = 'AdHoc',
    CHECKLIST = 'Checklist',
    EOD = 'EOD',
    GQL = 'GQL',
    INVESTOR = 'Investor',
    POSITIONS = 'Positions',
    RECON = 'Recon',
    SNOWFLAKE = 'Snowflake',
    TRADE_FILE_DETAILS = 'TradeFileDetails',
    TRADE_FILE_LOADER = 'TradeFileLoader',
    TRADE_FILE_SUMMARY = 'TradeFileSummary',
    TFL_INCOMPLETE_FILES = 'TFLIncompleteFiles',
    NET_SETTLEMENT_DETAILS = 'NetSettlement'
}
