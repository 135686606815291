import {
    TMode,
    DEFAULT_WIDGET_POSITION,
    MODE,
    LayoutHandler,
    WorkspaceSize,
    Widget,
    WidgetBehaviour,
    WidgetPosition, AppWidgetState,
} from '@ddv/models';
import { Theme, ThemeService } from '@hs/ui-core-presentation';

import { LayoutConfig } from '../models/layout-config';
import { ManagerService } from './manager.service';

export class CustomLayoutHandler implements LayoutHandler {
    // TODO: public state
    isCustomDragHandlingSupported = true;
    widgetPositions: Record<number, WidgetPosition> = {};
    dashboardContainer: HTMLElement | undefined;
    private readonly gridMinWidth: number = 0;
    private readonly gridLineWidth: number = 0.5;
    private readonly referenceContainerWidth: number = 0;
    private readonly referenceGridSize: number = 0;
    private gridSize = 0;
    private gridCols = 0;
    private workspace: HTMLElement | undefined;
    private localWorkspaceSize: WorkspaceSize | undefined;
    private dashboard: HTMLElement | undefined;
    private gridCanvas: HTMLCanvasElement | undefined;
    private marginContainer: HTMLElement | undefined;
    private dummyDashboardDiv: HTMLElement | undefined;
    private workspaceMode: TMode = 'view';
    private workspaceHeight: number = 0;
    private workspaceHeights: Record<string, number> = {};
    private readonly scrollSize: number;
    private readonly tabletModeWidth: number;
    private isTabletMode = false;
    private previousGridSize: number = 0;
    private theme: Theme = Theme.light;

    constructor(
        private readonly managerService: ManagerService,
        layoutConfig: LayoutConfig,
        private readonly themeService: ThemeService,
    ) {
        this.gridMinWidth = layoutConfig.minWidth;
        this.gridSize = layoutConfig.gridSize;
        this.referenceGridSize = layoutConfig.gridSize;
        this.referenceContainerWidth = layoutConfig.referenceWidth;
        this.tabletModeWidth = layoutConfig.tabletModeWidth;
        this.scrollSize = this.getScrollbarWidth();

        this.themeService.currentTheme$.subscribe((theme) => {
            this.theme = theme;

            if (this.workspaceMode !== 'view' && this.workspaceMode !== 'manageDatasets') {
                this.drawGrid();
            }
        });
    }

    getScrollbarWidth(): number {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        document.body.appendChild(outer);
        const widthNoScroll = outer.offsetWidth;
        outer.style.overflow = 'scroll';
        const inner = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);
        const widthWithScroll = inner.offsetWidth;
        outer.parentNode?.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    }

    setGridCanvas(gridCanvas: HTMLCanvasElement | undefined): void {
        this.gridCanvas = gridCanvas;
    }

    setDummyDashboardDiv(dummyDashboardDiv: HTMLElement | undefined): void {
        this.dummyDashboardDiv = dummyDashboardDiv;
    }

    setWorkspaceMode(mode: TMode): void {
        this.workspaceMode = mode;
    }

    getWorkspaceMode(): TMode {
        return this.workspaceMode;
    }

    onWindowResize(_?: WorkspaceSize, workspaceResized?: boolean): boolean {
        if (!workspaceResized || !this.dummyDashboardDiv || !this.dashboard || !this.localWorkspaceSize) {
            return true;
        }
        let retainContainerWidth = false;
        if (this.isTabletMode) {
            const dummyDashboardDivWidth: number = this.dummyDashboardDiv.clientWidth;
            this.dashboard.style.width = `${Math.min(dummyDashboardDivWidth, this.tabletModeWidth)}px`;
        } else {
            this.dashboard.style.width = `${this.dummyDashboardDiv.clientWidth}px`;
        }

        let gridAvailWidth: number = this.isTabletMode ?
            this.tabletModeWidth :
            Math.max(this.dashboard.offsetWidth - this.scrollSize, this.gridMinWidth);
        const prevGridSize = this.gridSize;
        this.gridSize = Math.floor(gridAvailWidth / this.gridCols);
        gridAvailWidth = this.gridCols * this.gridSize;
        if (!this.widgetsMinWidthMet(gridAvailWidth, this.gridSize)) {
            this.gridSize = (prevGridSize === this.gridSize) ? this.referenceGridSize : prevGridSize;
            gridAvailWidth = this.gridCols * this.gridSize;
            retainContainerWidth = true;
        }
        if (!this.isTabletMode) {
            this.previousGridSize = this.gridSize;
        }
        this.localWorkspaceSize.width = gridAvailWidth;
        this.recomputeWorkspaceHeight();
        this.updateWorkspaceHeight(gridAvailWidth, retainContainerWidth);
        this.resetMaximizedWidget();
        return true;
    }

    widgetsMinWidthMet(gridAvailWidth: number, gridSize: number): boolean {
        return !this.managerService.getWidgetIdsForWorkspace()
            .some((widgetId) => {
                const element = this.widgetPositions[widgetId];
                if (!element) {
                    return false;
                }
                const newWidgetWidth = (element.endColumn - element.startingColumn) * gridSize;
                return newWidgetWidth < element.minWidth;
            });
    }

    resetMaximizedWidget(): void {
        const widgetStates = this.managerService.getWorkspaceWidgetsState();
        for (const widgetState of widgetStates) {
            if (widgetState.maximized) {
                const dashboardDimension = this.dashboard?.getBoundingClientRect();
                if (widgetState.id && dashboardDimension) {
                    this.managerService.resizeWidget(widgetState.id, dashboardDimension?.width, dashboardDimension?.height);
                }
            }
        }
    }

    resizeWidget(widget: Widget): void {
        const widgetCurrentPosition = this.widgetPositions[widget.id];
        const newWidth: number = (widgetCurrentPosition.endColumn - widgetCurrentPosition.startingColumn) * this.gridSize;
        const newHeight: number = (widgetCurrentPosition.endRow - widgetCurrentPosition.startingRow) * this.gridSize;
        const newLeft: number = widgetCurrentPosition.startingColumn * this.gridSize;
        const newTop: number = widgetCurrentPosition.startingRow * this.gridSize;
        if (!widget.maximized && widgetCurrentPosition) {
            widget.resize(newWidth, newHeight);
            widget.move(newLeft, newTop);
        } else {
            widget.resizeDimensions = { top: newTop, left: newLeft, width: newWidth, height: newHeight };
        }
    }

    isHorzScrollNeeded(gridAvailWidth: number, retainContainerWidth?: boolean): boolean {
        return (gridAvailWidth <= this.gridMinWidth || retainContainerWidth || gridAvailWidth > (this.dummyDashboardDiv?.clientWidth ?? 0));
    }

    updateWorkspaceHeight(gridAvailWidth?: number, retainContainerWidth?: boolean): void {
        if (!this.dashboard) {
            return;
        }

        this.workspace = this.dashboardContainer?.querySelector('.workspace') ?? undefined;
        const updatedAvailWidth = gridAvailWidth ?? this.workspace?.clientWidth ?? 0;
        this.dashboard.style.overflowX = this.isHorzScrollNeeded(updatedAvailWidth, retainContainerWidth) ? 'auto' : 'hidden';
        this.workspaceHeight = this.workspaceHeights[this.dashboard.id];
        if (this.localWorkspaceSize) {
            this.localWorkspaceSize.height = this.workspaceHeight;
        }
        this.managerService.setCurrentWorkspaceSize(updatedAvailWidth, this.workspaceHeight);
        if (this.workspace) {
            this.workspace.style.height = `${this.workspaceHeight}px`;
        }
        if (this.workspaceMode === MODE.EDIT_WORKSPACE) {
            this.drawGrid();
        } else {
            this.clearGrid();
        }
    }

    isWidgetPositionAvailable(widget: Widget): boolean {
        return !!widget.extraParameters?.preferences?.widgetPosition;
    }

    configureWidget(isManagingWidget: boolean, widget: Widget): void {
        this.setDashboard();
        this.initializeWidgetPosition(widget);

        if (!this.localWorkspaceSize) {
            this.configureWorkspaceLayout();
        }
        if (widget.maximized && this.dashboard) {
            this.managerService.resizeWidget(
                widget.id,
                this.dashboard.getBoundingClientRect().width,
                this.dashboard.getBoundingClientRect().height);
        } else {
            if (this.isWidgetPositionAvailable(widget)) {
                this.updateWidgetPosition(widget, false);
                this.updateWidgetDimension(widget);
            } else {
                this.widgetCustomMove(widget, widget.top, widget.left);
                this.widgetCustomResize(widget, this.toNum(widget.width), this.toNum(widget.height));
                this.updateWidgetPosition(widget, true);
            }
        }
        if (this.dashboard) {
            this.workspaceHeights[this.dashboard.id] = Math.max(this.workspaceHeights[this.dashboard.id] || 0, widget.top + widget.height);
        }
        this.onWindowResize(undefined, true);
        this.updateWidgetBehaviour(isManagingWidget, widget.id);
    }

    updateWidgetIcons(isManagingWidget: boolean, widgetId: number): void {
        this.managerService.sendMessageToExistingWidget(widgetId, 'onModeChange');
        this.updateWidgetBehaviour(isManagingWidget, widgetId);
    }

    setDashboard(): void {
        if (!this.dashboard || this.dashboard !== this.dashboardContainer) {
            this.dashboard = this.dashboardContainer;
        }
    }

    configureWorkspaceLayout(): void {
        this.workspace = this.dashboardContainer?.querySelector('.workspace') ?? undefined;
        this.setDashboard();
        if (!this.dashboard) {
            return;
        }

        this.dashboard.scrollTop = 0;
        this.dashboard.id = this.managerService.getCurrentDashboardId() ?? 'CURRENT DASHBOARD ID SHOULD NEVER BE UNDEFINED HERE';
        this.marginContainer = this.dashboardContainer?.querySelector('.margin-container') ?? undefined;

        if (this.marginContainer) {
            this.marginContainer.style.minWidth = `${this.gridMinWidth}px`;
        }

        if (!this.workspaceHeights[this.dashboard.id]) {
            this.workspaceHeights[this.dashboard.id] = this.getDashboardHeight();
        }

        this.localWorkspaceSize = {
            height: this.dashboard.scrollHeight,
            width: Math.max(this.dashboard.offsetWidth - this.scrollSize, this.gridMinWidth),
        };

        this.gridCols = this.referenceContainerWidth / this.referenceGridSize;

        if ((this.marginContainer?.clientWidth ?? Number.MAX_VALUE) <= this.gridMinWidth) {
            this.dashboard.style.overflowX = 'auto';
        }

        this.onWindowResize(undefined, true);
    }

    drawGrid(isModeChange?: boolean): void {
        if (!this.localWorkspaceSize || !this.gridCanvas) {
            return;
        }
        const gridWidth = this.localWorkspaceSize.width;
        const gridHeight = this.localWorkspaceSize.height;
        const context = this.gridCanvas.getContext('2d');
        if (!context) {
            return;
        }

        const numberOfCols = Math.floor(gridWidth / this.gridSize);
        const numberOfRows = Math.floor(gridHeight / this.gridSize);
        if (isModeChange || this.gridCanvas.height !== gridHeight || this.gridCanvas.width !== gridWidth) {
            context.clearRect(0, 0, this.gridCanvas.width, this.gridCanvas.height);
            context.beginPath();
            this.gridCanvas.width = gridWidth;
            this.gridCanvas.height = this.gridSize * numberOfRows;
            for (let x = 0; x <= (numberOfCols * this.gridSize); x += this.gridSize) {
                context.moveTo(0.5 + x, 1);
                context.lineTo(0.5 + x, (numberOfRows * this.gridSize));
            }
            for (let y = 0; y <= (numberOfRows * this.gridSize); y += this.gridSize) {
                context.moveTo(1, 0.5 + y);
                context.lineTo((numberOfCols * this.gridSize), 0.5 + y);
            }
            context.moveTo((numberOfCols * this.gridSize), 0);
            context.lineTo((numberOfCols * this.gridSize), (numberOfRows * this.gridSize));

            context.moveTo(0, (numberOfRows * this.gridSize));
            context.lineTo((numberOfCols * this.gridSize), (numberOfRows * this.gridSize));

            context.lineWidth = this.gridLineWidth;
            context.strokeStyle = this.theme === Theme.dark ? 'rgba(0,112,163,.3)' : '#ccecfc';
            context.stroke();
        }
    }

    clearGrid(): void {
        if (this.gridCanvas) {
            const context = this.gridCanvas.getContext('2d');
            context?.clearRect(0, 0, this.gridCanvas.width, this.gridCanvas.height);
            this.gridCanvas.height = 0;
        }
    }

    onWidgetResizeStart(): void {}

    computeMaxHeightExcludingWidget(widgetId: number): number {
        const widgetIds = this.managerService.getWidgetIdsForWorkspace() || [];
        return widgetIds.filter((wid) => wid !== widgetId)
            .reduce((maxHeight, wid) => {
                const widgetHeight = this.widgetPositions[wid].endRow * this.gridSize;
                return Math.max(maxHeight, widgetHeight);
            }, this.getDashboardHeight());
    }

    getDashboardHeight(): number {
        if (!this.dashboard) {
            return 0;
        }

        const scrollHeight = this.dashboard.offsetWidth < this.gridMinWidth ? this.scrollSize : 0;
        return this.dashboard.getBoundingClientRect().height - scrollHeight;
    }

    onWidgetResize(widget: Widget): void {
        if (!this.dashboard) {
            return;
        }

        this.workspaceHeights[this.dashboard.id] = Math.max(this.computeMaxHeightExcludingWidget(widget.id), (widget.top + widget.height));
        this.updateWorkspaceHeight();
    }

    onWidgetResizeEnd(widget: Widget): void {
        if (!this.dashboard) {
            return;
        }

        this.workspaceHeights[this.dashboard.id] = Math.max(this.computeMaxHeightExcludingWidget(widget.id), (widget.top + widget.height));
        this.widgetCustomResize(widget, this.toNum(widget.width), this.toNum(widget.height));
        this.widgetCustomMove(widget, this.toNum(widget.top), this.toNum(widget.left));
        this.updateWidgetPosition(widget, true);
    }

    onDragStart(): void { }

    onDragOver(widgetRectangle: Rectangle, widgetId: number): void {
        if (!this.dashboard) {
            return;
        }

        this.workspaceHeights[this.dashboard.id] = Math.max(this.computeMaxHeightExcludingWidget(widgetId), (widgetRectangle.bottom));
        this.updateWorkspaceHeight();
    }

    onDragEnd(widget: Widget): void {
        if (!this.dashboard) {
            return;
        }

        this.workspaceHeights[this.dashboard.id] = Math.max(this.computeMaxHeightExcludingWidget(widget.id), (widget.top + widget.height));
        this.widgetCustomMove(widget, this.toNum(widget.top), this.toNum(widget.left));
        this.onWindowResize(undefined, true);
    }

    getClosestWidth(num: number): number {
        const cell: number = this.gridSize;
        let curr: number = Math.round(num / cell);
        if (curr > this.gridCols) {
            curr = this.gridCols;
        }
        curr = (curr * cell);
        return curr;
    }

    getClosestHeight(num: number): number {
        const cell: number = this.gridSize;
        let curr: number = Math.round(num / cell);
        curr = (curr * cell);
        return curr;
    }

    updateWidgetDimension(widget: Widget): void {
        const scaledWidgetPosition = this.getWidgetScaledPosition(this.widgetPositions[widget.id]);
        widget.resize(scaledWidgetPosition.width, scaledWidgetPosition.height);
        widget.move(scaledWidgetPosition.left, scaledWidgetPosition.top);
    }

    widgetCustomResize(widget: Widget, width: number, height: number): void {
        if (!this.gridCanvas) {
            return;
        }

        let newWidth = this.getClosestWidth((width + this.toNum(widget.left))) - this.toNum(widget.left);
        let newHeight = this.getClosestHeight((height + this.toNum(widget.top))) - this.toNum(widget.top);
        const left: number = this.toNum(widget.left);
        const minWidth: number = this.toNum(widget.minWidth);
        const minHeight: number = this.toNum(widget.minHeight);

        const gridAvailWidth: number = (this.gridCols * this.gridSize);

        if ((left + newWidth) > gridAvailWidth) {
            newWidth = Math.abs(gridAvailWidth - left);
        }

        if (newWidth < minWidth) {
            newWidth = minWidth;
        }
        if (newHeight < minHeight) {
            newHeight = minHeight;
        }
        newWidth = this.getClosestWidth(newWidth);
        newHeight = this.getClosestHeight(newHeight);
        if (this.gridCanvas.height > 0 && newHeight > this.gridCanvas.height) {
            newHeight = this.gridCanvas.height;
        }
        widget.resize(newWidth, newHeight);
    }

    widgetCustomMove(widget: Widget, top: number, left: number): void {
        if (!this.localWorkspaceSize || !this.gridCanvas) {
            return;
        }

        let newTop = this.getClosestHeight(top);
        let newLeft = this.getClosestWidth(left);
        const width: number = this.toNum(widget.width);
        const height: number = this.toNum(widget.height);
        const gridAvailWidth: number = (this.gridCols * this.gridSize);
        const gridAvailHeight: number = Math.max(this.localWorkspaceSize.height, this.gridCanvas.height);
        if ((left + width) > gridAvailWidth) {
            newLeft = Math.abs(gridAvailWidth - width);
            newLeft = this.getClosestWidth(left);
        }
        if ((top + height) > gridAvailHeight) {
            newTop = Math.abs(top - this.gridSize);
        }
        widget.move(newLeft, newTop);
        this.updateWidgetPosition(widget, true);
    }

    initializeWidgetPosition(widget: Widget): void {
        const widgetPositionObj: WidgetPosition = this.widgetPositions[widget.id] ?? newWidgetPosition(widget.id);
        this.computeWidgetPosition(widget, widgetPositionObj);
        this.widgetPositions[widget.id] = widgetPositionObj;
    }

    computeWidgetPosition(widget: Widget, widgetPositionObj: WidgetPosition): void {
        widgetPositionObj.startingRow = widget.top / this.gridSize;
        widgetPositionObj.startingColumn = widget.left / this.gridSize;
        widgetPositionObj.endRow = widgetPositionObj.startingRow + widget.height / this.gridSize;
        widgetPositionObj.endColumn = widgetPositionObj.startingColumn + widget.width / this.gridSize;
    }

    updateWidgetPosition(widget: Widget, recompute: boolean): void {
        const widgetPositionObj: WidgetPosition = this.widgetPositions[widget.id] ?? newWidgetPosition(widget.id);
        widgetPositionObj.minWidth = widget.minWidth;
        if (recompute) {
            this.computeWidgetPosition(widget, widgetPositionObj);
        } else {
            widgetPositionObj.startingRow = widget.extraParameters?.preferences?.widgetPosition?.startingRow ?? 0;
            widgetPositionObj.startingColumn = widget.extraParameters?.preferences?.widgetPosition?.startingColumn ?? 0;
            widgetPositionObj.endRow = widget.extraParameters?.preferences?.widgetPosition?.endRow ?? 0;
            widgetPositionObj.endColumn = widget.extraParameters?.preferences?.widgetPosition?.endColumn ?? 0;
        }
        this.widgetPositions[widget.id] = widgetPositionObj;
        // this used to default to {} instead of AppWidgetState which implies that at this line of code the new never happens
        const savedPrefs = this.managerService.getWidgetPreferences(widget.id) ?? new AppWidgetState();
        // AppWidgetState doens't have a widgetPosition property.  so somebody screwed something up
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (savedPrefs as any).widgetPosition = widgetPositionObj;
        this.managerService.setWidgetExtraPreferences(widget.id, savedPrefs);
    }

    updateWidgetTempState(): void {
        const widgetStates = this.managerService.getWorkspaceWidgetsState();
        const maximizedWidgetState = widgetStates.find((ws) => ws.maximized);
        if (maximizedWidgetState?.id) {
            const widgetPosition = this.widgetPositions[maximizedWidgetState.id];
            maximizedWidgetState.width = (widgetPosition.endColumn - widgetPosition.startingColumn) * this.gridSize;
            maximizedWidgetState.height = (widgetPosition.endRow - widgetPosition.startingRow) * this.gridSize;
            maximizedWidgetState.top = widgetPosition.startingRow * this.gridSize;
            maximizedWidgetState.left = widgetPosition.startingColumn * this.gridSize;
            this.managerService.updateWidgetTempState(maximizedWidgetState.id, maximizedWidgetState);
        }
    }

    toggleTabletMode(isTabletMode: boolean): void {
        if (!this.dummyDashboardDiv || !this.dashboard) {
            return;
        }

        this.isTabletMode = isTabletMode;
        const dummyDashboardDivWidth: number = this.dummyDashboardDiv.clientWidth;
        if (this.isTabletMode) {
            this.dashboard.style.width = `${Math.min(dummyDashboardDivWidth, this.tabletModeWidth)}px`;
        } else {
            this.gridSize = this.previousGridSize;
            this.dashboard.style.width = `${dummyDashboardDivWidth}px`;
        }
        this.onWindowResize(undefined, true);
        this.updateWidgetTempState();
    }

    toNum(numString: number | string): number {
        if (typeof numString === 'string') {
            return Number(numString.replace(/[^0-9.]+/g, ''));
        }

        return numString;
    }

    onWidgetClose(widgetId: number): void {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.widgetPositions[widgetId];
        this.recomputeWorkspaceHeight();
        this.onWindowResize(undefined, true);
    }

    recomputeWorkspaceHeight(): void {
        if (!this.dashboard) {
            return;
        }

        const widgetIds = this.managerService.getWidgetIdsForWorkspace() || [];
        this.workspaceHeights[this.dashboard.id] = widgetIds.reduce((maxHeight, wid) => {
            const widgetHeight = this.widgetPositions[wid]?.endRow * this.gridSize || 0;
            return Math.max(maxHeight, widgetHeight);
        }, this.getDashboardHeight());
    }

    getWidgetScaledPosition(widgetPosition: WidgetPosition): { width: number, height: number, top: number, left: number } {
        return {
            width: (widgetPosition.endColumn - widgetPosition.startingColumn) * this.gridSize,
            height: (widgetPosition.endRow - widgetPosition.startingRow) * this.gridSize,
            top: widgetPosition.startingRow * this.gridSize,
            left: widgetPosition.startingColumn * this.gridSize,
        };
    }

    getWidgetPositionById(widgetId: number): WidgetPosition {
        return this.widgetPositions[widgetId];
    }

    // these typeof checks are a bad sign.  at best they are unnecessary at worst
    // they imply that our types are lies
    getWidgetDefaultPosition(widgetPosition?: WidgetPosition): WidgetPosition {
        if (!this.dashboardContainer) {
            return {
                endRow: 0,
                startingRow: 0,
                startingColumn: 0,
                minWidth: 0,
                endColumn: 0,
            };
        }

        const defaultWidgetPosition = { ...DEFAULT_WIDGET_POSITION };
        const widgetWidth = (widgetPosition && typeof widgetPosition.endColumn === 'number'
            && typeof widgetPosition.startingColumn === 'number' ?
            Math.round(widgetPosition.endColumn - widgetPosition.startingColumn) :
            Math.round(defaultWidgetPosition.endColumn - defaultWidgetPosition.startingColumn)) * this.gridSize;
        const widgetHeight = (widgetPosition && typeof widgetPosition.endRow === 'number'
            && typeof widgetPosition.startingRow === 'number' ?
            Math.round(widgetPosition.endRow - widgetPosition.startingRow) :
            Math.round(defaultWidgetPosition.endRow - defaultWidgetPosition.startingRow)) * this.gridSize;

        const startingColumn = Math.round(((this.managerService.getCurrentWorkspaceSize()?.width ?? 0) - widgetWidth) /
            (2 * this.gridSize));
        const startingRow = Math.round((this.dashboardContainer.scrollTop +
            (this.dashboardContainer.getBoundingClientRect().height - widgetHeight) / 2) / this.gridSize);

        return {
            startingColumn,
            endColumn: startingColumn + widgetWidth / this.gridSize,
            startingRow,
            endRow: startingRow + widgetHeight / this.gridSize,
            minWidth: widgetPosition && typeof widgetPosition.minWidth === 'number' ?
                widgetPosition.minWidth :
                defaultWidgetPosition.minWidth,
        };
    }

    private updateWidgetBehaviour(isManagingWidget: boolean, widgetId: number): void {
        if (isManagingWidget) {
            return this.managerService.enableWidgetBehaviours(
                widgetId,
                [WidgetBehaviour.DRAG, WidgetBehaviour.RESIZE, WidgetBehaviour.CASCADE, WidgetBehaviour.HEADER_DOUBLE_CLICK]);
        }

        if (this.getWorkspaceMode() === MODE.EDIT_WORKSPACE) {
            this.managerService.enableWidgetBehaviours(widgetId, [WidgetBehaviour.DRAG, WidgetBehaviour.RESIZE]);
            return this.managerService.disableWidgetBehaviours(widgetId, [WidgetBehaviour.HEADER_DOUBLE_CLICK]);
        }

        this.managerService.enableWidgetBehaviours(widgetId, [WidgetBehaviour.HEADER_DOUBLE_CLICK]);
        this.managerService.disableWidgetBehaviours(widgetId, [WidgetBehaviour.DRAG, WidgetBehaviour.RESIZE]);
    }
}

function newWidgetPosition(id: number): WidgetPosition {
    return {
        id,
        endColumn: 0,
        endRow: 0,
        minWidth: 0,
        startingColumn: 0,
        startingRow: 0,
    };
}

interface Rectangle {
    top: number;
    left: number;
    bottom: number;
    right: number;
}
