import { DashboardDetails, DashboardModel, WorkspaceScrollBehaviour, WorkspaceState } from '@ddv/models';

import {
    setWidgetExtraParamsFromDashboardDetails,
} from './set-widget-extra-params-from-dashboard-details';

export class Workspace {
    id: string = '';
    name: string = '';
    layoutType: string = '';
    workspaceScrollBehaviour: WorkspaceScrollBehaviour | undefined;
    extraParameters: DashboardModel | undefined;
    isRendered = false;
    iconClass?: string;

    constructor(dashboardDetails?: DashboardDetails) {
        if (!dashboardDetails) {
            return;
        }

        this.id = dashboardDetails.id?.toString() ?? '';
        this.name = `${this.id}_${dashboardDetails.name}`;
        this.layoutType = dashboardDetails.layoutType ?? '';
        this.workspaceScrollBehaviour = {
            allowInfiniteHScroll: dashboardDetails.allowInfiniteHScroll ?? false,
            allowInfiniteVScroll: dashboardDetails.allowInfiniteVScroll ?? false,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getExtraParameters(): any {
        return this.extraParameters;
    }

    setExtraParameters(extraParameters: DashboardModel): void {
        this.extraParameters = extraParameters;
    }

    updateExtraParameters(updateFn: (extParams: DashboardModel) => void): void {
        if (this.extraParameters) {
            updateFn(this.extraParameters);
        }
    }

    getWorkspaceState(): WorkspaceState {
        const workSpaceState = new WorkspaceState();
        workSpaceState.id = this.id;
        workSpaceState.name = this.name;
        workSpaceState.layoutType = this.layoutType;
        return workSpaceState;
    }
}

export function createWorkspaceObj(dashboardDetails: DashboardDetails): Workspace {
    const workspace: Workspace = new Workspace(dashboardDetails);
    setWidgetExtraParamsFromDashboardDetails(dashboardDetails, workspace);
    return workspace;
}
