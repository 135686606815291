import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AlertService,
    ConfirmationPopupService,
    ModalDialogService,
    MultiSubscriptionComponent,
    WidgetDeleteAlertComponent,
} from '@ddv/common-components';
import { Entitlements, UserEntitlements, UserEntitlementService } from '@ddv/entitlements';
import {
    CONFIRM_DELETE,
    DdvDate,
    getGlobalSaveDialogOptions,
    GroupedWidgets,
    groupWidgetsByCriteria,
    VisualizationType,
    WidgetSnapshot,
} from '@ddv/models';
import { visualizationDescriptorMap } from '@ddv/visualizations';
import { Observable, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { WidgetsService } from '../../services/widgets.service';
import { WidgetConfigurationManager } from '../../widget.configuration';

export enum Tabs {
    ALL = 'ALL',
    GLOBAL = 'GLOBAL',
    PRIVATE = 'PRIVATE'
}

export enum Groups {
    DATA_SET = 'Dataset',
    DATA_TYPE = 'Datatype',
    GLOBAL = 'Global',
    NO_GROUP = 'No Filter',
    VIZ_TYPE = 'Visualization Type',
    WHATS_NEW = "What's New"
}

@Component({
    selector: 'app-widget-finder',
    templateUrl: './widget-finder.component.html',
    styleUrls: ['./widget-finder.component.scss'],
})
export class WidgetFinderComponent extends MultiSubscriptionComponent implements OnInit {
    @Input() showOnlyGlobal = false;
    @Output() widgetAdded = new EventEmitter<WidgetSnapshot>();
    @ViewChild('searchBox', { static: true }) searchBox: ElementRef | undefined;

    groupedWidgets: GroupedWidgets[] = [];
    searchParam: string = '';
    selectedTab: string | undefined;
    showTags = true;
    loaderInWidgetFinder = true;
    showWidgetDescById = 0;
    toggleWidgetData: boolean[] = [];
    showFiltersDropdown = false;
    hasViewAndEditAll = false;
    protected groupText: string | undefined;
    protected groupsList = Groups;
    protected tabs = Tabs;
    protected showPrivateTab = false;
    protected showDeleteWidgetButton = false;
    private widgetsCollection: WidgetSnapshot[] = [];
    private readonly groupByWhatsNewText = Groups.WHATS_NEW;

    constructor(
        private readonly modalService: ModalDialogService,
        private readonly userEntitlementService: UserEntitlementService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly alertService: AlertService,
        private readonly widgetsService: WidgetsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.selectedTab = this.showOnlyGlobal ? Tabs.GLOBAL : Tabs.ALL;
        this.groupText = Groups.DATA_SET;

        this.fetchWidgets().subscribe(() => this.loaderInWidgetFinder = false);

        this.subscribeTo(this.userEntitlementService.entitlementsForClientCode$, (entitlements: UserEntitlements) => {
            this.hasViewAndEditAll = entitlements?.haveViewAndEditAll;
            this.showDeleteWidgetButton = entitlements?.hasPermission(Entitlements.WIDGET_DELETE);
        });
    }

    updateSelectedGroup(group: string): void {
        if (this.groupText !== group) {
            this.groupText = group;

            this.showFiltersDropdown = false;
            this.filterSearchResults();
        }
    }

    fetchWidgets(): Observable<void> {
        return this.widgetsService.fetchAllWidgets()
            .pipe(
                map((data) => {
                    this.widgetsCollection = data;
                    this.filterSearchResults();
                }),
                tap(() => {
                    this.determinePrivateTabVisibility();
                }),
                catchError((error) => {
                    this.alertService.error(error.statusText);
                    return of(undefined);
                }),
            );
    }

    toggleTags(): void {
        this.showTags = !this.showTags;
    }

    toggleFiltersDropdown(): void {
        this.showFiltersDropdown = !this.showFiltersDropdown;
    }

    onTabChange(selectedTabName: string): void {
        this.selectedTab = selectedTabName;
        this.filterSearchResults();
    }

    addWidget(widget: WidgetSnapshot): void {
        if (this.showOnlyGlobal) {
            const confirmDialogOptions = getGlobalSaveDialogOptions();
            this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
                next: (action) => {
                    if (action === 'confirm') {
                        this.widgetAdded.emit(widget);
                    }
                },
            });
        } else {
            this.widgetAdded.emit(widget);
        }
    }

    removeWidget(widgetId: number, groupedWidgetsIndex: number, widgetIndexInGroup: number): void {
        const confirmDialogOptions = {
            message: CONFIRM_DELETE,
            confirmButtonText: 'Delete',
            denyButtonText: 'Cancel',
        };
        this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.onWidgetRemoval(widgetId, groupedWidgetsIndex, widgetIndexInGroup);
                }
            },
        });
    }

    toggleWidgetDescription(widgetId: number): void {
        this.showWidgetDescById = (this.showWidgetDescById !== widgetId) ? widgetId : -1;
    }

    toggleWidgetsData(index: number): void {
        this.toggleWidgetData[index] = !this.toggleWidgetData[index];
    }

    filterSearchResults(): void {
        this.formatResults(this.widgetsCollection);

        if (this.searchParam?.trim()) {
            const normalizedSearchTerm = this.searchParam.toLowerCase();
            this.groupedWidgets = this.groupedWidgets.filter((widgetGroup) => {
                widgetGroup.widgets = widgetGroup.widgets.filter((widget) => {
                    return widget.name.toLowerCase().includes(normalizedSearchTerm) ||
                        widget.widgetTags.some((tag) => tag.name.toLowerCase().includes(normalizedSearchTerm));
                });

                if (widgetGroup.widgets.length) {
                    return widgetGroup;
                }

                return;
            });
        }
    }

    private determinePrivateTabVisibility(): void {
        if (!this.showOnlyGlobal && this.filterPrivate(this.widgetsCollection).length) {
            this.showPrivateTab = true;
        } else {
            this.showPrivateTab = false;
        }
    }

    private getWidgetsByTab(widgets: WidgetSnapshot[]): WidgetSnapshot[] {
        switch (this.selectedTab) {
            case Tabs.ALL:
                return widgets;
            case Tabs.PRIVATE:
                return this.filterPrivate(widgets);
            case Tabs.GLOBAL:
                return this.filterGlobal(widgets);
        }

        return [];
    }

    private filterBy(data: WidgetSnapshot[]): GroupedWidgets[] {
        switch (this.groupText) {
            case Groups.DATA_SET:
                return this.groupByDataset(data);
            case Groups.DATA_TYPE:
                return this.groupByDatatype(data);
            case Groups.GLOBAL:
                return this.groupByDatatype(this.filterGlobal(data));
            case Groups.NO_GROUP:
                return [{ groupName: 'No Filter', widgets: data, totalWidgets: data.length }];
            case Groups.VIZ_TYPE:
                return this.groupByVisualizationType(data);
            case Groups.WHATS_NEW:
                return this.groupByDate(data);
        }

        return [];
    }

    private groupByDataset(widgets: WidgetSnapshot[]): GroupedWidgets[] {
        const groupedByDataset = groupWidgetsByCriteria(widgets, 'datasetName');
        return this.groupWidgets(groupedByDataset);
    }

    private groupByDatatype(widgets: WidgetSnapshot[]): GroupedWidgets[] {
        const groupedByDatatype = groupWidgetsByCriteria(widgets, 'dataType');
        return this.groupWidgets(groupedByDatatype);
    }

    private groupByDate(widgets: WidgetSnapshot[]): GroupedWidgets[] {
        const groupedByDate = groupWidgetsByCriteria(widgets, 'createdDate');
        return this.groupWidgets(groupedByDate);
    }

    private groupWidgets(widgets: { [key: string]: WidgetSnapshot[] }): GroupedWidgets[] {
        return Object.entries(widgets).map((group) => {
            return { groupName: group[0], widgets: group[1], totalWidgets: group[1].length };
        });
    }

    private groupByVisualizationType(widgets: WidgetSnapshot[]): GroupedWidgets[] {
        const groupedByCoreWidgetType = groupWidgetsByCriteria(widgets, 'coreWidgetType');
        const groupedWidgets: GroupedWidgets[] = [];
        Object.entries(groupedByCoreWidgetType).forEach((group) => {
            if (group[0] !== 'VISUALIZATION') {
                groupedWidgets.push({ groupName: group[0], widgets: group[1], totalWidgets: group[1].length });
            } else {
                const visualizationWidgets = groupWidgetsByCriteria(group[1], 'visualizations');
                Object.entries(visualizationWidgets).forEach((vizGroup) => {
                    groupedWidgets.push({
                        groupName: vizGroup[0],
                        widgets: vizGroup[1],
                        totalWidgets: vizGroup[1].length,
                    });
                });
            }
        });
        return groupedWidgets;
    }

    private filterPrivate(widgets: WidgetSnapshot[]): WidgetSnapshot[] {
        return widgets.filter((widget) => !widget.isShared);
    }

    private filterGlobal(widgets: WidgetSnapshot[]): WidgetSnapshot[] {
        return widgets.filter((widget) => widget.isGlobal);
    }

    private formatResults(data: WidgetSnapshot[]): void {
        const widgets = this.getWidgetsByTab(data);
        const filteredWidgets = this.filterBy(widgets);

        filteredWidgets.forEach((widgetGroup, index) => {
            this.toggleWidgetData[index] = true;
            widgetGroup.widgets.forEach((widget) => {
                if (widget.coreWidgetType === 'VISUALIZATION') {
                    widget.vizForms = widget.visualizations.map((viz) => {
                        const vizConfig = visualizationDescriptorMap[viz];
                        return {
                            label: vizConfig.name,
                            cssClass: vizConfig.cssClass,
                            value: vizConfig.id,
                            uid: viz as VisualizationType,
                        };
                    });
                } else {
                    const widgetConfig = WidgetConfigurationManager.getWidgetByUID(widget.coreWidgetType)!;
                    widget.vizForms = [
                        {
                            label: widgetConfig.title,
                            cssClass: widgetConfig.cssClass!,
                            value: 'summaryWidget',
                            uid: widgetConfig.uid as VisualizationType,
                        },
                    ];
                }
            });

            if (this.groupText !== this.groupByWhatsNewText) {
                widgetGroup.widgets.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            } else {
                widgetGroup.widgets.sort((a, b) =>
                    DdvDate.fromISOFormat(a.createdDate).isBefore(DdvDate.fromISOFormat(b.createdDate)) ? 1 : -1);
            }
        });

        if (this.groupText !== this.groupByWhatsNewText) {
            filteredWidgets.sort((a, b) => a.groupName.toLowerCase().localeCompare(b.groupName.toLowerCase()));
        } else {
            filteredWidgets.sort((a, b) =>
                DdvDate.fromISOFormat(a.widgets[0].createdDate).isBefore(DdvDate.fromISOFormat(b.widgets[0].createdDate)) ? 1 : -1);
        }

        this.groupedWidgets = filteredWidgets;
    }

    private onWidgetRemoval(widgetId: number, groupedWidgetsIndex: number, widgetIndexInGroup: number): void {
        this.widgetsService.removeWidget(widgetId).subscribe({
            next: () => {
                this.groupedWidgets[groupedWidgetsIndex].widgets.splice(widgetIndexInGroup, 1);
                const widgetIndex = this.widgetsCollection.findIndex((widget) => widget.id === widgetId);
                this.widgetsCollection.splice(widgetIndex, 1);
            },
            error: (error) => {
                if (error.statusCode === 403 && error.body && Object.prototype.hasOwnProperty.call(error.body, 'dashboards')) {
                    const { clientFacingMessage, dashboards } = error.body;
                    const widgetDeleteAlert = this.modalService.open(
                        WidgetDeleteAlertComponent,
                        { windowClass: 'widget-delete-alert' });
                    widgetDeleteAlert.componentInstance.message = clientFacingMessage;
                    widgetDeleteAlert.componentInstance.dashboards = dashboards;
                }
            },
        });
    }
}
