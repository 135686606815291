import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiServices, SharedApiExecutorService } from '@ddv/http';
import { Service, UsageTrackingToken } from '@ddv/models';
import { Observable } from 'rxjs';

export class UsageTracker implements UsageTrackingToken {
    public outputInfo?: { [key: string]: unknown };
    public endTime: Date;
    public error: unknown | undefined;

    constructor(
        public readonly service: UsageTrackingService,
        public readonly taskName: string,
        public readonly clientCode: string,
        public inputInfo?: { [key: string]: unknown },
        public readonly startTime: Date = new Date(),
    ) {
        this.endTime = startTime;
    }

    succeeded(outputInfo?: { [key: string]: unknown }): void {
        this.outputInfo = outputInfo;
        this.endTime = new Date();

        this.service.publishAsSuccess(this).subscribe();
    }

    failed(error?: unknown): void {
        this.error = error;
        this.endTime = new Date();

        this.service.publishAsError(this).subscribe();
    }

    idForRequest(requestId: string): void {
        if (!this.inputInfo) {
            this.inputInfo = {};
        }

        this.inputInfo.requestId = requestId;
    }
}

@Injectable()
export class UsageTrackingService implements Service {
    private clientCode: string = '';

    constructor(
        @Inject(ApiServices.usage) private readonly usageApiService: SharedApiExecutorService,
        private readonly currentStateService: CurrentStateService,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    startTracking(taskName: string, inputInfo?: { [key: string]: unknown }): UsageTracker {
        return new UsageTracker(this, taskName, this.clientCode, inputInfo);
    }

    publishAsSuccess(usage: UsageTrackingToken): Observable<void> {
        return this.usageApiService.invokeServiceWithBody('/success', 'POST', this.toAmberFormat(usage));
    }

    publishAsError(usage: UsageTrackingToken): Observable<void> {
        return this.usageApiService.invokeServiceWithBody('/failure', 'POST', this.toAmberFormat(usage));
    }

    private toAmberFormat(usage: UsageTrackingToken): { [key: string]: unknown } {
        return {
            client: usage.clientCode,
            task: usage.taskName,
            input_info: usage.inputInfo ?? {},
            start_time: usage.startTime.toISOString(),
            end_time: usage.endTime.toISOString(),
            output_info: usage.outputInfo ?? {},
            error: usage.error || null,
        };
    }
}
