<div class="update-section bottom-section-break" *ngIf="!inFilterOnlyMode">
    <div class="disabled left-side">
        <span class="icon-settings-refresh disabled red-color"></span>

        <div class="messages">
            <div class="red-color"> Updates Available</div>

            <div class="update-time">Last Update: {{ dataLoadTime }}</div>
        </div>
    </div>

    <cc-option-toggle
        id="auto-refresh"
        [ngClass]="{'disabled-toggle': manualUpdates}"
        [disabled]="manualUpdates"
        [(value)]="manualUpdates"
        [labels]="['Manual', 'Auto']">
    </cc-option-toggle>
</div>

<div class="bottom-section-break" *ngIf="!inFilterOnlyMode">
    <h4 class="display-name">Display Name
        <span>(40 characters maximum)</span>
    </h4>

    <div *ngIf="effectiveState">
        <div class="radio-btn">
            <input
                id="display_as_auto"
                name="displayNameType"
                type="radio"
                value="AUTO"
                [ngModel]="effectiveState.displayNameType"
                (ngModelChange)="this.applyDisplayNameType($event)"/>

            <label for="display_as_auto">Auto</label>
        </div>

        <div class="radio-btn">
            <input
                id="display_as_configured"
                name="displayNameType"
                type="radio"
                value="CONFIGURED"
                [ngModel]="effectiveState.displayNameType"
                (ngModelChange)="this.applyDisplayNameType($event)"/>

            <label for="display_as_configured">As Configured</label>
        </div>

        <div class="radio-btn">
            <input
                id="display_as_custom"
                name="displayNameType"
                type="radio"
                value="CUSTOM"
                [ngModel]="effectiveState.displayNameType"
                (ngModelChange)="this.applyDisplayNameType($event)"/>

            <label for="display_as_custom">Custom</label>

            <input
                *ngIf="effectiveState.displayNameType === 'CUSTOM'"
                id="widget-display-name"
                type="text"
                class="formControl inline"
                placeholder="Display Name"
                maxlength="40"
                autocomplete="off"
                [ngModel]="effectiveState.customName"
                (ngModelChange)="this.applyCustomName($event)"/>
        </div>
    </div>
</div>

<div *ngIf="effectiveState" class="bottom-section-break">
    <h4 class="display-name" *ngIf="!inFilterOnlyMode">Filters</h4>

    <div class="toggle-row">
        <label class="label-text curPoint" [ngClass]="{'disabled': inFilterOnlyMode || !effectiveState.subscribedToDashboardFilters}">
            Master Widget
        </label>

        <cc-option-toggle
            id="master-widget"
            name="displayNameType"
            [disabled]="inFilterOnlyMode || !effectiveState.subscribedToDashboardFilters"
            [ngClass]="{'disabled-toggle': inFilterOnlyMode || !effectiveState.subscribedToDashboardFilters}"
            [(value)]="effectiveState.masterWidget"
            [labels]="['On', 'Off']"
            (valueChange)="applyMasterWidget($event)">
        </cc-option-toggle>
    </div>

    <div class="toggle-row">
        <label [ngClass]="{'disabled': effectiveState.masterWidget || isCompareModeToggled}">View Filters</label>

        <cc-option-toggle
            id="dashboard-filters"
            name="subscribedToBoardFilters"
            [disabled]="effectiveState.masterWidget || isMultiClient || isCompareModeToggled"
            [ngClass]="{'disabled-toggle': effectiveState.masterWidget || isMultiClient || isCompareModeToggled}"
            [(value)]="effectiveState.subscribedToDashboardFilters"
            [labels]="['Subscribed', 'Unsubscribed']"
            (valueChange)="applyIsSubscribedToDashboardFilters($event)">
        </cc-option-toggle>
    </div>

    <div class="toggle-row" *ngIf="isViewMode && isCompareModeOptionEnabled">
        <label [ngClass]="{'disabled': isSubscribedOrUnsubscribedToggled || isStackedQuery}">Compare Mode</label>

        <cc-option-toggle
            id="compare-mode"
            name="compareMode"
            [disabled]="isSubscribedOrUnsubscribedToggled || isStackedQuery"
            [ngClass]="{'disabled-toggle': isSubscribedOrUnsubscribedToggled || isStackedQuery}"
            [(value)]="isCompareModeChecked"
            [labels]="['On', 'Off']"
            (valueChange)="applyCompareMode($event)">
        </cc-option-toggle>
    </div>

    <div class="datepickerdialog" *ngIf="showDatePicker && isCompareModeOptionEnabled">
        <cc-date-select-dropdown
            *ngIf="!isDateRangeSupported"
            [disabled]="false"
            [format]="'us'"
            [fuzzies]="fuzzyDates"
            [anchorType]="'simple'"
            [allowNoSelection]="true"
            [value]="$any(compareDates)"
            (valueChange)="applyPickerSelectedDates($any($event))">
        </cc-date-select-dropdown>

        <cc-date-range-select-dropdown
            *ngIf="isDateRangeSupported"
            [anchorType]="'simple'"
            [label]="['compare from - to date']"
            [disabled]="false"
            [format]="'us'"
            [fuzzies]="fuzzyDates"
            [dateRange]="$any(compareDates)"
            (dateRangeChange)="applyPickerSelectedDates($event)">
        </cc-date-range-select-dropdown>
    </div>

    <div class="toggle-row" *ngIf="hasRealtimeUpdateOption">
        <label>Polling</label>

        <cc-option-toggle
            id="polling"
            name="realtimeUpdates"
            [(value)]="effectiveState.realtimeUpdates"
            [labels]="['On', 'Off']"
            (valueChange)="applyRealTimeUpdates($event)">
        </cc-option-toggle>
    </div>
</div>

<div *ngIf="widgetState?.configuredVisualizationTypes?.length && !inFilterOnlyMode" class="bottom-section-break">
    <h4 class="display-name">Visualization Defaults</h4>

    <div class="toggle-row dropdown-row">
        <span
            *ngIf="visualizationIconClass !== 'icon-stacked-area-chart'"
            class="{{visualizationIconClass}}">
        </span>

        <ddv-stacked-area-chart-icon
            *ngIf="visualizationIconClass === 'icon-stacked-area-chart'"
            class="{{visualizationIconClass}}">
        </ddv-stacked-area-chart-icon>

        <label class="label-text">Initial Visualization</label>

        <app-dropdown
            id="initial-visualization"
            [options]="visualizationOptions"
            [(selected)]="initialVisualization"
            (selectedChange)="applyDefaultVisualizationType($event.value)">
        </app-dropdown>
    </div>

    <div *ngIf="slicerOptions?.length" class="toggle-row dropdown-row">
        <span class="icon-search1"></span>

        <label class="label-text">Initial Slicer</label>

        <app-dropdown
            id="default-slicer"
            [options]="slicerOptions"
            [(selected)]="initialSlicer"
            (selectedChange)="applyDefaultSlicerForDefaultVisualization($event.value)">
        </app-dropdown>
    </div>

    <ng-container *ngIf="sortOptions?.length">
        <div class="toggle-row dropdown-row">
            <span class="icon-search1"></span>

            <label class="label-text">Sort On</label>

            <app-dropdown
                id="sort-order-based-on"
                [options]="sortOptions"
                [(selected)]="sortOn"
                (selectedChange)="applyDefaultSortOnForDefaultVisualization($event.value)">
            </app-dropdown>
        </div>

        <div class="toggle-row dropdown-row">
            <span class="icon-search1"></span>

            <label class="label-text curPoint">Sort Direction</label>

            <app-dropdown
                id="sort-table-ascending-filters"
                [options]="sortDirectionOptions"
                [(selected)]="sortDirection"
                (selectedChange)="applyDefaultSortDirectionForDefaultVisualization($event.value)">
            </app-dropdown>
        </div>
    </ng-container>
</div>

<div class="save-cancel">
    <div *ngIf="allowJumpToManageWidgets && !inFilterOnlyMode" class="manage-widgets-link" (click)="manageWidgetClicked.emit()">
        <span class="icon-editcw"></span>

        <label>Edit Base Widget</label>
    </div>

    <div class="link-row" *ngIf="isCrosstalkGrid && canManageCrosstalk" [ngClass]="{'edit-mode': !inFilterOnlyMode}">
        <a href="{{crosstalkManagementPageURL}}" target="_blank">Crosstalk Management</a>
    </div>

    <button class="empty-btn" type="button" (click)="modalDialogActive.close('cancel')">
        Cancel
    </button>

    <button class="fill-btn" type="button" (click)="saveClicked.emit(this.overrides)">
        {{ inFilterOnlyMode ? 'apply' : 'save' }}
    </button>
</div>
