import { Pipe, PipeTransform } from '@angular/core';

import { DashboardGroup } from '../../../models/dashboard-group';

@Pipe({ name: 'dashboardFamilyFilter' })
export class DashboardFamilyFilter implements PipeTransform {
    transform(array: DashboardGroup[], txt?: string): DashboardGroup[] {
        return !txt ? array : array.filter((pair) => {
            return (pair.name || 'None').toLocaleLowerCase().indexOf(txt.toLocaleLowerCase()) !== -1;
        });
    }
}
