import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentStateService, DirtyFlagService } from '@ddv/behaviors';
import { ConfirmationPopupService, ModalDialogRef, MultiSubscriptionComponent } from '@ddv/common-components';
import { appWidgetStateToConfig, DashBoardComponent, DashboardService, DdvWidgetService, SaveDashboard } from '@ddv/dashboards';
import { CompareModeService, DatasetManagerService } from '@ddv/datasets';
import { Entitlements, Roles, UserEntitlementService, UserService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import {
    AppLayoutState,
    createWorkspaceObj,
    LayoutService,
    ManagerService,
    setWidgetExtraParamsFromDashboardDetails,
    TopLevelWorkspaceComponent,
    Workspace,
} from '@ddv/layout';
import {
    AppWidgetState,
    CompareMode,
    DashboardDetails,
    DashboardModel,
    DashboardSnapshot,
    DashboardPreference,
    DatasetDefinitionDetails,
    TDeviceMode,
    MANAGE_WIDGET_WS_ID,
    MODE,
    QueryPeriodTypeName,
    WIDGET_LIFECYCLE_EVENT,
    getGlobalSaveDialogOptions,
    GLOBAL_SAVE_MESSAGE,
    GLOBAL_SAVE_NOTE,
} from '@ddv/models';
import { deepClone } from '@ddv/utils';
import { forkJoin, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { DashboardGroup } from '../../models/dashboard-group';
import { DashboardGroupsService } from '../../services/dashboard-groups.service';
import { DdvScreenDataCaptureService } from '../../services/ddv-screendata-capture.service';
import { RevertDashboardService } from '../../services/revert-dashboard.service';
import { DashboardNavComponent } from '../dashboard-nav/dashboard-nav.component';
import { HsDashboardComponent } from '../hs-dashboard/hs-dashboard.component';

@Component({
    templateUrl: 'view-edit-dashboard.component.html',
    styleUrls: ['view-edit-dashboard.component.scss'],
})
export class ViewEditDashboardComponent extends MultiSubscriptionComponent implements OnInit, OnDestroy, TopLevelWorkspaceComponent {
    @ViewChild('navComponent', { static: true }) dashboardNavComponent: DashboardNavComponent | undefined;
    @ViewChild('gridcanvas', { static: true }) gridcanvas: ElementRef | undefined;
    @ViewChild('dashboardContainer', { static: true }) dashboardContainer: ElementRef | undefined;
    @ViewChild('dummyDashboardDiv', { static: true }) dummyDashboardDiv: ElementRef | undefined;
    @ViewChild('saveGlobalTemplate') saveGlobalTemplate: TemplateRef<string> | undefined;

    inPresentationMode = true;
    isDashboardLoading = true;
    isGlobal = false;
    saveGlobalMessage = GLOBAL_SAVE_MESSAGE;
    saveGlobalNote = GLOBAL_SAVE_NOTE;
    currentDashboardId: string | undefined;
    currentDashboard: Workspace | undefined;
    dashboardGroups: DashboardGroup[] | undefined;
    dashboardSnapshots: DashboardSnapshot[] = [];
    currentActiveDate: string | null | undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private userEntitlements: any | undefined;
    private leftEditDirty = false;
    private subscription: Subscription | undefined;
    private dashboardPref: DashboardPreference | undefined;
    private isGlobalEditPartial = false;

    @ViewChild('hsDashboard', { static: true }) private readonly hsDashboardRef: HsDashboardComponent | undefined;
    @ViewChild('dashboard', { static: true }) private readonly dashboardComponent: DashBoardComponent | undefined;

    constructor(
        private readonly manager: ManagerService,
        private readonly dashboardService: DashboardService,
        private readonly layoutService: LayoutService,
        private readonly dirtyFlagService: DirtyFlagService,
        private readonly currentStateService: CurrentStateService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly datasetManagerService: DatasetManagerService,
        private readonly userEntitlementService: UserEntitlementService,
        private readonly cdr: ChangeDetectorRef,
        private readonly dashboardGroupsService: DashboardGroupsService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly queryParamsService: QueryParamsService,
        private readonly ddvScreendataCapture: DdvScreenDataCaptureService,
        private readonly ddvWidgetService: DdvWidgetService,
        private readonly compareModeService: CompareModeService,
        private readonly revertDashboardService: RevertDashboardService,
    ) {
        super();
    }

    private static isDirtyOnWorkspaceChange(isWorkspaceRendered: boolean, eventName: WIDGET_LIFECYCLE_EVENT): boolean {
        const nonDirtyingEvents = [
            WIDGET_LIFECYCLE_EVENT.INIT_WIDGET,
            WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE,
            WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE,
            WIDGET_LIFECYCLE_EVENT.AFTER_WORKSPACE_RESIZE,
            WIDGET_LIFECYCLE_EVENT.ON_CLOSE,
        ];
        return isWorkspaceRendered && !nonDirtyingEvents.includes(eventName);
    }

    override ngOnDestroy(): void {
        // this could be handled better in the route guard as you could check if you leaving to head to MW and purge
        // all workspaces on that condition
        this.handleDetailWidgetsOnBoard();
        this.manager.removeWorkspace();

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    ngOnInit(): void {
        this.layoutService.setTopLevelWorkspaceComponent(this);
        this.layoutService.initializeLayoutManager();
        this.manager.registerWorkspaceStateChangeHandler(this.onWorkspaceChangeEvent.bind(this));

        // all of this reference data is needed before continuing, though some of it isn't needed by this component directly
        // but this component won't work right if you don't make sure its loaded.  this is an obvious smell
        forkJoin([
            this.userEntitlementService.entitlementsForClientCode$.pipe(take(1)),
            this.dashboardService.dashboardSnapshots$.pipe(take(1)),
        ]).subscribe(([userEntitlements, dashboardSnapshots]) => {
            if (userEntitlements.hasNoPermissions()) {
                throw new Error('You are not entitled to anything');
            }

            this.userEntitlements = userEntitlements;
            this.isGlobalEditPartial = this.userEntitlements.haveGlobalEditPartial;

            this.dashboardSnapshots = dashboardSnapshots;

            this.getDashboardGroups(true);
        });

        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (dashboardPref: DashboardPreference) => {
            this.dashboardPref = dashboardPref;
        });
    }

    guardAgainstUnsavedChanges(): boolean | Observable<boolean> {
        if (this.inPresentationMode || !this.dirtyFlagService.isDirty) {
            return true;
        }

        const canContinue = new Subject<boolean>();
        const confirmDialogOptions = {
            message: 'You have un-saved changes. What would you like to do:',
            confirmButtonText: 'Continue Editing',
            denyButtonText: 'Discard Changes',
        };

        this.confirmationService.showConfirmationPopup(confirmDialogOptions, true).subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.continueEditingCallback(canContinue);
                } else if (action === 'deny') {
                    this.discardChangesCallback(canContinue);
                }
            },
        });

        return canContinue;
    }

    async onDashboardSelectedInLeftNav(dashboardId: string): Promise<void> {
        await this.navigateToBoardAndMode(dashboardId, this.presentationModeAsString);
    }

    createLastStateForDashboard(dashboardId: string): Observable<void | string> {
        if (this.dashboardPref?.isPreferenceChangedImplicitly) {
            return this.dashboardService.saveDashboardLastState(this.dashboardPref, dashboardId)
                .pipe(map(() => {
                    if (this.dashboardPref) {
                        this.dashboardPref.isPreferenceChangedImplicitly = false;
                        this.queryParamsService.dispatchUpdatedQueryParams(this.dashboardPref);
                    }
                }));
        } else {
            return this.userService.openDashboard(dashboardId);
        }
    }

    async onCancelEdit(): Promise<void> {
        this.compareModeService.disableCompareMode();
        await this.navigateToBoardAndMode(this.currentDashboardId, MODE.VIEW);
    }

    onRevertChanges(): void {
        this.manager.removeWorkspace();
        this.manager.selectWidget(false, undefined);
        this.activateDashboardById(this.currentDashboardId!);
        this.dirtyFlagService.exitDirtyState();
    }

    saveWidgetChangesToServer(queryParams: DashboardPreference): void {
        this.saveWorkspaceConfigurationToCurrentDashboard(queryParams);
    }

    onSaveWidgetChangesToServer(queryParams: DashboardPreference): void {
        const dashboardExtraParams = this.manager.getWorkspace()?.getExtraParameters();

        if (dashboardExtraParams.isGlobal && !this.isGlobalEditPartial) {
            const confirmDialogOptions = getGlobalSaveDialogOptions(this.saveGlobalTemplate);
            this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
                next: (action) => {
                    if (action === 'confirm') {
                        this.saveWorkspaceConfigurationToCurrentDashboard(queryParams);
                    }
                },
            });
        } else {
            this.saveWorkspaceConfigurationToCurrentDashboard(queryParams);
        }
    }

    saveWorkspaceConfigurationToCurrentDashboard(queryParams: DashboardPreference): void {
        this.isDashboardLoading = true;

        if (queryParams.isComparing) {
            queryParams.isComparing = false;
            queryParams.comparing = CompareMode.ORIGINAL;
            queryParams.compareDates = undefined;
        }
        const widgetDatasets: DatasetDefinitionDetails[] = this.manager.getExtraParametersForWorkspace()?.widgets
            .map((w: AppWidgetState) => w.datasetDefinition) || [];
        const isCalendarVisible = !widgetDatasets.length ||
            widgetDatasets.some((dsd) => dsd.queryPeriodType?.name !== QueryPeriodTypeName.NO_CALENDAR);
        if (!isCalendarVisible) {
            queryParams.startDate = '';
            queryParams.endDate = '';
        }

        queryParams.filters?.forEach((filter) => {
            filter.filterValuesType = this.getFilterValuesType(filter.values ?? []);
        });

        const savePayload: SaveDashboard = new SaveDashboard(
            this.currentDashboardId!,
            this.manager.getWorkspace()?.getExtraParameters().deviceMode,
            queryParams,
            this.manager.getAppWidgetsState());

        this.dashboardService.saveDashboard(savePayload).subscribe(
            async (dashboard: DashboardModel) => {
                // there's always only one preference in dashboardPreferences on saveDashboard
                this.currentActiveDate = dashboard.dashboardPreferences[0]?.activeDate;

                this.dirtyFlagService.exitDirtyState();

                dashboard.dashboardPreferences?.[0].filters?.forEach((filter) => {
                    if (filter.filterValuesType === 'number') {
                        filter.values = filter.values?.map((value) => Number(value));
                    }

                    if (filter.filterValuesType === 'boolean') {
                        filter.values = filter.values?.map((value) => {
                            if (value != null) {
                                return value === 'true';
                            } else {
                                return value;
                            }
                        });
                    }
                });

                const savedParams = dashboard.dashboardPreferences.find((preference) => {
                    return preference.dashboardPreferenceMode === MODE.EDIT_WORKSPACE;
                });

                const updatedDashboard = this.manager.getWorkspace();

                dashboard.widgets = updatedDashboard?.extraParameters?.widgets ?? [];

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.manager.updateWidgetsVersion(dashboard.widgets as any);

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setWidgetExtraParamsFromDashboardDetails(dashboard as any, updatedDashboard!);
                this.updateDashboardQueryParams(savedParams!);
                this.updateWidgetExtraParams(updatedDashboard?.getExtraParameters().widgets);
                await this.navigateToBoardAndMode(this.currentDashboardId, 'view');
            },
            (error) => { this.onDashboardSaveError(error); });
    }

    onWorkspaceChangeEvent(appLayoutState: AppLayoutState, isWorkspaceRendered: boolean, eventName: WIDGET_LIFECYCLE_EVENT): void {
        if (eventName === WIDGET_LIFECYCLE_EVENT.INIT_WIDGET && isWorkspaceRendered) {
            // Should only execute once, after widgets are rendered
            this.layoutService.updateWidgetBehaviour(false);
            this.hsDashboardRef?.setHighlightVisibility(!this.inPresentationMode);
        }

        if (!this.inPresentationMode && ViewEditDashboardComponent.isDirtyOnWorkspaceChange(isWorkspaceRendered, eventName)) {
            this.dirtyFlagService.enterDirtyState(this.manager.getCurrentDashboardId() ?? '');
        }

        this.cdr.detectChanges();
    }

    private getDashboardGroups(initWorkspace = false): void {
        this.subscribeTo(this.dashboardGroupsService.getDashboardGroups(), (dashboardGroups: DashboardGroup[]) => {
            this.dashboardGroups = dashboardGroups.length ? dashboardGroups : [];

            if (initWorkspace) {
                this.initializeWorkspace();
            }
        });
    }

    private initializeWorkspace(): void {
        if (!this.manager.getCurrentDashboardId() || this.manager.getCurrentDashboardId() === MANAGE_WIDGET_WS_ID) {
            this.manager.removeAllWidgets();
            this.manager.removeWorkspace();
            this.layoutService.setTopLevelWorkspaceComponent(this);
            this.layoutService.initializeLayoutManager();
            this.manager.registerWorkspaceStateChangeHandler(this.onWorkspaceChangeEvent.bind(this));
        }

        if (this.hsDashboardRef) {
            this.hsDashboardRef.isLastDashboardClosed = true;
        }

        this.subscription = combineLatest([this.currentStateService.dashboardModeAndId$, this.dashboardService.dashboardSnapshots$])
            .subscribe(async ([{ id, mode }, dashboardSnapshots]): Promise<void> => {
                // in practice, this will only ever happen in tests
                if (!id && !mode) {
                    return;
                }

                if (!id && !dashboardSnapshots) {
                    await this.goToNoDashboard();
                    return;
                }

                this.dashboardSnapshots = dashboardSnapshots;

                if (!id && this.dashboardSnapshots.length) {
                    await this.navigateToBoardAndMode(this.dashboardSnapshots[0].id, 'view');
                    return;
                }

                if (id && id.toString() === this.currentDashboardId) {
                    const dashboard = this.dashboardService.getDashboardSnapshotById(id.toString());
                    if (mode === 'edit' &&
                       ((dashboard && dashboard.privilege === 'VIEW') || this.hasNoPermissionToEnterEditMode(dashboard))
                    ) {
                        await this.navigateToBoardAndMode(id, 'view');
                        return;
                    }
                }
                await this.respondToRouteChange(id ?? undefined, mode ?? undefined);
            });
    }

    private async respondToRouteChange(dashboardId: number | string | undefined, viewMode: string | undefined): Promise<void> {
        const newDashboardId = dashboardId?.toString();
        const selectedDashboardHasChanged = newDashboardId !== this.currentDashboardId;

        this.currentDashboardId = newDashboardId;

        this.compareModeService.disableCompareMode();

        if (!this.currentDashboardId) {
            if (viewMode === 'edit') {
                this.inPresentationMode = false;
            }
            return this.goToDefaultDashboard();
        }

        if (viewMode === MODE.CLOSE) {
            const dashboard = this.dashboardService.getDashboardSnapshotById(`${dashboardId}`);
            if (dashboard) {
                this.dashboardService.closeDashboard(dashboard)
                    .subscribe(async () => {
                        this.manager.removeWorkspace();
                        this.currentDashboard = undefined;

                        if (this.dashboardService.noOpenDashboards()) {
                            await this.goToNoDashboard();
                        } else {
                            await this.goToDefaultDashboard();
                        }
                    });
            }
            return;
        }

        const newModeIsPresentation = viewMode === MODE.VIEW;
        const modeHasChanged = newModeIsPresentation !== this.inPresentationMode;
        const transitionIsFromEditToView = modeHasChanged && !this.inPresentationMode && viewMode;
        this.inPresentationMode = newModeIsPresentation;

        this.configureViewForMode();

        if (selectedDashboardHasChanged) {
            this.handleDetailWidgetsOnBoard();
            this.manager.removeWorkspace();
            this.activateDashboardById(this.currentDashboardId);
            this.compareModeService.clearWidgetsCompareModeStatus();
        } else if (modeHasChanged) {
            this.layoutService.updateWidgetBehaviour(false);

            if (transitionIsFromEditToView && this.leftEditDirty) {
                // this is a bit of a hack, but the dashboard/workspace/widget/event flow
                // is impossible to track.  this causes the underlying workspace to not respond
                if (this.dashboardComponent) {
                    this.dashboardComponent.resetCurrentWorkspace();
                }

                this.manager.removeAllWidgets();
                this.activateDashboardById(this.currentDashboardId);
            } else {
                this.isDashboardLoading = false;
            }
        }

        this.layoutService.cancelWorkspaceChanges();
        this.leftEditDirty = false;
        if (this.dashboardService.getDashboardSnapshotById(this.currentDashboardId)) {
            this.isGlobal = !!this.dashboardService.getDashboardSnapshotById(this.currentDashboardId)?.isGlobal;
        }
    }

    private async goToDefaultDashboard(): Promise<void> {
        const defaultDashboardId = await this.dashboardService.getDefaultDashboardId();
        if (defaultDashboardId) {
            await this.navigateToBoardAndMode(defaultDashboardId, this.presentationModeAsString);
        } else {
            this.onLastDashboardClosed();
        }
    }

    private async goToNoDashboard(): Promise<void> {
        this.inPresentationMode = true;
        await this.navigateToBoardAndMode(undefined, 'view');
        this.isDashboardLoading = false;
    }

    private configureViewForMode(): void {
        this.layoutService.changeWorkspaceMode(this.inPresentationMode ? MODE.VIEW : MODE.EDIT_WORKSPACE);
        this.hsDashboardRef?.setHighlightVisibility(!this.inPresentationMode);
    }

    private discardChangesCallback(canContinue: Subject<boolean>): void {
        this.leftEditDirty = true;
        this.manager.selectWidget(false, undefined);
        this.dirtyFlagService.exitDirtyState();
        canContinue.next(true);
    }

    private continueEditingCallback(canContinue: Subject<boolean>): void {
        canContinue.next(false);
    }

    private async navigateToBoardAndMode(dashboardId: string | number | undefined, mode: string): Promise<boolean> {
        return this.router.navigate([`../../${mode}/${dashboardId ?? 'none'}`], { relativeTo: this.activatedRoute });
    }

    private activateDashboardById(dashboardId: string): void {
        this.isDashboardLoading = true;
        this.dashboardService.getDashboard(dashboardId)
            .subscribe(
                (dashboard: DashboardDetails) => {
                    // activeDate is probably always the same for view and edit mode
                    // but just to be on the safe side
                    this.currentActiveDate = this.inPresentationMode ?
                        dashboard.dashboardPreferences.find((pref) => pref.dashboardPreferenceMode === MODE.EDIT_WORKSPACE)?.activeDate :
                        dashboard.dashboardPreferences.find((pref) => pref.dashboardPreferenceMode === MODE.VIEW)?.activeDate;
                    this.addInfinityWorkspace(createWorkspaceObj(dashboard));
                    if (dashboard.id) {
                        this.ddvScreendataCapture.addTrackData(undefined, dashboard.id);
                    }
                },
                async (error) => {
                    console.error(error);

                    const dashboardSnapshot = this.dashboardService.getDashboardSnapshotById(dashboardId);
                    if (dashboardSnapshot) {
                        this.dashboardService.updateDashboardList(dashboardSnapshot, 'remove');
                    }
                    await this.navigateToBoardAndMode(undefined, this.presentationModeAsString);
                });
    }

    private addInfinityWorkspace(workspace: Workspace): void {
        this.manager.addWorkspace(workspace);
        this.currentDashboard = workspace;

        this.initiateDashboardSwitch(this.currentDashboard.getExtraParameters().deviceMode);

        const currDashboardModel: DashboardModel = workspace.getExtraParameters();
        this.createLastStateForDashboard(workspace.id)
            .subscribe(() => {
                this.manager.createWidgets(appWidgetStateToConfig(currDashboardModel.widgets, this.layoutService.getWorkspaceMode()));
            });
    }

    private initiateDashboardSwitch(deviceMode: TDeviceMode): void {
        this.datasetManagerService.clearDashboardPreference();
        if (this.hsDashboardRef) {
            this.hsDashboardRef.isLastDashboardClosed = false;  // this looks suspect
        }
        this.layoutService.resetLayoutForNewDashboard(deviceMode);
        this.isDashboardLoading = false;
    }

    private get presentationModeAsString(): string {
        return this.inPresentationMode ? MODE.VIEW : 'edit';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onDashboardSaveError(error: any, form?: ModalDialogRef): void {
        if (!error._body) {
            return;
        }

        const errorDetails = typeof error._body === 'string' ? JSON.parse(error._body) : error._body;

        if (errorDetails.message === 'Dashboard name is already in use') {
            return form?.componentInstance.nameUnavailable();
        }

        if (errorDetails.message === 'Dashboard version is stale') {
            if (form) {
                form.close();
            }

            this.handleStaleDashboardVersion(errorDetails.message);
        }
    }

    private updateWidgetExtraParams(widgets: AppWidgetState[]): void {
        widgets.forEach((widget) => {
            if (widget.widgetFilters?.isComparing) {
                widget.widgetFilters.isComparing = false;
                widget.widgetFilters.compareDates = undefined;
                widget.widgetFilters.comparing = CompareMode.ORIGINAL;
                widget.widgetFilters.agGridShowDatePicker = false;
            }
            this.manager.setWidgetExtraPreferences(widget.id, widget);
        });
    }

    private updateDashboardQueryParams(dashboardPreference: DashboardPreference): void {
        const dashboard = this.manager.getWorkspace();
        const extParams: DashboardModel = dashboard?.getExtraParameters();
        const viewPreference = deepClone(dashboardPreference);
        const editPreference = deepClone(dashboardPreference);
        viewPreference.dashboardPreferenceMode = MODE.VIEW;
        editPreference.dashboardPreferenceMode = MODE.EDIT_WORKSPACE;
        extParams.dashboardPreferences = [viewPreference, editPreference];
        dashboard?.setExtraParameters(extParams);

        this.queryParamsService.dispatchUpdatedQueryParams({ version: editPreference.version });
    }

    private onLastDashboardClosed(): void {
        if (this.hsDashboardRef) {
            this.hsDashboardRef.isLastDashboardClosed = true;
        }
        this.currentDashboard = undefined;
        this.isDashboardLoading = false;
        this.layoutService.removeGridLines();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private hasNoPermissionToEnterEditMode(dashboard: any | undefined): boolean {
        if (!dashboard?.dashboardPermissions || !dashboard.createdBy) {
            return true;
        }

        if (dashboard.createdBy.toLocaleLowerCase() === this.userEntitlements.user.username.toLocaleLowerCase()) {
            return false;
        }

        const roleName = this.userEntitlements.user.roleName;
        return (roleName === Roles.DDV_ClientBaseUser || roleName === Roles.DDV_HSBaseUser) &&
            !this.userEntitlements.hasPermission(Entitlements.DASHBOARD_EDIT) &&
            !this.userEntitlements.hasPermission(Entitlements.DASHBOARD_EDIT_ALL);
    }

    private handleDetailWidgetsOnBoard(): void {
        if (this.currentDashboard?.extraParameters?.widgets.some((widget) => widget.isDetailWidget)) {
            this.ddvWidgetService.resetDetailWidgetAndViewQueryParams();
        }
    }

    private getFilterValuesType(filterValues: (string | number | boolean)[]): string {
        const allValuesTypes: string[] = filterValues?.map((value) => {
            if (typeof value === 'object' && value == null) {
                return 'null';
            }

            return typeof value;
        });

        const valuesTypes = Array.from(new Set(allValuesTypes));
        const type = valuesTypes.length === 1 ?
            valuesTypes[0] :
            valuesTypes.length === 2 ? valuesTypes.find((valuesType) => valuesType !== 'null') : '';

        return type ?? '';
    }

    private handleStaleDashboardVersion(message: string): void {
        this.confirmationService.showConfirmationPopup({ message }, true).subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.revert();
                }
            },
        });
    }

    private revert(): void {
        const dashboardId = this.manager.getCurrentDashboardId();
        this.manager.removeAllWidgets();
        if (dashboardId) {
            this.revertDashboardService.revert(dashboardId, this.layoutService.getWorkspaceMode(), true);
        }
    }
}
