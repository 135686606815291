import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { ColumnSearch } from '../../models/column-search';

@Component({
    selector: 'app-grid-column-search',
    templateUrl: './grid-column-search.component.html',
    styleUrls: ['./grid-column-search.component.scss'],
})
export class GridColumnSearchComponent implements OnInit {
    searchColumns: ColumnSearch[] = [];
    showColumnSearchList: boolean = false;
    @Input() gridColumns: ColumnSearch[] = [];
    @Input() isReadOnly = false;
    @Output() columnVisibleChange = new EventEmitter<ColumnSearch>();

    ngOnInit(): void {
        this.searchColumns = this.gridColumns;
    }

    onColumnFilterChanged(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        if (value) {
            this.searchColumns = this.gridColumns.filter((column) =>
                column.colName.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1,
            );
        } else {
            this.searchColumns = this.gridColumns;
        }
    }

    setColumnVisible(column: ColumnSearch): void {
        this.columnVisibleChange.emit(column);
    }
}
