import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService, MultiSubscriptionComponent } from '@ddv/common-components';
import { VIZ_STYLE_CLASS, DdvDate, GroupedWidgets, groupWidgetsByCriteria, WidgetSnapshot, VisualizationType } from '@ddv/models';
import { visualizationDescriptorMap } from '@ddv/visualizations';
import { WidgetsService, WidgetConfigurationManager } from '@ddv/widgets';
import { Theme } from '@hs/ui-core-presentation';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FilterWidgetsPipe } from './filter-widgets.pipe';

export enum WidgetGroups {
    DATA_TYPE = 'Datatype',
    VIZ_TYPE = 'Visualization Type',
    WHATS_NEW = "What's New"
}

// only instantiated dynamically, so no reference to the selector
// only launched by the DashboardNavComponent
@Component({
    selector: 'app-widget-finder-v2',
    templateUrl: './widget-finder-v2.component.html',
    styleUrls: ['./widget-finder-v2.component.scss'],
    providers: [FilterWidgetsPipe],
})
export class WidgetFinderV2Component extends MultiSubscriptionComponent implements OnInit {
    @Output() widgetOpened = new EventEmitter<WidgetSnapshot>();
    @Output() createWidget = new EventEmitter();
    @Output() widgetFinderLastTabChange = new EventEmitter<string>();
    @Input() groupByText: string = WidgetGroups.DATA_TYPE;
    @Input() theme: Theme = Theme.light;
    @Input() inPresentationMode = true;
    widgetGroups: typeof WidgetGroups = WidgetGroups;
    groupedWidgets: GroupedWidgets[] = [];
    filteredWidgets: GroupedWidgets[] = [];
    loaderInFinder = true;
    onlyGlobals = false;
    searchParam: string = '';
    toggleWidgetData: boolean[] = [];
    visStyle = VIZ_STYLE_CLASS;
    private readonly visMap = visualizationDescriptorMap;
    private widgetsCollection: WidgetSnapshot[] = [];

    constructor(
        private readonly alertService: AlertService,
        private readonly filterWidgetPipe: FilterWidgetsPipe,
        private readonly widgetsService: WidgetsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(this.widgetsService.fetchAllWidgets()
            .pipe(
                map((response) => {
                    this.loaderInFinder = false;
                    this.widgetsCollection = response;
                    this.formatResults();
                }),
                catchError((error) => {
                    this.alertService.error(error.statusText);
                    this.loaderInFinder = false;
                    return of(null);
                }),
            ));
    }

    showOnlyGlobals(isGlobal: boolean): void {
        this.onlyGlobals = isGlobal;
        this.formatResults();
    }

    groupWidgetsBy(selectedGroup = this.widgetGroups.DATA_TYPE): void {
        if (this.groupByText !== selectedGroup) {
            this.groupByText = selectedGroup;
            this.widgetFinderLastTabChange.emit(this.groupByText);
            this.formatResults();
        }
    }

    filterWidgets(): void {
        this.filteredWidgets = this.filterWidgetPipe.transform(this.groupedWidgets, this.searchParam ?? '');
    }

    openWidget(widget: WidgetSnapshot): void {
        if (this.inPresentationMode) {
            return;
        }

        this.widgetOpened.emit(widget);
    }

    private formatResults(): void {
        const widgets = this.onlyGlobals ? this.filterGlobal(this.widgetsCollection) : this.widgetsCollection;
        const filteredWidgets = this.groupBy(widgets);

        filteredWidgets.forEach((widgetGroup, index) => {
            this.toggleWidgetData[index] = true;
            widgetGroup.widgets.forEach((widget) => {
                if (widget.coreWidgetType === 'VISUALIZATION') {
                    widget.vizForms = widget.visualizations.map((viz) => {
                        const vizConfig = visualizationDescriptorMap[viz];
                        return {
                            label: vizConfig.name,
                            cssClass: vizConfig.cssClass,
                            value: vizConfig.id,
                            uid: viz as VisualizationType,
                        };
                    });
                } else {
                    const widgetConfig = WidgetConfigurationManager.getWidgetByUID(widget.coreWidgetType)!;
                    widget.vizForms = [
                        {
                            label: widgetConfig.title,
                            cssClass: widgetConfig.cssClass!,
                            value: 'summaryWidget',
                            uid: widgetConfig.uid as VisualizationType,
                        },
                    ];
                }
            });

            if (this.groupByText !== WidgetGroups.WHATS_NEW) {
                widgetGroup.widgets.sort((a, b) =>
                    a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
            } else {
                widgetGroup.widgets.sort((a, b) =>
                    DdvDate.fromISOFormat(a.createdDate).isBefore(DdvDate.fromISOFormat(b.createdDate)) ? 1 : -1);
            }
        });

        if (this.groupByText !== WidgetGroups.WHATS_NEW) {
            filteredWidgets.sort((a, b) =>
                a.groupName.toLocaleLowerCase().localeCompare(b.groupName.toLocaleLowerCase()));
        } else {
            filteredWidgets.sort((a, b) =>
                DdvDate.fromISOFormat(a.widgets[0].createdDate).isBefore(DdvDate.fromISOFormat(b.widgets[0].createdDate)) ? 1 : -1);
        }

        this.groupedWidgets = filteredWidgets;
        this.filterWidgets();
    }

    private groupBy(data: WidgetSnapshot[]): GroupedWidgets[] {
        switch (this.groupByText) {
            case WidgetGroups.DATA_TYPE:
                return this.groupByDatatype(data);
            case WidgetGroups.VIZ_TYPE:
                return this.groupByVisualizationType(data);
            case WidgetGroups.WHATS_NEW:
                return this.groupByDate(data);
            default: return [];
        }
    }

    private filterGlobal(widgets: WidgetSnapshot[]): WidgetSnapshot[] {
        return widgets.filter((widget) => widget.isGlobal);
    }

    private groupByDatatype(widgets: WidgetSnapshot[]): GroupedWidgets[] {
        const groupedByDatatype = groupWidgetsByCriteria(widgets, 'dataType');
        return this.groupWidgets(groupedByDatatype);
    }

    private groupByDate(widgets: WidgetSnapshot[]): GroupedWidgets[] {
        const groupedByDate = groupWidgetsByCriteria(widgets, 'createdDate');
        return this.groupWidgets(groupedByDate);
    }

    private groupByVisualizationType(widgets: WidgetSnapshot[]): GroupedWidgets[] {
        const groupedByCoreWidgetType = groupWidgetsByCriteria(widgets, 'coreWidgetType');
        const groupedWidgets: GroupedWidgets[] = [];

        Object.entries(groupedByCoreWidgetType).forEach((group) => {
            if (group[0] !== 'VISUALIZATION') {
                groupedWidgets.push({
                    groupName: WidgetConfigurationManager.getWidgetByUID(group[0])?.title ?? '',
                    widgets: group[1],
                    totalWidgets: group[1].length,
                    collapsed: true,
                });
            } else {
                const visualizationWidgets = groupWidgetsByCriteria(group[1], 'visualizations');
                Object.entries(visualizationWidgets).forEach((vizGroup) => {
                    groupedWidgets.push({
                        groupName: this.visMap[vizGroup[0]]?.name ?? '',
                        widgets: vizGroup[1],
                        totalWidgets: vizGroup[1].length,
                        collapsed: true,
                    });
                });
            }
        });

        return groupedWidgets;
    }

    private groupWidgets(widgets: { [key: string]: WidgetSnapshot[] }): GroupedWidgets[] {
        return Object.entries(widgets).map((group) => ({
            groupName: group[0],
            widgets: group[1],
            totalWidgets: group[1].length,
            collapsed: true,
        }));
    }
}
