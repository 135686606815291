import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appScrollToChild]',
})
export class ScrollToChildDirective {
    @Output() public scrollToChild = new EventEmitter();

    @HostListener('click', ['$event.target'])
    public setScrollToChildElement(element: HTMLElement): void {
        const scrollHeight = document.getElementsByClassName('modal-dialog-content')[0].scrollTop;
        document.getElementsByClassName('modal-dialog-content')[0].scrollTop = element.getBoundingClientRect().bottom;
        if (!scrollHeight) {
            this.scrollToChild.emit(null);
        }
    }
}
