import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { DropdownOption } from '@ddv/common-components';
import { FeatureFlagService } from '@ddv/entitlements';
import { DatasetDefinition, NamedQuery } from '@ddv/models';
import { isNamedQuery, NamedQueriesService } from '@ddv/named-queries';
import { combineLatest, of, switchMap } from 'rxjs';

import { DatasetDefinitionsService } from '../../services/dataset-definitions.service';

function sortByName(a: { name: string }, b: { name: string }): number {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

@Component({
    selector: 'app-dsd-selector',
    templateUrl: './dsd-selector.component.html',
    styleUrls: ['./dsd-selector.component.scss'],
})
export class DatasetDefinitionSelectorComponent implements OnInit, OnChanges {
    @Input() datasetDefinition: DatasetDefinition | undefined;
    @Input() showJumpLink = false;
    @Input() jumpedFrom: string | undefined;
    @Input() isReadOnly = false;
    @Input() enabled = true;

    @Output() datasetDefinitionSelected = new EventEmitter<DatasetDefinition>();

    queryTypeList: DropdownOption[] = [{ text: 'ALL', key: 0, value: 0 }];
    queryType: DropdownOption = { text: 'ALL', key: 0, value: 0 };
    dataSource: (DatasetDefinition | NamedQuery)[] = [];
    filteredDataSource: (DatasetDefinition | NamedQuery)[] = [];

    protected preSelectedItem: DatasetDefinition | NamedQuery | undefined;

    private priorDatasetDefinition: DatasetDefinition | NamedQuery | undefined;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly featureFlagsService: FeatureFlagService,
        private readonly namedQueriesService: NamedQueriesService,
    ) {}

    ngOnInit(): void {
        if (this.enabled) {
            this.fetchDatasets();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.datasetDefinition) {
            this.priorDatasetDefinition = this.datasetDefinition;
            this.selectQueryTypeFromDefinition(changes.datasetDefinition.currentValue?.queryType?.name ?? 'ALL');
            this.preSelectedItem = changes.datasetDefinition.currentValue;

            if (!changes.datasetDefinition.currentValue && changes.datasetDefinition.previousValue) {
                this.fetchDatasets();
            }
        }
    }

    resetSelector(): void {
        this.selectQueryTypeFromDefinition('ALL');
        this.filteredDataSource = [...this.dataSource];
    }

    restorePriorSelection(): void {
        this.selectQueryTypeFromDefinition(
            isNamedQuery(this.priorDatasetDefinition) ?
                this.priorDatasetDefinition.type :
                this.priorDatasetDefinition?.queryType?.name ?? 'ALL',
        );
        this.preSelectedItem = undefined;
        this.cdr.detectChanges();
        this.preSelectedItem = this.priorDatasetDefinition;
    }

    onQueryTypeChanged(): void {
        if (this.queryType.text === 'ALL') {
            this.filteredDataSource = [...this.dataSource];
        } else {
            this.filteredDataSource = this.dataSource.filter((dsd) =>
                (isNamedQuery(dsd) ? dsd.type === this.queryType.text : dsd.queryType!.name === this.queryType.text),
            );
        }
    }

    onDatasetDefinitionSelected(item: DatasetDefinition | NamedQuery | undefined): void {
        this.selectQueryTypeFromDefinition(isNamedQuery(item) ? item.type : item?.queryType?.name ?? 'ALL');
        // Since we are not making selecting a named query work for now, emit only if the item is a dataset definition
        if (!isNamedQuery(item)) {
            this.datasetDefinitionSelected.emit(item);
        }
    }

    private fetchDatasets(): void {
        this.featureFlagsService.isFlagEnabled('ddv-use-nqs')
            .pipe(
                switchMap((enabled) => {
                    if (enabled) {
                        return combineLatest([
                            this.datasetDefinitionsService.fetchDatasets('ALL', ''),
                            this.namedQueriesService.fetchNamedQueries(),
                        ]);
                    }

                    return combineLatest([this.datasetDefinitionsService.fetchDatasets('ALL', ''), of([])]);
                }),
            )
            .subscribe(([data, namedQueries]) => {
                namedQueries.forEach((namedQuery) => {
                    if (namedQuery.datasetDefinitionId) {
                        namedQuery.name = `${namedQuery.name} [${namedQuery.datasetDefinitionId}]`;
                    }
                });
                const datasets = [...data, ...namedQueries];
                this.dataSource = datasets.sort(sortByName);
                this.initializeQueryTypeList();
            });
    }

    private initializeQueryTypeList(): void {
        const queryTypeNames = new Set<string>();
        this.dataSource.forEach((dsd) => queryTypeNames.add(isNamedQuery(dsd) ? dsd.type : dsd.queryType!.name));
        this.filteredDataSource = this.dataSource;
        this.queryTypeList = [
            ...this.queryTypeList,
            ...([...queryTypeNames].sort()).map((name, index) => ({ text: name, key: index + 1, value: index + 1 })),
        ];

        if (this.datasetDefinition) {
            this.selectQueryTypeFromDefinition(this.datasetDefinition.queryType?.name ?? 'ALL');
        }
    }

    private selectQueryTypeFromDefinition(queryType: string): void {
        this.queryType = this.queryTypeList.find((qt) => qt.text === queryType) ?? { text: 'ALL', key: 0, value: 0 };
    }
}
