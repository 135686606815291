import { CellStyle } from '@ag-grid-community/core';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LinkConfiguration } from '@ddv/models';
import { Observable, ReplaySubject } from 'rxjs';

import { CrosstalkComment } from '../models/crosstalk-comment';
import { ADD_NEW_CROSSTALK_SCHEMA, Schema } from './comments.service';

export interface CellValue {
    formatted: string;
    original: unknown;
}

export const xtlkLocationToken: InjectionToken<string> = new InjectionToken('xtlkLocationToken');
export const pscHostAndPortToken: InjectionToken<string> = new InjectionToken('pscHostAndPort');
export const portfolioDetailsHostAndPortToken: InjectionToken<string> = new InjectionToken('portfolioDetailsHostAndPort');

type UpdateCallback = (error: string, update: string | CrosstalkComment) => void;

/*
    This name is bad.  This service launches all modals, not just Crosstalk
 */
@Injectable()
export class CrosstalkModalService {
    public readonly crosstalkSchema$: Observable<Schema | {}>;

    private activeModal: Window | null = null;
    private readonly crosstalkSchemaSubject = new ReplaySubject<Schema | {}>(1);

    constructor(
        @Inject(xtlkLocationToken) private readonly location: string,
        // does not belong here.  there should be a generic modal service
        @Inject(pscHostAndPortToken) private readonly pscHostAndPort: string,
        // does not belong here.  there should be a generic modal service
        @Inject(portfolioDetailsHostAndPortToken) private readonly portfolioDetailsHostAndPort: string,
    ) {
        this.crosstalkSchema$ = this.crosstalkSchemaSubject.asObservable();
        this.crosstalkSchemaSubject.next({});
    }

    configureCellForLinking(
        linkConfiguration: LinkConfiguration,
        cellValue: CellValue,
        cellStyle: CellStyle,
        skipBuildUrl: boolean = false,
    ): string {
        const url = skipBuildUrl ? linkConfiguration.uri : this.buildURLFromURI(linkConfiguration.uri);
        const linkText: string = this.deriveLinkText(cellValue, linkConfiguration);
        const linkStyle: string = this.deriveLinkStyle(cellValue, cellStyle);

        if (this.linkAsAModal(linkConfiguration)) {
            return this.makeModalLink(linkText, linkStyle);
        }

        if (this.linkAsDDVWidget(linkConfiguration)) {
            return this.buildDDVWidgetAsLink(linkText, linkStyle, url);
        }

        if (this.openInSameNewWindow(linkConfiguration)) {
            return this.buildSameNewWindowUrlLink(linkText, linkStyle, url, linkConfiguration.launchType);
        }

        return this.makeNewTabLink(linkText, linkStyle, url);
    }

    // This method is used to open any modal from a grid cell, not just crosstalk
    openModal(linkConfiguration: LinkConfiguration, event: MouseEvent, _?: UpdateCallback): void {
        this.closeModal();

        const url = this.buildURLFromURI(linkConfiguration.uri);

        const widthPart = linkConfiguration.size?.width ? `,width=${linkConfiguration.size.width}` : '';
        const heightPart = linkConfiguration.size?.height ? `,height=${linkConfiguration.size.height}` : '';
        const locationPart = `top=${event.screenY},left=${event.screenX}`;
        const optionsString = `${locationPart},toolbar=0,menubar=0${widthPart}${heightPart}`;

        this.activeModal = window.open(url, '_blank', optionsString);

        if (linkConfiguration.label === ADD_NEW_CROSSTALK_SCHEMA) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            window.removeEventListener('message', this.handleNewCrosstalkSchema);
            this.updateCrosstalkSchemaSubject({});
            window.addEventListener('message', this.handleNewCrosstalkSchema.bind(this));
        }
    }

    linkAsAModal(linkConfiguration: LinkConfiguration): boolean {
        return linkConfiguration.launchType === 'modal';
    }

    linkAsDDVWidget(linkConfiguration: LinkConfiguration): boolean {
        return linkConfiguration.launchType === 'masterdetail';
    }

    getModalPosition(modalWidth: number, modalHeight: number): { screenX: number, screenY: number } {
        // in case the user has dual screen setup
        const dualScreenLeft = window.screenLeft || window.screenX;
        const dualScreenTop = window.screenTop || window.screenY;

        const width = window.innerWidth || document.documentElement.clientWidth || screen.width;
        const height = window.innerHeight || document.documentElement.clientHeight || screen.height;

        const zoom = width / window.screen.availWidth;

        // center modal on screen
        const screenX = ((width - modalWidth) / 2 / zoom) + dualScreenLeft;
        const screenY = ((height - modalHeight) / 2 / zoom) + dualScreenTop;

        return { screenX, screenY };
    }

    closeModal(): void {
        if (this.activeModal) {
            this.activeModal.close();
            this.activeModal = null;
        }
    }

    private buildURLFromURI(uri: string): string {
        let rootLocation = this.location;
        switch (uri.split('/')[1]) {
            case 'psc':
                rootLocation = this.pscHostAndPort;
                break;
            case 'portfolio':
                rootLocation = this.portfolioDetailsHostAndPort;
                break;
            default:
                rootLocation = this.location;
        }

        return `${rootLocation}${uri}`;
    }

    private makeNewTabLink(linkText: string, linkStyle: string, url: string): string {
        return `<a${linkStyle} href="${url}" target="_blank">${linkText}</a>`;
    }

    private makeModalLink(linkText: string, linkStyle: string): string {
        return `<span${linkStyle}>${linkText}</span>`;
    }

    private openInSameNewWindow(linkConfiguration: LinkConfiguration): boolean {
        return linkConfiguration.launchType === '_self' || linkConfiguration.launchType === '_blank';
    }

    private buildSameNewWindowUrlLink(linkText: string, linkStyle: string, url: string, target: string | null = '_self'): string {
        return `<a${linkStyle} href="${(url.indexOf('//') === -1 ? '//' : '') + url}" target="${target}">${linkText}</a>`;
    }

    private buildDDVWidgetAsLink(linkText: string, linkStyle: string, url: string): string {
        return `<span${linkStyle} data-ddv-widget=${url}>${linkText}</span>`;
    }

    private deriveLinkStyle(cellValue: CellValue, cellStyle: CellStyle): string {
        const stylePart = cellStyle.color ? ` style="color:${cellStyle.color}"` : '';
        const classPart = (cellValue.original == null || cellValue.original === '') ?
            ' class="originallyUndefined external-app-link "' :
            ' class="external-app-link"';

        return `${stylePart}${classPart}`;
    }

    private deriveLinkText(cellValue: CellValue, link: LinkConfiguration): string {
        return (cellValue.original == null || cellValue.original === '') ? link.label : cellValue.formatted;
    }

    private handleNewCrosstalkSchema(event: MessageEvent): void {
        if (event.origin !== window.location.origin) {
            return;
        }

        if (event.data.status === 'success' && event.data.type === 'crosstalk-new-schema') {
            this.updateCrosstalkSchemaSubject(event.data.schema);
        }
    }

    private updateCrosstalkSchemaSubject(data: Schema | {} = {}): void {
        this.crosstalkSchemaSubject.next({ ...data });
    }
}
