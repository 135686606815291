import { VisualizationPreferences } from '@ddv/models';

export const defaultVisualizationPreferencesMap: Record<string, VisualizationPreferences[]> = {
    LINE_CHART: [
        {
            label: 'Legend',
            type: 'toggle',
            name: 'line-tooltip',
            id: 'enableLegend',
            selectedValue: true,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Right',
                            value: 'right',
                        },
                        {
                            label: 'Top',
                            value: 'top',
                        },
                        {
                            label: 'Bottom',
                            value: 'bottom',
                        },
                    ],
                    name: 'line-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'leftYAxisLabels',
            label: 'Left Axis Labels (Value 1)',
            type: 'toggle',
            name: 'line-leftYAxisLabels',
            selectedValue: true,
            configs: [
                {
                    id: 'leftYAxis',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    selectedValue: false,
                    name: 'line-leftYAxis',
                    cssClass: 'display-axis-line',
                    disabled: false,
                },
                {
                    id: 'leftYAxisDataRange',
                    label: 'Data Range',
                    type: undefined,
                    cssClass: 'data-range-select',
                    disabled: false,
                    name: 'line-leftYAxisDataRange',
                    configs: [
                        {
                            id: 'leftYAxisMinRangeToggle',
                            label: '',
                            cssClass: 'data-range-select-toggle',
                            type: 'toggle',
                            valueLabels: [
                                'Custom',
                                'Auto',
                            ],
                            disabled: true,
                            dataType: 'data-range',
                            name: 'line-leftYAxisDataRangeLeftToggle',
                            configs: [
                                {
                                    id: 'leftYAxisRangeMin',
                                    label: '',
                                    cssClass: 'data-range-select-input',
                                    type: 'range',
                                    dataType: 'number',
                                    name: 'line-letYAxisRangeInput',
                                    disabled: true,
                                    selectedValue: 'AUTO',
                                },
                            ],
                        },
                        {
                            id: 'leftYAxisMaxRangeToggle',
                            label: '',
                            cssClass: 'data-range-select-toggle',
                            type: 'toggle',
                            valueLabels: [
                                'Custom',
                                'Auto',
                            ],
                            disabled: true,
                            dataType: 'data-range',
                            name: 'line-leftYAxisDataRangeRightToggle',

                            configs: [
                                {
                                    id: 'leftYAxisRangeMax',
                                    label: '',
                                    cssClass: 'data-range-select-input',
                                    type: 'range',
                                    dataType: 'number',
                                    name: 'line-letYAxisRangeInput',
                                    disabled: true,
                                    selectedValue: 'AUTO',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: 'rightYAxisLabels',
            label: 'Right Axis Labels',
            type: 'toggle',
            name: 'line-rightYAxisLabels',
            selectedValue: false,
            configs: [
                {
                    id: 'rightYAxis',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    selectedValue: false,
                    name: 'line-rightYAxis',
                    cssClass: 'display-axis-line',
                    disabled: false,
                },
                {
                    id: 'rightYAxisDataRange',
                    label: 'Data Range',
                    type: undefined,
                    cssClass: 'data-range-select',
                    disabled: false,
                    name: 'line-rightYAxisDataRange',
                    configs: [
                        {
                            id: 'rightYAxisMinRangeToggle',
                            label: '',
                            cssClass: 'data-range-select-toggle',
                            type: 'toggle',
                            disabled: true,
                            dataType: 'data-range',
                            valueLabels: [
                                'Custom',
                                'Auto',
                            ],
                            name: 'line-rightYAxisDataRangeLeftToggle',
                            configs: [
                                {
                                    id: 'rightYAxisRangeMin',
                                    label: '',
                                    cssClass: 'data-range-select-input',
                                    type: 'range',
                                    dataType: 'number',
                                    name: 'line-rightYAxisRangeInput',
                                    disabled: true,
                                    selectedValue: 'AUTO',
                                },
                            ],
                        },
                        {
                            id: 'rightYAxisMaxRangeToggle',
                            label: '',
                            cssClass: 'data-range-select-toggle',
                            type: 'toggle',
                            disabled: true,
                            dataType: 'data-range',
                            valueLabels: [
                                'Custom',
                                'Auto',
                            ],
                            name: 'line-rightYAxisDataRangeRightToggle',
                            configs: [
                                {
                                    id: 'rightYAxisRangeMax',
                                    label: '',
                                    cssClass: 'data-range-select-input',
                                    type: 'range',
                                    dataType: 'number',
                                    name: 'line-rightYAxisRangeInput',
                                    disabled: true,
                                    selectedValue: 'AUTO',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: 'bottomXAxisLabels',
            label: 'Horizontal Axis Labels',
            type: 'toggle',
            name: 'line-bottomXAxisLabels',
            selectedValue: false,
            configs: [
                {
                    id: 'bottomXAxis',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    selectedValue: true,
                    name: 'line-bottomXAxis',
                    cssClass: 'display-axis-line',
                    disabled: false,
                },
                {
                    id: 'bottomXAxisOrientation',
                    label: 'Orientation',
                    type: 'select',
                    disabled: false,
                    values: [
                        {
                            label: 'Horizontal',
                            value: 'horizontal',
                        },
                        {
                            label: 'Vertical',
                            value: 'Vertical',
                        },
                    ],
                    name: 'line-bottomXAxisOrientation',
                    selectedValue: 'horizontal',
                },
            ],
        },
        {
            id: 'inChartLabel',
            label: 'In Chart Label',
            type: 'toggle',
            name: 'line-inChartLabel',
            selectedValue: false,
            cssClass: 'display-inline-block',
            disabled: false,
        },
        {
            id: 'showBrush',
            label: 'Brush / Zoom',
            type: 'toggle',
            name: 'line-showBrush',
            selectedValue: true,
            configs: [
                {
                    id: 'zoomLevels',
                    label: 'Zoom Level',
                    type: 'multi-select',
                    values: [
                        {
                            label: '1 day',
                            value: '1D',
                        },
                        {
                            label: '1 month',
                            value: '1M',
                        },
                        {
                            label: '3 months',
                            value: '3M',
                        },
                        {
                            label: '6 months',
                            value: '6M',
                        },
                        {
                            label: '12 months',
                            value: '12M',
                        },
                        {
                            label: 'YTD',
                            value: 'YTD',
                        },
                        {
                            label: 'ALL',
                            value: 'ALL',
                        },
                    ],
                    name: 'line-zoomLevels',
                    selectedValue: [
                        { label: '1 day', value: '1D' },
                        { label: '1 month', value: '1M' },
                        { label: '3 months', value: '3M' },
                        { label: '6 months', value: '6M' },
                        { label: '12 months', value: '12M' },
                        { label: 'YTD', value: 'YTD' },
                        { label: 'ALL', value: 'ALL' },
                    ],
                    disabled: false,
                },
            ],
        },
        {
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            name: 'line-enableTooltip',
            selectedValue: true,
            cssClass: 'display-inline-block',
            disabled: false,
        },
        {
            id: 'showHoverLines',
            label: 'Hover Line',
            type: 'toggle',
            name: 'line-showHoverLines',
            selectedValue: true,
            cssClass: 'display-inline-block',
            disabled: false,
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'line-numberunits',
                    selectedValue: 'actual',
                    disabled: false,
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'line-numberformat',
                    selectedValue: 'XX.00',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'line-decimalpos',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 2,
                    disabled: false,
                },
                {
                    id: 'gridLineStyle',
                    label: 'GridLine Style',
                    type: 'select',
                    values: [
                        {
                            label: 'Solid',
                            value: 'solid-line',
                        },
                        {
                            label: 'Dotted',
                            value: 'dotted-line',
                        },
                        {
                            label: 'None',
                            value: 'none',
                        },
                    ],
                    name: 'line-gridLineStyle',
                    selectedValue: 'solid-line',
                    disabled: false,
                },
            ],
        },
    ],
    STACKED_AREA_CHART: [
        {
            label: 'Legend',
            type: 'toggle',
            name: 'stacked-area-tooltip',
            id: 'enableLegend',
            selectedValue: true,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Right',
                            value: 'right',
                        },
                        {
                            label: 'Top',
                            value: 'top',
                        },
                        {
                            label: 'Bottom',
                            value: 'bottom',
                        },
                    ],
                    name: 'stacked-area-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'leftYAxisLabels',
            label: 'Vertical Axis Labels',
            type: 'toggle',
            name: 'stacked-area-leftYAxisLabels',
            selectedValue: false,
            configs: [
                {
                    id: 'leftYAxis',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    selectedValue: false,
                    name: 'stacked-area-leftYAxis',
                    cssClass: 'display-axis-line',
                    disabled: false,
                },
                {
                    id: 'leftYAxisDataRange',
                    label: 'Data Range',
                    type: undefined,
                    cssClass: 'data-range-select',
                    name: 'stacked-area-leftYAxisDataRange',
                    selectedValue: 'AUTO',
                    values: [
                        {
                            label: 'Auto',
                            value: 'AUTO',
                        },
                    ],
                    hidden: true,
                },
            ],
        },
        {
            id: 'bottomXAxisLabels',
            label: 'Horizontal Axis Labels',
            type: 'toggle',
            name: 'stacked-area-bottomXAxisLabels',
            selectedValue: false,
            configs: [
                {
                    id: 'bottomXAxis',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    selectedValue: true,
                    name: 'stacked-area-bottomXAxis',
                    cssClass: 'display-axis-line',
                    disabled: false,
                },
                {
                    id: 'bottomXAxisOrientation',
                    label: 'Orientation',
                    type: 'select',
                    disabled: false,
                    values: [
                        {
                            label: 'Horizontal',
                            value: 'horizontal',
                        },
                        {
                            label: 'Vertical',
                            value: 'Vertical',
                        },
                    ],
                    name: 'stacked-area-bottomXAxisOrientation',
                    selectedValue: 'horizontal',
                },
            ],
        },
        {
            id: 'enableSliceManagement',
            label: 'Area Management',
            type: 'toggle',
            name: 'stacked-area-enableSliceManagement',
            selectedValue: false,
            disableChild: true,
            configs: [
                {
                    id: 'sliceManagementType',
                    label: 'Management Type',
                    type: 'select',
                    values: [
                        {
                            label: 'Percentage',
                            value: 'percentage',
                        },
                        {
                            label: 'Max Count',
                            value: 'maxCount',
                        },
                    ],
                    name: 'stacked-area-slice-management-type',
                    selectedValue: 'percentage',
                },
                {
                    id: 'groupByPercent',
                    label: 'Only Show Areas Above',
                    type: 'select',
                    values: [
                        {
                            label: '0%',
                            value: 0,
                        },
                        {
                            label: '1%',
                            value: 1,
                        },
                        {
                            label: '2%',
                            value: 2,
                        },
                        {
                            label: '3%',
                            value: 3,
                        },
                        {
                            label: '4%',
                            value: 4,
                        },
                        {
                            label: '5%',
                            value: 5,
                        },
                        {
                            label: '6%',
                            value: 6,
                        },
                        {
                            label: '7%',
                            value: 7,
                        },
                        {
                            label: '8%',
                            value: 8,
                        },
                        {
                            label: '9%',
                            value: 9,
                        },
                        {
                            label: '10%',
                            value: 10,
                        },
                    ],
                    name: 'stacked-area-groupByPercent',
                    selectedValue: 0,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'groupByMaxCount',
                    label: 'Max number of areas',
                    type: 'text',
                    dataType: 'number',
                    name: 'stacked-area-slice-max-count',
                    minValue: 1,
                    maxValue: 12,
                    allowPattern: '^([1-9]|1[0-2])$',
                    selectedValue: 7,
                    hidden: true,
                    cssClass: 'hidden',
                },
            ],
        },
        {
            id: 'showBrush',
            label: 'Brush / Zoom',
            type: 'toggle',
            name: 'stacked-area-showBrush',
            selectedValue: true,
            configs: [
                {
                    id: 'zoomLevels',
                    label: 'Zoom Level',
                    type: 'multi-select',
                    values: [
                        {
                            label: '1 day',
                            value: '1D',
                        },
                        {
                            label: '1 month',
                            value: '1M',
                        },
                        {
                            label: '3 months',
                            value: '3M',
                        },
                        {
                            label: '6 months',
                            value: '6M',
                        },
                        {
                            label: '12 months',
                            value: '12M',
                        },
                        {
                            label: 'YTD',
                            value: 'YTD',
                        },
                        {
                            label: 'ALL',
                            value: 'ALL',
                        },
                    ],
                    name: 'stacked-area-zoomLevels',
                    selectedValue: [
                        { label: '1 day', value: '1D' },
                        { label: '1 month', value: '1M' },
                        { label: '3 months', value: '3M' },
                        { label: '6 months', value: '6M' },
                        { label: '12 months', value: '12M' },
                        { label: 'YTD', value: 'YTD' },
                        { label: 'ALL', value: 'ALL' },
                    ],
                    disabled: false,
                },
            ],
        },
        {
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            name: 'stacked-area-enableTooltip',
            selectedValue: true,
            cssClass: 'display-inline-block',
            disabled: false,
        },
        {
            id: 'showHoverLines',
            label: 'Hover Line',
            type: 'toggle',
            name: 'stacked-area-showHoverLines',
            selectedValue: true,
            cssClass: 'display-inline-block',
            disabled: false,
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'stacked-area-numberunits',
                    selectedValue: 'actual',
                    disabled: false,
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'stacked-area-numberformat',
                    selectedValue: 'XX.00',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'stacked-area-decimalpos',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 2,
                    disabled: false,
                },
                {
                    id: 'gridLineStyle',
                    label: 'GridLine Style',
                    type: 'select',
                    values: [
                        {
                            label: 'Solid',
                            value: 'solid-line',
                        },
                        {
                            label: 'None',
                            value: 'none',
                        },
                    ],
                    name: 'stacked-area-gridLineStyle',
                    selectedValue: 'solid-line',
                    disabled: false,
                },
            ],
        },
    ],
    PIE_CHART: [
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Chart Sorting',
            selectedValue: true,
            configs: [
                {
                    label: 'Sort By',
                    type: 'select',
                    name: 'sort-table-by',
                    id: 'sortTableBy',
                    selectedValue: 'VALUE',
                    values: [
                        {
                            label: 'Slicer',
                            value: 'SLICER',
                        },
                        {
                            label: 'Value',
                            value: 'VALUE',
                        },
                    ],
                },
                {
                    label: 'Sort Direction',
                    id: 'tableSortDirection',
                    type: 'select',
                    name: 'sort-table-ascending',
                    selectedValue: 'DESC',
                    values: [
                        {
                            label: 'Asc',
                            value: 'ASC',
                        },
                        {
                            label: 'Desc',
                            value: 'DESC',
                        },
                    ],
                },
                {
                    label: 'Slicer',
                    id: 'sortOrderSlicer',
                    type: 'select',
                    name: 'sort-order-slicer',
                    hidden: true,
                },
            ],
        },
        {
            label: 'Legend',
            type: 'toggle',
            name: 'pie-tooltip',
            id: 'enableLegend',
            selectedValue: true,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Right',
                            value: 'right',
                        },
                        {
                            label: 'Top',
                            value: 'top',
                        },
                        {
                            label: 'Bottom',
                            value: 'bottom',
                        },
                    ],
                    name: 'pie-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'pie-tooltip',
            selectedValue: true,
        },
        {
            label: 'Labels',
            type: 'toggle',
            name: 'pie-enableLabels-toggle',
            id: 'enableLabels',
            selectedValue: true,
            configs: [
                {
                    id: 'labelStyle',
                    type: 'select',
                    label: '',
                    values: [
                        {
                            label: 'Labels w/ lines',
                            value: 'showLines',
                        },
                        {
                            label: 'Labels w/o lines',
                            value: 'hideLines',
                        },
                        {
                            label: 'Inside',
                            value: 'inline',
                        },
                    ],
                    name: 'pie-labels',
                    selectedValue: 'showLines',
                },
            ],
        },
        {
            id: 'highlightSlice',
            label: 'Slice Selection',
            type: 'toggle',
            cssClass: 'display-inline-block highlight',
            valueLabels: [
                'Highlight',
                'Drilldown',
            ],
            name: 'pie-higlightSlice-toggle',
            selectedValue: true,
        },
        {
            label: 'Slice Management',
            type: 'toggle',
            name: 'pie-slicemgmt-toggle',
            id: 'enableSliceManagement',
            selectedValue: false,
            configs: [
                {
                    id: 'sliceManagementType',
                    label: 'Management Type',
                    type: 'select',
                    values: [
                        {
                            label: 'Percentage',
                            value: 'percentage',
                        },
                        {
                            label: 'Max Count',
                            value: 'maxCount',
                        },
                    ],
                    name: 'pie-slice-management-type',
                    selectedValue: 'percentage',
                },
                {
                    id: 'groupByPercent',
                    label: 'Only show slices above',
                    type: 'select',
                    values: [
                        {
                            label: '1%',
                            value: 1,
                        },
                        {
                            label: '2%',
                            value: 2,
                        },
                        {
                            label: '3%',
                            value: 3,
                        },
                        {
                            label: '4%',
                            value: 4,
                        },
                        {
                            label: '5%',
                            value: 5,
                        },
                        {
                            label: '6%',
                            value: 6,
                        },
                        {
                            label: '7%',
                            value: 7,
                        },
                        {
                            label: '8%',
                            value: 8,
                        },
                        {
                            label: '9%',
                            value: 9,
                        },
                        {
                            label: '10%',
                            value: 10,
                        },
                    ],
                    name: 'pie-select-slice-perc',
                    selectedValue: 10,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'groupByMaxCount',
                    label: 'Max number of slices',
                    type: 'text',
                    dataType: 'number',
                    name: 'pie-slice-max-count',
                    minValue: 1,
                    maxValue: 12,
                    allowPattern: '^([1-9]|1[0-2])$',
                    selectedValue: 7,
                    hidden: true,
                    cssClass: 'hidden',
                },
            ],
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'pie-numberunits',
                    selectedValue: 'actual',
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'pie-numberformat',
                    selectedValue: 'XX',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'pie-decimalpos',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 0,
                },
            ],
        },
    ],
    DONUT_CHART: [
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Chart Sorting',
            selectedValue: true,
            configs: [
                {
                    label: 'Sort By',
                    type: 'select',
                    name: 'sort-table-by',
                    id: 'sortTableBy',
                    selectedValue: 'VALUE',
                    values: [
                        {
                            label: 'Slicer',
                            value: 'SLICER',
                        },
                        {
                            label: 'Value',
                            value: 'VALUE',
                        },
                    ],
                },
                {
                    label: 'Sort Direction',
                    id: 'tableSortDirection',
                    type: 'select',
                    name: 'sort-table-ascending',
                    selectedValue: 'DESC',
                    values: [
                        {
                            label: 'Asc',
                            value: 'ASC',
                        },
                        {
                            label: 'Desc',
                            value: 'DESC',
                        },
                    ],
                },
                {
                    label: 'Slicer',
                    id: 'sortOrderSlicer',
                    type: 'select',
                    name: 'sort-order-slicer',
                    hidden: true,
                },
            ],
        },
        {
            label: 'Legend',
            type: 'toggle',
            name: 'donut-tooltip',
            id: 'enableLegend',
            selectedValue: true,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Right',
                            value: 'right',
                        },
                        {
                            label: 'Top',
                            value: 'top',
                        },
                        {
                            label: 'Bottom',
                            value: 'bottom',
                        },
                    ],
                    name: 'donut-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'displayTotal',
            label: 'Display Total',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'display-total',
            selectedValue: true,
        },
        {
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'donut-tooltip',
            selectedValue: true,
        },
        {
            label: 'Labels',
            type: 'toggle',
            name: 'donut-enableLabels-toggle',
            id: 'enableLabels',
            selectedValue: true,
            configs: [
                {
                    id: 'labelStyle',
                    type: 'select',
                    label: '',
                    values: [
                        {
                            label: 'Labels w/ lines',
                            value: 'showLines',
                        },
                        {
                            label: 'Labels w/o lines',
                            value: 'hideLines',
                        },
                    ],
                    name: 'donut-labels',
                    selectedValue: 'showLines',
                },
            ],
        },
        {
            id: 'highlightSlice',
            label: 'Slice Selection',
            type: 'toggle',
            cssClass: 'display-inline-block highlight',
            valueLabels: [
                'Highlight',
                'Drilldown',
            ],
            name: 'donut-higlightSlice-toggle',
            selectedValue: true,
        },
        {
            label: 'Slice Management',
            type: 'toggle',
            name: 'donut-slicemgmt-toggle',
            id: 'enableSliceManagement',
            selectedValue: false,
            configs: [
                {
                    id: 'sliceManagementType',
                    label: 'Management Type',
                    type: 'select',
                    values: [
                        {
                            label: 'Percentage',
                            value: 'percentage',
                        },
                        {
                            label: 'Max Count',
                            value: 'maxCount',
                        },
                    ],
                    name: 'donut-slice-management-type',
                    selectedValue: 'percentage',
                },
                {
                    id: 'groupByPercent',
                    label: 'Only show slices above',
                    type: 'select',
                    values: [
                        {
                            label: '1%',
                            value: 1,
                        },
                        {
                            label: '2%',
                            value: 2,
                        },
                        {
                            label: '3%',
                            value: 3,
                        },
                        {
                            label: '4%',
                            value: 4,
                        },
                        {
                            label: '5%',
                            value: 5,
                        },
                        {
                            label: '6%',
                            value: 6,
                        },
                        {
                            label: '7%',
                            value: 7,
                        },
                        {
                            label: '8%',
                            value: 8,
                        },
                        {
                            label: '9%',
                            value: 9,
                        },
                        {
                            label: '10%',
                            value: 10,
                        },
                    ],
                    name: 'donut-select-slice-perc',
                    selectedValue: 10,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'groupByMaxCount',
                    label: 'Max number of slices',
                    type: 'text',
                    dataType: 'number',
                    name: 'donut-slice-max-count',
                    minValue: 1,
                    maxValue: 12,
                    allowPattern: '^([1-9]|1[0-2])$',
                    selectedValue: 7,
                    hidden: true,
                    cssClass: 'hidden',
                },
            ],
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'donut-numberunits',
                    selectedValue: 'actual',
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'donut-numberformat',
                    selectedValue: 'XX',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'donut-decimalpos',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 0,
                },
            ],
        },
    ],
    VERTICAL_BAR_CHART: [
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Chart Sorting',
            selectedValue: true,
            configs: [
                {
                    label: 'Sort By',
                    type: 'select',
                    name: 'sort-table-by',
                    id: 'sortTableBy',
                    selectedValue: 'VALUE',
                    values: [
                        {
                            label: 'Slicer',
                            value: 'SLICER',
                        },
                        {
                            label: 'Value',
                            value: 'VALUE',
                        },
                    ],
                },
                {
                    label: 'Sort Direction',
                    id: 'tableSortDirection',
                    type: 'select',
                    name: 'sort-table-ascending',
                    selectedValue: 'DESC',
                    values: [
                        {
                            label: 'Asc',
                            value: 'ASC',
                        },
                        {
                            label: 'Desc',
                            value: 'DESC',
                        },
                    ],
                },
                {
                    label: 'Slicer',
                    id: 'sortOrderSlicer',
                    type: 'select',
                    name: 'sort-order-slicer',
                    hidden: true,
                },
            ],
        },
        {
            label: 'Legend',
            type: 'toggle',
            name: 'vbar-tooltip',
            id: 'enableLegend',
            selectedValue: false,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Right',
                            value: 'right',
                        },
                        {
                            label: 'Top',
                            value: 'top',
                        },
                        {
                            label: 'Bottom',
                            value: 'bottom',
                        },
                    ],
                    name: 'vbar-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'barYAxis',
            label: 'Vertical Axis',
            type: 'toggle',
            name: 'vbar-barYAxis',
            selectedValue: true,
            configs: [
                {
                    id: 'barYAxisPosition',
                    label: 'Axis Position',
                    type: 'toggle',
                    valueLabels: [
                        'Left',
                        'Right',
                    ],
                    name: 'vbar-barYAxisPosition',
                    selectedValue: true,
                    cssClass: 'display-axis-line',
                },
                {
                    id: 'barYAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'vbar-barYAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barYAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'vbar-barYAxisLine',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
            ],
        },
        {
            id: 'barXAxis',
            label: 'Horizontal Axis',
            type: 'toggle',
            name: 'vbar-barXAxis',
            selectedValue: true,
            configs: [
                {
                    id: 'barXAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'vbar-barXAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barXAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'vbar-barXAxisLine',
                    cssClass: 'display-inline',
                    selectedValue: false,
                },
            ],
        },
        {
            id: 'inChartLabel',
            label: 'Bar Labels',
            type: 'toggle',
            name: 'vbar-inChartLabel',
            selectedValue: false,
            disableChild: true,
            configs: [
                {
                    id: 'enableInsideLabel',
                    label: 'Label Position',
                    type: 'toggle',
                    name: 'vbar-enableInsideLabel',
                    valueLabels: [
                        'Inside',
                        'Outside',
                    ],
                    selectedValue: false,
                    cssClass: 'display-inline label-pos',
                },
                {
                    id: 'labelOrientation',
                    label: 'Orientation',
                    type: 'select',
                    disabled: false,
                    values: [
                        {
                            label: 'Horizontal',
                            value: 'horizontal',
                        },
                        {
                            label: 'Vertical',
                            value: 'vertical',
                        },
                    ],
                    name: 'vbar-labelOrientation',
                    selectedValue: 'vertical',
                },
            ],
        },
        {
            id: 'enableSliceManagement',
            label: 'Bar Management',
            type: 'toggle',
            name: 'vbar-enableSliceManagement',
            selectedValue: false,
            disableChild: true,
            configs: [
                {
                    id: 'sliceManagementType',
                    label: 'Management Type',
                    type: 'select',
                    values: [
                        {
                            label: 'Percentage',
                            value: 'percentage',
                        },
                        {
                            label: 'Max Count',
                            value: 'maxCount',
                        },
                    ],
                    name: 'vbar-slice-management-type',
                    selectedValue: 'percentage',
                },
                {
                    id: 'groupByPercent',
                    label: 'Only Show Bars Above',
                    type: 'select',
                    values: [
                        {
                            label: '0%',
                            value: 0,
                        },
                        {
                            label: '1%',
                            value: 1,
                        },
                        {
                            label: '2%',
                            value: 2,
                        },
                        {
                            label: '3%',
                            value: 3,
                        },
                        {
                            label: '4%',
                            value: 4,
                        },
                        {
                            label: '5%',
                            value: 5,
                        },
                        {
                            label: '6%',
                            value: 6,
                        },
                        {
                            label: '7%',
                            value: 7,
                        },
                        {
                            label: '8%',
                            value: 8,
                        },
                        {
                            label: '9%',
                            value: 9,
                        },
                        {
                            label: '10%',
                            value: 10,
                        },
                    ],
                    name: 'vbar-groupByPercent',
                    selectedValue: 0,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'groupByMaxCount',
                    label: 'Max number of bars',
                    type: 'text',
                    dataType: 'number',
                    name: 'vbar-slice-max-count',
                    minValue: 1,
                    maxValue: 12,
                    allowPattern: '^([1-9]|1[0-2])$',
                    selectedValue: 7,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'showOthers',
                    label: 'Others',
                    type: 'toggle',
                    name: 'vbar-show-others',
                    valueLabels: [
                        'Show',
                        'Hide',
                    ],
                    selectedValue: true,
                    cssClass: 'display-inline label-pos',
                },
            ],
        },
        {
            hideToggle: true,
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            name: 'vbar-enableTooltip',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            hideToggle: true,
            id: 'showHoverLines',
            label: 'Hover Line',
            type: 'toggle',
            name: 'vbar-showHoverLines',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'vbar-numberunits',
                    selectedValue: 'actual',
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'vbar-numberformat',
                    selectedValue: 'XX',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'vbar-decimalPlaces',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 0,
                },
                {
                    id: 'gridLineStyle',
                    label: 'GridLine Style',
                    type: 'select',
                    values: [
                        {
                            label: 'Solid',
                            value: 'solid-line',
                        },
                        {
                            label: 'Dotted',
                            value: 'dotted-line',
                        },
                        {
                            label: 'None',
                            value: 'none',
                        },
                    ],
                    name: 'vbar-gridLineStyle',
                    selectedValue: 'solid-line',
                },
            ],
        },
    ],
    HORIZONTAL_BAR_CHART: [
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Chart Sorting',
            selectedValue: true,
            configs: [
                {
                    label: 'Sort By',
                    type: 'select',
                    name: 'sort-table-by',
                    id: 'sortTableBy',
                    selectedValue: 'VALUE',
                    values: [
                        {
                            label: 'Slicer',
                            value: 'SLICER',
                        },
                        {
                            label: 'Value',
                            value: 'VALUE',
                        },
                    ],
                },
                {
                    label: 'Sort Direction',
                    id: 'tableSortDirection',
                    type: 'select',
                    name: 'sort-table-ascending',
                    selectedValue: 'DESC',
                    values: [
                        {
                            label: 'Asc',
                            value: 'ASC',
                        },
                        {
                            label: 'Desc',
                            value: 'DESC',
                        },
                    ],
                },
                {
                    label: 'Slicer',
                    id: 'sortOrderSlicer',
                    type: 'select',
                    name: 'sort-order-slicer',
                    hidden: true,
                },
            ],
        },
        {
            label: 'Legend',
            type: 'toggle',
            name: 'hbar-tooltip',
            id: 'enableLegend',
            selectedValue: false,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Right',
                            value: 'right',
                        },
                        {
                            label: 'Top',
                            value: 'top',
                        },
                    ],
                    name: 'hbar-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'barYAxis',
            label: 'Vertical Axis',
            type: 'toggle',
            name: 'hbar-barYAxis',
            selectedValue: true,
            configs: [
                {
                    id: 'barYAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'hbar-barYAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barYAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'hbar-barYAxisLine',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
            ],
        },
        {
            id: 'barXAxis',
            label: 'Horizontal Axis',
            type: 'toggle',
            name: 'hbar-barXAxis',
            selectedValue: false,
            configs: [
                {
                    id: 'barXAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'hbar-barXAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barXAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'hbar-barXAxisLine',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
            ],
        },
        {
            id: 'inChartLabel',
            label: 'Bar Labels',
            type: 'toggle',
            name: 'hbar-inChartLabel',
            selectedValue: true,
            disableChild: true,
            configs: [
                {
                    id: 'enableInsideLabel',
                    label: 'Label Position',
                    type: 'toggle',
                    name: 'hbar-enableInsideLabel',
                    valueLabels: [
                        'Inside',
                        'Outside',
                    ],
                    selectedValue: false,
                    cssClass: 'display-inline label-pos',
                },
            ],
        },
        {
            id: 'enableSliceManagement',
            label: 'Bar Management',
            type: 'toggle',
            name: 'hbar-enableSliceManagement',
            selectedValue: false,
            disableChild: true,
            configs: [
                {
                    id: 'sliceManagementType',
                    label: 'Management Type',
                    type: 'select',
                    values: [
                        {
                            label: 'Percentage',
                            value: 'percentage',
                        },
                        {
                            label: 'Max Count',
                            value: 'maxCount',
                        },
                    ],
                    name: 'hbar-slice-management-type',
                    selectedValue: 'percentage',
                },
                {
                    id: 'groupByPercent',
                    label: 'Only Show Bars Above',
                    type: 'select',
                    values: [
                        {
                            label: '0%',
                            value: 0,
                        },
                        {
                            label: '1%',
                            value: 1,
                        },
                        {
                            label: '2%',
                            value: 2,
                        },
                        {
                            label: '3%',
                            value: 3,
                        },
                        {
                            label: '4%',
                            value: 4,
                        },
                        {
                            label: '5%',
                            value: 5,
                        },
                        {
                            label: '6%',
                            value: 6,
                        },
                        {
                            label: '7%',
                            value: 7,
                        },
                        {
                            label: '8%',
                            value: 8,
                        },
                        {
                            label: '9%',
                            value: 9,
                        },
                        {
                            label: '10%',
                            value: 10,
                        },
                    ],
                    name: 'hbar-groupByPercent',
                    selectedValue: 0,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'groupByMaxCount',
                    label: 'Max number of bars',
                    type: 'text',
                    dataType: 'number',
                    name: 'hbar-slice-max-count',
                    minValue: 1,
                    maxValue: 12,
                    allowPattern: '^([1-9]|1[0-2])$',
                    selectedValue: 7,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'showOthers',
                    label: 'Others',
                    type: 'toggle',
                    name: 'vbar-show-others',
                    valueLabels: [
                        'Show',
                        'Hide',
                    ],
                    selectedValue: true,
                    cssClass: 'display-inline label-pos',
                },
            ],
        },
        {
            hideToggle: true,
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            name: 'hbar-enableTooltip',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            hideToggle: true,
            id: 'showHoverLines',
            label: 'Hover Line',
            type: 'toggle',
            name: 'hbar-showHoverLines',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'hbar-numberunits',
                    selectedValue: 'actual',
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'hbar-numberformat',
                    selectedValue: 'XX',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'hbar-decimalPlaces',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 0,
                },
                {
                    id: 'gridLineStyle',
                    label: 'GridLine Style',
                    type: 'select',
                    values: [
                        {
                            label: 'Solid',
                            value: 'solid-line',
                        },
                        {
                            label: 'Dotted',
                            value: 'dotted-line',
                        },
                        {
                            label: 'None',
                            value: 'none',
                        },
                    ],
                    name: 'hbar-gridLineStyle',
                    selectedValue: 'none',
                },
            ],
        },
    ],
    VERTICAL_STACKED_BAR_CHART: [
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Chart Sorting',
            selectedValue: true,
            configs: [
                {
                    label: 'Sort By',
                    type: 'select',
                    name: 'sort-table-by',
                    id: 'sortTableBy',
                    selectedValue: 'VALUE',
                    values: [
                        {
                            label: 'Slicer',
                            value: 'SLICER',
                        },
                        {
                            label: 'Value',
                            value: 'VALUE',
                        },
                    ],
                },
                {
                    label: 'Sort Direction',
                    id: 'tableSortDirection',
                    type: 'select',
                    name: 'sort-table-ascending',
                    selectedValue: 'DESC',
                    values: [
                        {
                            label: 'Asc',
                            value: 'ASC',
                        },
                        {
                            label: 'Desc',
                            value: 'DESC',
                        },
                    ],
                },
                {
                    label: 'Slicer',
                    id: 'sortOrderSlicer',
                    type: 'select',
                    name: 'sort-order-slicer',
                    hidden: true,
                },
            ],
        },
        {
            label: 'Legend',
            type: 'toggle',
            name: 'vsbar-tooltip',
            id: 'enableLegend',
            selectedValue: true,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Right',
                            value: 'right',
                        },
                        {
                            label: 'Top',
                            value: 'top',
                        },
                        {
                            label: 'Bottom',
                            value: 'bottom',
                        },
                    ],
                    name: 'vsbar-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'barYAxis',
            label: 'Vertical Axis',
            type: 'toggle',
            name: 'vsbar-barYAxis',
            selectedValue: true,
            configs: [
                {
                    id: 'barYAxisPosition',
                    label: 'Axis Position',
                    type: 'toggle',
                    valueLabels: [
                        'Left',
                        'Right',
                    ],
                    name: 'vsbar-barYAxisPosition',
                    selectedValue: true,
                    cssClass: 'display-axis-line',
                },
                {
                    id: 'barYAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'vsbar-barYAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barYAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'vsbar-barYAxisLine',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
            ],
        },
        {
            id: 'barXAxis',
            label: 'Horizontal Axis',
            type: 'toggle',
            name: 'vsbar-barXAxis',
            selectedValue: true,
            configs: [
                {
                    id: 'barXAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'vsbar-barXAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barXAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'vsbar-barXAxisLine',
                    cssClass: 'display-inline',
                    selectedValue: false,
                },
            ],
        },
        {
            id: 'inChartLabel',
            label: 'Bar Labels',
            type: 'toggle',
            name: 'vsbar-inChartLabel',
            selectedValue: false,
            disableChild: true,
            configs: [
                {
                    id: 'enableInsideLabel',
                    label: 'In Chart Value',
                    type: 'toggle',
                    name: 'vsbar-enableInsideLabel',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
                {
                    id: 'enableTotalLabel',
                    label: 'Total Value',
                    type: 'toggle',
                    name: 'vsbar-enableTotalLabel',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
                {
                    id: 'labelOrientation',
                    label: 'Orientation',
                    type: 'select',
                    disabled: false,
                    values: [
                        {
                            label: 'Horizontal',
                            value: 'horizontal',
                        },
                        {
                            label: 'Vertical',
                            value: 'vertical',
                        },
                    ],
                    name: 'vsbar-labelOrientation',
                    selectedValue: 'vertical',
                },
            ],
        },
        {
            id: 'enableSliceManagement',
            label: 'Bar Management',
            type: 'toggle',
            name: 'vsbar-enableSliceManagement',
            selectedValue: false,
            disableChild: true,
            configs: [
                {
                    id: 'sliceManagementType',
                    label: 'Management Type',
                    type: 'select',
                    values: [
                        {
                            label: 'Percentage',
                            value: 'percentage',
                        },
                        {
                            label: 'Max Count',
                            value: 'maxCount',
                        },
                    ],
                    name: 'vsbar-slice-management-type',
                    selectedValue: 'percentage',
                },
                {
                    id: 'groupByPercent',
                    label: 'Only Show Bars Above',
                    type: 'select',
                    values: [
                        {
                            label: '0%',
                            value: 0,
                        },
                        {
                            label: '1%',
                            value: 1,
                        },
                        {
                            label: '2%',
                            value: 2,
                        },
                        {
                            label: '3%',
                            value: 3,
                        },
                        {
                            label: '4%',
                            value: 4,
                        },
                        {
                            label: '5%',
                            value: 5,
                        },
                        {
                            label: '6%',
                            value: 6,
                        },
                        {
                            label: '7%',
                            value: 7,
                        },
                        {
                            label: '8%',
                            value: 8,
                        },
                        {
                            label: '9%',
                            value: 9,
                        },
                        {
                            label: '10%',
                            value: 10,
                        },
                    ],
                    name: 'vsbar-groupByPercent',
                    selectedValue: 0,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'groupByMaxCount',
                    label: 'Max number of bars',
                    type: 'text',
                    dataType: 'number',
                    name: 'vsbar-slice-max-count',
                    minValue: 1,
                    maxValue: 12,
                    allowPattern: '^([1-9]|1[0-2])$',
                    selectedValue: 7,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'showOthers',
                    label: 'Others',
                    type: 'toggle',
                    name: 'vbar-show-others',
                    valueLabels: [
                        'Show',
                        'Hide',
                    ],
                    selectedValue: true,
                    cssClass: 'display-inline label-pos',
                },
            ],
        },
        {
            hideToggle: true,
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            name: 'vsbar-enableTooltip',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            hideToggle: true,
            id: 'showHoverLines',
            label: 'Hover Line',
            type: 'toggle',
            name: 'vsbar-showHoverLines',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'vsbar-numberunits',
                    selectedValue: 'actual',
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'vsbar-numberformat',
                    selectedValue: 'XX',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'vsbar-decimalPlaces',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 0,
                },
                {
                    id: 'gridLineStyle',
                    label: 'GridLine Style',
                    type: 'select',
                    values: [
                        {
                            label: 'Solid',
                            value: 'solid-line',
                        },
                        {
                            label: 'Dotted',
                            value: 'dotted-line',
                        },
                        {
                            label: 'None',
                            value: 'none',
                        },
                    ],
                    name: 'vsbar-gridLineStyle',
                    selectedValue: 'solid-line',
                },
            ],
        },
    ],
    HORIZONTAL_STACKED_BAR_CHART: [
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Chart Sorting',
            selectedValue: true,
            configs: [
                {
                    label: 'Sort By',
                    type: 'select',
                    name: 'sort-table-by',
                    id: 'sortTableBy',
                    selectedValue: 'VALUE',
                    values: [
                        {
                            label: 'Slicer',
                            value: 'SLICER',
                        },
                        {
                            label: 'Value',
                            value: 'VALUE',
                        },
                    ],
                },
                {
                    label: 'Sort Direction',
                    id: 'tableSortDirection',
                    type: 'select',
                    name: 'sort-table-ascending',
                    selectedValue: 'DESC',
                    values: [
                        {
                            label: 'Asc',
                            value: 'ASC',
                        },
                        {
                            label: 'Desc',
                            value: 'DESC',
                        },
                    ],
                },
                {
                    label: 'Slicer',
                    id: 'sortOrderSlicer',
                    type: 'select',
                    name: 'sort-order-slicer',
                    hidden: true,
                },
            ],
        },
        {
            label: 'Legend',
            type: 'toggle',
            name: 'hsbar-tooltip',
            id: 'enableLegend',
            selectedValue: true,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Top',
                            value: 'top',
                        },
                    ],
                    name: 'hsbar-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'barYAxis',
            label: 'Vertical Axis',
            type: 'toggle',
            name: 'hsbar-barYAxis',
            selectedValue: true,
            configs: [
                {
                    id: 'barYAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'hsbar-barYAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barYAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'hsbar-barYAxisLine',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
            ],
        },
        {
            id: 'barXAxis',
            label: 'Horizontal Axis',
            type: 'toggle',
            name: 'hsbar-barXAxis',
            selectedValue: false,
            configs: [
                {
                    id: 'barXAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'hsbar-barXAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barXAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'hsbar-barXAxisLine',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
            ],
        },
        {
            id: 'inChartLabel',
            label: 'Bar Labels',
            type: 'toggle',
            name: 'hsbar-inChartLabel',
            selectedValue: true,
            disableChild: true,
            configs: [
                {
                    id: 'enableInsideLabel',
                    label: 'In Chart Value',
                    type: 'toggle',
                    name: 'hsbar-enableInsideLabel',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
                {
                    id: 'enableTotalLabel',
                    label: 'Total Value',
                    type: 'toggle',
                    name: 'hsbar-enableTotalLabel',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
            ],
        },
        {
            id: 'enableSliceManagement',
            label: 'Bar Management',
            type: 'toggle',
            name: 'hsbar-enableSliceManagement',
            selectedValue: false,
            disableChild: true,
            configs: [
                {
                    id: 'sliceManagementType',
                    label: 'Management Type',
                    type: 'select',
                    values: [
                        {
                            label: 'Percentage',
                            value: 'percentage',
                        },
                        {
                            label: 'Max Count',
                            value: 'maxCount',
                        },
                    ],
                    name: 'hsbar-slice-management-type',
                    selectedValue: 'percentage',
                },
                {
                    id: 'groupByPercent',
                    label: 'Only Show Bars Above',
                    type: 'select',
                    values: [
                        {
                            label: '0%',
                            value: 0,
                        },
                        {
                            label: '1%',
                            value: 1,
                        },
                        {
                            label: '2%',
                            value: 2,
                        },
                        {
                            label: '3%',
                            value: 3,
                        },
                        {
                            label: '4%',
                            value: 4,
                        },
                        {
                            label: '5%',
                            value: 5,
                        },
                        {
                            label: '6%',
                            value: 6,
                        },
                        {
                            label: '7%',
                            value: 7,
                        },
                        {
                            label: '8%',
                            value: 8,
                        },
                        {
                            label: '9%',
                            value: 9,
                        },
                        {
                            label: '10%',
                            value: 10,
                        },
                    ],
                    name: 'hsbar-groupByPercent',
                    selectedValue: 0,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'groupByMaxCount',
                    label: 'Max number of bars',
                    type: 'text',
                    dataType: 'number',
                    name: 'hsbar-slice-max-count',
                    minValue: 1,
                    maxValue: 12,
                    allowPattern: '^([1-9]|1[0-2])$',
                    selectedValue: 7,
                    hidden: true,
                    cssClass: 'hidden',
                },
                {
                    id: 'showOthers',
                    label: 'Others',
                    type: 'toggle',
                    name: 'vbar-show-others',
                    valueLabels: [
                        'Show',
                        'Hide',
                    ],
                    selectedValue: true,
                    cssClass: 'display-inline label-pos',
                },
            ],
        },
        {
            hideToggle: true,
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            name: 'hsbar-enableTooltip',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            hideToggle: true,
            id: 'showHoverLines',
            label: 'Hover Line',
            type: 'toggle',
            name: 'hsbar-showHoverLines',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'hsbar-numberunits',
                    selectedValue: 'actual',
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'hsbar-numberformat',
                    selectedValue: 'XX',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'hsbar-decimalPlaces',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 0,
                },
                {
                    id: 'gridLineStyle',
                    label: 'GridLine Style',
                    type: 'select',
                    values: [
                        {
                            label: 'Solid',
                            value: 'solid-line',
                        },
                        {
                            label: 'Dotted',
                            value: 'dotted-line',
                        },
                        {
                            label: 'None',
                            value: 'none',
                        },
                    ],
                    name: 'hsbar-gridLineStyle',
                    selectedValue: 'none',
                },
            ],
        },
    ],
    VERTICAL_MIRRORED_BAR_CHART: [
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Chart Sorting',
            selectedValue: true,
            configs: [
                {
                    label: 'Sort By',
                    type: 'select',
                    name: 'sort-table-by',
                    id: 'sortTableBy',
                    selectedValue: 'VALUE',
                    values: [
                        {
                            label: 'Slicer',
                            value: 'SLICER',
                        },
                        {
                            label: 'Value',
                            value: 'VALUE',
                        },
                    ],
                },
                {
                    label: 'Sort Direction',
                    id: 'tableSortDirection',
                    type: 'select',
                    name: 'sort-table-ascending',
                    selectedValue: 'DESC',
                    values: [
                        {
                            label: 'Asc',
                            value: 'ASC',
                        },
                        {
                            label: 'Desc',
                            value: 'DESC',
                        },
                    ],
                },
            ],
        },
        {
            label: 'Legend',
            type: 'toggle',
            name: 'vmbar-tooltip',
            id: 'enableLegend',
            selectedValue: true,
            configs: [
                {
                    id: 'legendPosition',
                    label: 'Legend Position',
                    type: 'select',
                    values: [
                        {
                            label: 'Right',
                            value: 'right',
                        },
                        {
                            label: 'Top',
                            value: 'top',
                        },
                    ],
                    name: 'vmbar-legendPosition',
                    selectedValue: 'top',
                },
            ],
        },
        {
            id: 'barYAxis',
            label: 'Vertical Axis',
            type: 'toggle',
            name: 'vmbar-barYAxis',
            selectedValue: true,
            configs: [
                {
                    id: 'barYAxisPosition',
                    label: 'Axis Position',
                    type: 'toggle',
                    valueLabels: [
                        'Left',
                        'Right',
                    ],
                    name: 'vmbar-barYAxisPosition',
                    selectedValue: true,
                    cssClass: 'display-axis-line',
                },
                {
                    id: 'barYAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'vmbar-barYAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barYAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'vmbar-barYAxisLine',
                    selectedValue: false,
                    cssClass: 'display-inline',
                },
            ],
        },
        {
            id: 'barXAxis',
            label: 'Horizontal Axis',
            type: 'toggle',
            name: 'vmbar-barXAxis',
            selectedValue: true,
            configs: [
                {
                    id: 'barXAxisLabels',
                    label: 'Labels',
                    type: 'toggle',
                    name: 'vmbar-barXAxisLabels',
                    selectedValue: true,
                    cssClass: 'display-inline',
                },
                {
                    id: 'barXAxisLine',
                    label: 'Display Axis Line',
                    type: 'toggle',
                    name: 'vmbar-barXAxisLine',
                    cssClass: 'display-inline',
                    selectedValue: false,
                },
            ],
        },
        {
            id: 'inChartLabel',
            label: 'Bar Labels',
            type: 'toggle',
            name: 'vmbar-inChartLabel',
            selectedValue: false,
            disableChild: true,
            configs: [
                {
                    id: 'enableInsideLabel',
                    label: 'Label Position',
                    type: 'toggle',
                    name: 'vmbar-enableInsideLabel',
                    valueLabels: [
                        'Inside',
                        'Outside',
                    ],
                    selectedValue: false,
                    cssClass: 'display-inline label-pos',
                },
                {
                    id: 'labelOrientation',
                    label: 'Orientation',
                    type: 'select',
                    disabled: false,
                    values: [
                        {
                            label: 'Horizontal',
                            value: 'horizontal',
                        },
                        {
                            label: 'Vertical',
                            value: 'vertical',
                        },
                    ],
                    name: 'vmbar-labelOrientation',
                    selectedValue: 'vertical',
                },
            ],
        },
        {
            hideToggle: true,
            id: 'enableTooltip',
            label: 'Display Tooltip',
            type: 'toggle',
            name: 'vmbar-enableTooltip',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            hideToggle: true,
            id: 'showHoverLines',
            label: 'Hover Line',
            type: 'toggle',
            name: 'vmbar-showHoverLines',
            selectedValue: true,
            cssClass: 'display-inline-block',
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Miscellaneous',
            selectedValue: true,
            configs: [
                {
                    id: 'numberUnits',
                    label: 'Number Units',
                    type: 'select',
                    values: [
                        {
                            label: 'Thousands',
                            value: 'thousands',
                        },
                        {
                            label: 'Millions',
                            value: 'millions',
                        },
                        {
                            label: 'Billions',
                            value: 'billions',
                        },
                        {
                            label: 'Actual',
                            value: 'actual',
                        },
                    ],
                    name: 'vmbar-numberunits',
                    selectedValue: 'actual',
                },
                {
                    id: 'numberFormat',
                    label: 'Number Format',
                    type: 'select',
                    values: [
                        {
                            label: 'XX.00 Trim',
                            value: 'XX.00 Trim',
                        },
                        {
                            label: 'XX.00',
                            value: 'XX.00',
                        },
                        {
                            label: 'XX',
                            value: 'XX',
                        },
                        {
                            label: '$',
                            value: '$',
                        },
                        {
                            label: '%',
                            value: '%',
                        },
                    ],
                    name: 'vmbar-numberformat',
                    selectedValue: 'XX',
                    disabled: false,
                },
                {
                    id: 'decimalPlaces',
                    label: 'Number Decimals',
                    type: 'text',
                    dataType: 'number',
                    name: 'vmbar-decimalPlaces',
                    minValue: 0,
                    maxValue: 20,
                    allowPattern: '^(\\d|1\\d|20)$',
                    selectedValue: 0,
                },
                {
                    id: 'gridLineStyle',
                    label: 'GridLine Style',
                    type: 'select',
                    values: [
                        {
                            label: 'Solid',
                            value: 'solid-line',
                        },
                        {
                            label: 'Dotted',
                            value: 'dotted-line',
                        },
                        {
                            label: 'None',
                            value: 'none',
                        },
                    ],
                    name: 'vmbar-gridLineStyle',
                    selectedValue: 'solid-line',
                },
            ],
        },
    ],
    SIMPLE_GRID: [
        {
            id: 'hideGridHeaders',
            label: 'Hide Grid Headers',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'hide-grid-headers',
            selectedValue: false,
        },
        {
            id: 'showQuickSearch',
            label: 'Show Row Quick-search',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'show-quick-search',
            selectedValue: false,
        },
        {
            id: 'distinctRowsOnly',
            label: 'Show Distinct Rows only',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'distinct-rows-only',
            selectedValue: false,
        },
        {
            label: 'Show Grand Total',
            type: 'toggle',
            name: 'simple-grid-grandtotal-toggle',
            id: 'showGrandTotal',
            selectedValue: false,
            configs: [
                {
                    id: 'showTotalAtTop',
                    label: 'Display Location',
                    type: 'toggle',
                    valueLabels: [
                        'Top',
                        'Bottom',
                    ],
                    name: 'simple-grid-totalpos',
                    cssClass: 'display-inline display-location',
                    selectedValue: false,
                },
            ],
        },
        {
            label: 'Alternate Row Shading',
            type: 'toggle',
            name: 'simple-grid-altrowshading-toggle',
            cssClass: 'display-inline-block',
            id: 'alternateRowShading',
            selectedValue: false,
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Row Height',
            selectedValue: true,
            configs: [
                {
                    label: 'Row Height',
                    id: 'rowHeight',
                    type: 'select',
                    valueType: 'number',
                    values: [
                        {
                            label: 'Small',
                            value: 20,
                        },
                        {
                            label: 'Medium',
                            value: 25,
                        },
                        {
                            label: 'Large',
                            value: 30,
                        },
                        {
                            label: 'Very Large',
                            value: 40,
                        },
                    ],
                    name: 'simple-grid-rowheight',
                    selectedValue: 25,
                },
            ],
        },
        {
            id: 'showHyperlinks',
            label: 'Hyperlinks',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'simple-grid-hyperlinks',
            selectedValue: true,
        },
        {
            id: 'autoSizeColumns',
            label: 'Auto Fit Columns On Update',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'simple-grid-auto-fit',
            selectedValue: true,
        },
        {
            id: 'showColumnHeaderToolTip',
            label: 'Column Header tooltip',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'simple-grid-col-hdr-ttp',
            selectedValue: true,
        },
        {
            name: '',
            id: 'isPivotMode',
            label: 'Pivot Mode',
            type: 'toggle',
            cssClass: 'hidden',
            selectedValue: false,
            hidden: true,
        },
    ],
    ADVANCED_GRID: [
        {
            id: 'hideGridHeaders',
            label: 'Hide Grid Headers',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'hide-grid-headers',
            selectedValue: false,
        },
        {
            id: 'useCustomHeaderNames',
            label: 'Use Custom Header Names',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'use-custom-header-names',
            selectedValue: false,
        },
        {
            id: 'distinctRowsOnly',
            label: 'Show Distinct Rows only',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'distinct-rows-only',
            selectedValue: false,
        },
        {
            label: 'Show Grand Total',
            type: 'toggle',
            name: 'advanced-grid-grandtotal-toggle',
            id: 'showGrandTotal',
            selectedValue: false,
            configs: [
                {
                    id: 'showTotalAtTop',
                    label: 'Display Location',
                    type: 'toggle',
                    valueLabels: [
                        'Top',
                        'Bottom',
                    ],
                    name: 'advanced-grid-totalpos',
                    cssClass: 'display-inline display-location',
                    selectedValue: false,
                },
            ],
        },
        {
            label: 'Show Subtotals',
            type: 'toggle',
            name: 'advanced-grid-subtotals-toggle',
            id: 'showSubTotal',
            selectedValue: true,
            configs: [
                {
                    id: 'showSubTotalAtTop',
                    label: 'Export Location',
                    type: 'toggle',
                    valueLabels: [
                        'Top',
                        'Bottom',
                    ],
                    name: 'advanced-grid-subtotalpos',
                    cssClass: 'display-inline',
                    selectedValue: false,
                },
            ],
        },
        {
            label: 'Alternate Row Shading',
            type: 'toggle',
            name: 'advanced-grid-altrowshading-toggle',
            cssClass: 'display-inline-block',
            id: 'alternateRowShading',
            selectedValue: false,
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Row Height',
            selectedValue: true,
            configs: [
                {
                    label: 'Row Height',
                    id: 'rowHeight',
                    type: 'select',
                    valueType: 'number',
                    values: [
                        {
                            label: 'Small',
                            value: 20,
                        },
                        {
                            label: 'Medium',
                            value: 25,
                        },
                        {
                            label: 'Large',
                            value: 30,
                        },
                        {
                            label: 'Very Large',
                            value: 40,
                        },
                    ],
                    name: 'advanced-grid-rowheight',
                    selectedValue: 25,
                },
            ],
        },
        {
            id: 'showHyperlinks',
            label: 'Hyperlinks',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'advanced-grid-hyperlinks',
            selectedValue: true,
        },
        {
            id: 'repeatGroupData',
            label: 'Repeat values for group data',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'advanced-grid-repeat-gp-data',
            selectedValue: false,
        },
        {
            id: 'autoSizeColumns',
            label: 'Auto Fit Columns On Update',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'advanced-grid-auto-fit',
            selectedValue: true,
        },
        {
            id: 'showColumnHeaderToolTip',
            label: 'Column Header tooltip',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'advanced-grid-col-hdr-ttp',
            selectedValue: true,
        },
        {
            id: 'showGrouperCount',
            label: 'Grouper Count',
            type: 'toggle',
            cssClass: 'display-inline-block',
            name: 'advanced-grid-col-hdr-ttp',
            selectedValue: false,
        },
        {
            id: 'isPivotMode',
            label: 'Pivot Mode',
            name: '',
            type: 'toggle',
            cssClass: 'hidden',
            selectedValue: false,
            hidden: true,
        },
        {
            id: 'groupSort',
            name: '',
            label: 'Group Sort',
            type: 'select',
            selectedValue: null,
            hidden: true,
        },
        {
            id: 'groupSortIndex',
            name: '',
            label: 'Group Sort Index',
            type: 'text',
            selectedValue: null,
            hidden: true,
        },
        {
            id: '',
            name: '',
            type: undefined,
            label: 'Grouper Behavior',
            selectedValue: true,
            configs: [
                {
                    label: 'Grouper Behavior',
                    id: 'groupDefaultExpanded',
                    type: 'select',
                    valueType: 'number',
                    values: [
                        {
                            label: 'All Default Closed',
                            value: 0,
                        },
                        {
                            label: 'First Grouper Default Open',
                            value: 1,
                        },
                        {
                            label: 'First 2 Groupers Default Open',
                            value: 2,
                        },
                        {
                            label: 'First 3 Groupers Default Open',
                            value: 3,
                        },
                        {
                            label: 'First 4 Groupers Default Open',
                            value: 4,
                        },
                        {
                            label: 'All Default Open',
                            value: -1,
                        },
                    ],
                    name: 'advanced-grid-grouperBehavior',
                    selectedValue: 0,
                },
            ],
        },
    ],
};
