import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValueOption } from '@ddv/common-components';

interface Option {
    name: string;
    id: number;
}

@Component({
    selector: 'app-selector',
    templateUrl: 'selector.component.html',
    styleUrls: ['selector.component.scss'],
})
export class SelectorComponent {
    @Input() searchable = false;
    @Input() placeholder: string | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() options: any[] | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() selected: any | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() selectedChange = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() displayField: any | undefined;
    @Input() headers: string[] | undefined;
    @Output() newValueAdded = new EventEmitter<string>();
    @Input() addBtnVisible = false;

    showValuesList = false;
    searchValue: string = '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    select(option: any): void {
        this.selected = option;
        this.selectedChange.emit(this.selected);
        this.closeValueList();
    }

    add(newValue: string): void {
        this.newValueAdded.emit(newValue);
        this.closeValueList();
    }

    closeValueList(): void {
        this.searchValue = '';
        this.showValuesList = false;
    }

    toggleValueList(): void {
        this.searchValue = '';
        this.showValuesList = !this.showValuesList;
    }

    getSelectedOptionText(selected: string | Option, option: ValueOption | Option, currentText?: string): string {
        if (selected) {
            if (currentText && typeof selected === 'string' && selected === (option as ValueOption).value) {
                return currentText;
            }

            const selectedOptionName = (selected as Option).name;
            if (selectedOptionName && selectedOptionName === (option as Option).name) {
                return selectedOptionName;
            }
        }

        return '';
    }
}
