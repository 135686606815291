import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DirtyFlagService } from '@ddv/behaviors';
import {
    ConfirmationPopupService,
    ModalDialogRef,
    ModalDialogService,
    MultiSubscriptionComponent,
} from '@ddv/common-components';
import { DashboardService } from '@ddv/dashboards';
import { Entitlements, FeatureFlagService, UserEntitlements, UserEntitlementService, UserService } from '@ddv/entitlements';
import { setWidgetExtraParamsFromDashboardDetails, LayoutService, ManagerService } from '@ddv/layout';
import {
    DashboardDetails,
    DashboardModel,
    DashboardSnapshot,
    WidgetOnBoard,
    UserPreferences,
    Tag,
    WidgetSnapshot,
} from '@ddv/models';
import { WidgetFinderComponent, WidgetConfigurationManager } from '@ddv/widgets';
import { Theme, ThemeService } from '@hs/ui-core-presentation';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { ViewLink, ViewTile } from '../../models/dashboard-nav.model';
import { DashboardNavService } from '../../services/dashboard-nav.service';
import { RevertDashboardService } from '../../services/revert-dashboard.service';
import { DashboardFinderComponent } from '../dashboard-finder-dialog/dashboard-finder.component';
import { SaveDashboardFormComponent } from '../saved-dashboard-form/save-dashboard-form.component';
import { WidgetFinderV2Component } from '../widget-finder-v2/widget-finder-v2.component';

@Component({
    selector: 'app-dashboard-nav',
    templateUrl: 'dashboard-nav.component.html',
    styleUrls: ['dashboard-nav.component.scss'],
})
export class DashboardNavComponent extends MultiSubscriptionComponent implements OnInit {
    @ViewChild('dashboardList', { static: false }) private readonly dashboardList: ElementRef | undefined;
    @ViewChild('leftNav', { static: true }) private readonly leftNav: ElementRef | undefined;

    tiles: ViewTile[] = [];
    currentView: ViewLink | undefined;
    inPresentationMode: boolean = false;
    copyIsAllowed: boolean = false;

    isSideNavExpanded = true;
    showManageWidgetTab = false;
    showManageViewsTab = false;
    showCreateViewTab = false;
    showViewsWrapper: number | undefined;
    showViewAndWidgetTab = false;
    showIconsWrapper = false;
    showFindWidgetIcon = false;
    dashboardEdit = false;
    userEntitlements: UserEntitlements | undefined;
    userPreferences: UserPreferences = new UserPreferences();
    isGlobalEditPartial = false;
    theme: Theme = Theme.light;

    @Output() revertChanges = new EventEmitter();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly manager: ManagerService,
        public dashboardService: DashboardService,
        private readonly dashboardNavService: DashboardNavService,
        private readonly userEntitlementService: UserEntitlementService,
        public dirtyFlagService: DirtyFlagService,
        private readonly userService: UserService,
        private readonly modalService: ModalDialogService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly layoutService: LayoutService,
        private readonly confirmationPopupService: ConfirmationPopupService,
        private readonly revertDashboardService: RevertDashboardService,
        private readonly themeService: ThemeService,
        private readonly featureFlagService: FeatureFlagService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initTransitionEvents();

        this.subscribeTo(combineLatest([
            this.route.paramMap,
            this.userEntitlementService.entitlementsForClientCode$,
            this.dashboardNavService.viewTiles,
            this.userService.userPreferences$,
            this.themeService.currentTheme$,
            this.featureFlagService.isFlagEnabled('allow-access-new-widget-flow'),
        ]), async ([params, entitlements, viewTiles, userPreferences, theme, showViewAndWidgetTab]) => {
            this.showViewAndWidgetTab = showViewAndWidgetTab;

            this.userPreferences = userPreferences;
            this.theme = theme;

            this.tiles = viewTiles;
            if (this.currentView) {
                if (!viewTiles.length) {
                    this.manager.removeWorkspace();
                    await this.navigateToBoardAndMode(undefined, 'view');
                } else if (!viewTiles.some((t) => t.links.some((l) => l.id === this.currentView?.id))) {
                    await this.navigateToBoardAndMode(this.tiles[0].selectedLink?.id, this.inPresentationMode ? 'view' : 'edit');
                }
            }

            const viewId = Number(params.get('dashboardId'));
            this.currentView = undefined;
            this.tiles.forEach((t) => {
                if (t.links.map((l) => l.id).includes(viewId)) {
                    t.selectedLink = t.links.find((l) => l.id === viewId);
                    this.currentView = t.selectedLink;
                }
            });

            this.inPresentationMode = params.get('mode') === 'view';
            this.bringDashboardIntoView();

            this.showManageWidgetTab = entitlements.hasPermission(Entitlements.WIDGET_VIEW);
            this.showManageViewsTab = entitlements.hasPermission(Entitlements.DASHBOARD_VIEW);
            this.showCreateViewTab = entitlements.hasPermission(Entitlements.DASHBOARD_CREATE);
            this.dashboardEdit = entitlements.hasPermission(Entitlements.DASHBOARD_EDIT);

            this.userEntitlements = entitlements;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.isGlobalEditPartial = (this.currentView as any)?.isGlobal && this.userEntitlements?.haveGlobalEditPartial;

            this.copyIsAllowed = !this.inPresentationMode && entitlements.hasPermission(Entitlements.DASHBOARD_CREATE);

            this.setIconsWrapperVisibility();
            this.setShowWidgetIcon();
        });

        this.subscribeTo(this.manager.isWidgetMaximized$, (isWidgetMaximized: boolean) => {
            this.toggleNavbar(isWidgetMaximized);
        });
    }

    onDragEnd(event: CdkDragDrop<ViewTile[]>): void {
        moveItemInArray(this.tiles, event.previousIndex, event.currentIndex);
        const viewIds = this.tiles.map((item) => item.links[0].id.toString());
        this.userService.updateOpenDashboardOrder(viewIds).subscribe();
    }

    createDashboard(): void {
        if (this.dirtyFlagService.isDirty) {
            const confirmDialogOptions = {
                message: 'You have un-saved changes. What would you like to do:',
                confirmButtonText: 'Continue Editing',
                denyButtonText: 'Discard Changes',
            };
            this.confirmationPopupService.showConfirmationPopup(confirmDialogOptions, true).subscribe({
                next: (action) => {
                    if (action !== 'confirm') {
                        this.discardChangesCallback();
                    }
                },
            });
        } else {
            this.createNewDashboard();
        }
    }

    findDashboard(): void {
        this.modalService.open(DashboardFinderComponent, { windowClass: 'dashboard-finder-popup' });
    }

    findWidget(): void {
        if (this.isGlobalEditPartial || !this.currentView) {
            return;
        }

        const dialogRef = this.modalService.open(WidgetFinderComponent, { windowClass: 'finder-popup' });
        const popup = (dialogRef.componentInstance as WidgetFinderComponent);

        popup.showOnlyGlobal = !!this.currentView.isGlobal;

        popup.widgetAdded.subscribe((widget: WidgetSnapshot) => {
            this.dashboardService.addWidgetToBoard(this.currentView!.id, widget.id)
                .subscribe((widgetOnBoard) => {
                    dialogRef.close();
                    this.addWidgetToDashboard(widgetOnBoard);
                });
        });
    }

    findWidgetv2(): void {
        if (this.isGlobalEditPartial && !this.inPresentationMode || !this.currentView) {
            return;
        }

        const dialogRef = this.modalService.open(WidgetFinderV2Component, { windowClass: 'widget-finder-popup' });

        dialogRef.componentInstance.groupByText = this.userPreferences.widgetFinderLastTab;
        dialogRef.componentInstance.theme = this.theme;
        dialogRef.componentInstance.inPresentationMode = this.inPresentationMode;

        dialogRef.componentInstance.widgetOpened.subscribe((widget: WidgetSnapshot) => {
            this.updateUserPreferences();
            this.dashboardService.addWidgetToBoard(this.currentView!.id, widget.id)
                .subscribe((widgetOnBoard) => {
                    dialogRef.close();
                    this.addWidgetToDashboard(widgetOnBoard);
                });
        });

        dialogRef.componentInstance.widgetFinderLastTabChange.subscribe(async () => {
            dialogRef.close();
            this.updateUserPreferences();
            await this.router.navigate(['../../../widgets/edit/none'], { relativeTo: this.route });
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dialogRef.componentInstance.widgetFinderLastTabChange.subscribe((widgetFinderLastTab: any) => {
            this.userPreferences.widgetFinderLastTab = widgetFinderLastTab;
        });

        dialogRef.result.then(
            () => {},
            () => { this.updateUserPreferences(); },
        );
    }

    copyDashboard(event: Event, viewId: number | undefined): void {
        event.stopPropagation();
        this.launchCopyDashboardModal(viewId);
    }

    createNewDashboard(): void {
        const saveDashboardFormDialogRef = this.modalService.open(SaveDashboardFormComponent, { windowClass: 'save-dialog' });

        const component: SaveDashboardFormComponent = saveDashboardFormDialogRef.componentInstance;
        component.selectedOption = 'new';
        component.isReadOnly = false;

        component.closeForm.subscribe(() => saveDashboardFormDialogRef.close());
        component.formSubmitted.subscribe((dashboard: DashboardDetails) => {
            this.invokeCreateDashboard(dashboard, saveDashboardFormDialogRef);
        });

        saveDashboardFormDialogRef.result.then(() => {}, () => {});
    }

    async switchDashboard(viewId?: number): Promise<void | undefined> {
        if (viewId?.toString() === this.manager.getCurrentDashboardId()) {
            return;
        }

        return this.navigateToBoardAndMode(viewId, this.inPresentationMode ? 'view' : 'edit')
            .then(() => {
                this.manager.selectWidget(false, undefined);
                this.showViewsWrapper = undefined;
            });
    }

    showGroupedViews(e: Event, groupId: number): void {
        e.stopPropagation();
        this.showViewsWrapper = this.showViewsWrapper === groupId ? undefined : groupId;
    }

    getViewIds(views: DashboardSnapshot[]): string[] {
        return views.map((view) => view.id?.toString() ?? '');
    }

    emitDashboardSnapshot(e: Event, dashboard?: ViewLink): void {
        e.stopPropagation();
        if (dashboard) {
            this.getDashboardDetails(dashboard);
        }
    }

    launchSaveForm(dashboardDetails?: DashboardDetails): void {
        const dialogRef: ModalDialogRef = this.modalService.open(SaveDashboardFormComponent, { windowClass: 'save-dialog' });

        const saveDashboardModal: SaveDashboardFormComponent = dialogRef.componentInstance;
        saveDashboardModal.selectedOption = 'edit';
        saveDashboardModal.isGlobalEditPartial = this.isGlobalEditPartial;
        if (dashboardDetails) {
            saveDashboardModal.dashboard = dashboardDetails;
        }
        saveDashboardModal.dashboardGroupName = this.tiles.find((tile) => tile.selectedLink === this.currentView)?.name ?? '';
        saveDashboardModal.isReadOnly = this.inPresentationMode;
        saveDashboardModal.closeForm.subscribe(() => dialogRef.close());
        saveDashboardModal.formSubmitted.subscribe((emittedDashboardDetails: DashboardDetails) => {
            if (this.inPresentationMode) {
                return this.dashboardService.updateDashboardTags(emittedDashboardDetails.id ?? '', emittedDashboardDetails.dashboardTags)
                    .subscribe({
                        next: (tags: Tag[]) => {
                            this.afterDashboardTagsOnlySaved(emittedDashboardDetails, tags);
                            dialogRef.close();
                        },
                        error: (error) => this.onDashboardSaveError(error, dialogRef),
                    });
            }

            this.dashboardService.updateDashboard(emittedDashboardDetails).subscribe({
                next: (updatedDashboard: DashboardDetails) => {
                    updatedDashboard.isGlobal = emittedDashboardDetails.isGlobal;
                    updatedDashboard.widgets = emittedDashboardDetails.widgets;
                    const dashboard = this.manager.getWorkspace();
                    if (dashboard) {
                        setWidgetExtraParamsFromDashboardDetails(updatedDashboard, dashboard);
                    }
                    dialogRef.close();
                },
                error: (error) => this.onDashboardSaveError(error, dialogRef),
            });

            return;
        });

        dialogRef.result.then(() => {}, () => {});
    }

    toggleNavbar(isWidgetMaximized: boolean | null = null): void {
        this.isSideNavExpanded = isWidgetMaximized != null ? !isWidgetMaximized : !this.isSideNavExpanded;
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        if (layoutHandler) {
            setTimeout(() => layoutHandler.onWindowResize(undefined, true), 500);
        }

        this.setIconsWrapperVisibility();
    }

    isSameView(tile: ViewTile): boolean {
        return tile.selectedLink?.id.toString() === this.currentView?.id.toString();
    }

    // this should be private
    // but because of the big combineLatest in ngInit, it's very difficult to test without calling this directly
    setIconsWrapperVisibility(): void {
        this.showIconsWrapper = this.isSideNavExpanded && this.showViewAndWidgetTab && !!this.userEntitlements?.user.roleName;
    }

    // this should be private
    // but because of the big combineLatest in ngInit, it's very difficult to test without calling this directly
    setShowWidgetIcon(): void {
        this.showFindWidgetIcon = this.userEntitlements?.user.roleName !== 'DDV_HSBaseUser' &&
            this.userEntitlements?.user.roleName !== 'DDV_ClientBaseUser';
    }

    private initTransitionEvents(): void {
        const leftNav = this.leftNav?.nativeElement;
        const transitionEvent = this.whichTransitionEvent(leftNav);
        if (transitionEvent) {
            leftNav.addEventListener(transitionEvent, () => {
                if (this.manager.getWorkspace()) {
                    const layoutHandler = this.manager.getWorkspaceLayoutHandler();
                    layoutHandler?.onWindowResize(undefined, true);
                }
            });
        }
    }

    private whichTransitionEvent(leftnav: HTMLElement): string {
        const transitions: Record<string, string> = {
            transition: 'transitionend',
            OTransition: 'oTransitionEnd',
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
        };

        for (const t in transitions) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((leftnav.style as any)[t] !== undefined) {
                return transitions[t];
            }
        }

        return '';
    }

    private updateUserPreferences(): void {
        this.userService.updateUserPreferenceData(this.userPreferences).subscribe(() => {});
    }

    private launchCopyDashboardModal(viewId: number | undefined): void {
        const saveDashboardFormDialogRef = this.modalService.open(SaveDashboardFormComponent, { windowClass: 'save-dialog' });

        const component: SaveDashboardFormComponent = saveDashboardFormDialogRef.componentInstance;
        component.selectedOption = 'edit';
        component.isReadOnly = false;
        component.isDashboardCopy = true;
        component.isGlobalEditPartial = this.isGlobalEditPartial;
        const dashboardToCopy = this.dashboardService.getDashboardSnapshotById(viewId?.toString() ?? '');
        component.dashboard = new DashboardSnapshot(dashboardToCopy).cloneForCopy();

        component.closeForm.subscribe(() => saveDashboardFormDialogRef.close());
        component.formSubmitted.subscribe((dashboard: DashboardDetails) => {
            this.dashboardService.copyDashboard(dashboard).subscribe(async (id) => {
                saveDashboardFormDialogRef.close();
                await this.navigateToBoardAndMode(id, 'edit');
            });
        });

        saveDashboardFormDialogRef.result.then(() => {}, () => {});
    }

    private invokeCreateDashboard(requestParam: DashboardDetails, form: ModalDialogRef): void {
        form.componentInstance.isSaveDashboardInvoked = true;
        this.dashboardService.createDashboard(requestParam).subscribe({
            next: (updatedDashboard: DashboardDetails) => {
                this.dashboardService.toggleDefault(updatedDashboard, false).subscribe();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.manager.updateWidgetsVersion(updatedDashboard.widgets as any);

                form.close();
                this.dirtyFlagService.exitDirtyState();

                const dashboardSnapshots = this.dashboardService.dashboardSnapshots$.pipe(take(1));
                dashboardSnapshots.subscribe(async () => {
                    if (!this.currentView || updatedDashboard.id !== this.currentView.id.toString()) {
                        await this.navigateToBoardAndMode(updatedDashboard.id, 'edit');
                    }
                });
            },
            error: (error) => this.onDashboardSaveError(error, form),
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onDashboardSaveError(error: any, form?: ModalDialogRef): void {
        if (!error._body) {
            return;
        }

        const errorDetails = typeof error._body === 'string' ? JSON.parse(error._body) : error._body;

        if (errorDetails.message === 'Dashboard name is already in use') {
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
            return form && form.componentInstance.nameUnavailable();
        }

        if (errorDetails.message === 'Dashboard version is stale') {
            if (form) {
                form.close();
            }

            this.handleStaleDashboardVersion(errorDetails.message);
        }
    }

    private async navigateToBoardAndMode(dashboardId: string | number | undefined, mode: string): Promise<boolean> {
        return this.router.navigate([`../../${mode}/${dashboardId ?? 'none'}`], { relativeTo: this.route });
    }

    private getDashboardDetails(dashboard: ViewLink): void {
        if (dashboard.id.toString() !== this.currentView?.id.toString()) {
            this.dashboardService.getDashboard(dashboard.id.toString()).subscribe((dashboardDetails) => {
                this.launchSaveForm(dashboardDetails);
            });
        } else {
            this.launchSaveForm();
        }
    }

    private afterDashboardTagsOnlySaved(_: DashboardDetails, savedTagsFromServer: Tag[]): void {
        if (!savedTagsFromServer) {
            return;
        }

        const dashboard = this.manager.getWorkspace();
        const extParams: DashboardModel = dashboard?.getExtraParameters();
        extParams.dashboardTags = savedTagsFromServer;
        dashboard?.setExtraParameters(extParams);
    }

    private addWidgetToDashboard(widgetOnBoard: WidgetOnBoard): void {
        if (!widgetOnBoard.widget) {
            return;
        }

        const widget = WidgetConfigurationManager.getWidgetConfigForWorkspace(
            widgetOnBoard.widget,
            this.layoutService.getWidgetDefaultPosition(widgetOnBoard.widget.widgetPosition));

        widget.styleClasses = widgetOnBoard.widget.enableWidgetHeader ? '' : 'hide-header';
        this.manager.createWidget(widget);
        this.manager.getWorkspace()?.extraParameters?.widgets.push(widgetOnBoard.widget);

        const snapshot = this.dashboardService.getDashboardSnapshotById(this.manager.getCurrentDashboardId() ?? '') as DashboardDetails;
        if (snapshot?.widgetOnBoards) {
            snapshot.widgetOnBoards.push(widgetOnBoard);
        }

        widgetOnBoard.widget.widgetPosition = this.layoutService.getWidgetPositionById(widget.id);
        this.manager.setWidgetExtraPreferences(widget.id, widgetOnBoard.widget);
        this.dashboardService.notifyForWidgetAddedOnDashboard();
    }

    private bringDashboardIntoView(): void {
        if (!this.currentView) {
            return;
        }
        this.changeDetectorRef.detectChanges();
        const dashboardElement = this.dashboardList?.nativeElement.querySelector(`#db_${this.currentView.id}`);
        if (!dashboardElement) {
            return;
        }
        const dashboardElementParent = dashboardElement.parentElement;
        const { offsetTop, clientHeight } = dashboardElement;
        const { clientHeight: parentClientHeight, scrollTop: parentScrollTop } = dashboardElementParent;
        if ((offsetTop as number) + (clientHeight as number) > (parentClientHeight as number) + (parentScrollTop as number)) {
            dashboardElement.scrollIntoView(false);
        } else if (this.checkElementOutOfBound(dashboardElement, dashboardElementParent)) {
            dashboardElement.scrollIntoView(true);
        }
    }

    private checkElementOutOfBound(dashboardElement: HTMLElement, dashboardElementParent: HTMLElement): boolean {
        return dashboardElement.offsetTop < dashboardElementParent.scrollTop ||
            (dashboardElementParent.scrollTop < (dashboardElement.clientHeight / 2) &&
            dashboardElement.offsetTop < dashboardElement.clientHeight);
    }

    private discardChangesCallback(): void {
        this.manager.selectWidget(false, undefined);
        this.dirtyFlagService.exitDirtyState();
        this.revertChanges.emit();
        this.createNewDashboard();
    }

    private handleStaleDashboardVersion(message: string): void {
        this.confirmationPopupService.showConfirmationPopup({ message }, true).subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.revert();
                }
            },
        });
    }

    private revert(): void {
        const dashboardId = this.manager.getCurrentDashboardId() ?? '';
        this.manager.removeAllWidgets();
        this.revertDashboardService.revert(dashboardId, this.layoutService.getWorkspaceMode(), true);
    }
}
