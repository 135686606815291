import { ColDef, ColumnResizedEvent, RowClassParams } from '@ag-grid-community/core';
import {
    Component,
    Input,
    OnInit,
    ViewChild,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    ElementRef,
} from '@angular/core';
import { BigWTablePair, ChartData, ChartSettings } from '@ddv/charts';
import { DataGridComponent, GridConfiguration, RowClickedEventModel } from '@ddv/data-grid';
import { ManagerService } from '@ddv/layout';
import { VisualizationType } from '@ddv/models';

import { VizInfo } from '../visualization-wrapper.interface';
import { BigWTableGridConfigService } from './big-w-table-grid-config.service';
import { BigWTableService } from './big-w-table.service';

@Component({
    selector: 'app-big-w-table',
    templateUrl: 'big-w-table.component.html',
    styleUrls: ['big-w-table.component.scss'],
})
export class BigWTableComponent implements OnInit, OnChanges {
    readonly footerCount = 1;
    @Input() widgetId = -1;
    @Input() plottedChartData: ChartData[] = [];
    @Input() plottedCompareData: ChartData[] | undefined;
    @Input() chartConfig: ChartSettings | undefined;
    @Input() slicerInfo: VizInfo | undefined;
    @Input() chartComponent: BigWTablePair | undefined;
    // this looks super bad and we should eliminate it.  it requires the parent component to do bad things
    @Input() availableHeight = 0;
    showGrid = false;
    isPITChart = false;
    isStacked = false;
    isMirrored = false;
    isMultiSeries = false;
    isStackedArea = false;
    areAdditionalRowsShown = false;
    gridConfiguration: GridConfiguration | undefined;
    additionalChartData: ChartData[] = [];
    private currentViz: VisualizationType | undefined;

    @ViewChild('dataGridComponent', { static: false }) dataGridComponent: DataGridComponent | undefined;
    @ViewChild('bigWGridContainer', { static: true }) bigWGridContainer: ElementRef | undefined;

    constructor(
        private readonly gridConfigService: BigWTableGridConfigService,
        private readonly bigWTableService: BigWTableService,
        private readonly cdr: ChangeDetectorRef,
        private readonly managerService: ManagerService,
    ) {}

    ngOnInit(): void {
        const widgetPrefs = this.managerService.getWidgetPreferences(this.widgetId);
        this.currentViz = widgetPrefs?.currentVisualization;
        this.gridConfigService.getGridColumns = (): ColDef[] => this.bigWTableService.getColumnDefinitions(
            this.widgetId,
            this.currentViz,
            this.chartConfig,
            this.plottedChartData,
            this.slicerInfo,
            this.chartComponent);
        this.initGrid();
        this.setGridStyles();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.chartConfig) {
            return console.error('cannot chartConfig must be defined');
        }

        const chartSeries = this.chartConfig?.series[0];
        this.isMirrored = this.bigWTableService.isMirrored(chartSeries);
        this.isPITChart = this.bigWTableService.isPITChart(chartSeries);
        this.isStacked = this.bigWTableService.isStacked(chartSeries);
        this.isStackedArea = this.bigWTableService.isStackedArea(chartSeries);
        this.isMultiSeries = this.bigWTableService.isMultiSeries(this.chartConfig);

        if (changes.plottedChartData) {
            if (!this.isPITChart && !this.isMirrored) {
                this.bigWTableService.updateSeriesChartsColorMap(this.plottedChartData);
            }

            if (!changes.plottedChartData.firstChange) {
                this.setGridData();
            }
        }

        if (changes.plottedCompareData) {
            if (!changes.plottedCompareData?.previousValue && changes.plottedCompareData?.currentValue) {
                this.initGrid();
            }

            if (!changes.plottedCompareData.firstChange) {
                this.setGridData();
            }
        }

        if (changes.availableHeight) {
            if (this.areAdditionalRowsShown && changes.availableHeight.currentValue === 0) {
                this.areAdditionalRowsShown = false;
            }
            if (changes.availableHeight.currentValue && changes.availableHeight.previousValue === 0) {
                this.hackTheLegendsOnPivotColumns();
            }
        }

        if (changes.chartConfig && !changes.chartConfig.firstChange) {
            this.areAdditionalRowsShown = false;
            this.redrawBigWTable();
        }
    }

    redrawBigWTable(): void {
        this.showGrid = false;
        this.cdr.detectChanges();
        this.initGrid();
        this.setGridStyles();
    }

    onGridReady(): void {
        this.setGridData();
        this.hackTheLegendsOnPivotColumns();
    }

    onRowClicked(event: RowClickedEventModel): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowData: any | undefined = event.rowData;
        if (rowData?.key?.toLowerCase?.() === 'others') {
            let additionalRows: ChartData[] = rowData.children;
            let compareColumnAdditionalRows: unknown[] = [];
            if (!additionalRows) {
                additionalRows = rowData.values[0].children;
                this.addKeyToEachAdditionalRow(rowData, additionalRows);
            }

            const dataCompareSource = this.dataCompareSource();
            if (dataCompareSource?.length) {
                compareColumnAdditionalRows = dataCompareSource.find((dcsElement) => dcsElement.children) ?
                    dataCompareSource.find((dcsElement) => dcsElement.children).children :
                    [];
            }
            this.toggleAdditionalRows(additionalRows, compareColumnAdditionalRows);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private dataCompareSource(): any[] | undefined {
        return this.plottedCompareData ?? this.chartConfig?.dataCompareSource;
    }

    deselectAllSelected(): void {
        this.dataGridComponent?.deselectAllSelected();
        this.hackTheLegendsOnPivotColumns();
    }

    onVirtualColumnsChanged(): void {
        this.hackTheLegendsOnPivotColumns();
    }

    private initGrid(): void {
        this.gridConfiguration = this.gridConfigService.getGridConfiguration({ visualizationType: 'SIMPLE_GRID', axisConfigurations: [] }, this.widgetId, 0);
        if (this.isPITChart && this.gridConfiguration.columnDefinitions.length && !this.gridConfiguration.columnDefinitions[0].headerName) {
            (this.gridConfiguration.columnDefinitions[0] as ColDef).cellClass = ['no-grid-alternateshade', 'right'];
        }

        const dataCompareSource = this.dataCompareSource();
        if (dataCompareSource?.length) {
            const gridDataSource = this.isPITChart ? this.plottedChartData : this.chartConfig?.dataSource ?? [];
            this.bigWTableService.updateColumnDefinitionsInCompareMode(
                this.gridConfiguration.columnDefinitions,
                gridDataSource,
                dataCompareSource,
            );
        }

        this.gridConfiguration.gridOptions.onColumnResized = (event: ColumnResizedEvent): void => {
            if (event.finished && event.column) {
                const colId = this.gridConfiguration?.gridOptions.pivotMode ?
                    event.column.getColDef().headerName :
                    event.column.getColId();

                this.bigWTableService.setColumnsCustomWidth(
                    this.widgetId,
                    this.currentViz,
                    this.slicerInfo?.slicer.value,
                    colId,
                    event.column.getActualWidth());
            }
        };

        this.isMirrored = this.bigWTableService.isMirrored(this.chartConfig?.series[0]);

        if ((!this.isPITChart && typeof this.chartConfig?.multiSeries !== 'undefined') || this.isStackedArea) {
            this.gridConfiguration.gridOptions = this.bigWTableService.getSeriesChartGridOptions(
                this.widgetId,
                this.currentViz,
                this.slicerInfo?.slicer.value,
                this.gridConfiguration.gridOptions,
                this.chartConfig?.series[0],
                this.chartConfig?.multiSeries);
            if (!this.isStacked && !this.isMirrored && this.gridConfiguration.gridOptions.autoGroupColumnDef) {
                this.gridConfiguration.gridOptions.autoGroupColumnDef.headerName = this.chartConfig?.axis[0][0].customName ??
                    this.gridConfiguration.gridOptions.autoGroupColumnDef.headerName;
            }
        }

        if (this.isMirrored && this.slicerInfo && this.gridConfiguration.gridOptions.autoGroupColumnDef) {
            // As of version 26 of AG-Grid
            // if we don't do the following, a Group column is produced
            // which wasn't visible previously
            // and I couldn't find a way to hide the group column
            // so with pivot we are hiding the column that is being grouped
            this.gridConfiguration.gridOptions.pivotMode = true;
            const { showCustomName, label, customName } = this.slicerInfo.slicer;
            const headerName = showCustomName ? customName : label;
            this.gridConfiguration.gridOptions.autoGroupColumnDef.headerName = headerName;
        }

        this.gridConfiguration.uniqueId = `${this.widgetId}-bigW-grid`;
        this.showGrid = true;
    }

    private setGridData(): void {
        if (!this.dataGridComponent) {
            return;
        }

        let gridDataSource = this.getGridDataSource();
        const dataCompareSource = this.dataCompareSource();
        if (dataCompareSource?.length) {
            gridDataSource = this.bigWTableService.updateGridDataSourceInCompareMode(gridDataSource, dataCompareSource);
        }
        this.dataGridComponent.setRowData(gridDataSource);

        let columns = this.dataGridComponent.getAllColumns();
        if (!this.isPITChart) {
            gridDataSource = [];
            this.dataGridComponent.gridOptions.api?.forEachNode((node) => {
                if (node.group && node.aggData) {
                    gridDataSource.push(node.aggData);
                }
            });
            columns = this.dataGridComponent.getAllDisplayedColumns() ?? [];
        }

        const index = this.findIndexOfOthersRow(gridDataSource);
        if (index > 0) {
            gridDataSource.splice(index);
        }
        this.dataGridComponent.setFooterRow(this.bigWTableService.getFooterRowData(columns, gridDataSource));
        this.updateGridHeight();
    }

    private getGridDataSource(): ChartData[] {
        if (this.isPITChart) {
            return this.plottedChartData;
        }

        if (!this.chartConfig) {
            throw new Error('cannot getGridDataSource with a chartConfig');
        }

        const { enableSliceManagement, showOthers } = this.chartConfig;
        if (this.isStacked && enableSliceManagement && !showOthers) {
            const key = this.chartConfig.selectedSlicer?.value ?? '';
            const activeSlicerValues = new Set<string>();
            this.plottedChartData.forEach((datum) => {
                datum.values.forEach((value) => {
                    activeSlicerValues.add(value[key]);
                });
            });
            return this.chartConfig.dataSource.filter((datum) => activeSlicerValues.has(datum[key]));
        }

        return this.chartConfig.dataSource;
    }

    private updateGridHeight(): void {
        const displayedRowsSize = ((this.dataGridComponent?.getTotalRowCount() ?? 0) + this.footerCount + 2) *
            (this.gridConfiguration?.gridOptions.rowHeight ?? 0);
        const requiredSize = displayedRowsSize + (this.gridConfiguration?.gridOptions.headerHeight ?? 0) + 5;

        if (this.bigWGridContainer) {
            this.bigWGridContainer.nativeElement.style.height = requiredSize < this.availableHeight ?
                `${requiredSize}px` :
                `${this.availableHeight - 50}px`;
        }
    }

    private toggleAdditionalRows(additionalRows: ChartData[], compareColumnAdditionalRows: unknown[]): void {
        if (!this.areAdditionalRowsShown) {
            this.plottedChartData.push(...additionalRows);
            this.additionalChartData.push(...additionalRows);
            if (this.chartConfig?.dataCompareSource?.length) {
                this.chartConfig.dataCompareSource.push(...compareColumnAdditionalRows);
            }
            this.areAdditionalRowsShown = true;
        } else {
            const index = this.findIndexOfOthersRow(this.plottedChartData);
            this.plottedChartData.splice(index);
            this.additionalChartData = [];
            if (this.chartConfig?.dataCompareSource?.length) {
                this.chartConfig.dataCompareSource.splice(index);
            }
            this.areAdditionalRowsShown = false;
        }

        this.setGridData();
        this.setGridStyles();
    }

    private setGridStyles(): void {
        if (!this.gridConfiguration) {
            return console.error('cannot setGridStyles without a gridConfiguration');
        }

        this.gridConfiguration.gridOptions.getRowClass = (params: RowClassParams): string | undefined => {
            if (params.data && params.data.key?.toLowerCase?.() === 'others') {
                return 'bold';
            }

            if (this.additionalChartData.findIndex((datum) => datum.key === params.data.key) !== -1) {
                return 'others-group-child-element';
            }

            return;
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addKeyToEachAdditionalRow(rowData: any, additionalRows: any[]): void {
        let prop: string;
        Object.keys(rowData.values[0]).forEach((key) => {
            if (rowData.values[0][key] === rowData.key) {
                prop = key;
            }
        });
        additionalRows.forEach((row) => row.key = row[prop]);
    }

    private findIndexOfOthersRow(gridDataSource: ChartData[]): number {
        return gridDataSource.findIndex((chartData) => chartData.key?.toLowerCase?.() === 'others') + 1;
    }

    private hackTheLegendsOnPivotColumns(): void {
        const pivots = this.bigWGridContainer?.nativeElement.querySelectorAll('[col-id*="pivot"]');
        pivots?.forEach((pivot: HTMLElement) => {
            const pivotLegend = pivot.querySelector('.pivot-header-legend');
            if (!pivotLegend) {
                const header = pivot.querySelector('[ref="agLabel"]');
                if (header) {
                    let key = pivot.innerText.trim();
                    key = key.toLowerCase() === 'blanks' ? '' : key;
                    const color = this.plottedChartData.find((cd) => {
                        if (cd.key != null) {
                            return cd.key.toString().toLowerCase() === key.toLowerCase();
                        } else {
                            return (Object.is(cd.key, null)) && key.toLowerCase() === 'null';
                        }
                    })?.color;
                    if (color) {
                        const legend = `<span class="pivot-header-legend" style="background-color: ${color}"></span>`;
                        header.insertAdjacentHTML('beforebegin', legend);
                    }
                }
            }
        });
    }
}

export type ChartDataTransformer = (source: unknown[]) => ChartData[];
