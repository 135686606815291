import { ColDef, Column, ValueGetterParams } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import {
    ChartData,
    ChartSettings,
    DrillInfo,
    Drilldown,
    MultiSeries,
    Series,
    safeStringFormatter,
    BigWTablePair,
} from '@ddv/charts';
import { HTMLRenderer, DataGridOptions, CustomCellRendererParams } from '@ddv/data-grid';
import { numberFormatter } from '@ddv/formatters';
import { DdvDate, AggType, calculateAggFunction, VisualizationType } from '@ddv/models';
import { deviceModeIsTablet, linkify, deepExtend } from '@ddv/utils';

import { VizInfo } from '../visualization-wrapper.interface';

interface TableColumnsWidth {
    currentViz: string;
    currentSlicer: string | undefined;
    columns: { colId: string, colWidth: number }[];
}

@Injectable()
export class BigWTableService {
    private seriesChartsColorMap: { [key: string]: string } = {};
    private readonly isTabletMode: boolean;
    private readonly columnsWidth: Map<number, TableColumnsWidth[]> = new Map();

    constructor() {
        this.isTabletMode = deviceModeIsTablet();
    }

    isPITChart(chartSeries: Series): boolean {
        return chartSeries.type === 'pie' || chartSeries.type === 'donut' || (chartSeries.type === 'bar' && !chartSeries.stacked);
    }

    isStacked(chartSeries?: Series): boolean {
        return chartSeries?.type === 'bar' && !!chartSeries.stacked && !chartSeries.mirror;
    }

    isMirrored(chartSeries?: Series): boolean {
        return !!(chartSeries?.type === 'bar' && chartSeries.mirror);
    }

    isStackedArea(chartSeries: Series): boolean {
        return chartSeries.type === 'stacked-area';
    }

    isMultiSeries(chartConfig: ChartSettings): boolean {
        return !!chartConfig.showMultipleSeries && !!chartConfig.multiSeries;
    }

    updateSeriesChartsColorMap(plottedChartData: ChartData[]): void {
        plottedChartData?.forEach((plottedChartDatum) => {
            this.seriesChartsColorMap[plottedChartDatum.key] = plottedChartDatum.color;
        });
    }

    getColumnsWidthByWidgetId(widgetId: number): TableColumnsWidth[] {
        return this.columnsWidth.get(widgetId) ?? [];
    }

    getDefaultColumnDefinition(
        widgetId: number,
        currentViz: string | undefined,
        currentSlicer: string | undefined,
        isValue: boolean,
        colField = '',
    ): ColDef {
        const currentBigWTable = this.getColumnsWidthByWidgetId(widgetId)?.find((table) => {
            return table.currentViz === currentViz && table.currentSlicer === currentSlicer;
        });
        const column = currentBigWTable?.columns.find((el) => el.colId === colField);
        return {
            headerComponent: HTMLRenderer,
            cellClass: ['no-grid-alternateshade', isValue ? 'right' : 'left'],
            suppressMenu: true,
            sortable: false,
            suppressMovable: true,
            resizable: true,
            minWidth: 50,
            width: this.isTabletMode ? 70 : (column ? column.colWidth : 170),
        };
    }

    getColumnDefinitions(
        widgetId: number,
        currentViz: string | undefined,
        chartConfig: ChartSettings | undefined,
        plottedChartData: ChartData[],
        slicerInfo: VizInfo | undefined,
        chartComponent: BigWTablePair | undefined,
    ): ColDef[] {
        if (!chartConfig) {
            return [];
        }

        const chartSeries = chartConfig.series[0];
        const isPITChart = this.isPITChart(chartSeries);
        const isStacked = this.isStacked(chartSeries);

        let fixedColumnKey: string;
        if (isStacked) {
            fixedColumnKey = chartConfig.multiSeries?.field ?? '';
        } else {
            fixedColumnKey = chartSeries.horizontal ? chartSeries.yField[0] : chartSeries.xField[0];
        }

        const columnDefinitions: ColDef[] = [];
        if (isPITChart) {
            columnDefinitions.push(deepExtend({
                headerName: '',
                headerClass: 'others-down-arrow',
                field: 'others-down-arrow',
                colId: 'others-down-arrow',
                minWidth: 14,
                maxWidth: 14,
                width: 14,
                cellRenderer: (params: CustomCellRendererParams) => this.getArrowSpan(params.data.key),
            }, [this.getDefaultColumnDefinition(widgetId, currentViz, slicerInfo?.slicer.value, false)]));
        }

        const columnFields = this.getColumnFields(
            chartConfig.series,
            slicerInfo,
            chartConfig.multiSeries,
            isStacked,
            !!chartSeries.horizontal);

        // eslint-disable-next-line complexity
        columnFields?.forEach((colField) => {
            const isValue = this.getIsValue(isStacked, slicerInfo, chartSeries, colField);
            const defaultColDef = this.getDefaultColumnDefinition(widgetId, currentViz, slicerInfo?.slicer.value, isValue, colField);
            this.setDefaultColDefProps(
                defaultColDef,
                isPITChart,
                colField,
                fixedColumnKey,
                chartConfig,
                chartComponent,
                slicerInfo,
                isValue);
            let pivotSlicer: string = '';

            if (!isPITChart) {
                if (!isStacked) {
                    if ((this.isMultiSeries(chartConfig) && chartConfig.multiSeries?.field === colField) ||
                        (this.isStackedArea(chartSeries) && slicerInfo?.slicer.value === colField)) {
                        // According to the ag-grid docs, 'pivot' is not a column property, however
                        // if one removes this line, the big-w table will break
                        // 'enablePivot', from the docs does not seem to do anything
                        defaultColDef.pivot = true;
                        defaultColDef.enablePivot = true;
                        defaultColDef.valueGetter = (params): string => ` ${this.getDefaultColDefValue(params, colField)}`;
                    }

                    if (!chartConfig.showMultipleSeries && isValue && !this.isStackedArea(chartSeries)) {
                        const color = plottedChartData.find((plottedDatum) => plottedDatum.key === colField)?.color ?? '';
                        defaultColDef.headerComponent = HTMLRenderer;
                        defaultColDef.headerName = `${this.getLegendColorSpan(color)} ${defaultColDef.headerName}`;
                    }
                }

                if (isStacked && colField === slicerInfo?.slicer.value) {
                    // See comment above about enablePivot and pivot
                    defaultColDef.enablePivot = true;
                    defaultColDef.pivot = true;
                    defaultColDef.valueGetter = (params): string => this.getDefaultColDefValue(params, colField);
                    pivotSlicer = colField;
                }

                if (colField === fixedColumnKey) {
                    defaultColDef.showRowGroup = colField;
                    defaultColDef.pinned = 'left';
                    defaultColDef.rowGroupIndex = 0;
                    defaultColDef.cellRendererParams = { suppressCount: true };
                    defaultColDef.rowGroup = true;

                    this.setDefaultColDefCellRendererForStackedChartAndDate(defaultColDef, isStacked, colField);
                }
            }

            if (isValue) {
                this.setDefaultColDefCellRendererForValue(chartConfig, defaultColDef, chartSeries, colField, chartComponent);
            }

            if (defaultColDef.pivot && plottedChartData?.[0].values && pivotSlicer) {
                this.setDefaultColDefPivotComparator(plottedChartData, pivotSlicer, defaultColDef);
            }

            if (isStacked && defaultColDef.colId === fixedColumnKey) {
                defaultColDef.sort = 'asc';
            }

            columnDefinitions.push(defaultColDef);

            if (isPITChart) {
                this.setCellRendererForPITChart(columnDefinitions);
            }
        });

        return columnDefinitions;
    }

    getSeriesChartGridOptions(
        widgetId: number,
        currentViz: VisualizationType | undefined,
        currentSlicer: string | undefined,
        gridOptions: DataGridOptions,
        chartSeries: Series | undefined,
        multiSeries: MultiSeries | null | undefined,
    ): DataGridOptions {
        gridOptions.pivotMode = true;
        gridOptions.groupDisplayType = 'singleColumn';
        const headerName = !this.isStacked(chartSeries) ? chartSeries?.xField[0] : multiSeries?.label;
        gridOptions.autoGroupColumnDef = deepExtend({
            headerName,
            maxWidth: this.isTabletMode ? 100 : undefined,
            minWidth: this.isTabletMode ? 100 : undefined,
            width: this.isTabletMode ? 100 : undefined,
            pinned: 'left',
            suppressSpanHeaderHeight: true,
        }, [this.getDefaultColumnDefinition(widgetId, currentViz, currentSlicer, false, headerName)]);
        return gridOptions;
    }

    getFooterRowData(
        columns: Column[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        plottedChartData: any[],
    ): { [key: string]: number } {
        // I do not know why this wasn't ever a problem before now but if you try to give BigW an emty array
        // of data to render, though there is code that will ultimately display "no rows", you can't get there without this function
        // blowing up.  perhaps for anything other than HBar its caught somewhere higher and i bypassing that check somehow
        if (!plottedChartData.length) {
            return {};
        }

        const compareKey = Object.keys(plottedChartData[0]).filter((key) => key.includes('Compare'))[0];
        if (compareKey) {
            plottedChartData.forEach((chartDataElement) => (
                chartDataElement[compareKey] = chartDataElement[compareKey] === '' ? 0 : chartDataElement[compareKey]
            ));
        }
        const footerRowData: { [key: string]: number } = {};
        columns.forEach((column) => {
            const aggregationType = column.getColDef().aggFunc;
            if (aggregationType) {
                const colId = column.getColId();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let chartData: any[];
                if (typeof plottedChartData[0]?.[colId] === 'string') {
                    chartData = this.updatePlottedChartDataBeforeAggregation(plottedChartData, colId);
                } else {
                    chartData = plottedChartData;
                }
                footerRowData[colId] = +(calculateAggFunction(chartData, colId, aggregationType as AggType) as number);
            }
        });
        return footerRowData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateColumnDefinitionsInCompareMode(columnDefinitions: ColDef[], gridDataSource: any[], dataCompareSource: any[]): void {
        const comparedDataKey = this.getComparedDataKey(gridDataSource[0], dataCompareSource[0]);
        const compareColumn: ColDef = {
            ...columnDefinitions.find((columnDef) => (columnDef.colId === comparedDataKey && columnDef.headerName)),
            colId: `${comparedDataKey}Compare`,
            field: `${comparedDataKey}Compare`,
            maxWidth: 270,
            width: undefined,
        };
        compareColumn.headerName = `${compareColumn.headerName} Compare`;
        if (compareColumn.headerName.length > 20) {
            compareColumn.width = 250;
        }
        columnDefinitions.push(compareColumn);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateGridDataSourceInCompareMode(gridDataSource: any[], dataCompareSource: any[]): any[] {
        const comparedDataKey = this.getComparedDataKey(gridDataSource[0], dataCompareSource[0]);
        return gridDataSource.map((gridDSElement) => {
            const dataCompareSourceElement = dataCompareSource.find((dcsElement) => (
                Object.values(dcsElement).find((value) => value === gridDSElement.key) !== undefined
            ));

            if (dataCompareSourceElement) {
                return { ...gridDSElement, [`${comparedDataKey}Compare`]: dataCompareSourceElement[comparedDataKey] };
            }
            return { ...gridDSElement, [`${comparedDataKey}Compare`]: '' };
        });
    }

    setColumnsCustomWidth(
        widgetId: number,
        currentViz: string | undefined,
        currentSlicer: string | undefined,
        colId: string | undefined,
        colWidth: number,
    ): void {
        if (!currentViz || !colId) {
            return console.error('Cannot set columns custom width without a currentViz and a colId.');
        }

        const bigWTableColumns = this.getColumnsWidthByWidgetId(widgetId);
        if (bigWTableColumns.length) {
            const currentVizData = bigWTableColumns.filter((el) => el.currentViz === currentViz);
            const currentSlicerData = bigWTableColumns.find((el) => el.currentSlicer === currentSlicer);

            if (!currentVizData.length) {
                const tableData: TableColumnsWidth = {
                    currentViz,
                    currentSlicer,
                    columns: [{ colId, colWidth }],
                };
                bigWTableColumns.push(tableData);
            } else if (currentSlicerData) {
                const column = currentSlicerData.columns.find((col) => col.colId === colId);
                if (column) {
                    column.colWidth = colWidth;
                } else {
                    currentSlicerData.columns.push({ colId, colWidth });
                }
            } else {
                bigWTableColumns.push({
                    currentViz,
                    currentSlicer,
                    columns: [{ colId, colWidth }],
                });
            }
        } else {
            const tableData: TableColumnsWidth = {
                currentViz,
                currentSlicer,
                columns: [{ colId, colWidth }],
            };
            this.columnsWidth.set(widgetId, [tableData]);
        }
    }

    private getColumnHeader(
        chartConfig: ChartSettings,
        chartComponent: BigWTablePair | undefined,
        slicerInfo: VizInfo | undefined,
        colField: string,
        isValue: boolean,
    ): string {
        if (isValue) {
            const label = slicerInfo?.values.find((slicerValue) => slicerValue.value === colField);
            return (!label ? colField : (label.showCustomName ? label.customName : label.label)) ?? '';
        }
        const header = slicerInfo?.slicer.value === colField ?
            (slicerInfo.slicer.showCustomName ? slicerInfo.slicer.customName : slicerInfo.slicer.label) :
            colField;

        // not all chart types have drillInfo
        return this.getPITChartFixedColKey(header, chartConfig.drilldown, chartComponent?.getDrillInfo?.());
    }

    private getColumnFields(
        chartSeries: Series[],
        slicerInfo: VizInfo | undefined,
        multiSeries: MultiSeries | null | undefined,
        isStacked: boolean,
        isHorizontal: boolean,
    ): string[] {
        if (!slicerInfo) {
            console.error('cannot getColumnFields without a slicerInfo');
            return [];
        }

        const columnFields: string[] = [];
        if (chartSeries) {
            chartSeries.forEach((series) => {
                if (!isHorizontal && !isStacked) {
                    columnFields.push(...series.xField, ...series.yField);
                } else if (isStacked) {
                    if (isHorizontal) {
                        columnFields.push(...series.yField);
                    } else {
                        columnFields.push(...series.xField);
                    }
                    columnFields.push(...slicerInfo.values.reduce((valFields: string[], slicerValues) => {
                        valFields.push(slicerValues.value as string);
                        return valFields;
                    }, []));
                } else {
                    columnFields.push(...series.yField, ...series.xField);
                }
            });
        }

        if (multiSeries?.field) {
            columnFields.push(multiSeries.field);
        }

        if (this.isStackedArea(chartSeries[0])) {
            columnFields.push(slicerInfo.slicer.value as string);
        }

        return columnFields.reduce((acc: string[], field) => {
            if (acc.indexOf(field) === -1) {
                acc.push(field);
            }
            return acc;
        }, []);
    }

    private getPITChartFixedColKey(currHeader?: string, chartDrillDown?: Drilldown, drillInfo?: DrillInfo): string {
        if (chartDrillDown?.keys && drillInfo?.list && typeof drillInfo.level !== 'undefined') {
            const othersLength = drillInfo.list.filter((item) => item.label === 'others').length;
            const index = othersLength ? drillInfo.level - othersLength : drillInfo.level;
            if (chartDrillDown.keys[index]) {
                return (chartDrillDown.keys[index].showCustomName ?
                    chartDrillDown.keys[index].customName :
                    chartDrillDown.keys[index].label) ?? '';
            }
        }

        return currHeader ?? '';
    }

    private getLegendColorSpan(color: string): string {
        return `<span class="table-legend" style="background-color: ${color}"></span>`;
    }

    private getArrowSpan(key: string): string | undefined {
        if (key?.toLowerCase?.() === 'others') {
            return '<span class="icon-arrow-down"></span>';
        }
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getComparedDataKey(gridDataSourceElement: any, dataCompareSourceElement: any): string {
        return Object.keys(gridDataSourceElement).reduce((key, currentKey) => {
            let newKey = key;
            if (currentKey !== 'values' && currentKey !== 'color' && dataCompareSourceElement[currentKey] !== undefined) {
                newKey = currentKey;
            }
            return newKey;
        }, '');
    }

    private getSafeStringValue(params: CustomCellRendererParams, colDef: ColDef): string {
        if (!params.node.rowPinned) {
            if (!colDef.colId?.includes('date') && params.value && isNaN(params.value)) {
                return linkify(safeStringFormatter(params.value), '_self');
            } else {
                return safeStringFormatter(params.value) || 'N/A';
            }
        } else {
            return '';
        }
    }

    private getDefaultColDefValue(params: ValueGetterParams, colField: string): string {
        return params.data[colField] == null ? 'Null' : (params.data[colField].toString().trim() ? params.data[colField] : 'Blanks');
    }

    private updatePlottedChartDataBeforeAggregation(
        plottedChartData: { [key: string]: string | number | { [key: string]: string | number }[] }[],
        colId: string,
    ): { [key: string]: string | number | { [key: string]: string | number }[] }[] {
        plottedChartData.forEach((datum) => datum[colId] = +datum[colId]);
        return plottedChartData;
    }

    private setDefaultColDefProps(
        defaultColDef: ColDef,
        isPITChart: boolean,
        colField: string,
        fixedColumnKey: string,
        chartConfig: ChartSettings,
        chartComponent: BigWTablePair | undefined,
        slicerInfo: VizInfo | undefined,
        isValue: boolean,
    ): void {
        defaultColDef.field = isPITChart && colField === fixedColumnKey ? 'key' : colField;
        defaultColDef.colId = colField;
        defaultColDef.headerName = this.getColumnHeader(chartConfig, chartComponent, slicerInfo, colField, isValue) || colField;
        defaultColDef.headerClass = isValue ? 'right' : 'left';
        defaultColDef.cellRenderer = !isValue ? ((params: CustomCellRendererParams): string => (
            // eslint-disable-next-line eqeqeq
            params.value === null ? 'Null' : params.value === undefined ?
                '' :
                params.value.toString().trim() ? this.getSafeStringValue(params, defaultColDef) : 'Blanks')) : undefined;
    }

    private setCellRendererForPITChart(columnDefinitions: ColDef[]): void {
        columnDefinitions[1].cellRenderer = (params: CustomCellRendererParams): string => {
            const colId = params.colDef?.colId ?? '';
            const value = (params.value == null && params.data.key == null && params.data[colId] == null) ?
                '' :
                (!params.value?.toString().trim() &&
                !params.data.key?.toString().trim() &&
                !params.data[colId]?.toString().trim() ?
                    'Blanks' :
                    params.value != null ?
                        params.value :
                        params.data.key != null ?
                            params.data.key :
                            params.data[colId]);
            return `${this.getLegendColorSpan(params.data.color)}` +
                ` ${!columnDefinitions[1].colId?.includes('date') && value && isNaN(value) ? linkify(value, '_self') : value}`;
        };
    }

    private setDefaultColDefPivotComparator(plottedChartData: ChartData[], pivotSlicer: string, defaultColDef: ColDef): void {
        const requiredOrder = plottedChartData[0].values.map((data) => data[pivotSlicer]);
        defaultColDef.pivotComparator = (a, b): number => requiredOrder.indexOf(a) - requiredOrder.indexOf(b);
    }

    private setDefaultColDefCellRendererForValue(
        chartConfig: ChartSettings,
        defaultColDef: ColDef,
        chartSeries: Series,
        colField: string,
        chartComponent: BigWTablePair | undefined,
    ): void {
        const isMirrored = this.isMirrored(chartSeries);
        const mirrorInfo = chartComponent?.getMirrorInfo?.();
        const mirrorY1AxisMultiplier = isMirrored && mirrorInfo?.mirrorY1Axis ? -1 : 1;
        const mirrorY2AxisMultiplier = isMirrored && mirrorInfo?.mirrorY2Axis ? -1 : 1;
        const formatter = chartConfig.formatter;
        const decimalPlaces = formatter && typeof formatter.decimalPlaces === 'number' ? formatter.decimalPlaces : 2;
        defaultColDef.aggFunc = 'sum';
        defaultColDef.cellRenderer = (params: CustomCellRendererParams): string => {
            const value = isMirrored ?
                (chartSeries.yField[0] === colField ?
                    mirrorY1AxisMultiplier * params.value :
                    mirrorY2AxisMultiplier * params.value) :
                params.value;
            const formattedNumber = typeof value === 'number' ? numberFormatter.asDisplayValue(value, decimalPlaces) : '';
            return `<span title='${formattedNumber}'>${formattedNumber}</span>`;
        };
    }

    private setDefaultColDefCellRendererForStackedChartAndDate(defaultColDef: ColDef, isStacked: boolean, colField: string): void {
        if (isStacked) {
            const colId = defaultColDef.colId;
            defaultColDef.cellRenderer = (params: CustomCellRendererParams): string => this.getCellRendererValueForStackedChart(
                params,
                colId,
            );
        } else if (colField.toLowerCase().indexOf('date') !== -1) {
            defaultColDef.cellRenderer = (params: CustomCellRendererParams): string => this.getCellRendererValueForDate(params);
        }
    }

    private getCellRendererValueForStackedChart(params: CustomCellRendererParams, colId: string | undefined): string {
        const color = params.value === 'Blanks' ?
            (this.seriesChartsColorMap[''] || this.seriesChartsColorMap[' ']) :
            this.seriesChartsColorMap[params.value];
        const value: string = params.value == null ? 'Null' : params.value.toString().trim() ? params.value : 'Blanks';
        return `${this.getLegendColorSpan(color)} ${!colId?.includes('date') ? linkify(value, '_self') : value}`;
    }

    private getCellRendererValueForDate(params: CustomCellRendererParams): string {
        if (params.value == null) {
            return '';
        }

        const date = this.getDate(params);
        return date.isValid() ? date.toUSPaddedFormat() : params.value;
    }

    private getDate(params: { value: string | Date }): DdvDate {
        return !DdvDate.isStringValidDate(params.value as string) || params.value instanceof Date ?
            DdvDate.fromDate(new Date(params.value)) :
            DdvDate.fromDashFormat(params.value);
    }

    private getIsValue(
        isStacked: boolean,
        slicerInfo: VizInfo | undefined,
        chartSeries: Series,
        colField: string,
    ): boolean {
        let isValue: boolean;
        if (isStacked) {
            isValue = !!slicerInfo?.values.some((slicerValue) => slicerValue.value === colField);
        } else {
            isValue = chartSeries[!chartSeries.horizontal ? 'yField' : 'xField'].some((val) => val === colField);
        }
        return isValue;
    }
}
