import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { NamedQuery } from '@ddv/models';
import { Observable } from 'rxjs';

@Injectable()
export class NamedQueriesService {
    private clientCode: string = '';

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.nqs) private readonly nqsApiExecutor: ApiExecutorService,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    fetchNamedQueries(): Observable<NamedQuery[]> {
        return this.nqsApiExecutor.invokeServiceWithParams<NamedQuery[]>(this.clientCode, '/queries');
    }
}
