<div class="advanced-data-grid data-grid">
    <div class="grid-wrapper widget-flex" [ngClass]="{'tp-opened': isToolPanelShowing, 'maximized': isWidgetMaximized && enableCompareMode, 'ag-readonly': isWidgetReadOnly}">
        <div class="search-wrap">
            <div class="pull-left">
                <ddv-search-box
                    [quickSearchPlaceholder]="quickSearchPlaceholder"
                    [rowSearchText]="rowSearchText"
                    (quickFilterChanged)="onQuickFilterChanged($event)">
                </ddv-search-box>
            </div>

            <div class="pull-right disp-flex">
                <app-grid-column-search *ngIf="searchColumns.length > 0"
                    (columnVisibleChange)="showDataGridColumn($event)"
                    [gridColumns]="searchColumns"
                    [isReadOnly]="isWidgetReadOnly">
                </app-grid-column-search>

                <div *ngIf="isWidgetMaximized && actionIconsAllowed" class="action-icons-container">
                    <ddv-action-icons
                        [params]="params"
                        [isTFLDetails]="isTFLDetails"
                        (handleAcknowledge)="handleAcknowledge()"
                        (handleResubmit)="handleResubmit()">
                    </ddv-action-icons>
                </div>

                <button *ngIf="isToolPanelVisible()" class="empty-btn" (click)="toggleToolpanel()">
                    <i class="icon-right-expand" *ngIf="!isToolPanelShowing"></i>

                    <i class="icon-left-expand" *ngIf="isToolPanelShowing"></i>

                    <i class="icon-advancedgrid-list"></i>
                </button>
            </div>
        </div>

        <div class="widget-wrap" (mousedown)="isMousedownInside = true">
            <app-data-grid
                *ngIf="showGrid"
                [gridConfiguration]="gridConfiguration"
                [frameworkComponents]="allFrameworkComponents"
                [suppressAggFuncInHeader]="!!preferences?.useCustomHeaderNames"
                [isManagingWidget]="isManagingWidget"
                [hideLoaderAfterFirstDataLoad]="hideLoaderAfterFirstDataLoad"
                [isOnMasterWidget]="isMaster"
                [isShowingTFLData]="isTFLDetails || isTFLIncompleteFiles"
                [datasetIncludesCrosstalk]="isCrosstalkGrid"
                [enableGroupEdit]="enableGroupEdit"
                [widgetId]="widgetId"
                [visualizationId]="visualizationId"
                (cellClickedEvent)="conditionallyFollowLink($event)"
                (cellValueChanged)="onCellValueChanged($event)"
                (columnEverythingChanged)="onColumnEverythingChanged($event)"
                (columnMovedEvent)="onColumnMoved($event.event, $event.columns)"
                (columnPivotModeChangedEvent)="onColumnPivotModeChanged($event)"
                (columnVisibleEvent)="setColumnVisibility($event)"
                (displayedColumnsChangedEvent)="onDisplayedColumnsChangedEvent()"
                (gridFilterChangedEvent)="onGridFilterChanged()"
                (gridReadyEvent)="onGridReady()"
                (dragStoppedEvent)="onDragStopped($event.event, $event.columns)"
                (pivotColumnChangedEvent)="onPivotColumnChanged()"
                (rowGroupColumnChangedEvent)="onRowGroupColumnChanged($event)"
                (rowGroupOpenedEvent)="onRowGroupOpened($event)"
                (rowSelectionEvent)="onRowSelected($event)"
                (rowUnselectedEvent)="onRowUnselected($event)"
                (valueColumnChangedEvent)="onValueColumnChanged($event)"
                (selectionChangedEvent)="onSelectionChanged($event)">
            </app-data-grid>
        </div>
    </div>
</div>

<ng-template #bulkEditTemplate>
    <span class="bulk-edit-text">You are about to upload comment to <strong>{{ bulkRowsNumber }} rows</strong></span>
</ng-template>
