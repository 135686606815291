import { AbstractControl } from '@angular/forms';
import { DdvDate } from '@ddv/models';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CustomFormValidators {
    static isInputSpaces(control: AbstractControl): { isSpaces: boolean } | null {
        return (control.value && control.value.trim() === '') ? { isSpaces: true } : null;
    }

    static isInvalidDate(control: AbstractControl): { invalidDate: boolean } | null {
        return DdvDate.isStringValidDate(control.value) ? null : { invalidDate: true };
    }

    static isInvalidRange(rangeFromControl: AbstractControl, rangeToControl: AbstractControl, isDate: boolean = false) {
        return (_: AbstractControl): { invalidRange: boolean } | null => {
            let rangeFrom = rangeFromControl.value;
            let rangeTo = rangeToControl.value;
            if (rangeFrom != null && rangeTo != null) {
                if (isDate) {
                    rangeFrom = DdvDate.fromUSFormat(rangeFrom).toUSPaddedFormat();
                    rangeTo = DdvDate.fromUSFormat(rangeTo).toUSPaddedFormat();
                }
                if (rangeFrom <= rangeTo) {
                    rangeFromControl.setErrors(null);
                    rangeToControl.setErrors(null);
                    return null;
                }
            }
            return { invalidRange: true };
        };
    }
}
