import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { CustomCellRendererParams } from '@ddv/data-grid';
import { EDITABLE_CHECKBOX_FIELD, TFL_DETAILS_AUTO_GROUP_COLUMN_ID, TRANSACTION_TYPE_FIELD, isTradeFileDetails } from '@ddv/models';

@Component({
    selector: 'app-checkbox-cell-renderer',
    templateUrl: './checkbox-cell-renderer.component.html',
    styleUrls: ['./checkbox-cell-renderer.component.scss'],
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
    // value can be undefined for a group where not all children are selected
    value: boolean | undefined = false;
    showCheckbox: boolean = false;
    private params: CustomCellRendererParams | undefined;
    private isBlock = false;

    agInit(params: CustomCellRendererParams): void {
        this.params = params;
        const { columnApi, node, queryType } = params;
        this.value = node.isSelected();
        let isAllocation = false;
        if (isTradeFileDetails(queryType?.name)) {
            const rowGroupColumns = columnApi.getRowGroupColumns();
            const gridGroupedByAllocationGroupId = rowGroupColumns.length === 1 &&
                rowGroupColumns[0].getColDef().field === TFL_DETAILS_AUTO_GROUP_COLUMN_ID;
            isAllocation = gridGroupedByAllocationGroupId &&
                node.data?.[TFL_DETAILS_AUTO_GROUP_COLUMN_ID] && !node.data[TRANSACTION_TYPE_FIELD];
            this.isBlock = gridGroupedByAllocationGroupId &&
                node.data?.[TFL_DETAILS_AUTO_GROUP_COLUMN_ID] && node.data[TRANSACTION_TYPE_FIELD];
        }
        this.showCheckbox = !node.group && !isAllocation;
    }

    refresh(): boolean {
        this.value = !!this.params?.node.isSelected();
        this.params?.setValue?.(this.value);
        return true;
    }

    onClick(value: boolean): void {
        if (this.isBlock) {
            this.params?.node.parent?.allLeafChildren.forEach((childNode) => {
                // this seems really bad smashing our own stuff back onto ag-grid objects
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (childNode as any)[EDITABLE_CHECKBOX_FIELD] = value;
                childNode.setSelected(value);
            });
        } else {
            this.params?.setValue?.(value);
            this.params?.node.setSelected(value);
        }
        this.params?.api.refreshHeader();
    }
}
