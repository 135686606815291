import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DdvDate } from '@ddv/models';
import { Observable } from 'rxjs';

import { DateRange } from '../calendar/date-range';
import { HiDate, HiDateStatus } from '../hi-date';
import { HiDatesService } from '../hi-dates.service';

@Component({
    selector: 'app-calendar-list-view',
    templateUrl: './calendar-list-view.component.html',
    styleUrls: ['./calendar-list-view.component.scss'],
})
export class CalendarListViewComponent implements OnInit {
    @Input() selectedDate: DdvDate | DateRange | undefined;
    @Input() isDateRangePicker: boolean = false;
    @Output() selectedHiDate = new EventEmitter<DateRange>();

    hiDates: Observable<HiDate[]> | undefined;
    hiDateStatus = HiDateStatus;

    constructor(private readonly hiDatesService: HiDatesService) {}

    ngOnInit(): void {
        this.hiDates = this.hiDatesService.hiDates();
    }

    isSelected(date: DdvDate): boolean {
        if (this.selectedDate instanceof DdvDate) {
            return date.sameAs(this.selectedDate);
        } else {
            const dateFrom = this.selectedDate?.dateFrom;
            const dateTo = this.selectedDate?.dateTo;

            return date.sameAs(dateFrom) || date.sameAs(dateTo);
        }
    }

    selectHiDate(hiDate: HiDate): void {
        this.selectedHiDate.emit({ dateFrom: hiDate.date, dateTo: hiDate.date });
    }
}
