import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@ddv/behaviors';
import { DataGridUtilities } from '@ddv/data-grid';
import { UserService } from '@ddv/entitlements';
import { UserPreferences } from '@ddv/models';
import { checkOrientation, deviceModeIsTablet } from '@ddv/utils';
import { ApmService } from '@elastic/apm-rum-angular';

import { environment } from '../environments/environment';
import { ApplicationService } from './application.service';
import { WrapperAppBindingService } from './wrapper/wrapper-app-binding.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isLoading: boolean | undefined;
    isTabletInPortraitMode: boolean;
    protected clientCode: string | undefined;
    protected appDisplayName: string | undefined;

    userPreference: UserPreferences = new UserPreferences();

    constructor(
        private readonly applicationService: ApplicationService,
        private readonly loaderService: LoaderService,
        private readonly userService: UserService,
        private readonly apmService: ApmService,
        private readonly wrapperAppBinding: WrapperAppBindingService,
    ) {
        this.wrapperAppBinding.startup();

        this.loaderService.isLoading().subscribe((loading) => {
            this.isLoading = loading;
        });

        this.isTabletInPortraitMode = this.isPortrait();
    }

    ngOnInit(): void {
        window.addEventListener('orientationchange', () => {
            this.isTabletInPortraitMode = this.isPortrait();
        });
        this.applicationService.registerInterceptors();
        DataGridUtilities.setThemeClass('ag-theme-blue');
        DataGridUtilities.registerLicenseKey(environment.agGridLicenseKey);

        this.userService.userPreferences$.subscribe((userPreferences: UserPreferences) => {
            this.userPreference = userPreferences;
            const username = this.userPreference.username;
            this.apmService.apm.setUserContext(username.includes('@') ? { email: username } : { username });

            this.clientCode = this.userPreference.clientCode;
            this.appDisplayName = appNameFromURL(window.location.hostname, window.location.pathname);
        });
    }

    isPortrait(): boolean {
        return deviceModeIsTablet() && checkOrientation();
    }
}

export function appNameFromURL(hostname: string, path: string): string {
    const hostnameParts = hostname.toLowerCase().split('.');
    if (hostnameParts[0] === 'ddv') {
        if (hostnameParts[1] === 'poc-rnd') {
            return 'DDV (RND)';
        }

        return 'DDV';
    }

    if (path.includes('-edge')) {
        return 'DDV (Edge)';
    }

    if (path.includes('-aws-rnd')) {
        return 'DDV (RND)';
    }

    return 'DDV';
}
