import { UserGroupType } from './user-group-type';
import { UserPermission } from './user-permission';

export class UserPreferences {
    id: number = Number.MIN_VALUE;
    username: string = '';
    clientCode: string = '';
    fundsCodes: string[] = [];
    clientsCodes: string[] = [];
    autoDismissErrors: boolean = false;
    startDate?: string;
    endDate?: string;
    version: number = Number.MIN_VALUE;
    fundNamesSource: string = '';  // this is a non-sensical default that was added because the types were difficult to fix
    widgetFinderLastTab = 'Datatype';
    isFullExport?: boolean;
    showAcknowledged?: boolean;
    clients?: { clientCode: string }[];
    funds?: { fundCode: string }[];

    public constructor(init?: Partial<UserPreferences>) {
        Object.assign(this, init);

        this.fundsCodes = this.fundsCodes || [];
        this.clientsCodes = this.clientsCodes || [];
        this.showAcknowledged = this.showAcknowledged != null ? this.showAcknowledged : false;
    }
}

export interface UserWithType {
    name: string;
    type: UserGroupType;
    internal?: boolean;
}

export interface User extends UserPermission {
    selected?: boolean;
    displayPrivilege?: string;
}

