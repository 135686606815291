import { UserGridColumnOverridesMap } from '../data-grid/user-grid-column-overrides';
import { AppWidgetState } from '../widgets/app-widget-state';
import { WidgetPosition } from '../widgets/widget-position';

export class WidgetOnBoard {
    id: number = Number.MIN_VALUE;
    height: number = 0;
    width: number = 0;
    left: number = 0;
    top: number = 0;
    isMaster?: boolean;
    maximized?: boolean;
    widgetPosition?: WidgetPosition;
    widget?: AppWidgetState;
    filters?: string;
    userGridColumnOverrides?: UserGridColumnOverridesMap;

    public constructor(init?: Partial<WidgetOnBoard>) {
        Object.assign(this, init);

        this.widget = new AppWidgetState(this.widget);

        this.widget.height = this.height;
        this.widget.width = this.width;
        this.widget.left = this.left;
        this.widget.top = this.top;
        this.widget.widgetPosition = this.widgetPosition;
        this.widget.id = this.id;
        this.widget.isMaster = this.isMaster;
        this.widget.userGridColumnOverrides = this.userGridColumnOverrides;

        if (!this.widget.isSubscribedToDashboardFilters && this.filters) {
            this.applyFilters();
        }
    }

    private applyFilters(): void {
        if (!this.widget?.widgetFilters) {
            return;
        }

        const filters = JSON.parse(this.filters ?? '');

        // this results in the type on this.widget.widgetFilters.funds being a lie
        // it should be Fund[] and it ends up just a POJSO
        if (filters.funds) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.widget.widgetFilters.funds = filters.funds.map((fund: any) => ({
                fundId: fund.fundId,
                fundName: fund.fundName,
                checked: true,
            }));
        }

        // this results in the type on this.widget.widgetFilters.clients being a lie
        // it should be Clinet[] and it ends up just a POJSO
        if (filters.clients) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.widget.widgetFilters.clients = filters.clients.map((client: any) => ({
                clientId: client.clientId,
                clientName: client.clientName,
                checked: true,
            }));
        }

        if (filters.startDate || filters.endDate) {
            this.widget.widgetFilters.startDate = filters.startDate;
            this.widget.widgetFilters.endDate = filters.endDate;
        }

        this.widget.widgetFilters.activeDate = filters.activeDate;

        if (filters.filters) {
            this.widget.widgetFilters.filters = filters.filters;
        }
    }
}
