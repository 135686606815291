import { ColumnDisplayType } from '../fields/column-display-type';
import { FieldMetadata } from '../fields/field-metadata';

export class HSColumnDefinition implements FieldMetadata {
    agBand: string = '';
    alignment: string = '';
    canAggregate: boolean = false;
    canPivotOn: boolean = false;
    datatype: string = '';
    description: string = '';
    displayName: string = '';
    hierarchy: string = '';
    isAttribute: boolean = false;
    isDynamic: boolean = false;
    isOrdinal: boolean = false;
    name: string = '';
    zeroIfNone: boolean = false;
    displayType: ColumnDisplayType = 'string';
    format: string = '';
    value: string | undefined;
    nameChecked: boolean = false;
    editable: boolean = false;

    public constructor(init?: FieldMetadata) {
        Object.assign(this, init);
    }
}
