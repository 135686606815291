export class BoardWidgetDefinitionIds {
    constructor(
        public readonly dashboardId?: string | number | undefined,
        public readonly widgetId?: number | undefined,
        public readonly definitionId?: number | undefined,
    ) {}

    toRecord(): Record<string, unknown> {
        return {
            dashboardId: this.dashboardId,
            widgetId: this.widgetId,
            definitionId: this.definitionId,
        };
    }
}
