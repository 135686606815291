import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PERMISSION, UserPermission, UserWithType, User } from '@ddv/models';

import { Roles } from '../../models/entitlement.constants';
import { UserEntitlementService } from '../../services/user-entitlement.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-select',
    templateUrl: './user-select.component.html',
    styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit {
    @Input() showUserList = false;
    @Input() selectedUsers?: UserPermission[];
    @Input() title?: string;
    @Input() featureType?: 'DASHBOARD' | 'WIDGET';
    @Input() permission?: string;

    @Output() selectedUsersChange = new EventEmitter<UserPermission[]>();

    selectedTab: string = '';
    searchParam: string = '';
    userList: User[] = [];
    private hasExternalShareEntitlement = false;
    private userRole?: string;

    constructor(
        private readonly userService: UserService,
        private readonly userEntitlementsService: UserEntitlementService,
    ) { }

    ngOnInit(): void {
        this.selectedTab = 'ALL';
        this.userEntitlementsService.entitlementsForClientCode$.subscribe((entitlements) => {
            this.userRole = entitlements.user.roleName;
            this.hasExternalShareEntitlement = entitlements.haveExternalShare;
        });

        this.userService.fetchUserListForPermissions(this.featureType).subscribe((data) => {
            this.convertToUserList(data);
            this.mergeSelectedUsers();
        });
    }

    toggleUserList(): void {
        if (!this.showUserList) {
            this.updateUserList();
        }
        this.showUserList = !this.showUserList;
    }

    applySelectedUsers(event: MouseEvent): void {
        event.stopPropagation();
        this.showUserList = false;
        this.searchParam = '';
        this.updateSelectedUsers();
    }

    onUserSelect(userName: string): void {
        let privilege: string | undefined;
        let displayPrivilege: string | undefined;
        const userSelected = this.userList.find((user) => user.name === userName);

        if (!userSelected) {
            return;
        }

        if (userSelected?.selected) {
            privilege = this.permission ?? '';
            displayPrivilege = this.getDisplayPrivilege(this.permission);
        } else {
            const existingUser = this.selectedUsers?.find((exUser) => exUser.name === userSelected.name);
            if (existingUser && existingUser.privilege !== this.permission) {
                privilege = existingUser.privilege;
                displayPrivilege = this.getDisplayPrivilege(existingUser.privilege);
            }
        }
        userSelected.privilege = privilege;
        userSelected.displayPrivilege = displayPrivilege;
    }

    removeUserPermission(event: MouseEvent, index: number): void {
        event.stopPropagation();
        this.showUserList = false;
        const removedUser = this.selectedUsers?.splice(index, 1)[0];
        const pos = this.userList.findIndex((user) => user.name === removedUser?.name);
        if (pos !== -1) { // user might be selected in the UI but still missing from the received user list
            this.userList[pos].selected = false;
        }
    }

    private convertToUserList(data: UserWithType[]): void {
        this.userList = data.reduce((users, user) => {
            if (this.includeUserInList(user)) {
                users.push({
                    id: undefined,
                    name: user.name,
                    sharedType: user.type,
                    privilege: undefined,
                    selected: false,
                });
            }

            return users;
        }, [] as User[]);
    }

    private mergeSelectedUsers(): void {
        this.selectedUsers?.forEach((selectedUser) => {
            if (!this.userList.find((user) => user.name === selectedUser.name)) {
                selectedUser.isMissing = true; // user is selected but missing in the received user list
                if (this.userRole !== Roles.DDV_SuperUser) {
                    selectedUser.cannotBeRemoved = true;
                }
            }
        });

        this.userList.forEach((user) => {
            const existingUser = this.selectedUsers?.find((exUser) => exUser.name === user.name);
            if (existingUser) {
                user.id = (existingUser.privilege === this.permission) ? existingUser.id : undefined;
                user.selected = existingUser.privilege === this.permission;
                user.privilege = existingUser.privilege;
                user.displayPrivilege = this.getDisplayPrivilege(existingUser.privilege);
            } else if (user.isMissing) {
                user.selected = true;
            }
        });
    }

    private updateUserList(): void {
        if (!this.userList.length && !this.selectedUsers?.length) {
            return;
        }
        this.userList.forEach((user) => {
            user.privilege = '';
            user.selected = false;
        });

        this.selectedUsers?.forEach((selectedUser) => {
            const user = this.userList.find((u) => u.name === selectedUser.name);
            if (user) {
                user.selected = selectedUser.privilege === this.permission;
                user.privilege = selectedUser.privilege;
                user.displayPrivilege = this.getDisplayPrivilege(selectedUser.privilege);
            }
        });
    }

    private updateSelectedUsers(): void {
        const currentSelectedUsers: UserPermission[] = this.userList.reduce((selectedUsers, user) => {
            if (user.selected) {
                selectedUsers.push({
                    id: user.id,
                    name: user.name,
                    privilege: user.privilege,
                    sharedType: user.sharedType,
                });
            }
            return selectedUsers;
        }, [] as UserPermission[]);

        this.selectedUsers = this.selectedUsers?.filter((selectedUser) => (selectedUser.privilege !== this.permission &&
            !currentSelectedUsers.find((currSelectedUser) => currSelectedUser.name === selectedUser.name)) ||
            selectedUser.cannotBeRemoved ||
            selectedUser.isMissing);
        this.selectedUsers = [...(this.selectedUsers ?? []), ...currentSelectedUsers];
        this.selectedUsersChange.emit(this.selectedUsers);
    }

    private getDisplayPrivilege(privilege?: string): string {
        return privilege === PERMISSION.EDIT ? 'View & Edit' : 'View Only';
    }

    private includeUserInList(user: UserWithType): boolean {
        return this.hasExternalShareEntitlement || (user.internal || (user.type === 'TEAM' && user.name !== 'Client Users'));
    }
}
