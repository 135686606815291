import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardSnapshot } from '@ddv/models';

import { DashboardGroup } from '../../../models/dashboard-group';

// only created dynamically, so you won't see a reference to the selector
// only created by DashboardNavComponent
@Component({
    selector: 'app-dashboard-family-dialog',
    templateUrl: './db-family-dialog.component.html',
    styleUrls: ['./db-family-dialog.component.scss'],
})
export class DashboardFamilyDialogComponent implements OnInit {
    @Input() dashboardGroupsList: DashboardGroup[] = [];
    @Input() currentDashboardGroup: DashboardGroup | undefined;
    @Input() currentDashboard: DashboardSnapshot | undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() changeDashboardFamily = new EventEmitter<any>();
    @Output() showFamilyToggle = new EventEmitter<boolean>(true);
    @Output() dashboardFamilyChanged = new EventEmitter<boolean>(false);

    isDisabled = false;
    value: string | undefined;

    private dashboardGroupsNames: string[] = [];

    ngOnInit(): void {
        this.dashboardGroupsNames = this.dashboardGroupsList.map((dashboardGroup) => dashboardGroup.name || 'None');
    }

    onDashboardFamilyCreation(value?: string): void {
        if (value === this.currentDashboardGroup?.name) {
            return;
        }

        const maxSortOrder = Math.max(...this.dashboardGroupsList.map((group) => group.sortOrder)) + 1;

        const newDashboardGroup = new DashboardGroup({
            id: 0,
            name: value?.trim(),
            sortOrder: maxSortOrder,
            dashboards: [],
        });

        this.dashboardGroupsList.push(newDashboardGroup);
        this.dashboardGroupsList.sort((dbgA, dbgB) =>
            dbgA.name == null ? 1 : (dbgB.name == null ? -1 : dbgA.name.localeCompare(dbgB.name)));
        this.onDashboardFamilyChange(newDashboardGroup);
    }

    onDashboardFamilyChange(dbGroup: DashboardGroup): void {
        this.changeDashboardFamily.emit({
            oldDbFamily: this.currentDashboardGroup,
            newDbFamily: dbGroup,
            dashboard: this.currentDashboard,
        });
        this.showFamilyToggle.emit(false);
        this.dashboardFamilyChanged.emit(true);
    }

    onFamilyValueValidation(value?: string): void {
        this.isDisabled = !value?.trim() || this.dashboardGroupsNames.some((name) => name === value.trim());
    }
}
