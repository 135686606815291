import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentStateService, DirtyFlagService, RealtimeActiveService } from '@ddv/behaviors';
import {
    ConfirmationPopupService,
    DropdownOption,
    ModalDialogRef,
    ModalDialogService,
    MultiSubscriptionComponent,
} from '@ddv/common-components';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { CompareModeService, DatasetManagerService, WidgetDataSourceService } from '@ddv/datasets';
import { Entitlements, UserEntitlements, UserEntitlementService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { LayoutService, ManagerService } from '@ddv/layout';
import {
    MODE,
    PERMISSION,
    CompareMode,
    DashboardPreference,
    WidgetOnBoard,
    UserPreferences,
    AppWidgetState,
    MenuOptionConfig,
    WidgetSettingsOverrides,
    ConfigItem,
    VisualizationMenuItem,
    getGlobalSaveDialogOptions,
} from '@ddv/models';
import { FuzzyDatesServiceV2 } from '@ddv/reference-data';
import { getDefault, clone, cloneArray, deepClone } from '@ddv/utils';
import { GridEvent, DashboardDetailsRelayService } from '@ddv/visualizations';
import { CommonDate, DateRange, FuzzyDate } from '@hs/ui-core-date';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { WidgetHeaderOptions } from '../../models/widget-header.options';
import { WidgetMenuItem } from '../../models/widget-menu.item';
import { WidgetSettingsOverridesService } from '../../services/widget-settings-overrides.service';
import { WidgetService } from '../../services/widget.service';
import { SaveWidgetFormComponent } from '../save-widget-form/save-widget-form.component';
import { VisMenuComponent } from './vis-menu/vis-menu.component';
import { WidgetSettingsOverridesComponent } from './widget-settings-overrides/widget-settings-overrides.component';

@Component({
    selector: 'app-widget-header',
    templateUrl: './widget-header.component.html',
    styleUrls: ['./widget-header.component.scss'],
})

export class WidgetHeaderComponent extends MultiSubscriptionComponent implements AfterContentInit, OnInit, OnDestroy {
    @Input() dashboardId: string | number = 0;
    @Input() widgetId: number = 0;
    @Input() headers: WidgetHeaderOptions[] = [];
    @Input() userPreferences: UserPreferences | undefined;
    @Input() widgetOnBoard: WidgetOnBoard | undefined;

    @Output() updateFilter = new EventEmitter();
    @Output() visualizationChanged = new EventEmitter<VisualizationMenuItem>();
    @Output() widgetSaved = new EventEmitter<AppWidgetState>();
    @Output() settingsChanged = new EventEmitter<{ updatedWidget: AppWidgetState, changes: WidgetSettingsOverrides }>();
    @Output() headerInit = new EventEmitter<Event>();
    @Output() headerMenuSelect = new EventEmitter<string>();

    private dashboardPreferences: DashboardPreference[] | undefined;
    private clientCode: string = '';
    filterBy: WidgetMenuItem | ConfigItem = { value: '' };
    filterOptions: DropdownOption[] = [];
    selectedFilterOption: DropdownOption | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visMenuOptions: any[] = [];
    selectedMenuIcon = '';
    private menuOptions: MenuOptionConfig[] = [];
    private dashboardQueryParams: DashboardPreference | undefined;
    private widgetPrefs: AppWidgetState | undefined;
    private settingsOverridesAlreadyLoaded = false; // in view mode we need to load settings only once!
    private widgetEdit: boolean = false;
    private canManageCrosstalk: boolean = false;
    private isApprover: boolean = false;
    private crosstalkManagementPageURL: string = '';
    private options: WidgetMenuItem[] | ConfigItem[] = [];
    fuzzyDates: FuzzyDate[] = [];

    @ViewChild('visMenu', { static: true }) private readonly visMenu: VisMenuComponent | undefined;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly dirtyFlagService: DirtyFlagService,
        private readonly layoutService: LayoutService,
        private readonly manager: ManagerService,
        private readonly modalService: ModalDialogService,
        private readonly widgetService: WidgetService,
        private readonly queryParamsService: QueryParamsService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly userEntitlementsService: UserEntitlementService,
        private readonly datasetManagerService: DatasetManagerService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly compareModeService: CompareModeService,
        private readonly fuzzyDatesService: FuzzyDatesServiceV2,
        private readonly currentStateService: CurrentStateService,
        private readonly dsdService: DatasetDefinitionsService,
        private readonly widgetSettingsOverridesService: WidgetSettingsOverridesService,
        private readonly realtimeActiveService: RealtimeActiveService,
        private readonly dashboardDetailsRelay: DashboardDetailsRelayService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.widgetPrefs = this.widgetService.getDefaultWidgetState();

        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (dashboardPref: DashboardPreference) => {
            this.dashboardQueryParams = dashboardPref;
        });

        this.subscribeTo(this.userEntitlementsService.entitlementsForClientCode$, (entitlements: UserEntitlements) => {
            this.widgetEdit = entitlements.hasPermission(Entitlements.WIDGET_EDIT);
            this.canManageCrosstalk = entitlements.canManageCrosstalk;
        });

        this.subscribeTo(this.userEntitlementsService.isApprover$, (isApprover) => this.isApprover = isApprover);

        this.currentStateService.clientCode$
            .pipe(switchMap((clientCode) => {
                this.clientCode = clientCode;
                this.crosstalkManagementPageURL = `/two-way/#/${clientCode}/management`;
                return this.fuzzyDatesService.getFuzzyDatesCC();
            }))
            .subscribe((fuzzyDates) => this.fuzzyDates = fuzzyDates);

        this.dashboardDetailsRelay.currentDashboardDetails.subscribe((currentDashboardDetails) => {
            this.dashboardPreferences = currentDashboardDetails?.dashboardPreferences;
        });
    }

    ngAfterContentInit(): void {
        this.configureHeaders();
        this.widgetService.gridStateObs.subscribe((gridEvent: GridEvent) => {
            if (gridEvent.triggeredByUser) {
                this.dirtyFlagService.enterDirtyState(this.manager.getCurrentDashboardId() ?? '');
            }
        });
        this.headerInit.emit();
    }

    private configureHeaders(): void {
        this.headers.forEach((item) => {
            const menuBtn = this.createMenuBtn(item);
            if (item.selector === 'custom-filter') {
                this.updateFilterOptions(item.menuItems);
            } else if (item.selector === 'visBtn') {
                this.visMenuOptions = item.menuItems ?? [];
            }
            this.menuOptions.push(menuBtn);
        });
        this.manager.addMenuOptionsForWidget(this.widgetId, this.menuOptions);
    }

    // called by viz-widget-component
    updateMenuOptions(headerList: WidgetHeaderOptions[]): void {
        this.menuOptions = [];
        this.headers = [...headerList];
        this.configureHeaders();
        this.visMenu?.updateMenuItems(this.visMenuOptions);
    }

    // called by viz-widget-component
    updateFilterOptions(items: WidgetMenuItem[] | ConfigItem[] = []): void {
        if (items.length) {
            this.filterOptions = items.map((i: WidgetMenuItem | ConfigItem) => ({
                text: i.showCustomName ? i.customName ?? '' : i.label ?? '',
                key: i.value,
                value: i.value,
            }));
            this.options = items;
            if (!this.filterOptions.find((filterItem) => filterItem.value === this.filterBy.value)) {
                this.filterBy = getDefault(items, this.filterBy)!;
            }
            this.selectedFilterOption = { text: this.filterBy.label ?? '', key: this.filterBy.value, value: this.filterBy.value };
        }
    }

    // called by viz-widget-component
    updateVizMenuOptions(menuItems: VisualizationMenuItem[]): void {
        this.visMenuOptions = menuItems;
        this.visMenu?.updateMenuItems(this.visMenuOptions);
    }

    private createMenuBtn(item: WidgetHeaderOptions): MenuOptionConfig {
        const result: MenuOptionConfig = {
            selector: item.selector,
            title: item.title,
            hidden: item.hidden,
            callBack: this.onMenuIconClick.bind(this),
        };
        if (item.styleClasses) {
            result.styleClasses = item.styleClasses;
        }
        if (item.iconName) {
            result.iconName = item.iconName;
        }
        return result;
    }

    private onMenuIconClick(selector: string): void {
        this.selectedMenuIcon = this.selectedMenuIcon !== selector ? selector : '';
        if (this.selectedMenuIcon === 'menuBtn') {
            this.openSaveDialog();
        } else if (this.selectedMenuIcon === 'settingsOverridesBtn') {
            this.openWidgetSettingsOverridesModal();
        } else if (this.selectedMenuIcon === 'visBtn' && this.visMenuOptions.length > 1) {
            this.cdr.detectChanges();
            this.headerMenuSelect.emit(selector);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onFilterChanged(item: any): void {
        this.widgetPrefs = this.manager.getWidgetPreferences(this.widgetId)!;

        const widgetCompareData = this.compareModeService.compareDataMap.get(this.widgetPrefs?.id ?? 0);
        if (widgetCompareData) {
            this.toggleCompareMode(false, undefined, this.widgetPrefs, this.dashboardQueryParams!);
            this.compareModeService.clearCompareDataByWidgetId(this.widgetPrefs.id ?? 0);
        }
        const selectedItem = (this.options as ConfigItem[]).find((opt) => opt.value === item.value)!;
        this.queryParamsService.dispatchUpdatedQueryParams({ areFiltersAppliedByMaster: false });
        this.filterBy = item;
        this.updateFilter.emit({
            label: selectedItem.label,
            value: selectedItem.value,
            showCustomName: selectedItem.showCustomName,
            customName: selectedItem.customName,
            colorSortBy: selectedItem.colorSortBy,
            colorSortDirection: selectedItem.colorSortDirection,
        });
        this.selectedMenuIcon = '';
    }

    onVisSelect(item: VisualizationMenuItem): void {
        this.widgetPrefs = this.manager.getWidgetPreferences(this.widgetId) ?? undefined;
        if (this.widgetPrefs?.isMaster) {
            this.removeAllMasterWidgetFilters();
        }
        this.onVisChanged(item);
    }

    onVisChanged(item: VisualizationMenuItem): void {
        this.visualizationChanged.emit(item);
        this.selectedMenuIcon = '';
    }

    selectVisualization(menuItem: WidgetMenuItem): void {
        this.visMenuOptions.forEach((visMenuItem) => {
            visMenuItem.selected = visMenuItem.value === menuItem.value;
        });
        this.visMenu?.selectViz(menuItem);
    }

    selectFilter(filterBy: ConfigItem): void {
        this.filterBy = filterBy;
    }

    removeAllMasterWidgetFilters(): void {
        const filters = this.dashboardQueryParams?.filters?.filter((dashboardFilter) => !dashboardFilter.isMasterWidgetFilter);
        this.queryParamsService.dispatchUpdatedQueryParams({ filters });
    }

    // from here, this is always on a dashboard
    private openSaveDialog(): void {
        this.widgetPrefs = this.manager.getWidgetPreferences(this.widgetId) ?? undefined;
        const info: AppWidgetState = deepClone(this.widgetPrefs)!;
        const saveWidgetDialogRef = this.modalService.open(SaveWidgetFormComponent, { windowClass: 'save-dialog' });
        const dialog: SaveWidgetFormComponent = saveWidgetDialogRef.componentInstance;
        dialog.formData = info;
        dialog.isDashboardWidget = true;
        dialog.isReadOnly = this.isReadOnlyWidget();
        dialog.userWidgetPermissions = info.widgetPermissions ? cloneArray(info.widgetPermissions) : [];
        dialog.widgetSaveFormSubmit.subscribe((widgetData: AppWidgetState) => {
            if (this.isReadOnlyWidget()) {
                this.updateWidgetTags(widgetData, saveWidgetDialogRef);
            } else {
                this.updateWidget(widgetData, saveWidgetDialogRef);
            }
        });
    }

    private updateWidgetPreferences(
        updatedOverrides: WidgetSettingsOverrides,
        widgetState: AppWidgetState,
        overrides: WidgetSettingsOverrides,
    ): void {
        if (!widgetState.widgetFilters) {
            return;
        }

        widgetState.widgetFilters.isComparing = updatedOverrides.compareMode;
        widgetState.widgetFilters.compareDates = this.compareModeService.toDateRangeString(updatedOverrides.compareDates);
        widgetState.widgetFilters.comparing = updatedOverrides.compareMode ? CompareMode.COMPARED : CompareMode.ORIGINAL;

        const dbFiltersSubscriptionChanged = widgetState.isSubscribedToDashboardFilters !== updatedOverrides.subscribedToDashboardFilters;

        updatedOverrides.applyToWidget(widgetState);

        this.queryParamsService.dispatchUpdatedQueryParams({
            isComparing: updatedOverrides.compareMode,
            compareDates: this.compareModeService.toDateRangeString(updatedOverrides.compareDates),
            comparing: updatedOverrides.compareMode ? CompareMode.COMPARED : CompareMode.ORIGINAL,
        });

        const changes = updatedOverrides.enumerateChanges(overrides);
        changes.subscribedToDashboardFilters = dbFiltersSubscriptionChanged ? changes.subscribedToDashboardFilters : false;

        this.settingsChanged.emit({ updatedWidget: widgetState, changes });
    }

    private toggleCompareMode(
        compareMode: boolean,
        compareDates: DateRange | CommonDate | undefined,
        widgetState: AppWidgetState,
        currentDashboardQueryParams: DashboardPreference,
    ): void {
        if (!widgetState) {
            return;
        }

        const widgetPrefs = this.manager.getWidgetPreferences(widgetState.id ?? 0);
        if (!widgetPrefs) {
            return;
        }

        const widgetFilters = widgetPrefs.widgetFilters;
        if (widgetFilters) {
            widgetFilters.isComparing = compareMode;
            widgetFilters.compareDates = compareMode ? this.compareModeService.toDateRangeString(compareDates) : undefined;
            widgetFilters.comparing = compareMode ? CompareMode.COMPARED : undefined;

            const isSubscribedToDashboardFilters = widgetPrefs.isSubscribedToDashboardFilters;
            if (widgetFilters.isComparing) {
                widgetFilters.funds = isSubscribedToDashboardFilters ? currentDashboardQueryParams.funds : widgetFilters.funds;
                widgetFilters.filters = isSubscribedToDashboardFilters ? currentDashboardQueryParams.filters : widgetFilters.filters;
                widgetFilters.timestamp = new Date().getTime();

                this.compareModeService.updateWidgetsCompareModeStatus({
                    widgetId: widgetState.id ?? 0,
                    isInCompareMode: !!widgetPrefs.enableCompareMode,
                    compareDates,
                });
                this.queryParamsService.addWidgetQueryParam(widgetState.id ?? 0, widgetFilters);
            } else {
                if (isSubscribedToDashboardFilters) {
                    this.queryParamsService.removeWidgetQueryParam(widgetState.id ?? 0);
                }
                this.compareModeService.updateWidgetsCompareModeStatus({
                    widgetId: widgetState.id ?? 0,
                    isInCompareMode: compareMode,
                    compareDates,
                });
                const uniqueKey = this.datasetManagerService.getUniqueKey(widgetPrefs);
                this.widgetDataSourceService.restoreDataSource(uniqueKey);
            }
        }
    }

    private openWidgetSettingsOverridesModal(): Subscription {
        const widgetState = this.manager.getWidgetPreferences(this.widgetId)!;
        const settingsOverridesId: number = widgetState.id ?? 0;

        const widgetOverrides$ = this.settingsOverridesAlreadyLoaded ?
            of(new WidgetSettingsOverrides().deriveEffectiveStateFrom(widgetState)) :
            this.widgetSettingsOverridesService.getWidgetSettingsOverrides(this.clientCode, settingsOverridesId);

        return widgetOverrides$.subscribe((overrides: WidgetSettingsOverrides) => {
            const modalRef = this.modalService.open(WidgetSettingsOverridesComponent, { windowClass: 'save-dialog overrides-dialog' });
            const modal: WidgetSettingsOverridesComponent = modalRef.componentInstance;
            modal.widgetState = widgetState;
            modal.fuzzyDates = this.fuzzyDates;
            modal.overrides = overrides.clone();
            modal.inFilterOnlyMode = this.viewIsInViewMode;
            modal.dataLoadTime = this.datasetManagerService.dataLoadedTime.get(this.datasetManagerService.getUniqueKey(widgetState)) || '-';
            modal.canManageCrosstalk = this.canManageCrosstalk || this.isApprover;
            modal.crosstalkManagementPageURL = this.crosstalkManagementPageURL;
            modal.dashboardPreferences = this.dashboardPreferences;

            modal.allowJumpToManageWidgets = widgetState.permissionForUser(this.userPreferences?.username ?? '') === 'EDIT' || this.widgetEdit;

            if (widgetState?.datasetDefinition?.id) {
                this.dsdService.fetchDatasetDefinitionDetails(widgetState.datasetDefinition.id).subscribe((dsdDetail) => {
                    if (dsdDetail.isQueryTemplateStacked) {
                        modal.isStackedQuery = true;
                    }
                });
            }

            this.settingsOverridesAlreadyLoaded = true;

            modal.saveClicked.subscribe((updatedOverrides: WidgetSettingsOverrides) => {
                modalRef.close();

                if (updatedOverrides.defaultSlicerForDefaultVisualization) {
                    const filterOption = this.filterOptions
                        .find((fo) => fo.value === updatedOverrides.defaultSlicerForDefaultVisualization);
                    this.filterBy = filterOption || { value: '' };
                }

                if (this.viewIsInViewMode) {
                    if (updatedOverrides.compareMode !== undefined) {
                        this.compareModeService.updateWidgetsCompareModeStatus({
                            widgetId: widgetState.id ?? 0,
                            isInCompareMode: updatedOverrides.compareMode,
                            compareDates: updatedOverrides.compareDates,
                        });
                    }
                    this.updateWidgetPreferences(updatedOverrides, widgetState, overrides);
                    this.compareModeService.updateCurrentWidgetInCompareMode(widgetState.id ?? 0);
                    this.toggleCompareMode(
                        !!updatedOverrides.compareMode,
                        updatedOverrides.compareDates,
                        widgetState,
                        updatedOverrides.currentDashboardQueryParams!);
                    return;
                }

                if (widgetState.isGlobal) {
                    this.confirmSavingGlobalWidgetSettings(settingsOverridesId, widgetState, overrides, updatedOverrides);
                } else {
                    this.saveWidgetSettings(settingsOverridesId, widgetState, overrides, updatedOverrides);
                }
            });

            modal.manageWidgetClicked.subscribe(async () => {
                modalRef.close();

                await this.router.navigate(
                    ['../../../', 'widgets', 'edit', widgetState.coreWidgetId],
                    { relativeTo: this.route });
            });
        });
    }

    saveWidgetSettings(
        settingsOverridesId: number,
        widgetState: AppWidgetState,
        overrides: WidgetSettingsOverrides,
        updatedOverrides: WidgetSettingsOverrides,
    ): void {
        if (updatedOverrides.masterWidget) {
            const currentDashboardWidgets = this.manager.getWorkspace()?.extraParameters?.widgets ?? [];
            currentDashboardWidgets.forEach((widget) => {
                if (widget.id !== this.widgetId) {
                    this.widgetSettingsOverridesService.getWidgetSettingsOverrides(this.clientCode, widget.id ?? 0)
                        .subscribe((additonalWidgetOverrides) => {
                            if (additonalWidgetOverrides.masterWidget) {
                                const updatedAdditionalWidgetOverrides = additonalWidgetOverrides.clone();
                                const additionalWidgetState = this.manager.getWidgetPreferences(widget.id ?? 0)!;

                                updatedAdditionalWidgetOverrides.masterWidget = false;

                                this.widgetSettingsOverridesService
                                    .saveWidgetSettingsOverrides(this.clientCode, widget.id ?? 0, updatedAdditionalWidgetOverrides)
                                    .subscribe(() => {
                                        updatedAdditionalWidgetOverrides.applyToWidget(additionalWidgetState);

                                        this.settingsChanged.emit({
                                            updatedWidget: additionalWidgetState,
                                            changes: updatedAdditionalWidgetOverrides.enumerateChanges(additonalWidgetOverrides),
                                        });
                                    });
                            }
                        });
                }
            });
        }

        this.widgetSettingsOverridesService.saveWidgetSettingsOverrides(this.clientCode, settingsOverridesId, updatedOverrides)
            .subscribe(() => {
                this.updateWidgetPreferences(updatedOverrides, widgetState, overrides);

                const widgets = this.manager.getWorkspace()?.extraParameters?.widgets ?? [];
                this.realtimeActiveService.updateRealtimeActive(widgets);
            });
    }

    private updateWidgetTags(widgetInfo: AppWidgetState, saveWidgetDialogRef: ModalDialogRef): void {
        const widgetPrefs = this.widgetPrefs;
        if (!widgetPrefs) {
            return;
        }

        this.widgetService.updateWidgetTags(widgetPrefs.coreWidgetId ?? 0, widgetInfo.widgetTags ?? []).subscribe((tags) => {
            this.selectedMenuIcon = '';
            widgetPrefs.widgetTags = tags;
            this.manager.setWidgetExtraPreferences(widgetPrefs.id, widgetPrefs);
            saveWidgetDialogRef.close();
        });
    }

    private updateWidget(widgetInfo: AppWidgetState, saveWidgetDialogRef: ModalDialogRef): void {
        if (!this.widgetPrefs) {
            return;
        }

        const updatedWidgetInfo = new AppWidgetState(clone(widgetInfo, {
            id: this.widgetPrefs.coreWidgetId,
            coreWidgetType: this.widgetPrefs.coreWidgetType,
            visualizationConfigs: this.widgetPrefs.visualizationConfigs,
            widgetFilters: this.widgetPrefs.widgetFilters,
            datasetDefinition: this.widgetPrefs.datasetDefinition,
        }));

        this.widgetService.updateDashboardWidget(updatedWidgetInfo).subscribe((widget) => {
            if (!this.widgetPrefs) {
                return;
            }

            this.selectedMenuIcon = '';
            this.widgetPrefs.displayNameType = widget.displayNameType;
            this.widgetPrefs.widgetTags = widget.widgetTags;
            this.widgetPrefs.customDisplayName = widget.customDisplayName;
            this.widgetPrefs.version = widget.version;
            this.manager.setWidgetExtraPreferences(this.widgetPrefs.id, this.widgetPrefs);
            this.widgetSaved.emit(this.widgetPrefs);
            saveWidgetDialogRef.close();
        });
    }

    private get viewIsInViewMode(): boolean {
        return this.layoutService.getWorkspaceMode() === MODE.VIEW;
    }

    private confirmSavingGlobalWidgetSettings(
        settingsOverridesId: number,
        widgetState: AppWidgetState,
        overrides: WidgetSettingsOverrides,
        updatedOverrides: WidgetSettingsOverrides,
    ): void {
        const confirmDialogOptions = getGlobalSaveDialogOptions();
        this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.saveWidgetSettings(settingsOverridesId, widgetState, overrides, updatedOverrides);
                }
            },
        });
    }

    private isReadOnlyWidget(): boolean {
        return this.viewIsInViewMode || this.widgetPrefs?.privilege === PERMISSION.VIEW;
    }
}
