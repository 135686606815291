import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { ManagerService } from '@ddv/layout';
import { VisualizationMenuItem } from '@ddv/models';

import { WidgetMenuItem } from '../../../models/widget-menu.item';

@Component({
    selector: 'app-vis-menu',
    templateUrl: './vis-menu.component.html',
    styleUrls: ['../widget-header.component.scss'],
})
export class VisMenuComponent extends MultiSubscriptionComponent implements AfterContentInit, OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() menuItems: any = [];
    @Input() widgetId: number = 0;
    @Input() selectedMenuIcon = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() visualizationChanged = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() selectVisualization = new EventEmitter<any>();
    selectedItem: VisualizationMenuItem = { label: '', value: '', cssClass: '' };
    isVizMaximized = false;

    constructor(private readonly manager: ManagerService) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(this.manager.maximizeWidgetAction$, ({ toBeMaximized, widgetId, item }) => {
            if (!toBeMaximized && widgetId === this.widgetId && item) {
                this.onVisChanged(item, true);
            }
        });

        this.subscribeTo(this.manager.isWidgetMaximized$, (value: boolean) => this.isVizMaximized = value);
    }

    ngAfterContentInit(): void {
        this.updateViz();
    }

    updateViz(): void {
        if (this.menuItems.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const selectedItem = this.menuItems.find((menuItem: any) => menuItem.selected);
            if (selectedItem) {
                this.onVisChanged(selectedItem, false);
            }
        }
    }

    selectViz(menuItem: WidgetMenuItem): void {
        if (menuItem) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.menuItems.map((visMenuItem: any) => visMenuItem.selected = visMenuItem.value === menuItem.value);
        }
        this.updateViz();
    }

    updateMenuItems(menuList: WidgetMenuItem[]): void {
        this.menuItems = [...menuList];
        this.updateViz();
    }

    onVisChanged(item: VisualizationMenuItem, onClick: boolean): void {
        if (this.selectedItem.cssClass === item.cssClass) {
            return;
        }
        this.selectedItem = item;

        this.manager.updateMenuOptionStyleForWidget(this.widgetId, 'visBtn', item.cssClass);
        if (onClick) {
            this.selectVisualization.emit(item);
        } else {
            this.visualizationChanged.emit(item);
        }
    }

    onVisChangedBigW(item: VisualizationMenuItem): void {
        if (this.selectedItem.cssClass === item.cssClass) {
            return;
        }
        const options = {
            toBeMaximized: true,
            widgetId: this.widgetId,
            item: this.selectedItem,
        };

        this.manager.setMaximizeWidgetActionSubjectValue(options);

        this.selectedItem = item;
        this.manager.updateMenuOptionStyleForWidget(this.widgetId, 'visBtn', item.cssClass);
        this.selectVisualization.emit(item);
    }
}

