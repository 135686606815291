import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardService } from '@ddv/dashboards';
import { CustomColDef } from '@ddv/data-grid';
import { DatasetManagerService, Datasource, MetadataService, WidgetDataSourceService } from '@ddv/datasets';
import { UserEntitlementService, UserService } from '@ddv/entitlements';
import { Workspace, ManagerService } from '@ddv/layout';
import {
    BoardWidgetDefinitionIds,
    DATASET_KEY,
    WIDGET_KEY,
    WIDGET_LIFECYCLE_EVENT,
    crosstalkCheckboxFieldId,
    UserDefinedFieldType,
    CompareColumnID,
    WidgetData,
    DdvDate,
    DdvDateTime,
    UserPreferences,
    ExportDatasetInfo,
    ExportDatasetWidgetInfo,
    ExportInfo,
    ExportWidgetInfo,
    TExportFormat,
    ConfigItem,
    MetadataLookup,
} from '@ddv/models';
import { deepClone } from '@ddv/utils';
import { FilteredDataService, WidgetExportService } from '@ddv/widgets';
import { Theme, ThemeService } from '@hs/ui-core-presentation';
import { combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { DashboardPDFExportService } from '../../services/dashboard-pdf-export';

interface ExportRequestParameter {
    dashboardName: string;
    dashboardId: string;
    dataSet: ExportDatasetInfo[];
}

enum ExportType {
    FULL = 'Full',
    FILTERED = 'Filtered'
}

const SPECIAL_CHARS_REGEX = /[\\/?:*"|<>#()]/g;

@Component({
    selector: 'app-dashboard-export',
    templateUrl: 'dashboard-export.component.html',
    styleUrls: ['dashboard-export.component.scss'],
})
export class DashboardExportComponent extends MultiSubscriptionComponent implements OnInit {
    @Input() isWidgetExport = false;
    @Input() widgetId: number | undefined;
    @Output() exportOptionsClose = new EventEmitter<ExportInfo>();

    exportWidgetInfos: ExportWidgetInfo[] = [];
    isFullExport = false;
    selectAll = { isChecked: false, value: false };
    selectedWidgetCount = 0;
    includeCrosstalkKeys = false;
    showCrosstalkKeysExportToggle = false;

    private currentDashboard: Workspace | undefined;
    private exportFormat: TExportFormat | undefined;
    private exportInfo: ExportInfo | undefined;
    private metadata: MetadataLookup | { [widgetId: number]: MetadataLookup } | undefined;
    private requestParam: ExportRequestParameter | undefined;
    private selectedWidgetInfos: ExportWidgetInfo[] = [];
    private gridsSelectedWidgetsInfos: ExportWidgetInfo[] = [];
    private nonGridsSelectedWidgetsInfos: ExportWidgetInfo[] = [];
    private sequenceNo = 1;
    private gridsFullExportColumns: { widgetId: string, columns: string[] | undefined }[] | undefined;
    private gridsMetadata: MetadataLookup | { [widgetId: number]: MetadataLookup } | undefined;
    private nonGridsMetadata: MetadataLookup | { [widgetId: number]: MetadataLookup } | undefined;
    private userPreferences: UserPreferences = new UserPreferences();
    private theme: Theme = Theme.light;
    private widgetDatasource: Datasource | undefined;

    constructor(
        private readonly dashboardPdfExportService: DashboardPDFExportService,
        private readonly dashboardService: DashboardService,
        private readonly filteredDataService: FilteredDataService,
        private readonly managerService: ManagerService,
        private readonly metadataService: MetadataService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly userService: UserService,
        private readonly userEntitlementService: UserEntitlementService,
        private readonly datasetManagerService: DatasetManagerService,
        private readonly exportService: WidgetExportService,
        private readonly themeService: ThemeService,
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([this.userService.userPreferences$, this.themeService.currentTheme$])
            .subscribe(([userPreferences, theme]) => {
                this.userPreferences = userPreferences;
                this.isFullExport = !!this.userPreferences.isFullExport;
                this.theme = theme;
            });

        this.subscribeTo(this.widgetDataSourceService.dataSource$, (dataSource) => this.widgetDatasource = dataSource);

        this.currentDashboard = this.managerService.getWorkspace();
        this.exportWidgetInfos = this.managerService.getAppWidgetsState()
            .map((widget) => {
                return {
                    id: widget.id ?? 0,
                    isSubscribedToDashboardFilters: !!widget.isSubscribedToDashboardFilters,
                    name: (widget.displayNameType === 'CUSTOM' ? widget.customDisplayName : widget.name) ?? '',
                    isChecked: false,
                    datasetDefinition: widget.datasetDefinition!,
                    currentVisualization: widget.currentVisualization,
                    currentVisualizationConfig: widget.visualizationConfigs.find((config) => {
                        return config.visualizationType === widget.currentVisualization;
                    }),
                };
            })
            .sort((a, b) => (a.name ?? '').toLowerCase().localeCompare((b.name ?? '').toLowerCase()));

        this.userEntitlementService.entitlementsForClientCode$.subscribe((entitlements) => {
            if (entitlements.haveCrosstalkCommentImport) {
                this.showCrosstalkKeysExportToggle = !!this.currentDashboard?.extraParameters?.widgets.some((widget) => {
                    return widget.datasetDefinition?.conversableType && widget.currentVisualization === 'ADVANCED_GRID';
                });
            }
        });

        this.exportService.updateIncludeCrosstalkKeys(false);
    }

    get dataIsLoaded(): boolean {
        return !!(this.widgetDatasource && this.widgetDatasource.datasources.length > 0);
    }

    exportDashboard(exportFormat: TExportFormat): void {
        this.exportFormat = exportFormat;
        this.setExportInfo();
        this.sequenceNo = 1;

        if (this.isWidgetExport) {
            this.selectedWidgetInfos = this.exportWidgetInfos.filter((ew) => ew.id === this.widgetId);
            this.selectedWidgetCount = 1;
        } else {
            this.selectedWidgetInfos = this.exportWidgetInfos.filter((ew) => ew.isChecked);
        }

        if (!this.selectedWidgetInfos.length && this.exportFormat === 'PDF') {
            const widgets = this.exportWidgetInfos.map((widget) => ({
                widgetName: widget.name,
                widgetId: widget.id.toString(),
                columnNames: [],
            }));
            this.requestParam = this.getExportRequestParams(undefined);
            this.requestParam.dataSet.push({ metadata: undefined, widgets, data: [], summary: undefined });
            this.exportData();
        }

        if (!this.selectedWidgetInfos.length) {
            this.sendNoWidgetMessage();
            return;
        }

        this.gridsSelectedWidgetsInfos = this.selectedWidgetInfos.filter((widget) => {
            return widget.currentVisualization?.includes('GRID');
        });

        if (this.isFullExport) {
            this.nonGridsSelectedWidgetsInfos = this.selectedWidgetInfos.filter((widget) => {
                return !widget.currentVisualization?.includes('GRID');
            });

            let gridContexts: BoardWidgetDefinitionIds[] | undefined;
            let nonGridContexts: BoardWidgetDefinitionIds[] | undefined;

            if (this.gridsSelectedWidgetsInfos.length) {
                gridContexts = this.exportInfosToMetadataFetchContexts(this.gridsSelectedWidgetsInfos);
            }

            if (this.nonGridsSelectedWidgetsInfos.length) {
                nonGridContexts = this.exportInfosToMetadataFetchContexts(this.nonGridsSelectedWidgetsInfos);
            }

            this.selectedWidgetInfos.forEach((selectedWidget) => {
                this.managerService
                    .sendMessageToExistingWidget(selectedWidget.id, WIDGET_LIFECYCLE_EVENT.EXPORT_FULL_DATA);
            });

            this.requestParam = {
                dashboardName: this.currentDashboard?.getExtraParameters().name,
                dashboardId: this.currentDashboard?.id.toString() ?? '',
                dataSet: [],
            };

            if (gridContexts && !nonGridContexts) {
                this.subscribeTo(this.metadataService.fetchMetadataAllMetadata(gridContexts)
                    .pipe(
                        tap((gridsMetadata) => {
                            this.gridsMetadata = gridsMetadata;
                        }),
                        switchMap(() => this.filteredDataService.fullData$)), (fullData) => this.exportFullDashboard(fullData));
            }

            if (!gridContexts && nonGridContexts) {
                this.subscribeTo(this.metadataService.fetchMetadataAllMetadata(nonGridContexts)
                    .pipe(
                        tap((nonGridsMetadata) => {
                            this.nonGridsMetadata = nonGridsMetadata;
                        }),
                        switchMap(() => this.filteredDataService.fullData$)), (fullData) => this.exportFullDashboard(fullData));
            }

            if (gridContexts && nonGridContexts) {
                this.subscribeTo(this.metadataService.fetchMetadataAllMetadata([...gridContexts, ...nonGridContexts])
                    .pipe(
                        tap((metadata) => {
                            this.gridsMetadata = {};
                            this.nonGridsMetadata = {};
                            for (const widgetId in metadata) {
                                if (gridContexts?.find((gc) => gc.widgetId?.toString() === widgetId)) {
                                    this.gridsMetadata = {
                                        ...this.gridsMetadata,
                                        [widgetId]: metadata[widgetId],
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    } as any;
                                } else if (nonGridContexts?.find((gc) => gc.widgetId?.toString() === widgetId)) {
                                    this.nonGridsMetadata = {
                                        ...this.nonGridsMetadata,
                                        [widgetId]: metadata[widgetId],
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    } as any;
                                }
                            }
                        }),
                        switchMap(() => this.filteredDataService.fullData$)), (fullData) => this.exportFullDashboard(fullData));
            }
        } else {
            const contexts: BoardWidgetDefinitionIds[] = this.exportInfosToMetadataFetchContexts(this.selectedWidgetInfos);

            this.subscribeTo(this.metadataService.fetchMetadataAllMetadata(contexts)
                .pipe(
                    tap((metadata) => {
                        this.metadata = metadata;
                        this.selectedWidgetInfos.forEach((widget) => {
                            if (this.exportFormat === 'CSV' && widget.currentVisualization === 'ADVANCED_GRID') {
                                this.managerService.sendMessageToExistingWidget(
                                    widget.id, WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_ADVANCED_GRID_DATA_TO_CSV);
                            } else {
                                this.managerService.sendMessageToExistingWidget(
                                    widget.id, WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA);
                            }
                        });
                    }),
                    switchMap(() => this.filteredDataService.filteredData$)), (filteredData) => this.exportFilteredDashboard(filteredData));
        }
    }

    onExportTypeChange(isFilteredExport: boolean): void {
        this.isFullExport = !isFilteredExport;
        this.userPreferences.isFullExport = this.isFullExport;
        this.userService.updateUserPreferenceData(this.userPreferences).subscribe();
    }

    onCrosstalkKeyExportChange(excludeCrosstalkKeys: boolean): void {
        this.includeCrosstalkKeys = !excludeCrosstalkKeys;
        this.exportService.updateIncludeCrosstalkKeys(!excludeCrosstalkKeys);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    getSubscribedWidgetDatasets(metadata: any, isGrid: boolean, exportData?: any): ExportDatasetInfo[] {
        if (!this.dataIsLoaded) {
            throw new Error('Cannot export - data sources have not loaded');
        }

        const exportDatasetInfos: ExportDatasetInfo[] = [];
        const selectedWidgetInfos = isGrid ? this.gridsSelectedWidgetsInfos : this.nonGridsSelectedWidgetsInfos;
        selectedWidgetInfos.forEach((sw) => {
            const widgetColumns = this.getWidgetColumns(sw.currentVisualizationConfig?.configs?.values);
            const dateTypeOverrides = this.getDataTypeOverrides(sw, metadata);
            if (sw.isSubscribedToDashboardFilters) {
                let udfColumns: ConfigItem[] = [];
                if (sw.currentVisualization === 'ADVANCED_GRID') {
                    udfColumns = sw.currentVisualizationConfig?.configs?.values
                        .filter((column) => this.exportService.isUserDefinedFieldColumn(column.name))
                        .map((column) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            return { colId: column.name, userDefinedFieldType: column.datatype as UserDefinedFieldType } as any;
                        }) ?? [];
                }
                let data = this.getWidgetData(DATASET_KEY, sw.datasetDefinition.id!, udfColumns);
                const columns = this.getColumns(sw, isGrid, widgetColumns, metadata);
                data = this.addDataToCompareColumns(sw, columns, data, exportData);
                const widgets = this.getWidgets(sw, columns, exportData, dateTypeOverrides);
                if (this.exportService.shouldIncludeCrosstalkKeys(sw)) {
                    data = this.addCrosstalkKeysData(sw, data);
                    const crosstalkFields = this.datasetManagerService.crosstalkSchemaFields.get(sw.datasetDefinition.id!);
                    exportDatasetInfos.push({ metadata: metadata[sw.id], widgets, data, summary: {}, crosstalkFields });
                } else {
                    exportDatasetInfos.push({ metadata: metadata[sw.id], widgets, data, summary: {} });
                }
                this.sequenceNo += 1;
            }
        });
        return exportDatasetInfos;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    getUnsubscribedWidgetDatasets(metadata: any, isGrid: boolean, exportData?: any): ExportDatasetInfo[] {
        if (!this.dataIsLoaded) {
            throw new Error('Cannot export - data sources have not loaded');
        }
        const exportDatasetInfos: ExportDatasetInfo[] = [];
        const selectedWidgetInfos: ExportWidgetInfo[] = isGrid ? this.gridsSelectedWidgetsInfos : this.nonGridsSelectedWidgetsInfos;
        selectedWidgetInfos.forEach((sw) => {
            const widgetColumns = this.getWidgetColumns(sw.currentVisualizationConfig?.configs?.values);
            const dateTypeOverrides = this.getDataTypeOverrides(sw, metadata);
            if (!sw.isSubscribedToDashboardFilters) {
                let udfColumns: CustomColDef[] = [];
                if (sw.currentVisualization === 'ADVANCED_GRID') {
                    udfColumns = sw.currentVisualizationConfig?.configs?.values
                        .filter((column) => this.exportService.isUserDefinedFieldColumn(column.name))
                        .map((column) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            return { colId: column.name, userDefinedFieldType: column.datatype as UserDefinedFieldType } as any;
                        }) ?? [];
                }
                let data = this.getWidgetData(WIDGET_KEY, sw.id, udfColumns);
                const columns = this.getColumns(sw, isGrid, widgetColumns, metadata);
                data = this.addDataToCompareColumns(sw, columns, data, exportData);
                const widgets = this.getWidgets(sw, columns, exportData, dateTypeOverrides);
                if (this.exportService.shouldIncludeCrosstalkKeys(sw)) {
                    data = this.addCrosstalkKeysData(sw, data);
                    const crosstalkFields = this.datasetManagerService.crosstalkSchemaFields.get(sw.datasetDefinition.id!);
                    exportDatasetInfos.push({ metadata: metadata[sw.id], widgets, data, summary: {}, crosstalkFields });
                } else {
                    exportDatasetInfos.push({ metadata: metadata[sw.id], widgets, data, summary: {} });
                }
                this.sequenceNo += 1;
            }
        });
        return exportDatasetInfos;
    }

    setExportInfo(): void {
        this.currentDashboard = this.managerService.getWorkspace();
        this.exportInfo = {
            dashboardName: this.currentDashboard?.getExtraParameters().name,
            isExportInitialized: false,
            exportFormat: this.exportFormat,
        };
    }

    sendNoWidgetMessage(): void {
        if (this.exportInfo) {
            this.exportInfo.message = 'No widgets available for export.';
        }
        this.exportOptionsClose.emit(this.exportInfo);
    }

    exportData(): void {
        const exportType = this.isFullExport ? ExportType.FULL : ExportType.FILTERED;
        this.replaceSpecialCharacters(this.requestParam!);
        this.dashboardService.exportDashboard(
            this.requestParam!.dashboardName,
            this.requestParam!.dashboardId,
            this.requestParam!.dataSet,
            this.exportFormat!,
            this.exportFormat !== 'PDF' ? exportType : '');
        this.exportInfo!.isExportInitialized = true;
        this.exportInfo!.message = `${this.requestParam!.dashboardName}, ${this.exportFormat} has been initiated.`;
        this.exportOptionsClose.emit(this.exportInfo);
        if (this.exportFormat === 'PDF') {
            this.exportDashboardToPDF();
        }
    }

    onSelectChange(item: ExportWidgetInfo, isChecked: boolean): void {
        const info = this.exportWidgetInfos?.find((widget) => widget.id === item.id);
        if (info) {
            info.isChecked = isChecked;
        }
        this.selectedWidgetCount = this.exportWidgetInfos.filter((widget) => widget.isChecked).length;
        this.selectAll.isChecked = this.selectedWidgetCount === this.exportWidgetInfos.length;
    }

    onSelectAllChange(isChecked: boolean = false): void {
        this.exportWidgetInfos.forEach((widget) => { widget.isChecked = isChecked; });
        this.selectedWidgetCount = isChecked ? this.exportWidgetInfos.length : 0;
    }

    exportDashboardToPDF(): void {
        const dashboardName = this.managerService.getWorkspace()?.name;
        this.dashboardPdfExportService.exportDashboardToPdf(`${dashboardName}_${DdvDate.today.toDDMMYYYYFormat()}`, this.theme);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getParamDatasets(widgetsMetadata: any, isGrid: boolean, exportData?: any): any[] {
        const exportParamDatasets = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(widgetsMetadata).forEach((metadata: any) => {
            Object.entries(metadata).forEach(([key, value]) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (value as any).name = key;
            });
        });

        if (exportData) {
            Object.keys(exportData).forEach((key) => {
                this.setDisplayName(key, exportData[key], widgetsMetadata);
            });
        }
        exportParamDatasets.push(...this.getSubscribedWidgetDatasets(widgetsMetadata, isGrid, exportData));
        exportParamDatasets.push(...this.getUnsubscribedWidgetDatasets(widgetsMetadata, isGrid, exportData));

        return exportParamDatasets;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getGridsFullExportColumns(exportData: any): { widgetId: string, columns: string[] | undefined }[] {
        return Object.keys(exportData).map((fd) => {
            const exportDataColumns = exportData[fd].data.length ? Object.keys(exportData[fd].data[0]) : [];
            let gridColumns: (string | undefined)[];
            let columns: string[] | undefined;
            if (exportDataColumns.length) {
                const widgetInfo = this.gridsSelectedWidgetsInfos.find((widget) => widget.id?.toString() === fd.toString());
                gridColumns = widgetInfo?.currentVisualizationConfig?.configs?.values.map((value) => value.value) ?? [];
                // keep the column order saved in the grid configuration
                columns = exportDataColumns.filter((column) => gridColumns.includes(column));

                gridColumns.forEach((item, ind) => {
                    if (columns![ind] !== item) {
                        const currIndex = columns!.findIndex((col) => col === item);
                        if (currIndex !== -1) {
                            columns!.splice(ind, 0, columns!.splice(currIndex, 1)[0]);
                        }
                    }
                });
            }
            return {
                widgetId: fd,
                columns,
            };
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private exportFullDashboard(exportData: any): void {
        if (Object.keys(exportData).length !== this.selectedWidgetInfos.length) {
            return;
        }

        let gridsDataset;
        let nonGridsDataset;

        if (this.gridsSelectedWidgetsInfos.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const exportGridData: Record<string, any> = {};
            const gridIds = this.gridsSelectedWidgetsInfos.map((gridWidget) => gridWidget.id.toString());
            const exportDataKeys = Object.keys(exportData).filter((key) => gridIds.indexOf(key) !== -1);
            exportDataKeys.forEach((key) => {
                exportGridData[key] = exportData[key];
            });

            this.gridsFullExportColumns = this.getGridsFullExportColumns(exportGridData);
            gridsDataset = this.getParamDatasets(this.gridsMetadata, true, exportData);
            this.requestParam?.dataSet.push(...gridsDataset);
        }

        if (this.nonGridsSelectedWidgetsInfos.length) {
            nonGridsDataset = this.getParamDatasets(this.nonGridsMetadata, false, exportData);
            this.requestParam?.dataSet.push(...nonGridsDataset);
        }

        this.exportData();
        this.filteredDataService.clearFullData({});
    }

    private exportFilteredDashboard(exportData: object): void {
        if (Object.keys(exportData).length !== this.selectedWidgetCount) {
            return;
        }

        this.requestParam = this.getExportRequestParams(exportData);
        this.exportData();
        this.filteredDataService.clearFilteredData({});
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getExportRequestParams(exportData: any | undefined): ExportRequestParameter {
        const requestParam = {
            dashboardName: this.currentDashboard?.getExtraParameters().name,
            dashboardId: this.currentDashboard?.id.toString() ?? '',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dataSet: [] as any[],
        };

        if (!exportData && this.exportFormat === 'PDF') {
            return requestParam;
        }

        requestParam.dataSet = Object.keys(exportData).map((fd) => {
            let columns = exportData[fd].data.length ? Object.keys(exportData[fd].data[0]) : [];
            if (this.exportFormat === 'CSV' && columns.length) {
                columns = this.exportService.addDateColumnsForCSVExport(exportData[fd].datasetType, fd, columns);

                columns = columns.map((column) => {
                    let newColumn: string;
                    if (column.includes('__Grouper__')) {
                        newColumn = column.substring(0, column.indexOf('__Grouper__') - 1);
                    } else {
                        newColumn = column;
                    }
                    return newColumn;
                });
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const dateTypeOverrides: Record<string, any> = {};
            let currentWidget: ExportWidgetInfo | undefined;
            if (this.gridsSelectedWidgetsInfos) {
                currentWidget = this.gridsSelectedWidgetsInfos.find((sw) => sw.id.toString() === fd);
                if (currentWidget) {
                    const udfColumns: { name: string, type: UserDefinedFieldType }[] = [];
                    currentWidget.currentVisualizationConfig?.configs?.values.forEach((column) => {
                        const columnName = column.name ?? '';
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const md = (this.metadata as any)?.[currentWidget?.id ?? ''][columnName];
                        if (columns.includes(column.displayName ?? '') &&
                            columnName === md?.name &&
                            column.displayType !== md.displayType
                        ) {
                            dateTypeOverrides[column.displayName ?? ''] = column.displayType;
                        } else if (column.displayType === 'string') {
                            dateTypeOverrides[column.displayName ?? ''] = column.displayType;
                        }

                        if (this.exportService.isUserDefinedFieldColumn(column.name)) {
                            udfColumns.push({
                                name: (column.customName ?? column.displayName) ?? '',
                                type: column.datatype as UserDefinedFieldType,
                            });
                        }
                    });

                    if (udfColumns.length) {
                        udfColumns.forEach((udf) => {
                            const { name, type } = udf;
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            exportData[fd].data.forEach((datum: any) => {
                                datum[name] = this.exportService.setUserDefinedFieldExportValue(datum, name, type);
                            });
                        });
                    }
                }
            }

            const widgetNameSuffix = this.isFullExport ? ExportType.FULL : ExportType.FILTERED;
            const widgets = [{
                widgetId: fd.toString(),
                widgetName: `${this.sequenceNo}_${exportData[fd].widgetName}-${widgetNameSuffix}`,
                columnNames: !this.isFullExport && this.exportFormat === 'EXCEL' && exportData[fd].visibleColumns?.length ?
                    exportData[fd].visibleColumns :
                    columns,
                datasetType: exportData[fd].datasetType,
                dateFrom: exportData[fd] ? this.exportService.fuzzyDateCheck(exportData[fd].startDate, true) : undefined,
                dateTo: exportData[fd] ? this.exportService.fuzzyDateCheck(exportData[fd].endDate) : undefined,
                extractionDateTime: DdvDateTime.now.toString(),
                groupers: exportData[fd].groupers || [],
                datetypeoverrides: dateTypeOverrides,
                showSubTotal: currentWidget?.currentVisualizationConfig?.showSubTotal,
            }];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const metadata = this.setDisplayName(fd, exportData[fd], this.metadata as any, true);
            this.sequenceNo += 1;

            const dataset = this.exportService.shouldIncludeCrosstalkKeys(currentWidget) ?
                {
                    metadata,
                    widgets,
                    data: exportData[fd].data,
                    summary: exportData[fd].summary,
                    crosstalkFields: this.datasetManagerService.crosstalkSchemaFields.get(currentWidget?.datasetDefinition.id ?? 0),
                } :
                {
                    metadata,
                    widgets,
                    data: exportData[fd].data,
                    summary: exportData[fd].summary,
                };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return dataset as any;
        });
        return requestParam;
    }

    private exportInfosToMetadataFetchContexts(infos: ExportWidgetInfo[]): BoardWidgetDefinitionIds[] {
        return infos.map((info) => new BoardWidgetDefinitionIds(undefined, info.id, info.datasetDefinition.id));
    }

    private setDisplayName(
        widgetId: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        metadata: any,
        returnFilteredMetadata: boolean = false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any {
        this.selectedWidgetInfos.forEach((widget) => {
            if (widget.name === data.widgetName) {
                this.managerService.getWidgetPreferences(widget.id)?.visualizationConfigs.forEach((vizConfig) => {
                    vizConfig.configs?.values.forEach((configValue) => {
                        if (metadata[widget.id]?.[configValue.value!] &&
                            !metadata[widget.id][configValue.value!].nameChecked
                        ) {
                            metadata[widget.id][configValue.value!].displayName =
                                configValue.showCustomName ? configValue.customName : configValue.displayName;
                            metadata[widget.id][configValue.value!].nameChecked = true;
                        }
                    });
                });
            }
        });

        if (metadata[widgetId]) {
            Object.keys(metadata[widgetId]).forEach((key) => {
                metadata[widgetId][key].nameChecked = undefined;
            });
        }

        if (returnFilteredMetadata) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (this.metadata as any)?.[widgetId];
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addDataToCompareColumns(ew: ExportWidgetInfo, columns: string[], data: WidgetData[], exportData: any): WidgetData[] {
        let updatedData = data;
        columns.forEach((column) => {
            if (column.includes(CompareColumnID.COMPARE) || column.includes(CompareColumnID.DIFF)) {
                const compareData = Object.keys(exportData).map((fd) => {
                    if (ew.id.toString() === fd) {
                        return exportData[fd].data;
                    }
                }).filter((fd) => !!fd);

                const index = (column.indexOf(CompareColumnID.COMPARE) !== -1) ?
                    column.indexOf(CompareColumnID.COMPARE) :
                    column.indexOf(CompareColumnID.DIFF);
                const compareKey = column.substring(0, index);

                updatedData = updatedData.map((dataObj) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const compareDataObj = compareData[0].find((obj: any) => obj[compareKey] === dataObj[compareKey]);
                    return compareDataObj ? { ...dataObj, [column]: compareDataObj[column] } : dataObj;
                });
            }
        });

        return updatedData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getWidgetData(key: string, datasetId: number, userDefinedFieldColumns?: any[]): WidgetData[] {
        const originalData = this.widgetDatasource?.datasources.find((dw) => {
            return dw.uniqueKey === (`${key}${datasetId}`);
        })?.originalData;
        const data = this.widgetDatasource?.datasources.find((dw) => {
            return dw.uniqueKey === (`${key}${datasetId}`);
        })?.data;

        const widgetData = deepClone(originalData ?? data ?? []);

        if (userDefinedFieldColumns?.length) {
            userDefinedFieldColumns.forEach((udf) => {
                const { colId, userDefinedFieldType: type } = udf;
                if (colId && type) {
                    widgetData.forEach((datum) => datum[colId] = this.exportService.setUserDefinedFieldExportValue(datum, colId, type));
                }
            });
        }

        return widgetData;
    }

    private getDataTypeOverrides(widgetInfo: ExportWidgetInfo, metadata: MetadataLookup): { [key: string]: string } {
        const widgetId = widgetInfo.id;
        if (!widgetInfo) {
            return {};
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataTypeOverrides: Record<string, any> = {};
        widgetInfo.currentVisualizationConfig?.configs?.values.forEach((column) => {
            if (column.colId !== crosstalkCheckboxFieldId) {
                const columnName = column.name ?? '';
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (columnName === (metadata[widgetId] as any)[columnName]?.name &&
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    column.displayType !== (metadata[widgetId] as any)[columnName].displayType
                ) {
                    dataTypeOverrides[column.displayName ?? ''] = column.displayType;
                } else if (column.displayType === 'string') {
                    dataTypeOverrides[column.displayName ?? ''] = column.displayType;
                }
            }
        });
        return dataTypeOverrides;
    }

    private getWidgetColumns(values: ConfigItem[] | undefined): string[] {
        if (values) {
            return values.reduce((columns: string[], column: ConfigItem) => {
                if (column.value != null) {
                    columns.push(column.value);
                }
                return columns;
            }, []);
        }
        return [];
    }

    private getColumns(
        widgetInfo: ExportWidgetInfo,
        isGrid: boolean,
        widgetColumns: (string | undefined)[],
        metadata: MetadataLookup,
    ): string[] {
        const gridColumnsForWidget = this.gridsFullExportColumns?.length ?
            this.gridsFullExportColumns.find((widget) => widget.widgetId.toString() === widgetInfo.id.toString()) :
            null;
        const columns = !isGrid ?
            Object.keys(metadata[widgetInfo.id]) :
            (gridColumnsForWidget ? (gridColumnsForWidget.columns! || widgetColumns) : []);

        if (this.exportFormat === 'CSV' && columns.length) {
            if (['Recon', 'Checklists'].indexOf(widgetInfo.datasetDefinition.dataType ?? '') === -1) {
                columns.push('From Date');
            }
            columns.push(...['To Date', 'Time of Extract']);
        }

        if (this.exportService.shouldIncludeCrosstalkKeys(widgetInfo)) {
            this.datasetManagerService.crosstalkSchemaFields.get(widgetInfo.datasetDefinition.id!)?.forEach((field) => {
                columns.push(field.name);
            });
        }
        return columns;
    }

    private getWidgets(
        widgetInfo: ExportWidgetInfo,
        columns: string[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        exportData: any,
        dataTypeOverrides: { [key: string]: string },
    ): ExportDatasetWidgetInfo[] {
        return [{
            widgetId: widgetInfo.id.toString(),
            widgetName: `${this.sequenceNo}_${widgetInfo.name}-${ExportType.FULL}`,
            columnNames: columns,
            datasetType: widgetInfo.datasetDefinition.dataType,
            extractionDateTime: DdvDateTime.now.toString(),
            dateFrom: exportData[widgetInfo.id] ?
                this.exportService.fuzzyDateCheck(exportData[widgetInfo.id].startDate, true) :
                undefined,
            dateTo: exportData[widgetInfo.id] ?
                this.exportService.fuzzyDateCheck(exportData[widgetInfo.id].endDate) :
                undefined,
            datetypeoverrides: dataTypeOverrides,
        }];
    }

    private replaceSpecialCharacters(requestParam: ExportRequestParameter): void {
        requestParam.dashboardName = requestParam.dashboardName.replace(SPECIAL_CHARS_REGEX, '_');
        requestParam.dataSet.forEach((d) => {
            d.widgets.forEach((w) => {
                w.widgetName = w.widgetName.replace(SPECIAL_CHARS_REGEX, '_');
            });
        });
    }

    private addCrosstalkKeysData(sw: ExportWidgetInfo, data: WidgetData[]): WidgetData[] {
        const crosstalkFields = this.datasetManagerService.crosstalkSchemaFields.get(sw.datasetDefinition.id!) ?? [];
        data.forEach((row) => {
            crosstalkFields.forEach((field) => {
                const valueToString = row[field.displayName]?.toString();
                row[field.name] = valueToString ? valueToString : '';
            });
        });

        return data;
    }
}
