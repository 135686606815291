import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    BarChartColorConfig,
    BarChartData,
    ChartData,
    ChartSettings,
    SlicerInfo,
} from '@ddv/charts';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import { DashboardFilter, DashboardPreference, DdvDate, ExportFilteredData, MetadataLookup } from '@ddv/models';
import { deepClone } from '@ddv/utils';

import { ChartsSharedService } from '../charts-shared.service';
import { VizInfo } from '../visualization-wrapper.interface';
import { ChartDataTableRow } from './chart-data-table/chart-data-table-row';

// This component is only for HBar and will hopefully die
@Component({
    selector: 'app-visualization',
    templateUrl: './visualization.component.html',
    styleUrls: ['./visualization.component.scss'],
})
export class VisualizationComponent extends MultiSubscriptionComponent implements OnInit, OnChanges, OnDestroy {
    // the widgetId is used for:
    //  - sendMessageToAllWidgetsOnWorkspace
    //      * to set the id for the widget that sent the message
    //      * obviously what ever is triggering this end should be bubbling and @Output and something that is ACTUALLY A WIDGET should be sending this
    //  - ManagerService.getWidgetPreferences
    //      * isMaster
    //      * enableCompareMode
    //      * id (this seems super utterly stupid since you have to use the id you already have to get this object)
    //          - this is only used by this stupid method: toggleSelectedSlicerToFilter and only to get the displayName for the filter
    //          - perhaps the display is unneeded or can be looked up later by what ever actually cares about the display name
    //  - ManagerService.getWidgetState
    //      * maximized
    @Input() widgetId: number = 0;
    @Input() vizInfo: VizInfo | undefined;
    @Input() vizConfig: ChartSettings<BarChartData> | undefined;
    @Input() useNewLegend = false;
    @Input() isDataLoading = true;
    @Input() fieldMetadata: MetadataLookup = {};

    protected slicerInfo: SlicerInfo | undefined;
    protected chartConfig: ChartSettings<BarChartData> | undefined;
    protected maximized = false;
    protected showVizTable = false;
    protected noDataAvailable = false;
    protected isMaster: boolean = false;
    protected bigWTableData: ChartData[] = [];
    protected bigWTableCompareData: ChartData[] | undefined;
    protected chartTableData: ChartDataTableRow[] = [];
    protected chartTableCompareData: ChartDataTableRow[] | undefined;
    protected enableCompareMode: boolean = false;
    protected legendVisibility: 'show' | 'hide' = 'show';
    protected colorConfig: BarChartColorConfig | undefined;
    protected slicerDisplayName = '';
    protected valueDisplayName = '';

    private vizChartData: BarChartData[] = [];
    private filterRemovedByMaster = false;
    private queryParams: DashboardPreference | undefined;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly managerService: ManagerService,
        private readonly chartsSharedService: ChartsSharedService,
        private readonly queryParamsService: QueryParamsService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.widgetId) {
            return console.error('Widget id is missing.');
        }

        if (!this.vizConfig) {
            return console.error('Visualization config is missing.');
        }

        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (dashboardPref: DashboardPreference) => {
            this.queryParams = dashboardPref;

            const widgetState = this.managerService.getWidgetPreferences(this.widgetId);
            this.isMaster = !!widgetState?.isMaster;
            this.enableCompareMode = widgetState?.enableCompareMode ?? false;

            const masterAppliedFilter = this.queryParams.filters?.find((filter) => filter.isMasterWidgetFilter);

            if (this.isMaster && (!this.queryParams.areFiltersAppliedByMaster || this.filterRemovedByMaster)) {
                this.initVisualization();
                this.drawBigWChart(true);
                this.filterRemovedByMaster = false;
                this.restoreMasterWidgetHighlight();
            }

            if (this.isMaster && !this.queryParams.highlight && masterAppliedFilter) {
                this.subscribeToMasterWidgetChartDataOnLoad(masterAppliedFilter);
            }
        });

        this.initVisualization();

        this.lookupFieldDisplayNames();
    }

    // This is the magic.  This relies on the outer BaseVisualizationComponent to set properties
    // of the object that is bound to this.vizConfig INCLUDING the dataSource.
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fieldMetadata) {
            this.lookupFieldDisplayNames();
        }

        // First change is undefined to a value.
        if (!changes.vizConfig || changes.vizConfig.isFirstChange()) {
            return;
        }

        const isMaster = this.managerService.getWidgetPreferences(this.widgetId)?.isMaster;
        if (isMaster && this.queryParams?.areFiltersAppliedByMaster && (this.queryParams.filters?.length ?? 0) > 0) {
            return;
        }

        this.initVisualization();
        this.drawBigWChart(true);
    }

    initVisualization(): void {
        if (!this.vizInfo || !this.vizConfig) {
            return console.error('cannot initVisualization without a vizInfo and a vizConfig');
        }

        this.slicerInfo = {
            slicer: { ...this.vizInfo.slicer },
            values: [...this.vizInfo.values],
        };
        this.noDataAvailable = !this.vizConfig.dataSource || this.vizConfig.dataSource.length === 0;

        if (this.managerService.getCurrentDashboardFilters().length && this.filterRemovedByMaster) {
            const filteredRows = this.getFilteredRows();
            if (filteredRows.length) {
                this.vizConfig.dataSource = filteredRows;
            }
        }
        this.chartConfig = this.vizConfig;
        this.lookupFieldDisplayNames();

        if (this.vizInfo.slicer?.colorSortBy && this.vizInfo.slicer.colorSortDirection) {
            this.chartConfig.selectedSlicer = this.vizInfo.slicer;
        }

        this.colorConfig = this.pullColorConfigFromChartConfig();

        if (this.isMaster) {
            this.restoreMasterWidgetHighlight();
        }

        this.afterChartInit();
    }

    resizeVisualization(): void {
        // reaching into private properties of the CDR seems really really not smart
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(this.cdr as any).destroyed) {
            this.cdr.detectChanges();
        }

        this.reAssignMargin();
        this.setLegendVisibility();

        // TODO: see if this can just go.  its not obvious that we need this each time the chart is resized
        // long ago, the HBar component actually created whole different objects
        // not it just assigns colors to existing objects, so there is no reason to use
        // the chart object to get the data
        //
        // when you're in ManageWidgets AND you're maximized AND then you change anything on the "format" or "colors" tab:
        //      doing so causes the chart to be destroyed and rendered
        //      which now that you're already maximize causes code to execute in a different order
        this.bigWTableData = this.convertBackToChartData(this.chartConfig?.dataSource) ?? [];
        this.bigWTableCompareData = this.convertBackToChartData(this.chartConfig?.dataCompareSource);
    }

    onMaximize(): void {
        const widgetState = this.managerService.getWidgetPreferences(this.widgetId);
        this.enableCompareMode = !!widgetState?.enableCompareMode;
        this.maximized = true;

        this.showVizTable = true;
        this.updateVizDataTable();
        this.drawBigWChart(false);
    }

    onRestore(): void {
        this.maximized = false;
        this.showVizTable = false;
        this.drawBigWChart(false);
    }

    // this is only relevant for PIE/DONUT but i left it here to make less work in BigWTable
    getDrillInfo(): undefined {
        return undefined;
    }

    // this is utter garbage
    // not only does it violate the type, it's incredibly stupid to pass around
    // a reference to a dom element that angular could destroy at any time
    protected setSelector(rootElement: HTMLElement | undefined): void {
        if (!this.chartConfig) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.chartConfig.selector = rootElement as any;
    }

    protected onChartSizeChange(size: { width: number, height: number }): void {
        if (this.chartConfig) {
            this.chartConfig.width = size.width;
            this.chartConfig.height = size.height;
        }
    }

    protected onChartClicked(data: BarChartData, alreadySelected: boolean): void {
        // TODO: this should probably just end up a two-way binding in the template
        if (this.chartConfig) {
            if (alreadySelected) {
                this.chartConfig.highlight = undefined;
            } else {
                this.chartConfig.highlight = { data };
            }
        }

        if (this.maximized && alreadySelected) {
            this.updateVizDataTable();
        }

        const widgetState = this.managerService.getWidgetPreferences(this.widgetId);

        // if (!selectedItem.data) {
        //     this.updateActiveDateOnMasterWidget(selectedItem.date, !!widgetState?.isMaster);
        //     return;
        // }

        const highlightValue: string = data?.key.value ?? '';

        if (widgetState?.isMaster) {
            // let activeDate = selectedItem.date || selectedItem.data.date;
            let activeDate: string | null | undefined = '';
            activeDate = activeDate && DdvDate.isStringValidDashFormatDate(activeDate) ?
                DdvDate.fromDashFormat(activeDate.toString()).toUSPaddedFormat() :
                this.queryParams?.activeDate;
            this.toggleSelectedSlicerToFilter(data, alreadySelected, activeDate, highlightValue != null ? highlightValue : undefined);
        } else {
            const isAttNameAdd = this.queryParams?.highlight && this.queryParams.highlight === highlightValue;
            const masterFilters = this.queryParams?.filters?.findIndex((filter) => filter.isMasterWidgetFilter);

            this.queryParamsService.dispatchUpdatedQueryParams({
                highlight: isAttNameAdd ? '' : highlightValue,
                areFiltersAppliedByMaster: masterFilters !== -1,
            });

            this.managerService.sendMessageToAllWidgetsOnWorkspace(
                this.widgetId,
                {
                    action: 'VISUALIZATION_SELECTED',
                    dataSource: this.chartConfig?.dataSource.map((d) => {
                        return { key: d.key.value };
                    }),
                    selectedItem: isAttNameAdd ? null : { data: { key: data.key.value } },
                    field: this.vizConfig?.multiSeries ? this.vizConfig.multiSeries.field : '',
                    chartUpdated: isAttNameAdd,
                });
        }
    }

    protected onTableToggled(): void {
        this.setLegendVisibility();
        this.drawBigWChart(false);
        this.updateVizDataTable();
    }

    private setLegendVisibility(): void {
        if (this.maximized && this.showVizTable) {
            this.legendVisibility = 'hide';
        } else if (this.chartConfig?.legend?.showCustom) {
            this.legendVisibility = 'show';
        } else {
            this.legendVisibility = 'hide';
        }
    }

    private lookupFieldDisplayNames(): void {
        const slicerFieldName = this.chartConfig?.axis?.[1]?.[0].field?.[0];
        const valueFieldName = this.chartConfig?.axis?.[0]?.[0].field?.[0];

        this.slicerDisplayName = this.fieldMetadata[slicerFieldName ?? '']?.displayName;
        this.valueDisplayName = this.fieldMetadata[valueFieldName ?? '']?.displayName;
    }

    getExportFilteredData(): ExportFilteredData {
        return this.getExportData();
    }

    getExportFullData(): ExportFilteredData {
        return this.getExportData();
    }

    // eslint-disable-next-line complexity
    private toggleSelectedSlicerToFilter(data: BarChartData, chartUpdated: boolean, activeDate: string | null | undefined, highlight: string = ''): void {
        // if (this.vizConfig?.series?.[0].type === 'line' && this.vizConfig.series[0].yField.length > 1) {
        //     this.queryParamsService.dispatchUpdatedQueryParams({ activeDate });
        //     return;
        // }

        // let filterValue = elt.key ?? elt.data.key;
        // if (filterValue === 'others') {
        //     filterValue = this.getFilterValueForOthers(elt);
        // }
        let filterValue: string | string[] = data?.key.value ?? '';
        if (data?.isAggregate) {
            filterValue = data.aggregatedFrom?.map((d) => d.key.value) ?? [];
        }

        const filterName = this.vizConfig?.multiSeries ? this.vizConfig.multiSeries.field : this.slicerInfo?.slicer.value;
        // if (this.vizConfig?.enableDrilldown) {
        //     filterName = this.getFilterNameForDrillDown(elt);
        // }
        // if (this.checkForStackedBarSlicerMaster(elt.slicer)) {
        //     filterName = elt.slicer;
        // }

        let toggle = false;
        const index = this.queryParams?.filters?.findIndex((filter) => filter.isMasterWidgetFilter && filter.name === filterName) ?? -1;

        let resetDrillDown =
            !!(this.vizConfig?.enableDrilldown &&
                index > -1 &&
                (this.queryParams?.filters?.[index]?.values as string[]).indexOf(highlight) > -1);

        if (index !== -1) {
            toggle = this.setToggle(filterValue, index);
            if (this.vizConfig?.enableDrilldown && chartUpdated && toggle && index !== (this.vizConfig.drilldown?.keys.length ?? 0) - 1) {
                toggle = false;
            }

            const filterToRemove = this.queryParams?.filters?.[index];
            this.queryParamsService.dispatchUpdatedQueryParams({
                filters: this.getFilters(filterToRemove),
            });
            this.filterRemovedByMaster = true;
            resetDrillDown = false;
        }

        if (highlight !== undefined && filterValue !== 'others' && !toggle) {
            if (this.queryParams?.filters?.some((f) => f.name === filterName)) {
                this.queryParams?.filters?.splice(this.queryParams?.filters.findIndex((f) => f.name === filterName), 1);
            }

            this.updateFilters(filterName, filterValue);

            this.filterRemovedByMaster = false;
        }

        this.dispatchQueryParams(resetDrillDown, activeDate, highlight);
    }

    private getFilterDisplayName(key?: string): string | undefined {
        return this.fieldMetadata[key ?? '']?.displayName;
    }

    private setToggle(filterValue: string | string[], index: number): boolean {
        return Array.isArray(filterValue) ?
            filterValue.every((value) => this.queryParams?.filters?.[index].values?.indexOf(value) !== -1) :
            this.queryParams?.filters?.[index].values?.indexOf(filterValue) !== -1;
    }

    private redrawChart(): void {
        if (!this.chartConfig) {
            return;
        }

        this.reAssignMargin();

        if (this.maximized && this.showVizTable) {
            this.updateVizDataTable();
        }
    }

    private reAssignMargin(): void {
        if (!this.chartConfig?.margin) {
            return;
        }

        const yAxis = this.chartConfig.axis[1][0];
        const marginCondition = this.chartsSharedService.getMarginCondition(
            this.chartConfig.legend?.showCustom ? this.chartConfig.legend?.docked : '',
            yAxis.position ?? '',
            !yAxis.hide,
            (yAxis.nTicks ?? 0) > 0);

        this.chartConfig.margin.left = marginCondition.leftSideAxisFlag ? 5 : 25;
        this.chartConfig.margin.right = marginCondition.rightSideAxisFlag ? 15 : 35;
    }

    private prepareVizData(): void {
        if (!this.chartConfig) {
            return console.error('cannot prepareVizData without chartConfig');
        }

        // long ago, the HBar component actually created whole different objects
        // not it just assigns colors to existing objects, so there is no reason to use
        // the chart object to get the data
        this.vizChartData = this.chartConfig.dataSource ?? [];
        this.bigWTableData = this.convertBackToChartData(this.vizChartData) ?? [];
        this.bigWTableCompareData = this.convertBackToChartData(this.chartConfig.dataCompareSource);
        this.chartTableData = this.convertToChartTableData(this.vizChartData) ?? [];
        this.chartTableCompareData = this.convertToChartTableData(this.chartConfig.dataCompareSource);
    }

    // TODO: this shouldn't need to be called as often as it is
    // the result can only change when vizChartData changes,
    // which can only happen when the vizConfig input is changed
    private updateVizDataTable(): void {
        if (!this.showVizTable) {
            return;
        }

        // long ago, the HBar component actually created whole different objects
        // not it just assigns colors to existing objects, so there is no reason to use
        // the chart object to get the data
        this.vizChartData = this.chartConfig?.dataSource ?? [];
        this.bigWTableData = this.convertBackToChartData(this.vizChartData) ?? [];
        this.bigWTableCompareData = this.convertBackToChartData(this.chartConfig?.dataCompareSource);
        this.chartTableData = this.convertToChartTableData(this.vizChartData) ?? [];
        this.chartTableCompareData = this.convertToChartTableData(this.chartConfig?.dataCompareSource);
    }

    private drawBigWChart(redraw: boolean): void {
        if (this.noDataAvailable) {
            return;
        }
        if (redraw) {
            this.redrawChart();
        } else {
            this.resizeVisualization();
        }
    }

    private afterChartInit(): void {
        const widgetState = this.managerService.getWidgetState(this.widgetId);
        if (widgetState?.maximized) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!(this.cdr as any).destroyed) {
                this.cdr.detectChanges();
            }

            this.onMaximize();
            this.resizeVisualization();
        }
    }

    private getExportData(): ExportFilteredData {
        this.prepareVizData();

        return {
            data: this.chartConfig?.dataSource.map((chartData) => {
                return {
                    [this.slicerInfo?.slicer.value ?? '']: chartData.key.value,
                    [this.slicerInfo?.values[0].value ?? '']: chartData.value.value,
                };
            }) ?? [],
            summary: {},
        };
    }

    // private updateActiveDateOnMasterWidget(date: Date, isMaster: boolean): void {
    //     if (isMaster && date) {
    //         this.queryParamsService.dispatchUpdatedQueryParams({
    //             activeDate: DdvDate.fromDate(date).toUSPaddedFormat(),
    //         });
    //     }
    // }

    private subscribeToMasterWidgetChartDataOnLoad(masterAppliedFilter: DashboardFilter): void {
        const chartData = this.chartConfig?.dataSource ?? [];

        const aggregatedBar = chartData?.find((data) => data.isAggregate);
        const filterLength = masterAppliedFilter.values?.length ?? 0;
        if (aggregatedBar) {
            if (filterLength > 1) {
                this.updateMasterAppliedFiltersOnLoad(masterAppliedFilter, aggregatedBar);
            } else if (filterLength === 1 && !chartData.find((data) => data.key.value === masterAppliedFilter.values?.[0])) {
                this.updateMasterAppliedFiltersOnLoad(masterAppliedFilter, aggregatedBar);
            }
        }
    }

    private updateMasterAppliedFiltersOnLoad(masterAppliedFilter: DashboardFilter, aggregatedBar: BarChartData): void {
        if (aggregatedBar.aggregatedFrom && masterAppliedFilter.values?.length !== aggregatedBar.aggregatedFrom.length) {
            masterAppliedFilter.values = aggregatedBar.aggregatedFrom.map((child: BarChartData) => child.key.value);
            this.queryParamsService.dispatchUpdatedQueryParams({ filters: this.queryParams?.filters });
        }
    }

    // // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // private getFilterNameForDrillDown(elt: any): string | undefined {
    //     return this.vizConfig?.drilldown?.keys.find((key) => {
    //         return Object.prototype.hasOwnProperty.call(elt.data, key.value ?? '') && elt.data[key.value ?? ''] === elt.data.key;
    //     })?.value;
    // }

    private dispatchQueryParams(resetDrillDown: boolean, activeDate: string | null | undefined, highlight: string): void {
        this.queryParamsService.dispatchUpdatedQueryParams({
            filters: resetDrillDown ? [] : this.queryParams?.filters,
            activeDate,
            highlight: highlight || '',
            areFiltersAppliedByMaster: true,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateFilters(filterName: string | undefined, filterValue: any): void {
        this.queryParams?.filters?.push({
            name: filterName,
            displayName: this.vizConfig?.multiSeries || this.vizConfig?.enableDrilldown ?
                this.getFilterDisplayName(filterName) :
                this.slicerInfo?.slicer.label,
            criteria: 'INCLUDE',
            isMasterWidgetFilter: true,
            values: typeof filterValue === 'string' || typeof filterValue === 'boolean' ? [filterValue] : [...filterValue],
        });
    }

    private restoreMasterWidgetHighlight(): void {
        if (!this.chartConfig) {
            return;
        }

        const appliedMasterFilter = this.queryParams?.filters?.find((filter) => filter.isMasterWidgetFilter);
        const highlightedMasterData = this.vizConfig?.dataSource.find((datum) => {
            return datum.key.value === appliedMasterFilter?.values?.[0];
        });

        this.chartConfig.highlight = highlightedMasterData ? { data: highlightedMasterData } : undefined;
    }

    private getFilteredRows(): BarChartData[] {
        let filteredRows: BarChartData[] = [];
        this.managerService.getCurrentDashboardFilters().forEach((filter) => {
            if (filteredRows.length) {
                filteredRows = this.getFilteredRowsByDashboardFilter(filteredRows, filter);
            } else {
                filteredRows = this.getFilteredRowsByDashboardFilter(this.vizConfig?.dataSource ?? [], filter);
            }
        });
        return filteredRows;
    }

    // this function should pass all data when the filter being applied is not the slicer
    private getFilteredRowsByDashboardFilter(dataToFilter: BarChartData[], filter: DashboardFilter): BarChartData[] {
        if (!dataToFilter.length || dataToFilter[0].key.fieldName !== filter.name) {
            return dataToFilter;
        }

        const filteredRows: BarChartData[] = [];
        const filterValues = filter.values ?? [];
        for (const row of dataToFilter) {
            if (filter.criteria === 'EXCLUDE') {
                if (row.sourceRows.every((sr) => !filterValues.includes(sr[filter.name ?? '']))) {
                    filteredRows.push(row);
                }
            } else {
                if (row.sourceRows.some((sr) => filterValues.includes(sr[filter.name ?? '']))) {
                    filteredRows.push(row);
                }
            }
        }

        return filteredRows.length ? filteredRows : dataToFilter;
    }

    private getFilters(filterToRemove?: DashboardFilter): DashboardFilter[] {
        const masterFilters = this.queryParams?.filters?.filter((el) => el.isMasterWidgetFilter && filterToRemove?.name !== el.name);
        const updatedFilters = this.queryParams?.filters?.filter((el) => el.name !== filterToRemove?.name);
        if (this.doesMasterFilterEqualDashboardFilter(filterToRemove)) {
            return masterFilters?.length ? masterFilters : deepClone(this.managerService.getCurrentDashboardFilters());
        }

        return updatedFilters ?? [];
    }

    private doesMasterFilterEqualDashboardFilter(filterToRemove?: DashboardFilter): boolean {
        return !!this.managerService.getCurrentDashboardFilters().find((el) => {
            return el.name === filterToRemove?.name && filterToRemove?.isMasterWidgetFilter;
        });
    }

    private convertBackToChartData(barChartData: BarChartData[] | undefined): ChartData[] | undefined {
        if (!barChartData) {
            return undefined;
        }

        return barChartData.map((d) => {
            const cd = {
                key: d.key.value,
                [d.key.fieldName]: d.key.value,
                [d.value.fieldName]: d.value.value,
                color: d.color ?? '',
                values: d.sourceRows,
            };

            if (d.isAggregate) {
                cd.children = this.convertBackToChartData(d.aggregatedFrom);
            }

            return cd;
        });
    }

    private convertToChartTableData(barChartData: BarChartData[] | undefined): ChartDataTableRow[] | undefined {
        return barChartData?.map((bar) => {
            return {
                slicer: bar.key.value,
                value: Number(bar.value.value),
            };
        });
    }

    private pullColorConfigFromChartConfig(): BarChartColorConfig | undefined {
        if (!this.chartConfig) {
            return;
        }

        return {
            sortBy: this.chartConfig.selectedSlicer?.colorSortBy,
            sortDirection: this.chartConfig.selectedSlicer?.colorSortDirection,
            range: this.chartConfig.colorRange,
            explicitColorBySlicer: this.chartConfig.attributeCustomColors,
        };
    }
}
