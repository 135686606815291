import { Inject, Injectable, TemplateRef } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ConfirmationPopupService } from '@ddv/common-components';
import { UserService } from '@ddv/entitlements';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { FastnessQuery, FastnessResponse, DdvDate, FuzzyDates } from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { ModalAction } from '@hs/ui-core-notifications';
import { lastValueFrom, Observable, of, Subject } from 'rxjs';
import { take, map, mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class DataFastnessService {
    private dateFrom: string | undefined;
    private dateTo: string | undefined;
    private clientCode: string = ''; // not a real default and is dangerous
    private fastnessCheckStarted: boolean = false;
    private readonly popupSubject: Subject<boolean> = new Subject();

    constructor(
        private readonly userService: UserService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly currentStateService: CurrentStateService,
        private readonly confirmationService: ConfirmationPopupService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
    ) {
        this.userService.userPreferences$.subscribe((userPreferences) => {
            this.dateFrom = userPreferences.startDate;
            this.dateTo = userPreferences.endDate;
        });

        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    checkDataFastness(dateFrom: string, dateTo: string, message: TemplateRef<string> | undefined): Observable<Promise<boolean>> {
        return this.fuzzyDatesService.fuzzyDates()
            .pipe(take(1))
            .pipe(mergeMap((fuzzyDates: FuzzyDates) => {
                const from = dateFrom || this.dateFrom;
                const to = dateTo || this.dateTo;
                const queryType = 'rc2';
                const endDate = this.getValidDate('to', to, fuzzyDates).toReversePaddedDashFormat();
                return this.checkDataForDateCache(this.clientCode, from, to, queryType, fuzzyDates)
                    .pipe(take(1))
                    .pipe(map(async (result: FastnessResponse) => {
                        const fastnessResponseSlow = !result?.[queryType]?.[endDate]?.fast;
                        if (fastnessResponseSlow && !this.fastnessCheckStarted) {
                            this.fastnessCheckStarted = true;
                            return this.handleCacheDialog(message);
                        } else if (fastnessResponseSlow && this.fastnessCheckStarted) {
                            return new Promise((resolve) => {
                                this.popupSubject.pipe(take(1)).subscribe((res) => {
                                    return res ? resolve(true) : resolve(false);
                                });
                            });
                        } else {
                            return Promise.resolve(true);
                        }
                    }));
            })) as Observable<Promise<boolean>>;
    }

    openCacheDialog(message: TemplateRef<string> | undefined): Observable<ModalAction | undefined> {
        const confirmDialogOptions = {
            message,
            confirmButtonText: 'OK',
            denyButtonText: 'Cancel',
        };
        return this.confirmationService.showConfirmationPopup(confirmDialogOptions);
    }

    checkDataForDateCache(
        clientCode: string,
        dateFrom: string | undefined,
        dateTo: string | undefined,
        queryType: string,
        fuzzyDates?: FuzzyDates,
    ): Observable<FastnessResponse> {
        const requestBody: FastnessQuery = this.constructFastnessCheckBody(dateFrom, dateTo, queryType, fuzzyDates);
        return this.trebekApiExecutor.invokeServiceWithBody(clientCode, '/fastness/', 'POST', requestBody);
    }

    private constructFastnessCheckBody(
        dateFrom: string | undefined,
        dateTo: string | undefined,
        queryType: string,
        fuzzyDates?: FuzzyDates,
    ): FastnessQuery {
        const startDate = this.getValidDate('from', dateFrom, fuzzyDates)?.toReversePaddedDashFormat();
        const endDate = this.getValidDate('to', dateTo, fuzzyDates)?.toReversePaddedDashFormat();
        return {
            timeseriesRange: {
                granularity: 'none',
                startDate,
                endDate,
            },
            queryTypes: [queryType],
        };
    }

    private getValidDate(dateList: keyof FuzzyDates, date: string = '', fuzzyDates?: FuzzyDates): DdvDate {
        return DdvDate.isStringValidDate(date) ?
            DdvDate.fromUSFormat(date) :
            (this.getDateFromFuzzy(dateList, date, fuzzyDates) ?? DdvDate.empty);
    }

    private getDateFromFuzzy(dateList: keyof FuzzyDates, fuzzyDate: string, fuzzyDates?: FuzzyDates): DdvDate | undefined {
        const option = fuzzyDates?.[dateList].findByName(fuzzyDate);
        return option ? DdvDate.fromISOFormat(option.value) : undefined;
    }

    private async handleCacheDialog(message: TemplateRef<string> | undefined): Promise<boolean> {
        return lastValueFrom(this.openCacheDialog(message).pipe(
            map((action) => {
                if (action === 'confirm') {
                    this.emitPopupState(true);
                    return true;
                }

                this.emitPopupState(false);
                return false;
            }),
            catchError((error) => {
                this.emitPopupState(false);
                if (error) {
                    console.error(`An error occurred: ${error}`);
                }
                return of(false);
            }),
        ));
    }

    private emitPopupState(resolved: boolean): void {
        this.fastnessCheckStarted = false;
        this.popupSubject.next(resolved);
        this.popupSubject.complete();
    }
}
