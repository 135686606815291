import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { DashboardDetails } from '@ddv/models';
import { ApplicationWidgetConfiguration, WidgetConfigurationManager } from '@ddv/widgets';
import { Observable } from 'rxjs';

@Injectable()
export class DataService {
    private clientCode: string = '';

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    // why isn't this in the dashboard service?
    getUserDashboards(): Observable<DashboardDetails> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, 'user/dashboards');
    }

    getWidgetsForDashboard(): ApplicationWidgetConfiguration[] {
        return WidgetConfigurationManager.getWidgetsForDashboard();
    }
}
