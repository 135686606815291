import { Injectable } from '@angular/core';
import { DatasetManagerService } from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { ManagerService } from '@ddv/layout';
import {
    DdvDate,
    DdvDateTime,
    UserPreferences,
    ExportDatasetInfo,
    ExportWidgetInfo,
    TExportFormat,
    FuzzyDate,
    FuzzyDates,
    AppWidgetState,
    MetadataLookup,
    WidgetData,
    UserDefinedFieldType,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { Observable, Subject } from 'rxjs';

import { FilteredDataService } from './filtered-data.service';

interface ExportRequest {
    dashboardName: string;
    dashboardId: string;
    datasets: ExportDatasetInfo[];
    exportFormat: TExportFormat;
    exportType: string;
}

@Injectable()
export class WidgetExportService {
    public readonly exportRequested: Observable<ExportRequest>;

    private includeCrosstalkKeys: boolean = false;
    private fuzzyDates: FuzzyDates | undefined;
    private userPreferences: UserPreferences | undefined;
    private readonly exportRequestedSubject: Subject<ExportRequest> = new Subject<ExportRequest>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private exportData: { [widgetId: number]: any } | undefined;

    constructor(
        private readonly managerService: ManagerService,
        private readonly datasetManagerService: DatasetManagerService,
        private readonly filteredDataService: FilteredDataService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly userService: UserService,
    ) {
        this.fuzzyDatesService.fuzzyDates().subscribe((fuzzyDates: FuzzyDates) => {
            this.fuzzyDates = fuzzyDates;
        });

        this.userService.userPreferences$.subscribe((userPreferences: UserPreferences) => {
            this.userPreferences = userPreferences;
        });

        this.filteredDataService.filteredData$.subscribe((filteredData) => {
            this.exportData = filteredData;
        });

        this.exportRequested = this.exportRequestedSubject.asObservable();
    }

    updateIncludeCrosstalkKeys(includeCrosstalkKeys: boolean): void {
        this.includeCrosstalkKeys = includeCrosstalkKeys;
    }

    shouldIncludeCrosstalkKeys(widget: ExportWidgetInfo | AppWidgetState | undefined): boolean {
        return !!(this.includeCrosstalkKeys &&
            widget?.datasetDefinition?.conversableType &&
            widget?.currentVisualization === 'ADVANCED_GRID');
    }

    private userDefinedFieldColumnHasValue(value: string | number | boolean | null): boolean {
        return value != null && !!(value || value === 0);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setUserDefinedFieldExportValue(datum: WidgetData, colId: string, type: UserDefinedFieldType): any {
        // eslint-disable-next-line eqeqeq
        const value = (typeof datum[colId] === 'object' && datum[colId] !== null) ?
            datum[colId].value :
            datum[colId];

        if (this.userDefinedFieldColumnHasValue(value)) {
            return value;
            // As nulls for number columns are transformed into zeros in the UI, we want the same in the export
            // eslint-disable-next-line  @typescript-eslint/prefer-optional-chain
        } else if (type === 'decimal' && (datum == null || datum[colId] == null || datum[colId].value == null)) {
            return 0;
        }

        return '';
    }

    exportWidgetFilteredCSV(widgetId: number, metadata: MetadataLookup, isTFLDetails: boolean = false): void {
        const exportData = this.exportData;
        if (!exportData) {
            return;
        }

        const exportFormat = 'CSV';
        const exportType = 'Filtered';
        const dashboardId = this.managerService.getWorkspace()?.name.split('_')[0] ?? '';
        const dashboardName = this.managerService.getWorkspace()?.name.split('_')[1] ?? '';
        const widgetPrefs = this.managerService.getWidgetPreferences(widgetId);
        const currentViz = widgetPrefs?.currentVisualization;
        const showSubTotal = !!widgetPrefs?.visualizationConfigs.find((config) => config.visualizationType === currentViz)?.showSubTotal;

        if (!Object.keys(exportData).length) {
            return;
        }

        if (currentViz === 'ADVANCED_GRID') {
            const udfColumns: { name: string, type: UserDefinedFieldType }[] = [];

            widgetPrefs?.visualizationConfigs
                .find((viz) => viz.visualizationType === currentViz)
                ?.configs
                ?.values
                ?.filter((vizConf) => this.isUserDefinedFieldColumn(vizConf.name))
                .forEach((udfColumn) => udfColumns.push({
                    name: udfColumn.customName ?? udfColumn.displayName ?? '',
                    type: udfColumn.datatype as UserDefinedFieldType,
                }));

            if (udfColumns.length) {
                udfColumns.forEach((udf) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    exportData[widgetId].data.forEach((datum: any) => {
                        datum[udf.name] = this.setUserDefinedFieldExportValue(datum, udf.name, udf.type);
                    });
                });
            }
        }

        const columns = Object.keys(exportData[widgetId]?.data[0] || []).map((column) => {
            return column.indexOf('___Grouper___') !== -1 ?
                column.replace('___Grouper___', '') :
                column;
        });

        if (!isTFLDetails && columns.length) {
            this.addDateColumnsForCSVExport(exportData[widgetId].datasetType, widgetId.toString(), columns);
        }

        const widgets = [{
            widgetId: widgetId.toString(),
            widgetName: `1_${exportData[widgetId].widgetName}-Filtered`,
            columnNames: columns,
            datasetType: exportData[widgetId].datasetType,
            dateFrom: exportData[widgetId] ? this.fuzzyDateCheck(exportData[widgetId].startDate, true) : null,
            dateTo: exportData[widgetId] ? this.fuzzyDateCheck(exportData[widgetId].endDate) : null,
            extractionDateTime: DdvDateTime.now.toString(),
            groupers: exportData[widgetId].groupers || [],
            datetypeoverrides: {},
            showSubTotal,
        }];

        this.exportRequestedSubject.next({
            dashboardName,
            dashboardId,
            datasets: [{
                data: exportData[widgetId].data,
                metadata,
                summary: exportData[widgetId].summary,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                widgets: widgets as any,
            }],
            exportFormat,
            exportType,
        });

        this.filteredDataService.clearFilteredData({});
    }

    fuzzyDateCheck(dateToCheck: string, isStartDate: boolean = false): string {
        const date = (!dateToCheck || dateToCheck.toUpperCase() === 'USE DEFAULTS' || dateToCheck.toUpperCase() === '' ?
            (isStartDate ? this.userPreferences?.startDate : this.userPreferences?.endDate) :
            dateToCheck) ?? '';
        const fuzzyDate = this.getFuzzyDate(date);
        return fuzzyDate ? DdvDate.fromISOFormat(fuzzyDate.value).toUSFormat() : date;
    }

    getFuzzyDate(value: string): FuzzyDate | undefined {
        return this.fuzzyDates?.from.find((fuzzyDate) => value && fuzzyDate.name.toLocaleUpperCase() === value.toUpperCase());
    }

    addDateColumnsForCSVExport(datasetType: string, widgetId: string, columns: string[]): string[] {
        const dateColumns: string[] = [];
        if (['Recon', 'Checklists'].indexOf(datasetType) === -1) {
            dateColumns.push('From Date');
        }
        dateColumns.push(...['To Date', 'Time of Extract']);

        const currentWidget = this.managerService.getWorkspace()?.extraParameters?.widgets.find((widget: AppWidgetState) => {
            return widget.id?.toString() === widgetId;
        });

        if (currentWidget && this.shouldIncludeCrosstalkKeys(currentWidget)) {
            const dsdId = currentWidget.datasetDefinition?.id ?? 0;
            const firstCrosstalkKeyColumn = this.datasetManagerService.crosstalkSchemaFields.get(dsdId)?.[0].name ?? '';
            const indexOfFirstCrosstalkKeyColumn = columns.lastIndexOf(firstCrosstalkKeyColumn);
            if (indexOfFirstCrosstalkKeyColumn > -1) {
                columns.splice(indexOfFirstCrosstalkKeyColumn, 0, ...dateColumns);
            }
        } else {
            columns.push(...dateColumns);
        }

        return columns;
    }

    isUserDefinedFieldColumn(name: string | undefined): boolean {
        return !!name?.startsWith('udf_');
    }
}
