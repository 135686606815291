import { Inject, Injectable } from '@angular/core';
import { ApiServices, SharedApiExecutorService } from '@ddv/http';
import { Observable, combineLatest, map } from 'rxjs';

@Injectable()
export class VersionsService {
    constructor(
        @Inject(ApiServices.monitorMW) private readonly monitorMWExecutor: SharedApiExecutorService,
        @Inject(ApiServices.monitorApi) private readonly monitorApiExecutor: SharedApiExecutorService,
    ) { }

    getVersions(): Observable<Versions> {
        return combineLatest([
            this.monitorApiExecutor.invokeServiceWithParams<{ version: string }>('version.json'),
            this.monitorMWExecutor.invokeServiceWithParams<{ build: { version: string } }>('api/monitor'),
            this.monitorApiExecutor.invokeServiceWithParams<{ build: { version: string } }>('ddv/api/monitor'),
        ]).pipe(map(([ui, mw, api]) => {
            return {
                ui: ui.version,
                mw: mw.build.version,
                api: api.build.version,
            };
        }));
    }
}

export interface Versions {
    ui: string;
    mw: string;
    api: string;
}
