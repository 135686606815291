import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CellStyle } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ColorMetadataService } from '@ddv/charts';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { CustomCellRendererParams } from '@ddv/data-grid';
import { CellFormat, AUTO_GROUP_COLUMN_ID } from '@ddv/models';
import { Theme, ThemeService } from '@hs/ui-core-presentation';

@Component({
    selector: 'app-column-condition-cell-renderer',
    templateUrl: './column-condition-cell-renderer.component.html',
    styleUrls: ['./column-condition-cell-renderer.component.scss'],
})
export class ColumnConditionCellRendererComponent extends MultiSubscriptionComponent implements ICellRendererAngularComp {
    cellInnerHTML: SafeHtml | undefined;
    private theme: Theme = Theme.light;
    private params: CustomCellRendererParams | undefined;

    constructor(private readonly domSanitizer: DomSanitizer, private readonly themeService: ThemeService) {
        super();
    }

    agInit(params: CustomCellRendererParams): void {
        this.params = params;

        this.subscribeTo(this.themeService.currentTheme$, (theme: Theme) => {
            this.theme = theme;
            this.setup();
        });
    }

    refresh(params: CustomCellRendererParams): boolean {
        this.params = params;
        this.setup();
        return true;
    }

    private setup(): void {
        if (!this.params) {
            return;
        }

        this.params.columnCondition?.forEach((colCondition: CellFormat) => {
            ColorMetadataService.mapOutdatedColors(this.theme, colCondition);
            ColorMetadataService.mapThemeColors(this.theme, colCondition);
        });

        let valueToSanitize: string;
        if (this.params.displayType === 'bar') {
            valueToSanitize = this.params.renderProgressBar!(
                this.params.value,
                this.params.columnCondition![0],
                this.params.getDatasetMinMax!(this.params.datasetId as number, this.params.colDef?.field),
            );
        } else {
            valueToSanitize = this.params.colDef?.colId === AUTO_GROUP_COLUMN_ID ?
                this.params.value :
                this.params.isRanked ?
                    this.params.valueFormatted :
                    // We attached innerHTML to valueFormatted
                    // So we have to cast to any until that's fixed
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (this.params.valueFormatted as any).innerHTML;
        }

        this.cellInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(valueToSanitize);

        const cellStyle: CellStyle = this.params.getCellStyle!(this.params.value, this.params.displayType, this.params.columnCondition);
        const agGroupCell = this.params.eParentOfValue?.parentElement?.classList.contains('ag-row-group');

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.params.eGridCell.style.color = (agGroupCell && !(this.params.valueFormatted as any).innerHTML ?
            '' :
            cellStyle.color) as string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.params.eGridCell.style.backgroundColor = (agGroupCell && !(this.params.valueFormatted as any).innerHTML ?
            '' :
            cellStyle.backgroundColor) as string;
    }
}
