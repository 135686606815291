<div class="dsd-selector-container">
    <div class="labeled-dropdown datatype">
        <label>Query Type</label>

        <div class="arrow-selector" *ngIf="enabled">
            <app-dropdown
                id="dsd-data-type-select"
                [options]="queryTypeList"
                [(selected)]="queryType"
                (selectedChange)="onQueryTypeChanged()"
                [readonly]="isReadOnly">
            </app-dropdown>
        </div>

        <div *ngIf="!enabled">
            <span class="disabled-label">{{ queryType.text !== 'ALL' ? queryType.text : 'TBD' }}</span>
        </div>
    </div>

    <div class="labeled-dropdown">
        <label>Name</label>

        <div *ngIf="enabled">
            <app-typeahead
                [dataSource]="filteredDataSource"
                [configuration]="{ placeholder: 'Name', displayProperty: 'name' }"
                (itemSelected)="onDatasetDefinitionSelected($event)"
                [preSelectedItem]="preSelectedItem"
                [readonly]="isReadOnly">
            </app-typeahead>
        </div>

        <div *ngIf="!enabled">
            <span class="disabled-label">{{ (datasetDefinition && datasetDefinition.name) || 'TBD' }}</span>
        </div>

        <div class="manage-button-container" *ngIf="showJumpLink" [ngClass]="{'readonly': isReadOnly}">
            <a
                    class="manage-button"
                    [routerLink]="['../../../dataset-definitions/edit', datasetDefinition ? datasetDefinition.id : 'none']"
                    [queryParams]="{from: this.jumpedFrom}">

                {{ datasetDefinition ? 'edit dataset' : 'new dataset' }}
            </a>
        </div>
    </div>
</div>
