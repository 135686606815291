import { Injectable } from '@angular/core';
import { DashboardService } from '@ddv/dashboards';
import { UserService } from '@ddv/entitlements';
import { DashboardSnapshot } from '@ddv/models';
import { Observable } from 'rxjs';
import { map, switchMap, shareReplay } from 'rxjs/operators';

import { DashboardGroup } from '../models/dashboard-group';
import { ViewLink, ViewTile } from '../models/dashboard-nav.model';
import { DashboardGroupsService } from './dashboard-groups.service';

@Injectable()
export class DashboardNavService {
    private viewTiles$: Observable<ViewTile[]> | undefined;

    constructor(
        private readonly dashboardGroupsService: DashboardGroupsService,
        private readonly dashboardService: DashboardService,
        private readonly userService: UserService,
    ) { }

    get viewTiles(): Observable<ViewTile[]> {
        if (!this.viewTiles$) {
            this.viewTiles$ = this.dashboardService.dashboardSnapshots$
                .pipe(switchMap((dashboards) => this.dashboardGroupsService.dashboardGroups$
                    .pipe(switchMap((groups) => this.userService.getOpenDashboardOrderedIds()
                        .pipe(map((dashboardOrderedIds) => this.mapToViewTiles(groups, dashboards, dashboardOrderedIds)))))))
                .pipe(shareReplay(1));
        }
        return this.viewTiles$;
    }

    private mapToViewTiles(groups: DashboardGroup[], dashboards: DashboardSnapshot[], dashboardOrderedIds: number[]): ViewTile[] {
        const result: ViewTile[] = [];

        const allDefaultViewsIds = dashboards.filter((d) => d.isDefault).map((d) => Number(d.id));

        const filteredGroups = groups.map((g) => {
            const resultGroup = new DashboardGroup(g);
            resultGroup.dashboards = resultGroup.dashboards.filter((d) => allDefaultViewsIds.includes(Number(d.id)));
            return resultGroup;
        });

        result.push(...filteredGroups.filter((g) => g.dashboards.length).map((g) => this.mapGroupToViewTile(g)));
        const allGroupViewsIds = ([] as number[]).concat(...filteredGroups.map((g) => g.dashboards.map((d) => Number(d.id))));
        result.push(...dashboards.filter((d) => !allGroupViewsIds.includes(Number(d.id))).map(mapSnapshotToViewTile));
        result.sort((a, b) => {
            const aIdx = dashboardOrderedIds.findIndex((id) => a.selectedLink?.id === id);
            const bIdx = dashboardOrderedIds.findIndex((id) => b.selectedLink?.id === id);

            // The purpose of the following lines is to:
            // 1. Always arrange the custom sorted tiles on top;
            // 2. Arrange the custom sorted tiles according to their sort.
            if (aIdx > -1 && bIdx > -1) {
                return aIdx - bIdx;
            } else if (aIdx > -1) {
                return -1;
            } else if (bIdx > -1) {
                return 1;
            }

            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        return result;
    }

    private mapGroupToViewTile(group: DashboardGroup): ViewTile {
        const links = group.dashboards.map(mapSnapshotToViewLink);

        return new ViewTile({
            id: group.id,
            name: group.name,
            isGroup: true,
            selectedLink: links[0],
            links,
        });
    }
}

function mapSnapshotToViewLink(snapshot: DashboardSnapshot): ViewLink {
    return {
        id: Number(snapshot.id),
        name: snapshot.name ?? '',
        abbreviation: snapshot.abbreviation ?? '',
        privilege: snapshot.privilege,
        isGlobal: snapshot.isGlobal,
    };
}

function mapSnapshotToViewTile(snapshot: DashboardSnapshot): ViewTile {
    const link = mapSnapshotToViewLink(snapshot);

    return new ViewTile({
        id: Number(snapshot.id),
        name: snapshot.name,
        isGroup: false,
        selectedLink: link,
        links: [link],
    });
}
