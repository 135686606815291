<div class="custom-filter-wrapper">

    <input
        type="text"
        class="search-input"
        [(ngModel)]="searchValue"
        (ngModelChange)="onSearch()"
        placeholder="Search..."/>

    <cc-checkbox
        class="select-all"
        *ngIf="filteredCellData.length"
        [label]="'(Select All)'"
        [(value)]="selectAll"
        (valueChange)="onSelectAll()">
    </cc-checkbox>

    <ul class="column-values">
        <li *ngFor="let item of filteredCellData | filter: searchValue : 'text'">
            <cc-checkbox
                [label]="!searchValue ? item.text : ''"
                [(value)]="item.checked"
                (valueChange)="onCheckChanged()">
                <label *ngIf="!!searchValue" [innerHTML]="searchValue && item.text | highlight: searchValue"></label>
            </cc-checkbox>
        </li>
    </ul>

    <div class="no-data error" *ngIf="!filteredCellData.length">
        <span>No Matches!</span>
    </div>

</div>
