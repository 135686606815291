import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { TypeaheadConfig } from './typeahead-config';

@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.scss'],
})

export class TypeaheadComponent implements OnChanges {
    @Input() configuration: TypeaheadConfig | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() dataSource: any[] | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() preSelectedItem: any | undefined;
    @Input() readonly = false;

    @Output() itemSelected = new EventEmitter();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filteredItems: any[] | undefined;
    queryFilter: string = '';
    showDropdown: boolean = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedItem: any | undefined;

    ngOnChanges(): void {
        this.filteredItems = this.dataSource;
        this.queryFilter = this.preSelectedItem && this.configuration?.displayProperty ?
            this.preSelectedItem[this.configuration.displayProperty] :
            undefined;
        this.selectedItem = this.preSelectedItem || (this.filteredItems ? this.filteredItems[0] : null);
        this.filterItems();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    emitSelection(item: any): void {
        if (this.configuration?.displayProperty) {
            this.queryFilter = item[this.configuration.displayProperty];
        }
        this.showDropdown = false;
        this.itemSelected.emit(item);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    selectItem(item: any | undefined): void {
        this.selectedItem = item;
    }

    changeSelectedItem(pos: number): void {
        if (!this.filteredItems) {
            return;
        }
        let index = this.filteredItems.indexOf(this.selectedItem) + pos;
        index = index >= this.filteredItems.length ? 0 : index;
        index = index < 0 ? this.filteredItems.length - 1 : index;
        this.selectedItem = this.filteredItems[index];
    }

    filterItems(): void {
        if (!this.dataSource || this.queryFilter == null) {
            return;
        }

        const displayProperty = this.configuration?.displayProperty;
        if (displayProperty) {
            this.filteredItems = this.dataSource.filter((item) =>
                item[displayProperty].toLocaleLowerCase().includes(this.queryFilter?.toLocaleLowerCase() ?? ''));
        }

        this.selectItem(this.filteredItems?.[0]);
    }

    updateSelectedItem(event: Event): void {
        (event.target as HTMLInputElement).blur();
        this.emitSelection(this.selectedItem);
    }
}
