import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CustomCellRendererParams } from '@ddv/data-grid';
import { linkify } from '@ddv/utils';

import { CrosstalkModalService } from '../../../services/crosstalk-modal.service';

@Component({
    selector: 'app-comment-cell-renderer',
    styleUrls: ['./comment-cell-renderer.component.scss'],
    templateUrl: './comment-cell-renderer.component.html',
})
export class CommentCellRendererComponent implements ICellRendererAngularComp {
    private params: CustomCellRendererParams | undefined;
    comment: SafeHtml | undefined;
    editMode = false;
    isGrouperComment: boolean = false;

    constructor(
        private readonly crosstalkModalService: CrosstalkModalService,
        private readonly sanitizer: DomSanitizer,
    ) { }

    get showComment(): boolean {
        return this.params?.value?.length !== 0;
    }

    agInit(params: CustomCellRendererParams): void {
        this.init(params);
    }

    refresh(params: CustomCellRendererParams): boolean {
        this.init(params);
        return true;
    }

    private getLinkifiedComment(comment: string): string {
        if (!comment) {
            return '';
        }
        return linkify(comment, '_blank');
    }

    private init(params: CustomCellRendererParams): void {
        this.params = params;
        this.isGrouperComment = !!this.params.node?.group;

        let linkifiedComment = this.getLinkifiedComment(this.params.value);

        // This disables the hyperlink when in edit mode
        if (params.colDef?.cellEditor) {
            this.editMode = true;
            linkifiedComment = linkifiedComment.replace(/\bhref=/ig, 'rel=');
        } else {
            this.editMode = false;
        }

        this.comment = this.sanitizer.bypassSecurityTrustHtml(linkifiedComment);
    }

    openHistory($event: MouseEvent): void {
        const colId = this.params?.colDef?.field ?? 'THIS SHOULD NEVER HAPPEN';
        const linkConfiguration = { columnId: colId, ...this.params?.data.links[colId] };

        if (linkConfiguration) {
            this.crosstalkModalService.openModal(linkConfiguration, $event);
        }

        if ($event.stopImmediatePropagation) {
            $event.stopImmediatePropagation();
        }
    }
}
