import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CurrentStateService, DirtyFlagService } from '@ddv/behaviors';
import { SelectedValues, ModalDialogService, MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardFiltersService, DdvWidgetService } from '@ddv/dashboards';
import { Datasource, DataWrapper, MetadataService, WidgetDataSourceService } from '@ddv/datasets';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService, SelectedWidgetAction } from '@ddv/layout';
import {
    BoardWidgetDefinitionIds,
    DATASET_KEY,
    EXCLUDE,
    FILTER_TYPE,
    MANAGE_WIDGET_ID,
    WIDGET_KEY,
    CompareMode,
    AdditionalFilter,
    DashboardFilter,
    FilterPreference,
    MetadataLookup,
} from '@ddv/models';
import { deepCompare, deepClone } from '@ddv/utils';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { WidgetFilterComponent } from '../widget-filter/widget-filter.component';

interface Filter {
    name: string;
    value: string | number | boolean | undefined;
    isMasterWidgetFilter: boolean;
    isDetailWidgetFilter?: boolean;
}

@Component({
    selector: 'app-filters-bar',
    templateUrl: './filters-bar.component.html',
    styleUrls: ['./filters-bar.component.scss'],
})
export class FiltersBarComponent extends MultiSubscriptionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isDashboardMode: boolean = false;
    @Input() dashboardId: string | undefined;
    @Input() inPresentationMode = false;
    @Input() widgetId: number | undefined;
    @Output() filtersApplied = new EventEmitter();
    metadataLoaded = false;
    noFiltersAvailable = true;
    showFilterableAttributes = false;
    showFilterableValues = false;
    isWidgetSelected = false;
    dashboardParamsChangedOnView = false;

    currentId: string | undefined;
    selectedWidgetId: number | undefined;
    subscriptionState: string | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deltas: any | undefined;
    noFiltersSelected = 'Add Filter';

    filters: Filter[] = [];

    selectedBubbleFilter: DashboardFilter[] = [];
    selectedDashboardFilter: DashboardFilter = { values: [] };
    selectedWidgetFilters: FilterPreference | undefined;
    filterAttributeList: { texts: string[], value: string, checked?: boolean }[] = [];

    private paramsSubscription: Subscription | undefined;
    private readonly unsubscribeNotifier$ = new Subject<void>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private widgetPrefs: any | undefined;
    private metadataSubscription: Subscription | undefined;
    private readonly appliedUrlQueryParams: FilterPreference = {};
    private isMultiClient: boolean = false;
    private currentDatasources: DataWrapper[] = [];
    private isDetailWidgetOpened: boolean = false;

    constructor(
        private readonly queryParamsService: QueryParamsService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly dirtyFlagCheck: DirtyFlagService,
        private readonly dashboardFiltersService: DashboardFiltersService,
        private readonly metadataService: MetadataService,
        private readonly manager: ManagerService,
        private readonly modalService: ModalDialogService,
        private readonly cdr: ChangeDetectorRef,
        private readonly activatedRoute: ActivatedRoute,
        private readonly currentStateService: CurrentStateService,
        private readonly ddvWidgetService: DdvWidgetService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(
            combineLatest([
                this.manager.selectedWidget$,
                this.widgetDataSourceService.dataSource$.pipe(filter((ds) => ds.datasources.length !== 0)),
                this.activatedRoute.queryParams,
                this.ddvWidgetService.currentOpenedDetailWidgetId,
            ]), (values: [SelectedWidgetAction, Datasource, Params, number | undefined]) => {
                const [ws, ds, queryParams, detailWidgetId] = values;

                this.currentId = this.dashboardId;

                this.isWidgetSelected = !!ws.isWidgetSelected;

                if (ws.config) {
                    this.selectedWidgetId = ws.config.id;
                    this.widgetPrefs = ws.config.extraParameters?.preferences;

                    this.selectedWidgetFilters = this.widgetPrefs.widgetFilters;
                    this.subscriptionState = this.widgetPrefs.isSubscribedToDashboardFilters ? 'All Subscribed' : 'Selected Widget';
                } else {
                    this.selectedWidgetId = undefined;
                    this.selectedWidgetFilters = undefined;
                    this.subscriptionState = 'All Subscribed';
                }

                if (queryParams && Object.keys(queryParams).length &&
                    (!this.appliedUrlQueryParams || !deepCompare(this.appliedUrlQueryParams.filters, queryParams.filters))
                ) {
                    this.queryParamsService.dispatchUpdatedQueryParams({
                        filters: JSON.parse(decodeURIComponent(queryParams.filters)) || [],
                    });
                    Object.assign(this.appliedUrlQueryParams, queryParams);
                }

                this.mapFilters(ds);

                let contexts: BoardWidgetDefinitionIds[] = [];

                if (this.isDashboardMode && this.subscriptionState !== 'Selected Widget') {
                    this.dashboardFiltersService.filterType = FILTER_TYPE.DASHBOARD;
                    contexts = this.dashboardFiltersService.initFilterDashboard();
                } else if (this.isDashboardMode && this.subscriptionState === 'Selected Widget') {
                    contexts = this.dashboardFiltersService.initFilterWidget(this.selectedWidgetId);
                } else {
                    contexts = this.dashboardFiltersService.initFilterWidget(this.widgetId);
                }
                this.dashboardFiltersService.setSlicerValue();

                this.noFiltersAvailable = contexts.length === 0;

                if (!deepCompare(this.currentDatasources, ds.datasources)) {
                    this.currentDatasources = deepClone(ds.datasources);

                    if (!this.noFiltersAvailable) {
                        this.onFetchMetadata(this.setContexts(contexts, detailWidgetId));
                    }
                }
            });

        this.subscribeTo(this.currentStateService.isMultiClient$, (isMultiClient) => this.isMultiClient = isMultiClient);

        this.subscribeTo(this.manager.isDetailWidgetOpened, (isDetailWidgetOpened) => {
            this.isDetailWidgetOpened = isDetailWidgetOpened;
            if (!this.isDetailWidgetOpened) {
                this.filters?.filter((f) => f.isDetailWidgetFilter).forEach((f) => this.removeFilter(f));
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dashboardId) {
            this.onDashboardChanges();
        }

        if (changes.inPresentationMode) {
            this.onModeChanges();
        }
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        this.manager.selectWidget(false, undefined);

        this.unsubscribeNotifier$.next();
        this.unsubscribeNotifier$.complete();

        this.unsubscribeFromMetadata();
    }

    openFilterableValue(selectedFilter: Filter): void {
        if (this.subscriptionState === 'Selected Widget') {
            this.onOpenFilterDialog();
            return;
        }
        if (selectedFilter.isMasterWidgetFilter ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.selectedDashboardFilter === (this.selectedBubbleFilter as any)[selectedFilter.name] && this.showFilterableValues) {
            this.showFilterableValues = false;
            return;
        }

        if (this.showFilterableValues) {
            this.showFilterableValues = false;
            this.cdr.detectChanges();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.selectedDashboardFilter = (this.selectedBubbleFilter as any)[selectedFilter.name];
        this.showFilterableValues = true;
    }

    onApplyFilters(event: DashboardFilter): void {
        if (this.subscriptionState === 'Selected Widget') {
            return;
        }

        this.dashboardFiltersService.dashboardFilters = this.dashboardFiltersService.dashboardFilters
            ?.map((df) => df.displayName === event.displayName ? event : df);

        this.queryParamsService.dispatchUpdatedQueryParams({
            filters: this.dashboardFiltersService.dashboardFilters,
            areFiltersAppliedByMaster: false,
            comparing: this.widgetPrefs?.widgetFilters?.isComparing ? CompareMode.BOTH : undefined,
        });

        this.onEnterDirtyState(this.dashboardFiltersService.dashboardFilters);
    }

    openFilterableAttributes(): void {
        if (this.subscriptionState === 'Selected Widget') {
            this.onOpenFilterDialog();
            return;
        }
        if (!this.noFiltersAvailable) {
            this.dashboardFiltersService.convertToMetadataList();

            const attributes = this.dashboardFiltersService.dashboardFilters?.map((dashboardFilter) => ({
                text: dashboardFilter.displayName ?? '',
                value: dashboardFilter.name ?? '',
                checked: true,
            })) ?? [];
            this.dashboardFiltersService.metadataList?.forEach((md) => {
                attributes.push({ checked: false, text: md.text, value: md.value });
            });

            attributes.sort((a, b) => a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1);

            this.filterAttributeList = attributes.map((a) => ({ texts: [a.text], value: a.value, checked: a.checked }));
        }

        this.showFilterableAttributes = !this.showFilterableAttributes;
    }

    onApplyAttributes(selectedFilters?: SelectedValues): void {
        if (!selectedFilters) {
            this.showFilterableAttributes = false;
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dbFilterObject: Record<string, any> = {};
        this.filterAttributeList.forEach((item) => dbFilterObject[item.value] = { name: item.value, displayName: item.texts[0] });
        this.dashboardFiltersService.dashboardFilters?.forEach((item) => dbFilterObject[item.name ?? ''] = item);

        const filters: AdditionalFilter[] = selectedFilters.values?.map((f: string) => ({
            name: f,
            displayName: dbFilterObject[f].displayName,
            criteria: (dbFilterObject[f].criteria || 'INCLUDE'),
            values: dbFilterObject[f].values || [],
            filterValuesType: this.getFilterValuesType(dbFilterObject[f]?.values),
        })) ?? [];

        if (this.subscriptionState === 'Selected Widget') {
            if (this.selectedWidgetFilters) {
                this.selectedWidgetFilters.filters = filters;
            }
            this.queryParamsService.addWidgetQueryParam(this.selectedWidgetId ?? 0, this.selectedWidgetFilters);
            this.onEnterDirtyState(undefined);
        } else {
            this.queryParamsService.dispatchUpdatedQueryParams({
                filters,
                areFiltersAppliedByMaster: false,
                comparing: this.widgetPrefs?.widgetFilters?.isComparing ? CompareMode.BOTH : undefined,
            });
            this.onEnterDirtyState(filters);
        }
        this.showFilterableAttributes = false;
    }

    removeFilter(filterToRemove: Filter): void {
        let filters: DashboardFilter[] | undefined;
        const dashboardFilters = this.dashboardFiltersService.dashboardFilters?.filter((df) => df.displayName !== filterToRemove.name);
        if (this.inPresentationMode && filterToRemove.isMasterWidgetFilter) {
            const masterFilterEqualsToDashboardFilter = this.manager.getCurrentDashboardFilters().find((el) => {
                return el.displayName === filterToRemove.name;
            });
            filters = masterFilterEqualsToDashboardFilter ?
                deepClone(this.manager.getCurrentDashboardFilters()) :
                dashboardFilters;
        } else {
            filters = dashboardFilters;
        }

        if (this.subscriptionState === 'Selected Widget') {
            if (this.selectedWidgetFilters) {
                this.selectedWidgetFilters.filters = filters;
            }
            if (this.selectedWidgetFilters?.isComparing) {
                this.selectedWidgetFilters.comparing = CompareMode.BOTH;
            }
            this.queryParamsService.addWidgetQueryParam(this.selectedWidgetId ?? 0, this.selectedWidgetFilters);
            this.onEnterDirtyState(null);
        } else {
            this.queryParamsService.dispatchUpdatedQueryParams({
                filters,
                areFiltersAppliedByMaster: false,
                comparing: this.widgetPrefs?.widgetFilters?.isComparing ? CompareMode.BOTH : undefined,
            });
            this.onEnterDirtyState(filters);
        }
        this.showFilterableValues = false;

        if (filterToRemove.isMasterWidgetFilter) {
            this.manager.updateWidgetsOnMasterFiltersRemoved();
        }
    }

    private getFilterOptionsCount(ds: Datasource, filterName: string, selectedValues: string[]): number {
        const uniqueKeyPrefix = this.isDashboardMode ?
            (this.subscriptionState === 'All Subscribed' ? DATASET_KEY : `${WIDGET_KEY}${this.selectedWidgetId}`) :
            `${WIDGET_KEY}${MANAGE_WIDGET_ID}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const slicerValues = ([] as any[]).concat(
            ...ds.datasources
                .filter((dw) => dw.uniqueKey.startsWith(uniqueKeyPrefix))
                .map((dw) => dw.data),
        );
        const filterOptions = new Set();

        slicerValues.forEach((value) => {
            if (value[filterName] != null) {
                filterOptions.add(value[filterName].toString());
            }
        });

        selectedValues.forEach((selectedValue) => filterOptions.add(selectedValue));

        return filterOptions.size;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onEnterDirtyState(deltas: any | undefined): void {
        if (!this.inPresentationMode) {
            this.dirtyFlagCheck.enterDirtyState(this.dashboardId ?? '');
        } else {
            this.dashboardParamsChangedOnView = true;
            if (deltas) {
                this.deltas = deltas;
            }
        }
    }

    private onFetchMetadata(contextsByDefinitionId: BoardWidgetDefinitionIds[]): void {
        let fetchedMetadata: MetadataLookup = {};
        let observable: Observable<MetadataLookup | { [widgetId: number]: MetadataLookup }>;
        if (!this.isMultiClient) {
            observable = this.metadataService.fetchMetadataUniqueMetadata(contextsByDefinitionId[0]);
        } else {
            observable = this.metadataService.fetchMetadataAllMetadata(contextsByDefinitionId);
        }

        this.metadataSubscription = observable
            .subscribe((uniqueMetadata: MetadataLookup | { [widgetId: number]: MetadataLookup }) => {
                if (Number(Object.keys(uniqueMetadata)[0])) {
                    const metadataLookup = uniqueMetadata as { [widgetId: number]: MetadataLookup };
                    contextsByDefinitionId.forEach((context) => {
                        const metadata: MetadataLookup = metadataLookup[context.widgetId!];
                        Object.entries(metadata).forEach(([key, value]) => {
                            value.name = key;
                        });
                        this.metadataService.addMetadataToState(context.widgetId!, metadata);
                    });
                    Object.keys(uniqueMetadata).forEach((key) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        fetchedMetadata = { ...fetchedMetadata, ...metadataLookup[key as any] };
                    });
                } else {
                    Object.entries(uniqueMetadata).forEach(([key, value]) => {
                        value.name = key;
                    });
                    this.metadataService.addMetadataToState(contextsByDefinitionId[0].widgetId!, uniqueMetadata as MetadataLookup);
                    fetchedMetadata = uniqueMetadata as MetadataLookup;
                }

                this.metadataLoaded = true;
                this.dashboardFiltersService.updateMetadata(fetchedMetadata);
            });
    }

    private onMapFilters(paramFilters: (DashboardFilter | AdditionalFilter)[], datasource: Datasource): void {
        const previouslySelectedFilters = this.filters;

        this.filters = paramFilters.map((f) => {
            const displayName = f.displayName ?? '';
            const filterName = f.name ?? '';

            // this thing is defined as an array, but we keep indexing it with strings.  something is way wrong.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const selectedBubbleFilterAsAny = this.selectedBubbleFilter as any;
            selectedBubbleFilterAsAny[displayName] = f;
            this.dashboardFiltersService.dashboardFilters?.push(f);
            let filterValue: string | number | boolean | undefined;

            if (f.criteria === EXCLUDE && f.values?.length) {
                filterValue = `EX: ${f.values.length.toString()} OF ${this.getFilterOptionsCount(datasource, filterName, selectedBubbleFilterAsAny[displayName].values)}`;
            } else if (f.values?.length === 1) {
                filterValue = f.values[0];
            } else if (Number(f.values?.length) > 1) {
                filterValue = `${f.values?.length.toString()} OF ${this.getFilterOptionsCount(datasource, filterName, selectedBubbleFilterAsAny[displayName].values)}`;
            }

            return {
                name: displayName,
                value: filterValue,
                isMasterWidgetFilter: !!(f as DashboardFilter).isMasterWidgetFilter,
            };
        });

        if (this.isDetailWidgetOpened) {
            this.filters.forEach((currentFilter) => {
                const f = previouslySelectedFilters?.find((selectedFilter) => selectedFilter.name === currentFilter.name);
                if (!f || f.isDetailWidgetFilter) {
                    currentFilter.isDetailWidgetFilter = true;
                }
            });
        }

        this.filters.sort((a, b) => a.name > b.name ? 1 : -1);
    }

    private onDashboardChanges(): void {
        this.manager.selectWidget(false, undefined);
        this.dashboardParamsChangedOnView = false;
        this.deltas = null;
        this.noFiltersAvailable = true;
        this.unsubscribeFromMetadata();
    }

    private onModeChanges(): void {
        const newDashboardCreated = !(this.currentId === this.dashboardId);
        if (!newDashboardCreated && !this.inPresentationMode && this.dashboardParamsChangedOnView) {
            if (this.deltas) {
                this.queryParamsService.dispatchUpdatedQueryParams({ filters: this.deltas, areFiltersAppliedByMaster: false });
            }
            this.dirtyFlagCheck.enterDirtyState(this.dashboardId ?? '');
            this.dashboardParamsChangedOnView = false;
            this.deltas = null;
        } else {
            this.currentId = this.dashboardId;
        }
    }

    private onOpenFilterDialog(): void {
        const widgetFilterModeDialogRef = this.modalService.open(WidgetFilterComponent, { windowClass: 'filter-popup' });
        widgetFilterModeDialogRef.componentInstance.widgetId = this.selectedWidgetId;
        widgetFilterModeDialogRef.componentInstance.isSubscribedToDashboardFilters = false;
    }

    private unsubscribeFromMetadata(): void {
        if (this.metadataSubscription) {
            this.metadataSubscription.unsubscribe();
        }
    }

    private setContexts(contexts: BoardWidgetDefinitionIds[], detailWidgetId: number | undefined): BoardWidgetDefinitionIds[] {
        if (detailWidgetId) {
            return contexts.filter((context) => context.widgetId === detailWidgetId);
        } else {
            const definitionIds = new Set<number>();
            return contexts.filter((context) => {
                if (!definitionIds.has(context.definitionId!)) {
                    definitionIds.add(context.definitionId!);
                    return context;
                }

                return;
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFilterValuesType(filterValues: any[]): string {
        const allValuesType: string[] = filterValues?.map((value) => {
            if (value == null) {
                return 'null';
            } else {
                return typeof value;
            }
        });

        const valuesTypes = Array.from(new Set(allValuesType));
        const type = valuesTypes.length === 1 ?
            valuesTypes[0] :
            valuesTypes.length === 2 ? valuesTypes.find((valuesType) => valuesType !== 'null') : '';

        return type ?? '';
    }

    private mapFilters(data: Datasource): void {
        let params: Observable<(DashboardFilter | AdditionalFilter)[] | undefined>;
        if (this.isDashboardMode && this.subscriptionState !== 'Selected Widget') {
            params = this.queryParamsService.dashboardQueryParams.pipe(map((qp) => qp.filters));
        } else if (this.isDashboardMode && this.subscriptionState === 'Selected Widget') {
            params = this.queryParamsService.widgetQueryParams
                .pipe(
                    filter((qp) => !!qp),
                    map((qp) => qp?.widgetFilters.get(this.selectedWidgetId ?? 0)?.filters),
                    takeUntil(this.unsubscribeNotifier$),
                );
        } else {
            params = this.queryParamsService.widgetQueryParams
                .pipe(
                    filter((qp) => !!qp),
                    map((qp) => qp?.widgetFilters.get(MANAGE_WIDGET_ID)?.filters),
                );
        }

        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }

        this.paramsSubscription = params.subscribe((p) => {
            // remove saved UDF filters
            // until we start fetching the data for UDF from Trebek
            const updatedFilters = p?.filter((f) => !f.name?.startsWith('udf_')) ?? [];
            this.dashboardFiltersService.dashboardFilters = [];
            this.onMapFilters(updatedFilters, data);
        });
    }
}
