<div class="dropdown-export" [ngClass]="{'crosstalk-keys': showCrosstalkKeysExportToggle}">
    <div class="full-export" *ngIf="!isWidgetExport">
        Export full view

        <button
                id="pdf"
                class="ghost-btn"
                [disabled]="exportWidgetInfos.length === 0" (click)="exportDashboard('PDF')">
            PDF
        </button>
    </div>

    <div class="export">
        <div class="export-type">
            <label>Export {{!isWidgetExport ? 'data' : 'Widget'}}</label>

            <cc-option-toggle
                class="export-type-toggle"
                [(value)]="!isFullExport"
                [labels]="['Filtered', 'Full']"
                [disabled]="!isWidgetExport && exportWidgetInfos.length === 0"
                [ngClass]="{'disabled-toggle': !isWidgetExport && exportWidgetInfos.length === 0}"
                (valueChange)="onExportTypeChange($event)">
            </cc-option-toggle>
        </div>

        <div class="export-crosstalk-keys" *ngIf="showCrosstalkKeysExportToggle">
            <label>Crosstalk keys</label>

            <cc-option-toggle
                [(value)]="!includeCrosstalkKeys"
                [labels]="['Excluded', 'Included']"
                [disabled]="!isWidgetExport && exportWidgetInfos.length === 0"
                [ngClass]="{'disabled-toggle': !isWidgetExport && exportWidgetInfos.length === 0}"
                (valueChange)="onCrosstalkKeyExportChange($event)">
            </cc-option-toggle>
        </div>

        <div class="export-widget-list max-height" *ngIf="!isWidgetExport">
            <cc-checkbox
                #selectAllCheckbox
                [label]="'Select All'"
                [(value)]="selectAll.isChecked"
                (valueChange)="onSelectAllChange(selectAllCheckbox.value)"
                [disabled]="exportWidgetInfos.length === 0">
            </cc-checkbox>

            <cc-checkbox
                *ngFor="let ew of exportWidgetInfos"
                id="ex-{{ew.id}}"
                [label]="ew.name"
                [(value)]="ew.isChecked"
                (valueChange)="onSelectChange(ew, ew.isChecked)"
                [disabled]="exportWidgetInfos.length === 0">
            </cc-checkbox>
        </div>

        <div class="button-wrap">
            <button
                    id="csv"
                    class="ghost-btn"
                    [disabled]="!dataIsLoaded || (!isWidgetExport && !selectedWidgetCount)"
                    (click)="exportDashboard('CSV')">
                CSV
            </button>

            <button
                    id="excel"
                    class="ghost-btn"
                    [disabled]="!dataIsLoaded || (!isWidgetExport && !selectedWidgetCount)"
                    (click)="exportDashboard('EXCEL')">
                EXCEL
            </button>
        </div>
    </div>
</div>
