import { ChangeDetectorRef, EventEmitter, Output, Input, ViewChild, TemplateRef, Directive } from '@angular/core';
import { ErrorModalComponent, ModalDialogService } from '@ddv/common-components';
import { DatasetManagerService, MetadataService } from '@ddv/datasets';
import { ClientDatasetFilterService, QueryParamsService } from '@ddv/filters';
import { CustomLayoutHandler, LayoutService, ManagerService } from '@ddv/layout';
import {
    BoardWidgetDefinitionIds,
    ACCESS_RESTRICTED_MESSAGE,
    HI_DATA_ADDITIONAL_MESSAGE,
    HI_DATA_CLIENT_ERROR_MESSAGE,
    HI_DATA_USER_ERROR_MESSAGE,
    LOADER_CANCELLED_MESSAGE,
    LOADER_MESSAGE,
    MANAGE_WIDGET_ID,
    MANAGE_WIDGET_WS_ID,
    MODE,
    WIDGET_LIFECYCLE_EVENT,
    WidgetOnBoard,
    DashboardClientQueryParam,
    ConfigItem,
    VisualizationConfigs,
    AppWidgetState,
    DataUpdateBody,
    isLifecycleAction,
    isLifecycleErrorAction,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    WidgetSettingsOverrides,
    VisualizationType,
    MetadataLookup,
    TMode,
} from '@ddv/models';
import { getDefault, deepCompare, deepClone } from '@ddv/utils';
import { Subscription } from 'rxjs';

import { BaseWidget } from './base-widget';
import { HeaderConfig } from './models/header-config';
import { WidgetHeaderOptions } from './models/widget-header.options';
import { WidgetTitleInfo } from './models/widget-title.into';
import { WidgetService } from './services/widget.service';

export const widgetIcons = ['closeBtn', 'maxBtn', 'cascadeBtn'];

@Directive()
export class ApplicationBaseWidgetComponent extends BaseWidget {
    @Input() dashboardId: string | number = 0;
    @Input() isManagingWidget = false;
    @Input() widgetOnBoard: WidgetOnBoard | undefined;
    @Input() useNewLegend = false;
    @Input() fieldMetadata: MetadataLookup = {};

    @Output() isDataLoadedToggleEvent = new EventEmitter<boolean>();

    @ViewChild('fastnessCheckTemplate', { static: true }) fastnessCheckTemplate: TemplateRef<string> | undefined;

    filters: DashboardClientQueryParam = {};
    widgetPrefs: AppWidgetState | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[] | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    compareData: any[] | undefined;
    headers: WidgetHeaderOptions[] = [];
    isMaximized = false;
    isErrorOccurred = false;
    isLoaderCancelled = false;
    isDataLoaded = false;
    isAccessRestricted = false;
    isVizRendering = false;
    selectedSlicer: ConfigItem | undefined;
    selectedVizForm: VisualizationType | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exception: { message?: unknown, exception?: any, clientFacingMessage?: any } | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentData: any[] | undefined;
    isStackedQuery: boolean | undefined;

    protected isWidgetPreferenceFetched = false;

    private clientFilterSubscription: Subscription | undefined;
    private datasetSubscription: Subscription | undefined;
    private hasCustomFilter = false;
    private scrollPosition: number = 0;

    constructor(
        protected cdr: ChangeDetectorRef,
        protected clientDatasetFilterService: ClientDatasetFilterService,
        protected datasetManagerService: DatasetManagerService,
        protected layoutService: LayoutService,
        protected metadataService: MetadataService,
        protected modalService: ModalDialogService,
        protected widgetService: WidgetService,
        protected workspaceManager: ManagerService,
        protected readonly queryParamsService: QueryParamsService,
    ) {
        super();
    }

    // this is only necessary because of dynamic components;
    updateFieldMetadata(fieldMetadata: MetadataLookup): void {
        this.fieldMetadata = fieldMetadata;
    }

    get inEditMode(): boolean {
        return this.layoutService.getWorkspaceMode() === MODE.EDIT_WORKSPACE;
    }

    get showLoader(): boolean {
        return !(this.isDataLoaded || this.isLoaderCancelled || this.isErrorOccurred);
    }

    get displayHIErrorMessage(): boolean {
        return this.exception?.message === HI_DATA_CLIENT_ERROR_MESSAGE || this.exception?.message === HI_DATA_USER_ERROR_MESSAGE;
    }

    toggleUseNewLegend(useNewLegend: boolean): void {
        this.useNewLegend = useNewLegend;
    }

    override widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    override widgetLifeCycleCallBack(_eventName: WidgetLifecycleEvent, _data: WidgetLifeCycleData): void {
        // this is overridden in subclasses
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    override widgetLifeCyclePostProcess(eventName: WidgetLifecycleEvent, data: any): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE:
                this.isMaximized = true;
                this.maximizeToViewArea();
                break;
            case WIDGET_LIFECYCLE_EVENT.WIDGET_PASSIVE_MODE:
                this.toggleDashboardStyle(true);
                break;
            case WIDGET_LIFECYCLE_EVENT.WIDGET_ACTIVE_MODE:
                this.toggleDashboardStyle(false);
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE:
                this.isMaximized = false;
                this.restoreMaximize();
                break;
            case WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION:
                this.onModeChange(data);
                break;
            case WIDGET_LIFECYCLE_EVENT.WIDGET_SELECTED:
                this.onWidgetSelection(data);
        }
    }

    onInterWidgetCommunication(data: WidgetLifecycleEvent): void {
        if (!data) {
            return;
        }
        if (data === WIDGET_LIFECYCLE_EVENT.HIDE_CANCEL) {
            this.isVizRendering = true;
        } else if (data === WIDGET_LIFECYCLE_EVENT.LOADING_DATA) {
            this.isDataLoaded = false;
        } else if (data === WIDGET_LIFECYCLE_EVENT.DATA_LOADING_CANCELLED) {
            this.enableMenuIcons();
            if (this.hasCustomFilter) {
                this.workspaceManager.disableMenuIconsForWidget(this.id, ['custom-filter']);
            }
            this.isLoaderCancelled = true;
        } else if (isLifecycleErrorAction(data)) {
            this.enableMenuIcons();
            this.isErrorOccurred = true;
            this.exception = data.exception || data.clientFacingMessage;
        } else if (this.widgetPrefs && isMasterWidgetResetAction(data) && data.masterWidgetId !== this.widgetPrefs.id) {
            this.widgetPrefs.isMaster = false;
            this.widgetService.updateIsMasterOnWidgetExtraParams(this.widgetPrefs.id ?? 0, this.widgetPrefs.isMaster);
        } else if (isLifecycleAction(data) && data.action === WIDGET_LIFECYCLE_EVENT.RESET_WIDGET) {
            this.isDataLoaded = true;
        }
    }

    onModeChange(data: string): void {
        if (data === 'onModeChange' && this.id) {
            if (this.widgetPrefs &&
                !this.inEditMode &&
                this.isWidgetPreferenceFetched &&
                !this.widgetPrefs.isSubscribedToDashboardFilters
            ) {
                this.datasetManagerService.updateViewWidgetQueryParams(this.widgetPrefs);
            }
            this.displayIcons();
        }
    }

    displayIcons(shouldDisableIcons = true): void {
        this.hideAllIcons();
        const visibleIcons = [];
        if (this.inEditMode && this.dashboardId !== MANAGE_WIDGET_WS_ID) {
            visibleIcons.push('closeBtn');
            visibleIcons.push(this.getMaximizeIcon());
            visibleIcons.push(...this.getHeaderIconsOtherThanGivenMode(MODE.VIEW));
        } else {
            visibleIcons.push(this.getMaximizeIcon());
            visibleIcons.push(...this.getHeaderIconsOtherThanGivenMode(MODE.EDIT_WORKSPACE));
        }
        this.workspaceManager.showMenuOptionForWidget(this.id, visibleIcons);
        if (shouldDisableIcons && this.showLoader) {
            this.disableMenuIcons();
        }
    }

    getHeaderIconsOtherThanGivenMode(mode: TMode): string[] {
        return this.headers.reduce((selectors: string[], header) => {
            if (header.mode !== mode) {
                // The original intent was that only VIEW vs. EDIT mode matter for deciding if something was hidden.
                // That's no longer the case, but this code is too spaghetti to unwind.  This makes sure the viz
                // switcher icon is only shown if there is another viz to switch to.
                if (header.option !== 'visBtn' || header.menuItems!.length > 1) {
                    selectors.push(header.selector);
                }
            }
            return selectors;
        }, []);
    }

    hideIcons(icons: string[]): void {
        if (icons) {
            this.workspaceManager.hideMenuOptionForWidget(this.id, icons);
        }
    }

    showIcons(icons: string[]): void {
        this.workspaceManager.showMenuOptionForWidget(this.id, icons);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onWidgetSelection(event: any): void {
        if (event.currentTarget.className.indexOf('widget-selected') !== -1
            && event.target.className.toString().indexOf('titleBar') !== -1) {
            this.workspaceManager.unselectWidget(this.id);
        }
    }

    maximizeToViewArea(): void {
        const currentDashboard = this.getDashboard();
        if (!currentDashboard) {
            return;
        }

        this.workspaceManager.resizeWidget(
            this.id,
            currentDashboard.getBoundingClientRect().width,
            currentDashboard.getBoundingClientRect().height);
        this.scrollPosition = currentDashboard.scrollTop;
        currentDashboard.scrollTop = 0;
        currentDashboard.style.overflowY = 'hidden';
    }

    toggleDashboardStyle(isPassiveMode: boolean): void {
        if (this.isMaximized) {
            const currentDashboard = this.getDashboard();
            if (isPassiveMode) {
                currentDashboard?.setAttribute('style', 'overflow-x: hidden;overflow-y:auto');
            } else {
                currentDashboard?.setAttribute('style', 'overflow-y:hidden');
            }
        }
    }

    restoreMaximize(): void {
        const currentDashboard = this.getDashboard();
        if (!currentDashboard) {
            return;
        }
        currentDashboard.scrollTop = this.scrollPosition;
        currentDashboard.style.overflowY = 'auto';
        currentDashboard.style.overflowX = 'hidden';
    }

    getWorkspace(): Element | undefined{
        const layoutHandler = this.workspaceManager.getWorkspaceLayoutHandler() as CustomLayoutHandler;
        return layoutHandler.dashboardContainer?.querySelector('.workspace') ?? undefined;
    }

    getDashboard(): HTMLElement | undefined {
        const layoutHandler = this.workspaceManager.getWorkspaceLayoutHandler() as CustomLayoutHandler;
        return layoutHandler.dashboardContainer;
    }

    getWidget(): Element | undefined{
        const layoutHandler = this.workspaceManager.getWorkspaceLayoutHandler() as CustomLayoutHandler;
        return layoutHandler.dashboardContainer?.querySelector('.widget-selected') ?? undefined;
    }

    getHeaders(headerConfig: HeaderConfig): WidgetHeaderOptions[] {
        const headerList: WidgetHeaderOptions[] = [
            {
                iconName: 'dots-vertical',
                option: 'menuBtn',
                title: 'Widget Properties',
                selector: 'menuBtn',
                hidden: !!headerConfig.hideMenu,
                mode: undefined,
                menuItems: [],
            },
        ];

        if (headerConfig.settingsOverridesMenu) {
            headerList.unshift({
                iconName: 'cog-outline',
                option: 'settingsOverrides',
                title: 'Widget Settings Overrides',
                selector: 'settingsOverridesBtn',
                hidden: false,
                mode: undefined,
                menuItems: [],
            });
        }

        if (headerConfig.hasViz) {
            headerList.unshift({
                option: 'visBtn',
                title: 'Change Visualization',
                selector: 'visBtn',
                styleClasses: 'visBtn',
                mode: MODE.VIEW,
                hidden: true,
                menuItems: headerConfig.vizMenuItems || [],
            });
        }

        if (headerConfig.hasCustomFilter) {
            this.hasCustomFilter = true;
            headerList.unshift({
                iconName: 'chevron-down2',
                option: 'custom-filter',
                selector: 'custom-filter',
                title: 'Filter By',
                hidden: false,
                mode: MODE.VIEW,
                menuItems: [],
            });
        }

        return headerList;
    }

    hideAllIcons(): void {
        const allIcons = widgetIcons.concat(
            this.headers.map((item) => item.selector),
        );
        this.workspaceManager.hideMenuOptionForWidget(this.id, allIcons);
    }

    getMaximizeIcon(): 'cascadeBtn' | 'maxBtn' {
        const widget = this.workspaceManager.getWidgetState(this.id);
        return widget?.maximized ? 'cascadeBtn' : 'maxBtn';
    }

    updateWidgetTitle(info?: AppWidgetState | WidgetTitleInfo): void {
        this.workspaceManager.updateWidgetTitle(this.id, this.getWidgetTitle(info));
    }

    getWidgetDisplayName(userPrefs: AppWidgetState): string | undefined {
        return userPrefs.displayNameType === 'CUSTOM' ? userPrefs.customDisplayName : userPrefs.name;
    }

    shouldWidgetReload(): boolean {
        return !!this.workspaceManager.getWidgetReload(this.id);
    }

    setWidgetReloaded(): void {
        const widgetPref = this.workspaceManager.getWidgetPreferences(this.id);
        if (widgetPref) {
            this.workspaceManager.setWidgetExtraPreferences(this.id, widgetPref);
        }
        this.workspaceManager.setWidgetExtraReload(this.id, false);
    }

    getErrorMessage(): string {
        if (this.isAccessRestricted) {
            return ACCESS_RESTRICTED_MESSAGE;
        }
        if (this.showLoader) {
            return LOADER_MESSAGE;
        }
        if (this.isLoaderCancelled) {
            return LOADER_CANCELLED_MESSAGE;
        }
        return '';
    }

    getHIErrorMessage(): string {
        return this.exception?.message === HI_DATA_CLIENT_ERROR_MESSAGE ? HI_DATA_CLIENT_ERROR_MESSAGE : HI_DATA_USER_ERROR_MESSAGE;
    }

    getHIAdditionalMessage(): string {
        return HI_DATA_ADDITIONAL_MESSAGE;
    }

    shouldDisplayMessage(): boolean {
        return this.isAccessRestricted || this.isLoaderCancelled;
    }

    shouldDisplayVisualization(): boolean {
        return !this.isAccessRestricted
            && !this.isLoaderCancelled && !this.isErrorOccurred;
    }

    onDataLoading(): void {
        if (!this.widgetPrefs?.realtimeUpdates ||
            (this.widgetPrefs?.realtimeUpdates && !this.widgetPrefs?.hideLoaderAfterFirstDataLoad)) {
            this.disableMenuIcons();
        }
        this.isDataLoaded = false;
        this.isLoaderCancelled = false;
        this.isVizRendering = false;
        this.isErrorOccurred = false;
        this.cdr.detectChanges();
    }

    fetchMetaData(): void {
        this.metadataService.getMetadata(new BoardWidgetDefinitionIds(undefined, this.id, this.widgetPrefs?.datasetDefinition?.id));
    }

    /*
        Called:
            - When ever the widget is toggled between subscribed/unsubscribed
            - VizWidgetComponent: When active vs. range is toggled
            - VizWidgetComponent: WLCB WIDGET_LIFECYCLE_EVENTS.WIDGET_ACTIVE_MODE and !needsReload and isLoaded
                - need to track this back to its source
            - VizWidgetComponent: When sortBasedOn or sortAscending is changed
                - Why?
            - VizWidgetComponent: When updateWidgetPreferences is called
                - Which is only called by fetchWidgetPreferences
                    - Which is only called:
                        - When activeMode is changed ****** (see above on WLCB)
                            - and not reload and not already loaded
                        - onWidgetPreferencesChanged
                            - only when activeMode changes and reload is needed
                        - WLCB WIDGET_LIFECYCLE_EVENTS.INIT_WIDGET and !isDetatched
                    - Inside of that method, it only happens:
                        - When they are loaded from the backend
                        - When we already have them
            - LiveDemoWidget: WLCB WIDGET_LIFECYCLE_EVENTS.WIDGET_ACTIVE_MODE
            - LiveDemoWidget: onInterWidgetCommunication
                - When DATA != LOADING_DATA and data.action = UPDATE_DATASET and datasetDefinition id has changed
     */
    fetchDataset(): void {
        this.isDataLoaded = false;
        this.isDataLoadedToggleEvent.emit(this.isDataLoaded);
        this.fetchClientFilter();
        if (this.datasetSubscription) {
            this.datasetSubscription.unsubscribe();
        }

        const widgetPrefs = this.widgetPrefs;
        if (widgetPrefs) {
            this.datasetSubscription = this.datasetManagerService.getDataSet(widgetPrefs, this.fastnessCheckTemplate)
                .subscribe((dataParams) => {
                    const { data, compareData, compareMode, isLoaded } = dataParams;
                    this.data = data;
                    if (compareData) {
                        this.compareData = compareData;
                    }
                    if (isLoaded) {
                        if (!widgetPrefs.realtimeUpdates ||
                            widgetPrefs.realtimeUpdates &&
                            (!widgetPrefs.hideLoaderAfterFirstDataLoad || !deepCompare(this.currentData, data))) {
                            this.widgetLifeCycleCallBack(
                                WIDGET_LIFECYCLE_EVENT.DATA_UPDATE,
                                {
                                    data: data ?? [],
                                    compareData,
                                    filters: this.filters,
                                    widgetPrefs,
                                    compareMode,
                                },
                            );
                        }

                        this.isDataLoaded = true;
                        this.isDataLoadedToggleEvent.emit(this.isDataLoaded);

                        if (widgetPrefs.realtimeUpdates) {
                            this.currentData = data;
                            widgetPrefs.hideLoaderAfterFirstDataLoad = true;
                        }
                    } else if (!widgetPrefs.datasetDefinition?.id) {
                        this.isDataLoaded = true;
                        this.isDataLoadedToggleEvent.emit(this.isDataLoaded);
                    }
                });
        }
    }

    fetchClientFilter(): void {
        let widgetPrefs = this.widgetPrefs;
        if (!widgetPrefs) {
            return console.error('Cannot fetchClientFilter without widgetPrefs');
        }

        const clientFilterObserver = this.clientDatasetFilterService.fetchFilterParams(widgetPrefs);
        if (this.clientFilterSubscription) {
            this.clientFilterSubscription.unsubscribe();
        }
        this.clientFilterSubscription = clientFilterObserver.subscribe((filterParams) => {
            let isFiltersChanged = false;
            widgetPrefs = this.workspaceManager.getWidgetPreferences(this.id) ?? undefined;
            if (widgetPrefs?.isMaster) {
                const advFilters = deepClone(filterParams ?? {});
                advFilters.filters = (advFilters.filters ?? []).filter((item) => !item.isMasterWidgetFilter);
                isFiltersChanged = !deepCompare(this.filters, advFilters);
                this.filters = advFilters;
            } else {
                this.filters = deepClone(filterParams ?? {});
                isFiltersChanged = true;
            }
            if (!filterParams?.isDateChanged && isFiltersChanged) {
                this.updateComponentData();
            }
        });
    }

    updateComponentData(): void {
        if (this.data) {
            const filters = this.isStackedQuery ? deepClone({ ...this.filters, activeDate: null }) : this.filters;
            this.widgetLifeCycleCallBack(
                WIDGET_LIFECYCLE_EVENT.DATA_UPDATE,
                { data: this.data, compareData: this.compareData, filters, widgetPrefs: this.widgetPrefs });
        }
    }

    removeDataset(): void {
        if (!this.isWidgetPreferenceFetched && this.id !== MANAGE_WIDGET_ID) {
            return;
        }
        this.removeSubscriptions();
        this.metadataService.removeMetadata(this.id);
        if (this.widgetPrefs) {
            this.datasetManagerService.removeDataset(this.widgetPrefs);
        }
    }

    removeSubscriptions(): void {
        if (this.datasetSubscription) {
            this.datasetSubscription.unsubscribe();
        }
        if (this.clientFilterSubscription) {
            this.clientFilterSubscription.unsubscribe();
        }
    }

    disableMenuIcons(): void {
        this.workspaceManager.disableAllMenuIconsForWidget(this.id);
    }

    enableMenuIcons(): void {
        this.workspaceManager.enableAllMenuIconsForWidget(this.id);
    }

    private getWidgetTitle(info?: AppWidgetState | WidgetTitleInfo): string {
        if (!this.widgetPrefs) {
            return '';
        }

        if (info) {
            this.widgetPrefs.name = info.name;
            this.widgetPrefs.displayNameType = info.displayNameType;
            this.widgetPrefs.customDisplayName = info.customDisplayName;
        }

        switch (this.widgetPrefs.displayNameType) {
            case 'AUTO':
                return this.getWidgetAutoName();
            case 'CUSTOM':
                return this.widgetPrefs.customDisplayName ?? '';
            default:
                return this.widgetPrefs.name ?? '';
        }
    }

    private getWidgetAutoName(): string {
        const vizConfigs = this.getUserPreferenceForSelectedViz() || this.widgetPrefs?.visualizationConfigs[0];
        if (!vizConfigs) {
            return this.widgetPrefs?.name ?? '';
        }

        const vizType = vizConfigs.visualizationType;
        const defaultSlicer = this.selectedSlicer || getDefault(vizConfigs.configs?.slicers || []);

        if (vizType === 'SIMPLE_GRID' || vizType === 'ADVANCED_GRID') {
            return this.widgetPrefs?.name ?? '';
        }
        if (vizType === 'HORIZONTAL_STACKED_BAR_CHART' || vizType === 'VERTICAL_STACKED_BAR_CHART') {
            return this.getStackedBarChartWidgetName(vizConfigs, defaultSlicer);
        }
        if (vizType === 'PIE_CHART' || vizType === 'DONUT_CHART' || vizType === 'VERTICAL_MIRRORED_BAR_CHART' ||
            vizType === 'HORIZONTAL_BAR_CHART' || vizType === 'VERTICAL_BAR_CHART') {
            return this.getRegularChartWidgetName(vizConfigs, defaultSlicer);
        }
        if (vizType === 'LINE_CHART') {
            return this.getLineChartWidgetName(vizConfigs, defaultSlicer);
        }
        if (vizType === 'STACKED_AREA_CHART') {
            return this.getStackedAreaChartWidgetName(vizConfigs, defaultSlicer);
        }

        return '';
    }

    private getStackedBarChartWidgetName(vizConfigs: VisualizationConfigs, defaultSlicer?: ConfigItem): string {
        if (defaultSlicer) {
            const value = vizConfigs.configs?.values[0];
            const detail = vizConfigs.configs?.details?.[0];
            return `${this.getConfigItemAutoName(value)} by ${this.getConfigItemAutoName(defaultSlicer)} by ${this.getConfigItemAutoName(detail)}`;
        }
        return this.widgetPrefs?.name ?? '';
    }

    private getRegularChartWidgetName(vizConfigs: VisualizationConfigs, defaultSlicer?: ConfigItem): string {
        if (defaultSlicer) {
            const value = vizConfigs.configs?.values[0];
            return `${this.getConfigItemAutoName(value)} by ${this.getConfigItemAutoName(defaultSlicer)}`;
        }
        return this.widgetPrefs?.name ?? '';
    }

    private getLineChartWidgetName(vizConfigs: VisualizationConfigs, defaultSlicer?: ConfigItem): string {
        const values = vizConfigs.configs?.values;
        const axisTime = vizConfigs.configs?.axisTimes?.[0];
        if (values?.length === 2) {
            return `${this.getConfigItemAutoName(values[0])} by ${this.getConfigItemAutoName(values[1])} by ${this.getConfigItemAutoName(axisTime)}`;
        }
        if (defaultSlicer) {
            return `${this.getConfigItemAutoName(values?.[0])} by ${this.getConfigItemAutoName(defaultSlicer)} by ${this.getConfigItemAutoName(axisTime)}`;
        }
        return this.widgetPrefs?.name ?? '';
    }

    private getStackedAreaChartWidgetName(vizConfigs: VisualizationConfigs, defaultSlicer?: ConfigItem): string {
        const value = vizConfigs.configs?.values[0];
        const axisTime = vizConfigs.configs?.axisTimes?.[0];
        return `${this.getConfigItemAutoName(value)} by ${this.getConfigItemAutoName(defaultSlicer)} by ${this.getConfigItemAutoName(axisTime)}`;
    }

    private getConfigItemAutoName(item?: ConfigItem): string {
        return (item?.showCustomName ? item.customName : item?.label) ?? '';
    }

    protected getUserPreferenceForSelectedViz(): VisualizationConfigs | undefined {
        return this.widgetPrefs?.visualizationConfigs.find((item) => item.visualizationType === this.selectedVizForm);
    }

    onWidgetSettingOverridesChanged(updatedWidget: AppWidgetState, changes: WidgetSettingsOverrides): void {
        if (changes.displayNameType || changes.customName) {
            this.updateWidgetTitle(updatedWidget);
        }

        if (changes.masterWidget) {
            this.widgetService.updateIsMasterOnWidgetExtraParams(updatedWidget.id ?? 0, changes.masterWidget);

            if (!changes.masterWidget) {
                if (Object.prototype.hasOwnProperty.call(changes, 'subscribed') && changes.subscribedToDashboardFilters) {
                    this.onBoardFilterSubscriptionChanged(updatedWidget);
                }

                this.workspaceManager.sendMessageToAllWidgetsOnWorkspace(
                    updatedWidget.id ?? 0,
                    { action: WIDGET_LIFECYCLE_EVENT.RESET_MASTER_WIDGET, masterWidgetId: updatedWidget.id });
            }

            this.queryParamsService.removeAllMasterWidgetFilters();
            this.onMasterChanged(changes.masterWidget);
        }

        if (changes.subscribedToDashboardFilters != null) {
            this.onBoardFilterSubscriptionChanged(updatedWidget);
        }

        if (changes.defaultSortOnForDefaultVisualization || changes.defaultSortDirectionForDefaultVisualization) {
            this.fetchMetaData();
            this.fetchDataset();
        }
    }

    onBoardFilterSubscriptionChanged(updatedWidget: AppWidgetState): void {
        this.datasetManagerService.removeDataset(updatedWidget);
        this.onSubscribeToggle();
    }

    onWidgetSaved(widgetPreference: AppWidgetState): void {
        this.updateWidgetTitle(widgetPreference);
    }

    onSubscribeToggle(): void {
        this.isLoaderCancelled = false;
        this.widgetPrefs = this.workspaceManager.getWidgetPreferences(this.id) ?? undefined;

        if (this.widgetPrefs && !this.widgetPrefs.isSubscribedToDashboardFilters && this.widgetOnBoard) {
            this.widgetPrefs.widgetFilters = this.widgetOnBoard.filters ?
                JSON.parse(this.widgetOnBoard.filters) :
                this.widgetPrefs.widgetFilters;
        }

        this.fetchDataset();
    }

    onHeaderMenuSelect(event: string): void {
        const workspaceRect = this.getWorkspace()?.getBoundingClientRect();
        const widgetRect = this.getWidget()?.getBoundingClientRect();
        const menuElement = this.getWidget()?.querySelector(`.${event}Menu`);
        const menuBoundRect = menuElement?.getBoundingClientRect();
        if (menuBoundRect && widgetRect && workspaceRect && menuBoundRect.left < widgetRect.left) {
            const leftDiff = widgetRect.left - menuBoundRect.left;
            if (widgetRect.left - workspaceRect.left < leftDiff) {
                menuElement?.setAttribute('style', `left:${widgetRect.left + 20}px`);
            }
        }
    }

    showErrorMessage(): void {
        const exportDialogRef = this.modalService.open(ErrorModalComponent, { windowClass: 'error-popup' });
        exportDialogRef.componentInstance.exception = this.exception;
        exportDialogRef.componentInstance.widgetName = this.widgetPrefs?.name;
    }

    onMasterChanged(_: boolean): void {}
}

function isMasterWidgetResetAction(action: unknown): action is { action: WIDGET_LIFECYCLE_EVENT, masterWidgetId: unknown } {
    return isLifecycleAction(action) && Object.prototype.hasOwnProperty.call(action, 'masterWidgetId');
}
