import { mapStringsToLabelValuePairs } from '@ddv/utils';

import { ColumnAlignment } from './fields/column-alignment';
import { ColumnDateFormat } from './fields/column-date-format';
import { ColumnDisplayType } from './fields/column-display-type';
import { ColumnFontStyle } from './fields/column-font-style';
import { ColumnNegativeValues } from './fields/column-negative-values';
import { DateOccurring } from './fields/date-occurring';
import { FormatOperator } from './fields/format-operator';
import { RankOrder } from './fields/rank-order';
import { LabelValuePair } from './label-value-pair';
import { NumberFormat, NumberUnit } from './numbers';
import { SummaryColumnConfig } from './summary/summary-column-config';
import { ConfigItem } from './visualizations/config-item';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MetadataFormatUtils {
    static getDisplayTypes(displayType: string): LabelValuePair<ColumnDisplayType>[] {
        switch (displayType) {
            case 'date':
                return [
                    { label: 'Date', value: 'date' },
                    { label: 'Text', value: 'string' },
                ];
            case 'string':
                return [
                    { label: 'Text', value: 'string' },
                ];
            default:
                return [
                    { label: 'Value', value: 'value' },
                    { label: 'Text', value: 'string' },
                    { label: 'Bar Chart', value: 'bar' },
                ];
        }
    }

    static getOperators(type: string): { text?: string, key?: string, label: string, value: FormatOperator, showInputRange?: boolean }[] {
        switch (type) {
            case 'date':
                return [
                    ...MetadataFormatUtils.getCommonOperators(),
                    {
                        label: 'A Date Occuring',
                        value: 'ADO',
                    },
                ];
            case 'string':
                return [
                    {
                        label: 'Containing',
                        value: 'CNT',
                    },
                    {
                        label: 'Not Containing',
                        value: 'NCNT',
                    },
                    {
                        label: 'Beginning With',
                        value: 'BEG',
                    },
                    {
                        label: 'Ending With',
                        value: 'END',
                    },
                    {
                        label: 'Equal To',
                        value: 'EQL',
                    },
                    {
                        label: 'Not Equal To',
                        value: 'NEQL',
                    },
                ];
            case 'bar':
                return [
                    {
                        text: 'Auto',
                        label: 'Auto',
                        key: 'Auto',
                        value: 'AUTO',
                    },
                    {
                        text: 'Number',
                        label: 'Number',
                        key: 'Num',
                        value: 'NUM',
                        showInputRange: true,
                    },
                    {
                        text: 'Percentage',
                        label: 'Percentage',
                        key: 'Prct',
                        value: 'PRCT',
                        showInputRange: true,
                    },
                ];
            default:
                return MetadataFormatUtils.getCommonOperators();
        }
    }

    static getNegativeValues(): LabelValuePair<ColumnNegativeValues>[] {
        return [
            {
                label: '-2,374.872',
                value: 'minus',
            },
            {
                label: '-2,374.872',
                value: 'redMinus',
            },
            {
                label: '(2,374.872)',
                value: 'parenthesis',
            },
            {
                label: '(2,374.872)',
                value: 'redParenthesis',
            },
        ];
    }

    static getFonts(): LabelValuePair<ColumnFontStyle>[] {
        return mapStringsToLabelValuePairs<ColumnFontStyle>(['Roman', 'Bold', 'Italic']);
    }

    static getNumberFormats(): LabelValuePair<NumberFormat>[] {
        return ['$', '%', 'XX', 'XX.00', 'XX.00 Trim']
            .map((item) => ({ label: item, value: item as NumberFormat }));
    }

    static getAlignments(): LabelValuePair<ColumnAlignment>[] {
        return mapStringsToLabelValuePairs<ColumnAlignment>(['Left', 'Right', 'Center']);
    }

    static getNumberNotations(): LabelValuePair<NumberUnit>[] {
        return mapStringsToLabelValuePairs<NumberUnit>(['Actual', 'Thousands', 'Millions', 'Billions']);
    }

    static getDefaultColFormatters(): Partial<ConfigItem> {
        return {
            customName: '',
            value: undefined,
            showCustomName: false,
            fontStyle: 'roman',
            numberFormat: 'XX.00',
            decimalPlaces: 2,
            negativeValue: 'parenthesis',
            columnCondition: [],
        };
    }

    static getDefaultSummaryColFormatter(isAttribute: boolean, isDate: boolean, defaultLabel: string = ''): SummaryColumnConfig {
        return {
            label: defaultLabel,
            aggregationType: isAttribute ? (isDate ? 'min' : 'first') : 'sum',
            numberFormat: '$',
            negativeValue: 'parenthesis',
            decimalPlaces: 2,
            numberUnits: 'actual',
        };
    }

    static getDateOccurringValues(): LabelValuePair<DateOccurring>[] {
        // for formatOperator = ADO pass the below value as search value
        return [
            {
                label: ' Yesterday ',
                value: 'YST',
            },
            {
                label: 'Today ',
                value: 'TDY',
            },
            {
                label: 'Tomorrow ',
                value: 'TMW',
            },
            {
                label: 'In the Last 7 days',
                value: 'LSD',
            },
            {
                label: 'Last Week',
                value: 'LWK',
            },
            {
                label: 'This Week',
                value: 'TWK',
            },
            {
                label: 'Next Week',
                value: 'NWK',
            },
            {
                label: 'Last Month',
                value: 'LMT',
            },
            {
                label: 'This Month',
                value: 'TMT',
            },
            {
                label: 'Next Month',
                value: 'NMT',
            },
        ];
    }

    static getRowHeights(): LabelValuePair<number>[] {
        return [
            {
                label: 'Small',
                value: 20,
            },
            {
                label: 'Medium',
                value: 25,
            },
            {
                label: 'Large',
                value: 30,
            },
            {
                label: 'Very Large',
                value: 40,
            },
        ];
    }

    private static getCommonOperators(): { label: string, value: FormatOperator, showInputRange?: boolean }[] {
        return [
            {
                label: 'Greater Than',
                value: 'GT',
            },
            {
                label: 'Less Than',
                value: 'LT',
            },
            {
                label: 'Equal To',
                value: 'EQL',
            },
            {
                label: 'Not Equal To',
                value: 'NEQL',
            },
            {
                label: 'Greater Than or Equal To',
                value: 'GTET',
            },
            {
                label: 'Less Than or Equal To',
                value: 'LTET',
            },
            {
                label: 'Between',
                value: 'BTW',
                showInputRange: true,
            },
            {
                label: 'Not Between',
                value: 'NBTW',
                showInputRange: true,
            },
        ];
    }

    static getRankOrders(): LabelValuePair<RankOrder>[] {
        return [
            {
                label: 'Rank Positive Only',
                value: 'POSITIVE',
            },
            {
                label: 'Rank Positive First',
                value: 'HIGH',
            },
            {
                label: 'Rank Negative Only',
                value: 'NEGATIVE',
            },
            {
                label: 'Rank Negative First',
                value: 'LOW',
            },
        ];
    }

    static getDateFormats(): ColumnDateFormat[] {
        return [
            'MM/DD/YYYY',
            'DD/MM/YYYY',
            'YYYY-MM-DD',
            'MMM YYYY',
            'MM/DD',
            'MMM',
            'DD',
            'QTR',
            'YYYY',
        ];
    }
}
