import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ValueOption } from '@ddv/common-components';
import { INCLUDE, DashboardFilter, ValueFilterOption } from '@ddv/models';
import { DropdownOption as FilterListDropdownOption } from '@hs/ui-core-common';
import { FilterListComponent } from '@hs/ui-core-lists';
import { Subscription } from 'rxjs';

import { DashboardFiltersService } from '../../services/dashboard-filters.service';

@Component({
    selector: 'app-filterable-value',
    templateUrl: 'filterable-value.component.html',
    styleUrls: ['filterable-value.component.scss'],
})
export class FilterableValueComponent implements OnInit, AfterViewInit, OnDestroy {
    selectedCount = 0;
    valueFilterOptions: ValueOption[] = [];
    items: ValueFilterOption[] = [];
    valueFiltersOptionSubscription: Subscription | undefined;

    @Input() selectedDashboardFilter: DashboardFilter | undefined;

    @Input() buttonLabel = 'Apply';
    @Output() addFilteredValues = new EventEmitter<DashboardFilter>();

    @ViewChild('ccFilterList', { static: true }) private readonly ccFilterList: FilterListComponent | undefined;

    protected searchOptions = {
        enabled: true,
        placeholder: 'Item search',
        addSearchValueAsOption: true,
    };
    protected selectedItems: ValueFilterOption[] = [];
    protected selectedOperator: FilterListDropdownOption = { text: 'INCLUDE', value: '+' };

    // This is needed, because up to v17.0.35 of CC cc-filter-list does not have a way
    // to pass an initial value for the selected operator, and also the 'selectedOperatorChange' event emits immediately
    private isOperatorInitialValueSet = false;

    constructor(public dashboardFiltersService: DashboardFiltersService) {}

    ngOnInit(): void {
        this.valueFiltersOptionSubscription = this.dashboardFiltersService.valueFilterOptionsObserver
            .subscribe((options) => {
                const filteredOptions = options
                    .filter((option) => {
                        const text = option.text.toString();

                        return !(text.startsWith('{') && text.endsWith('}')) && text.trim();
                    });
                this.valueFilterOptions = filteredOptions
                    .map((o) => ({
                        texts: this.getTexts(o.value, o.text),
                        value: o.value,
                        checked: !!o.checked,
                    }));
                this.valueFilterOptions.sort((a, b) => this.compareSortOptions(a, b));

                this.setItemsAndSelectedItems();
            });

        this.dashboardFiltersService.updateValueOptions(this.selectedDashboardFilter?.name ?? '');
        this.mergeSelectedValues();

        this.setItemsAndSelectedItems();
    }

    ngAfterViewInit(): void {
        const operator = this.determineSelectedOperator();
        setTimeout(() => {
            // we cast to any because this method (onMenuItemSelected) is protected
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.ccFilterList as any)?.onMenuItemSelected(operator);
            this.isOperatorInitialValueSet = true;
        }, 0);
    }

    ngOnDestroy(): void {
        this.valueFiltersOptionSubscription?.unsubscribe();
    }

    mergeSelectedValues(): void {
        if (!this.selectedDashboardFilter?.values || this.selectedDashboardFilter.values.length === 0) {
            return;
        }

        const selectedValueFilterOptions = this.selectedDashboardFilter.values.map((v) => (
            { texts: this.getTexts(v, v != null ? v.toString() : undefined), value: v, checked: true }
        ));

        selectedValueFilterOptions.forEach((value) => {
            const selectedValueFilterOption = this.valueFilterOptions.find((val) => val.value === value.value);
            if (selectedValueFilterOption) {
                selectedValueFilterOption.checked = true;
            } else {
                this.valueFilterOptions.push(value);
            }

            this.selectedCount += 1;
        });

        this.valueFilterOptions.sort((a, b) => this.compareSortOptions(a, b));
    }

    protected onSelectedOperatorChange(operator: FilterListDropdownOption): void {
        if (!this.selectedDashboardFilter || !this.isOperatorInitialValueSet) {
            return;
        }

        this.selectedDashboardFilter.criteria = (operator.text.toUpperCase() as 'INCLUDE' | 'EXCLUDE') ?? 'INCLUDE';
        this.selectedOperator = {
            text: this.selectedDashboardFilter.criteria,
            value: this.selectedDashboardFilter.criteria === 'INCLUDE' ? '+' : '-',
        };
        this.addFilteredValues.emit(this.selectedDashboardFilter);
    }

    protected onSelectedChoicesChange(choices: FilterListDropdownOption[]): void {
        if (!this.selectedDashboardFilter) {
            return;
        }

        this.selectedDashboardFilter.criteria = this.selectedDashboardFilter?.criteria ?? INCLUDE;
        this.selectedDashboardFilter.values = choices.map((ch) => ch.value);
        this.dashboardFiltersService.totalValueCount[this.selectedDashboardFilter.name ?? ''] = this.valueFilterOptions.length;
        this.addFilteredValues.emit(this.selectedDashboardFilter);
    }

    private getTexts(value: string | number | boolean, text: string = ''): string[] {
        return value == null || (typeof value === 'string' && value.trim() === '') ? ['Blanks'] : [text];
    }

    private getTextForFilterOption(texts: string[]): string {
        return texts.join(' ').trim();
    }

    private compareSortOptions(a: ValueOption, b: ValueOption): number {
        if (a.value == null || a.value === '') {
            return -1;
        } else if (b.value == null || b.value === '') {
            return 1;
        } else if (!isNaN(a.value) && !isNaN(b.value)) {
            return a.value as number - (b.value as number);
        } else {
            return a.value.localeCompare(b.value);
        }
    }

    private setItemsAndSelectedItems(): void {
        this.items = this.valueFilterOptions.map((option) => (
            {
                text: this.getTextForFilterOption(option.texts),
                value: option.value,
                checked: option.checked,
            }
        ));
        this.selectedItems = this.items.filter((option) => option.checked);
    }

    private determineSelectedOperator(): { text: string, value: string } {
        return this.selectedDashboardFilter?.criteria === 'EXCLUDE' ? { text: 'Exclude', value: '-' } : { text: 'Include', value: '+' };
    }
}
