import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    Axis,
    BarChartData,
    ChartData,
    ChartSettings,
    ColorMetadataService,
    getDefaultMargin,
    GridLines,
    hasOwnProperty,
    Highlight,
    Margin,
    Series,
} from '@ddv/charts';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { DatedPublicApiResponseRow, MetadataService } from '@ddv/datasets';
import { ClientDatasetFilterService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    CompareMode,
    ConfigItem,
    DataUpdateBody,
    ExportFilteredData,
    IAxis,
    DatasetMetadata,
    LegendConfig,
    LegendConfigurationService,
    MANAGE_WIDGET_ID,
    MetadataLookup,
    SliceManagement,
    SliceManagementOptions,
    TableSortType,
    VisualizationConfigs,
    VizConfigs,
    WIDGET_LIFECYCLE_EVENT,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
} from '@ddv/models';
import { clone, cloneArray, getDefault } from '@ddv/utils';
import { Theme, ThemeService } from '@hs/ui-core-presentation';
import { Subject } from 'rxjs';

import { buildBaseChartModel, buildMarginCondition, ChartsSharedService } from '../../../base/visualization-wrapper/charts-shared.service';
import { VisualizationComponent } from '../../../base/visualization-wrapper/visualization-component/visualization.component';
import { ILabelValue, VizInfo } from '../../../base/visualization-wrapper/visualization-wrapper.interface';
import { HorizontalBarChartVisualizationDataService } from './horizontal-bar-chart-visualization-data.service';

@Component({
    selector: 'app-hbar-chart-visualization',
    templateUrl: './horizontal-bar-chart-visualization.component.html',
    styleUrls: ['./horizontal-bar-chart-visualization.component.scss'],
    providers: [LegendConfigurationService],
})
export class HorizontalBarChartVisualizationComponent extends MultiSubscriptionComponent implements OnInit {
    @Input() preferences: VisualizationConfigs | undefined;
    // TODO: why should a visualization even know a widget exits?
    @Input() widgetId = 0;
    @Input() vizInfo: VizInfo | undefined;
    @Input() isManagingWidget = false;
    @Input() fieldMetadata: MetadataLookup = {};
    // TODO: this is only passed in so that we can know if the data came in from a "stacked" query.  put that on the data
    @Input() datasetId: number = 0;
    @Input() useNewLegend = false;

    @Output() vizSlicer: Subject<Partial<ConfigItem>> = new Subject();

    protected visualizationModel: ChartSettings<BarChartData> | undefined;

    private xAxis: Axis[] = [];
    @ViewChild('visualizationComponent', { static: true }) vizWrapperComponent: VisualizationComponent | undefined;
    private vizData: DatedPublicApiResponseRow[] = [];
    private yAxis: Axis[] = [];
    private chartSeries: Series[] = [];
    // TODO: filterBy is a stupid name.  its the slicer
    private filterBy: ILabelValue | undefined;
    private margin: Margin = getDefaultMargin();
    private configs: VizConfigs | undefined;
    private legendConfig: LegendConfig | undefined;
    private isChartInitialized = false;
    private isStackedQuery = false;
    private theme: Theme = Theme.light;

    constructor(
        private readonly metadataService: MetadataService,
        protected legendConfigurationService: LegendConfigurationService,
        protected chartsSharedService: ChartsSharedService,
        protected clientDatasetFilterService: ClientDatasetFilterService,
        protected colorMetadataService: ColorMetadataService,
        private readonly manager: ManagerService,
        private readonly dataService: HorizontalBarChartVisualizationDataService,
        private readonly themeService: ThemeService,
        private readonly cdr: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {
        // this is only needed so that the widget export can reach in here and get it
        this.subscribeTo(this.metadataService.metadataState, (_: DatasetMetadata) => {
            this.preferences?.configs?.getFlatList?.().forEach((config) => this.populateConfigWithMetadata(config));
        });

        this.legendConfig = this.legendConfigurationService.getConfigFromUserPreference(this.preferences);
        this.configs = this.preferences?.configs;

        this.isChartInitialized = true;

        this.vizInfo = this.vizInfo ?? {
            slicer: { label: '', value: '' },
            values: [],
        };

        this.subscribeTo(this.themeService.currentTheme$, ((theme: Theme) => {
            this.theme = theme;
            this.setVizSlicer();
        }));

        this.subscribeTo(this.manager.isStackedQuery(this.datasetId), (isStackedQuery) => this.isStackedQuery = isStackedQuery);
    }

    // this is only necessary because of dynamic components
    updateFieldMetadata(fieldMetadata: MetadataLookup): void {
        this.fieldMetadata = fieldMetadata;
        this.cdr.detectChanges();
    }

    // this name is horrible.  this gets called at least once just to get the visualization to render anything and has nothing to do with it being filtered
    onFilterChanged(item: ILabelValue): void {
        this.filterBy = item;
        this.populateChartData(item, this.preferences?.sortTableBy, this.preferences?.tableSortDirection);
    }

    // this is only needed so that the widget export can reach in here and get it
    getMetadata(): MetadataLookup | undefined {
        return this.fieldMetadata;
    }

    // this is only needed so that the widget export can reach in here and get it
    getExportFilteredData(): ExportFilteredData | undefined {
        // TODO: pull the export formatting code up to here.  The data that is used to do that is provided by this component so is safe to move
        return this.vizWrapperComponent?.getExportFilteredData();
    }

    // this is only needed so that the widget export can reach in here and get it
    getExportFullData(): ExportFilteredData | undefined {
        // TODO: pull the export formatting code up to here.  The data that is used to do that is provided by this component so is safe to move
        return this.vizWrapperComponent?.getExportFullData();
    }

    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data: WidgetLifecycleEvent): void;
    widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        data: DataUpdateBody | WidgetLifecycleEvent | WidgetLifeCycleData,
    ): void {
        switch (eventName) {
            // TODO: make this an @Input and throw in a CDR
            case WIDGET_LIFECYCLE_EVENT.LOADING_DATA:
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = true;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                this.updateDataSource(data as Required<DataUpdateBody>);
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = false;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.VISUALIZATION_SELECTED:
                this.updateChartSelection(data);
                break;
        }
    }

    widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.INIT_WIDGET:
            case WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE:
                this.vizWrapperComponent?.resizeVisualization();
                break;
            // TODO: replace onMaximize/onRestore with an @Input for the "state" and wire it to OnChanges
            case WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE:
                this.vizWrapperComponent?.onMaximize();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE:
                this.vizWrapperComponent?.onRestore();
                break;
        }
    }

    private getCurrentSortOrderSlicer(value?: string): ConfigItem | undefined {
        const slicers = this.preferences?.configs?.slicers ?? [];
        if (this.isManageWidgetMode() && !value) {
            return slicers.find((slicer) => slicer.value === this.preferences?.sortOrderSlicer);
        }

        const defaultSlicer = this.getDefaultSlicer(value, slicers);
        if (defaultSlicer) {
            return defaultSlicer;
        }

        return slicers[0];
    }

    private isManageWidgetMode(): boolean {
        return this.widgetId === MANAGE_WIDGET_ID;
    }

    private getDefaultSlicer(value?: string, slicers?: ConfigItem[]): ConfigItem | undefined {
        if (value) {
            return slicers?.length ? slicers.find((slicer) => slicer.value === value) : undefined;
        }
        return slicers?.length ? slicers.find((slicer) => slicer.isDefault) : undefined;
    }

    private setVizSlicer(): void {
        const slicer = this.vizInfo?.slicer;
        if (slicer?.label) {
            this.setSlicerAttributes(slicer);
            this.vizSlicer.next(slicer);
        } else {
            const slicers = this.configs?.slicers ?? [];
            const selectedSlicer = getDefault(slicers, { colId: '', label: '', value: '' } as ConfigItem);
            this.vizSlicer.next({
                label: selectedSlicer?.label,
                value: selectedSlicer?.value,
                showCustomName: selectedSlicer?.showCustomName,
                customName: selectedSlicer?.customName,
                colorSortBy: selectedSlicer?.colorSortBy,
                colorSortDirection: selectedSlicer?.colorSortDirection,
            });
        }
    }

    private setSlicerAttributes(slicer: Partial<ConfigItem>): void {
        this.configs?.slicers.forEach((s) => {
            if (this.isDifferentSlicerName(slicer, s)) {
                slicer.showCustomName = s.showCustomName;
                slicer.customName = s.customName;
                slicer.colorSortBy = s.colorSortBy;
                slicer.colorSortDirection = s.colorSortDirection;
            }
        });
    }

    private isDifferentSlicerName(slicer: Partial<ConfigItem>, configSlicer: ConfigItem): boolean {
        return slicer.value === configSlicer.value &&
            (slicer.customName !== configSlicer.customName || slicer.showCustomName !== configSlicer.showCustomName);
    }

    private updateDataSource(componentData: Required<DataUpdateBody>): void {
        const widgetFilters = componentData?.widgetPrefs?.widgetFilters ?? componentData.filters;
        this.vizData = this.getVizData(componentData);

        let sortTableBy = this.preferences?.sortTableBy;
        let tableSortDirection = this.preferences?.tableSortDirection;
        if (this.visualizationModel) {
            if (componentData.widgetPrefs && !this.isManagingWidget) {
                sortTableBy = componentData.widgetPrefs.sortTableBy ?? sortTableBy;
                tableSortDirection = componentData.widgetPrefs.tableSortDirection;
                if (this.isChartInitialized) {
                    this.visualizationModel.formatter = clone(this.visualizationModel.formatter, {
                        sortDirection: componentData.widgetPrefs.tableSortDirection,
                    });
                }
            }

            if (this.isChartInitialized && !this.chartSeries[0].stacked) {
                const sortOrderSlicer = this.getCurrentSortOrderSlicer(this.vizInfo?.slicer?.value);
                const sliceManagementOptions = this.getSliceManagementOptions(this.preferences);
                // const yField = chartSeries[0].horizontal ? chartSeries[0].xField[0] : chartSeries[0].yField[0];
                const valueField = this.chartSeries[0].xField[0];

                const barChartData = this.dataService.getData(
                    this.vizData,
                    this.filterBy?.value ?? '',
                    valueField,
                    this.preferences?.configs?.values?.[0].aggregationType,
                    sortTableBy,
                    tableSortDirection,
                    sortOrderSlicer,
                    sliceManagementOptions);

                if (!widgetFilters?.isComparing) {
                    this.visualizationModel = clone(this.visualizationModel, {
                        dataSource: barChartData,
                        dataCompareSource: null,
                    });
                } else {
                    if (componentData.compareMode === CompareMode.ORIGINAL || !componentData.compareMode) {
                        this.visualizationModel = clone(this.visualizationModel, {
                            dataSource: barChartData,
                        });
                    } else if (componentData.compareMode === CompareMode.COMPARED) {
                        this.visualizationModel = clone(this.visualizationModel, {
                            dataCompareSource: barChartData,
                        });
                    }
                }
            }
        }
    }

    private prepareChartModel(): void {
        this.initChartModel();

        const enableSliceManagement = this.preferences?.enableSliceManagement;
        if (enableSliceManagement) {
            this.setVisualizationModelSliceManagement(enableSliceManagement);
        }

        this.setVisualizationModelFormatter();

        if (this.preferences?.inChartLabel) {
            this.setVisualizationModelCurveValues();
        }

        // this check is largely for manage widgets where the may be a render before there are slicers selected
        if (this.preferences?.configs?.slicers) {
            this.setVisualizationModelColorRange();
        }
    }

    private setVisualizationModelColorRange(): void {
        if (!this.visualizationModel || !this.preferences) {
            return;
        }

        const colorRange = this.chartsSharedService.getColorRange(
            this.preferences,
            this.colorMetadataService,
            this.filterBy?.value ?? '',
            this.theme);

        if (colorRange?.length) {
            this.visualizationModel.colorRange = colorRange;
        }

        this.visualizationModel.attributeCustomColors = this.chartsSharedService.getAttributeCustomColors(
            this.preferences,
            this.filterBy?.value ?? '',
            this.theme);

        if ((this.visualizationModel.colorRange?.length ?? 0) > 3 && this.visualizationModel.attributeCustomColors?.length) {
            this.visualizationModel.colorRange = this.chartsSharedService.getFilteredColorRange(this.visualizationModel);
        }
    }

    private populateChartData(
        slicer: ILabelValue | undefined,
        sortOn: TableSortType | undefined,
        sortDirection: string | undefined,
        comparingMethod: string = CompareMode.ORIGINAL,
    ): void {
        if (!this.vizInfo) {
            return console.error('cannot populateChartData without a vizInfo');
        }

        this.vizInfo.slicer.value = slicer?.value;
        this.vizInfo.slicer.label = slicer?.label;
        this.vizInfo.slicer.showCustomName = slicer?.showCustomName;
        this.vizInfo.slicer.customName = slicer?.customName;
        this.vizInfo.slicer.colorSortBy = slicer?.colorSortBy;
        this.vizInfo.slicer.colorSortDirection = slicer?.colorSortDirection;

        this.configureChart();
        this.vizInfo.values = cloneArray(this.preferences?.configs?.values ?? []);
        const sortOrderSlicer = this.getCurrentSortOrderSlicer(this.vizInfo?.slicer.value);

        if (!this.chartSeries[0].stacked) {
            this.populateBarChartData(
                slicer?.value ?? '',
                sortOn,
                sortDirection,
                comparingMethod,
                sortOrderSlicer);
        }
    }

    private configureChart(): void {
        const xField = this.configs?.values[0].value;
        const yField = this.vizInfo?.slicer.value;
        this.yAxis = [
            {
                type: 'string',
                position: 'left',
                field: [yField ?? ''],
                hide: false,
                nTicks: 10,
                customClass: '',
                domain: [],
            },
        ];

        this.xAxis = [
            {
                type: 'numeric',
                position: 'bottom',
                field: [xField ?? ''],
                hide: false,
                domain: [],
            },
        ];

        this.chartSeries = [
            {
                type: 'bar',
                stacked: false,
                horizontal: true,
                // tooltipHTML: this.getToolTip.bind(this),
                xField: [xField ?? ''],
                yField: [yField ?? ''],
            },
        ];

        this.margin = this.getBarMargin();
        const axisConfig = this.preferences?.axisConfigurations;
        this.updateAxisConfig(this.xAxis[0], axisConfig?.[0]);
        this.updateAxisConfig(this.yAxis[0], axisConfig?.[1]);
        this.prepareChartModel();
    }

    private updateAxisConfig(axis: Axis, axisConfig?: IAxis): void {
        if (!axisConfig) {
            return console.error('cannot updateAxisConfig without an axisConfig');
        }

        axis.rotate = 0;
        axis.position = axisConfig.position;
        if (axisConfig.enableAxis) {
            axis.customClass = axisConfig.enableAxisLine ? '' : 'show-only-tick-labels';
            if (!axisConfig.displayAxisLabels) {
                axis.nTicks = 0;
            }
        } else {
            axis.customClass = 'hide';
        }

        // // this SHOULD never happen for HBar
        // if (axisConfig.dataRangeMode === DataRange.MANUAL) {
        //     let minDataRange = Number(axisConfig.minDataRange) || 0;
        //     minDataRange = this.chartSeries[0].mirror && minDataRange > 0 ? minDataRange * -1 : minDataRange;
        //     let maxDataRange = Number(axisConfig.maxDataRange) || 0;
        //     maxDataRange = this.chartSeries[0].mirror ? Math.abs(maxDataRange) : maxDataRange;
        //     axis.domain = [minDataRange, maxDataRange];
        // }
    }

    private getBarMargin(): Margin {
        const yAxis = this.preferences?.axisConfigurations?.[1];
        if (!yAxis) {
            throw new Error('cannot getBarMargin without a yAxis');
        }

        const marginCondition = buildMarginCondition(
            this.preferences?.enableLegend ? this.preferences.legendPosition : undefined,
            yAxis?.position,
            yAxis?.enableAxis,
            yAxis?.displayAxisLabels);

        const marginBottom = 30;

        return {
            top: marginCondition.legendPosition !== 'top' ? 20 : 30,
            right: marginCondition.rightSideAxisFlag ? 15 : 35,
            bottom: marginBottom,
            left: marginCondition.leftSideAxisFlag ? 5 : 25,
        };
    }

    private getGridLinesConfig(): GridLines {
        const chartSeries: Series = this.chartSeries[0];
        return {
            x: {
                hide: !chartSeries.horizontal,
                showLabels: false,
                nTicks: 10,
                customClass: this.preferences?.gridLineStyle,
            },
            y: {
                hide: chartSeries.horizontal,
                showLabels: false,
                nTicks: 10,
                customClass: this.preferences?.gridLineStyle,
            },
        };
    }

    // private getFilteredDataSource(hoveredBar: BarChartData): DatedPublicApiResponseRow[] {
    //     let dataSource = hoveredBar.sourceRows;
    //     if (hoveredBar.isAggregate) {
    //         dataSource = hoveredBar.aggregatedFrom?.reduce((childrenValues, d) => {
    //             return childrenValues.concat(d.sourceRows);
    //         }, [] as DatedPublicApiResponseRow[]) ?? [];
    //     }
    //     return dataSource;
    // }

    private initChartModel(): void {
        this.visualizationModel = buildBaseChartModel(this.chartSeries, [this.xAxis, this.yAxis], this.vizData);
        this.visualizationModel.margin = this.margin;
        this.visualizationModel.parentSelector = `app-bar-chart_${this.widgetId}`;
        this.visualizationModel.legend = this.legendConfig;
        this.visualizationModel.showTooltip = this.preferences?.enableTooltip;
        this.visualizationModel.tooltip = this.preferences?.configs?.tooltips.map((configItem) => {
            return {
                key: configItem.value,
                datatype: configItem.datatype,
                aggregationType: configItem.aggregationType,
                showCustomName: configItem.showCustomName,
                customName: configItem.customName,
                label: configItem.label,
            };
        });
        this.visualizationModel.showGridLines = this.preferences?.gridLineStyle !== 'none';
        this.visualizationModel.showCurveValues = this.preferences?.inChartLabel;
        this.visualizationModel.highlightXValueOnHover = false;
        this.visualizationModel.enableDynamicAxisLabelWidth = true;
        this.visualizationModel.highlightYValueOnHover = this.preferences?.showHoverLines;
        this.visualizationModel.gridLines = this.getGridLinesConfig();
        this.visualizationModel.highlight = undefined;
    }

    private populateBarChartData(
        slicerField: string,
        sortOn: TableSortType | undefined,
        sortDirection: string | undefined,
        comparingMethod: string,
        sortOrderSlicer: ConfigItem | undefined,
    ): void {
        const sliceManagementOptions = this.getSliceManagementOptions(this.preferences);
        // const yField = chartSeries[0].horizontal ? chartSeries[0].xField[0] : chartSeries[0].yField[0];
        const valueField = this.chartSeries[0].xField[0];

        if (comparingMethod === CompareMode.BOTH) {
            const getBarDataSource = this.dataService.getData(
                this.visualizationModel?.dataSource ?? [],
                slicerField,
                valueField,
                this.preferences?.configs?.values?.[0].aggregationType,
                sortOn,
                sortDirection,
                sortOrderSlicer,
                sliceManagementOptions);
            const getBarDataCompareSource = this.dataService.getData(
                this.visualizationModel?.dataCompareSource ?? [],
                slicerField,
                valueField,
                this.preferences?.configs?.values?.[0].aggregationType,
                sortOn,
                sortDirection,
                sortOrderSlicer,
                sliceManagementOptions);

            if (this.visualizationModel) {
                this.visualizationModel.dataSource = getBarDataSource;
                this.visualizationModel.dataCompareSource = getBarDataCompareSource;
            }
        } else {
            const dataSource = comparingMethod === CompareMode.COMPARED ?
                this.visualizationModel?.dataCompareSource :
                this.visualizationModel?.dataSource;

            const getBarDataSource = this.dataService.getData(
                dataSource ?? [],
                slicerField,
                valueField,
                this.preferences?.configs?.values?.[0].aggregationType,
                sortOn,
                sortDirection,
                sortOrderSlicer,
                sliceManagementOptions);

            if (this.visualizationModel) {
                if (comparingMethod === CompareMode.COMPARED) {
                    this.visualizationModel.dataCompareSource = getBarDataSource;
                } else {
                    this.visualizationModel.dataSource = getBarDataSource;
                }
            }
        }
    }

    private setVisualizationModelSliceManagement(enableSliceManagement: boolean): void {
        if (this.visualizationModel) {
            const sliceManagementOptions = this.getSliceManagementOptions(this.preferences);
            this.visualizationModel.enableSliceManagement = enableSliceManagement;
            this.visualizationModel.groupByType = sliceManagementOptions?.groupByType;
            this.visualizationModel.groupByValue = sliceManagementOptions?.groupByValue;
            this.visualizationModel.showOthers = sliceManagementOptions?.showOthers;
        }
    }

    private getSliceManagementOptions(config: VisualizationConfigs | undefined): SliceManagementOptions | undefined {
        if (!config?.enableSliceManagement) {
            return undefined;
        }

        return {
            groupByType: config.sliceManagementType ?? '',
            groupByValue: (config.sliceManagementType === SliceManagement.PERCENTAGE ?
                config.groupByPercent :
                config.groupByMaxCount) ?? 0,
            showOthers: !!config.showOthers,
        };
    }

    private setVisualizationModelFormatter(): void {
        if (this.visualizationModel && this.preferences) {
            this.visualizationModel.formatter = {
                isNumberFormatted: Boolean(this.preferences.numberFormat ?? this.preferences.decimalPlaces),
                numberUnits: this.preferences.numberUnits,
                numberFormat: this.preferences.numberFormat,
                decimalPlaces: this.preferences.decimalPlaces,
                sortDirection: this.preferences.tableSortDirection,
            };
        }
    }

    private setVisualizationModelCurveValues(): void {
        if (this.visualizationModel && this.preferences) {
            this.visualizationModel.curveValues = {
                enableInsideLabel: !!this.preferences.enableInsideLabel,
                labelOrientation: this.preferences.labelOrientation ?? 'horizontal',
                enableTotalLabel: this.chartSeries[0].stacked ? !!this.preferences.enableTotalLabel : true,
            };
        }
    }

    private getVizData(componentData: DataUpdateBody): DatedPublicApiResponseRow[] {
        const inCompareModeWithData = componentData.compareMode === CompareMode.COMPARED && componentData.compareData;
        const data: DatedPublicApiResponseRow[] | undefined = inCompareModeWithData ?
            componentData.compareData :
            componentData.data;
        const activeDate = (this.isStackedQuery || componentData.compareMode === CompareMode.COMPARED) ?
            undefined :
            componentData.filters?.activeDate;
        const filters = { activeDate };
        return this.clientDatasetFilterService.filterData(data, filters);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateChartSelection(data: any): void {
        if (!this.isChartInitialized) {
            return;
        }

        let highlight: Highlight<BarChartData> | undefined;
        if (data.selectedItem) {
            const chartData = data.selectedItem.data as ChartData;
            const slicerFieldName = this.filterBy?.value ?? '';
            const barData = this.visualizationModel?.dataSource?.find((d) => {
                const slicerValue = hasOwnProperty(chartData, slicerFieldName) ? chartData[slicerFieldName] : chartData.key;
                return d.key.fieldName === slicerFieldName && d.key.value === slicerValue;
            });

            if (barData) {
                highlight = {
                    data: barData,
                };
            }
        }
        this.visualizationModel = clone(this.visualizationModel, { highlight });
    }

    onVizInfoChanged(slicer: Partial<ConfigItem>): void {
        if (this.vizInfo && this.vizInfo.slicer.value !== slicer.value) {
            this.vizInfo.slicer = slicer;
        }
    }

    // exports dont work right without this
    protected populateConfigWithMetadata(config: ConfigItem): void {
        // These are fields in the metadata that are specifically overwritable by the user
        const alignment = config.alignment;
        const displayType = config.displayType;
        const isDynamic = config.isDynamic ?? false;
        Object.assign(config, this.fieldMetadata?.[config.value!]);
        config.alignment = alignment;
        config.displayType = displayType;
        config.isDynamic = isDynamic;
    }
}
