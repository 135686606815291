import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BehaviorsModule } from '@ddv/behaviors';

import { DataGridComponent } from './data-grid.component';
import { OverridesRelayService } from './services/overrides-relay.service';
import { RowGroupActionService } from './services/row-group-action.service';

@NgModule({
    imports: [
        BehaviorsModule,
        CommonModule,
        AgGridModule,
    ],
    declarations: [
        DataGridComponent,
    ],
    exports: [
        DataGridComponent,
    ],
    providers: [
        OverridesRelayService,
        RowGroupActionService,
    ],
})
export class DataGridModule {
    constructor() {
        ModuleRegistry.registerModules([
            ClientSideRowModelModule,
            ClipboardModule,
            ColumnsToolPanelModule,
            MenuModule,
            RangeSelectionModule,
            RowGroupingModule,
            StatusBarModule,
        ]);
    }
}
