import { Injectable } from '@angular/core';
import { VisualizationConfigs, AppWidgetState, WidgetDimension, WidgetPosition, FilterPreference } from '@ddv/models';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class WidgetsStateRelayService {
    public readonly enableWidgetSaveObs: Observable<boolean>;
    public readonly onWidgetInfoClearedObs: Observable<boolean>;
    public readonly updateWidgetInfoObs: Observable<AppWidgetState>;
    public readonly updateVizConfigObs: Observable<VisualizationConfigs>;
    public readonly isPreviewInitializedObs: Observable<boolean>;
    public readonly widgetDimensionObs: Observable<{ widgetDimension: WidgetDimension, widgetPosition: WidgetPosition }>;

    private currentQueryParams: FilterPreference | undefined = { funds: [], filters: [] };
    private readonly onWidgetInfoCleared = new Subject<boolean>();
    private readonly enableWidgetSave = new Subject<boolean>();
    private readonly updateWidgetInfo = new Subject<AppWidgetState>();
    private readonly updateVizConfig = new Subject<VisualizationConfigs>();
    private readonly isPreviewInitialized = new Subject<boolean>();
    private readonly widgetDimension = new Subject<{ widgetDimension: WidgetDimension, widgetPosition: WidgetPosition }>();

    constructor() {
        this.enableWidgetSaveObs = this.enableWidgetSave.asObservable();
        this.onWidgetInfoClearedObs = this.onWidgetInfoCleared.asObservable();
        this.updateWidgetInfoObs = this.updateWidgetInfo.asObservable();
        this.updateVizConfigObs = this.updateVizConfig.asObservable();
        this.isPreviewInitializedObs = this.isPreviewInitialized.asObservable();
        this.widgetDimensionObs = this.widgetDimension.asObservable();
    }

    getCurrentQueryParams(): FilterPreference | undefined {
        return this.currentQueryParams;
    }

    setCurrentQueryParams(queryParams: FilterPreference | undefined): void {
        this.currentQueryParams = queryParams;
    }

    publishSaveStatus(status: boolean): void {
        this.enableWidgetSave.next(status);
    }

    publishClearInfo(isWidgetFromFinder: boolean = false): void {
        this.onWidgetInfoCleared.next(isWidgetFromFinder);
    }

    publishUpdateInfo(info: AppWidgetState): void {
        this.updateWidgetInfo.next(info);
    }

    publishVizConfig(config: VisualizationConfigs): void {
        this.updateVizConfig.next(config);
    }

    publishPreviewWidgetInitStatus(): void {
        this.isPreviewInitialized.next(true);
    }

    publishWidgetDimension(info: { widgetDimension: WidgetDimension, widgetPosition: WidgetPosition }): void {
        this.widgetDimension.next(info);
    }
}
