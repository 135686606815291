import { Injectable } from '@angular/core';
import { deepClone } from '@ddv/utils';
import * as d3 from 'd3';

import { ChartData } from '../models/chart-data';
import { BaseChartService } from './base-chart.service';
import { BrushService } from './brush.service';

@Injectable()
export class BarChartBrushService extends BrushService {
    private interval = 0;
    private isStacked = false;

    addBrush(parentChart: BaseChartService): void {
        this.setParentChartProperties(parentChart);
        this.setBrushProperties();

        const offset = 20;
        this.setBrushDimensions();
        const height2 = (this.brushHeight < 0) ? 5 : (this.config?.brushSize ?? 0);

        this.x = d3.scaleTime().range([0, this.brushWidth]);
        this.x.domain(this.getXDomain(this.getX2Domain()));
        this.x2 = d3.scaleTime().range([0, this.brushWidth]);
        const yAxis = this.y || this.yAxisRight;
        const y2 = d3.scaleLinear().range([height2, 0]);
        this.xAxis2 = this.isXAxisTickSize() ?
            d3.axisBottom(this.x2).ticks(this.getXAxisTickSize()) :
            d3.axisBottom(this.x2).ticks(d3.timeDay);

        this.interval = this.getInterval(this.getDayInterval() || 1);

        this.brush = d3.brushX()
            .extent([[0, 0], [this.brushWidth, height2]])
            .on('brush end', (event: { selection: d3.BrushSelection }) => this.brushed(event));

        this.x2.domain(this.getX2Domain());
        y2.domain(yAxis.domain());

        this.context = this.getContext(offset);
        this.setXAxis2();

        this.gBrush = this.context.append('g')
            .attr('class', 'brush')
            .call(this.brush)
            .call(this.brush.move, this.getBrushRange());

        this.formatTicks('.tick');
    }

    protected override setBrushProperties(): void {
        super.setBrushProperties();

        this.dataSource = this.getDataSource();
        this.isStacked = this.parentChartProperties?.isStacked ?? false;
    }

    protected override getMarginTop(): number {
        return this.margin.top;
    }

    protected override getMarginBottom(): number {
        const marginBottom = (this.config?.height ?? 0) - ((this.config?.brushSize ?? 0) + this.margin.bottom);
        return ((this.config?.height ?? 0) < 400 && this.config?.axis[0][0].customClass === 'hide') ? marginBottom - 10 : marginBottom;
    }

    private brushed(event: { selection: d3.BrushSelection }): void {
        const s: any = event.selection || this.x2!.range();
        this.x.domain(this.getXDomain(s.map(this.x2!.invert, this.x2)));
        const chartLength = this.x.range()[1];
        const brushLength = s[1] - s[0];

        if (this.isStacked) {
            this.svg.selectAll('.bar rect')
                .attr('x', (d: any) => this.getXPosition(d.data[this.xField ?? '']))
                .attr('width', () => this.calculateBarWidth(chartLength, brushLength));
        } else {
            this.svg.selectAll('.bar')
                .attr('x', (d: any) => this.getXPosition(d[this.xField ?? '']))
                .attr('width', () => this.calculateBarWidth(chartLength, brushLength));
        }

        const xAxis = this.isXAxisTickSize() ?
            d3.axisBottom(this.x).ticks(this.getXAxisTickSize()) :
            d3.axisBottom(this.x).ticks(d3.timeDay);

        this.svg.select('.axis--x').call(xAxis);

        this.formatTicks('.axis--x .tick');
    }

    private getDataSource(): ChartData[] {
        if (this.parentChartProperties?.config.dataCompareSource) {
            return deepClone([...this.parentChartProperties.config.dataSource, ...this.parentChartProperties.config.dataCompareSource]);
        }

        return deepClone(this.parentChartProperties?.config.dataSource) ?? [];
    }

    // eslint-disable-next-line class-methods-use-this
    private getXDomain(domain: Date[]): Date[] {
        // This is a bit of hack, because if the date period on the chart itself is the same as the one on the brush,
        // the last bar starts exactly at the last pixel of the chart and is no longer visible.
        const modifier = this.getDateModifier();
        domain[1] = new Date(domain[1]?.setDate(domain[1].getDate() + modifier));
        return domain;
    }

    private getX2Domain(): Date[] {
        return d3.extent(this.dataSource ?? [], (d) => this.getDate(d[this.xField ?? ''])) as any;
    }

    private getBrushRange(): number[] {
        if (this.dataSource?.length === 1) {
            return [this.brushWidth / 2, this.brushWidth / 2];
        }

        return this.x.range();
    }

    private getDayInterval(): number {
        return Math.floor(((Math.abs(this.x.domain()[1]) - this.x.domain()[0]) / 1000) / (60 * 60 * 24));
    }

    private getInterval(dayInterval: number): number {
        return Math.ceil(dayInterval / this.getDateModifier());
    }

    private getDateModifier(): number {
        switch (this.xField) {
            case 'Month':
                return 30;
            case 'Quarter':
                return 91;
            case 'Year':
                return 365;
            default:
                return 1;
        }
    }

    private getXPosition(dateString: string): number {
        if (this.dataSource?.length === 1) {
            return this.brushWidth > 600 ? this.brushWidth / 2 - 60 :
                (this.brushWidth > 200 ? this.brushWidth / 2 - 50 : this.brushWidth / 2 - this.brushWidth / 4);
        }

        return this.x(this.getDate(dateString));
    }

    private getDate(dateString: string): Date | undefined{
        if (dateString) {
            const date = new Date(dateString);
            date.setHours(0, 0, 0, 0);
            return date;
        }

        return;
    }

    private getXAxisTickSize(): number {
        return Math.min(Math.max(this.brushWidth / this.tickOffset, 2), (this.dataSource?.length ?? 0));
    }

    private isXAxisTickSize(): boolean {
        const dayInterval = this.getDayInterval();

        return dayInterval > 4 || (dayInterval === 4 && this.width < 150);
    }

    private calculateBarWidth(chartLength: number, brushLength: number): number {
        if (this.dataSource?.length === 1) {
            return this.brushWidth > 600 ? 120 : (this.brushWidth > 200 ? 100 : this.brushWidth / 2);
        }

        if ((this.dataSource?.length ?? 0) <= 4) {
            return (brushLength / (this.interval * 1.6)) + ((chartLength - brushLength) / this.interval);
        }

        const offset = this.interval > 20 ? 0.4 : (this.interval > 8 ? 0.6 : 0.8);
        return (brushLength / (this.interval * 1.6)) + ((chartLength - brushLength) / (this.interval * offset));
    }
}
