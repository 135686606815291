<div class="dropdown-wrapper custom-style-wrapper" appMousedownOutside (mousedownOutside)="closeValueList()">
    <div class="input-box" (click)="toggleValueList()">
        <input
            id="selected"
            class="formControl"
            type="text"
            placeholder="{{ placeholder }}"
            autocomplete="off"
            [(ngModel)]="displayField && displayField !== 'value' && selected ? selected[displayField] : selected" />
        <span class="icon-arrow-down"></span>
    </div>
    <div class="dropdown custom-style-wrapper-list" [hidden]="!showValuesList">
        <div class="search-box" *ngIf="searchable">
            <input id="filterSearchValue" type="search" maxlength="50" class="formControl" name="filterSearchValue" autocomplete="off" [(ngModel)]="searchValue">
            <span class="icon-search1"></span>
            <button *ngIf="addBtnVisible" id="addButton" class="empty-btn" type="button" (click)="add(searchValue)">Add</button>
        </div>
        <div class="custom-style-wrapper-list-values">
            <ul *ngIf="!headers">
                <li *ngFor="let option of options" (click)="select(option)">
                    <span
                        *ngIf="!searchValue || (displayField ? option[displayField] : option).toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) !== -1"
                        innerHTML="{{ (displayField ? option[displayField] : option) | highlight : searchValue || getSelectedOptionText(selected, option) }}">
                    </span>
                </li>
            </ul>
            <ul *ngIf="headers">
                <li class="headers">
                    <span *ngFor="let header of headers">{{ header }}</span>
                </li>
                <li
                        *ngFor="let option of options | listBrowserFilter : searchValue"
                        (click)="select(displayField ? option[displayField] : option)"
                        class="option">
                    <span
                        *ngFor="let text of option.texts"
                        innerHTML="{{ text | highlight : searchValue || getSelectedOptionText(selected, option, text) }}">
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
