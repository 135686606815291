<div class="action-icons">
    <div class="tooltip" (click)="handleAcknowledge.emit()">
        <span class="hs-icon-check"></span>
        <span class="tooltiptext">Acknowledge</span>
    </div>
    <div *ngIf="isTFLDetails" class="tooltip" appMousedownOutside (mousedownOutside)="uploadModalOpened = false">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="20px" height="20px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve" (click)=toggleUploadModal()>
            <g>
                <defs>
                    <rect id="SVGID_1_" width="64" height="64"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_"  overflow="visible"/>
                </clipPath>
                <path clip-path="url(#SVGID_2_)" d="M16.441,19.559l2.828,2.828L30,11.657V46h4V11.657l10.729,10.731l2.829-2.829L32,4
                    L16.441,19.559z M52,44v8H12v-8H8v8c0,2.209,1.791,4,4,4h40c2.209,0,4-1.791,4-4v-8H52z"/>
            </g>
        </svg>
        <span class="tooltiptext">Upload</span>
        <div *ngIf="uploadModalOpened" class="upload-modal">
            <app-upload-modal [client]="getClient()" (closeModal)="closeUploadModal($event)"></app-upload-modal>
        </div>
    </div>
    <div *ngIf="isTFLDetails" class="tooltip" (click)="handleResubmit.emit()">
        <span class="hs-icon-redo"></span>
        <span class="tooltiptext">Resubmit</span>
    </div>
</div>
|
<div *ngIf="isTFLDetails" class="acknowledgement-toggle">
    <cc-option-toggle
        id="show-acknowledgement"
        [(value)]="!hideAcknowledged"
        [labels]="['SHOW', 'HIDE ACKNOWLEDGED']"
        (valueChange)="toggleAcknowledgement()">
    </cc-option-toggle>
</div>
