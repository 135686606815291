import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { DateRangeString, DdvDate, FuzzyDate, FuzzyDates } from '@ddv/models';

import { DateFromRange } from '../calendar/date-from-range';

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnChanges {
    @Input() dateRange: DateRangeString = { };
    @Input() fuzzyDates: FuzzyDates = new FuzzyDates();
    @Input() isHIDataAvailable: boolean = false;
    @Input() isInListViewMode = false;

    yearFrom: number | undefined;
    monthFrom: number | undefined;
    yearTo: number | undefined;
    monthTo: number | undefined;

    errorMessage: string | undefined;

    nextRangeDate: DateFromRange = 'dateFrom';

    @Output() dateSelected = new EventEmitter<DateRangeString>();
    @Output() errorMessageChange = new EventEmitter<string>();

    keyModalOpen = false;

    postedStates = [
        { class: 'all', text: 'All Posted' },
        { class: 'partial', text: 'Partial Posted' },
        { class: 'none', text: 'None Posted' },
    ];

    ngOnChanges(): void {
        this.setupYearMonth();
    }

    onDateSelected(dateRange: string | DateRangeString): void {
        this.dateRange = dateRange as DateRangeString;
        this.dateSelected.emit(dateRange as DateRangeString);
        this.setupYearMonth();
    }

    handleError(errorMessage?: string): void {
        this.errorMessageChange.emit(errorMessage);
    }

    setNextRangeDate(nextRangeDate: DateFromRange): void {
        this.nextRangeDate = nextRangeDate;
    }

    handleYearMonthChange(event: { year: number, month: number }, picker: DateFromRange): void {
        let date = new DdvDate(event.year, event.month, 1);

        if (picker === 'dateTo') {
            date = date.addMonths(-1);
        }

        this.setYearMonth(date);
    }

    private setupYearMonth(): void {
        let date = new DdvDate(DdvDate.today.year, DdvDate.today.month, DdvDate.today.day);
        if (this.dateRange && (this.dateRange.dateFrom || this.dateRange.dateTo)) {
            const dateString = this.dateRange.dateFrom || this.dateRange.dateTo;
            const fuzzyDate = this.getFuzzyDate(dateString);
            date = fuzzyDate ? DdvDate.fromISOFormat(fuzzyDate.value) : DdvDate.fromUSFormat(dateString);

            if (!this.dateRange.dateFrom) {
                date = date.addMonths(-1);
            }
        }
        this.setYearMonth(date);
    }

    private getFuzzyDate(value?: string): FuzzyDate | undefined {
        return this.fuzzyDates.from.find((fuzzyDate) => value && fuzzyDate.name.toLocaleUpperCase() === value.toUpperCase());
    }

    private setYearMonth(date: DdvDate): void {
        this.yearFrom = date.year;
        this.monthFrom = date.month;

        const toDate = date.addMonths(1);

        this.yearTo = toDate.year;
        this.monthTo = toDate.month;
    }
}
