import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DateRangeString, FuzzyDates } from '@ddv/models';

@Component({
    selector: 'app-date-range-picker-dialog',
    templateUrl: './date-range-picker-dialog.component.html',
    styleUrls: ['./date-range-picker-dialog.component.scss'],
})
export class DateRangePickerDialogComponent {
    @Input() dateRange: DateRangeString = { };
    @Input() fuzzyDates: FuzzyDates = new FuzzyDates();
    @Input() isHIDataAvailable: boolean = false;

    errorMessage: string | undefined;
    isDatePickerChecked = true;

    @Output() dateSelected = new EventEmitter<DateRangeString>();

    selectDate(dateRange: string | DateRangeString): void {
        this.dateRange = dateRange as DateRangeString;
    }

    emitDate(): void {
        if ((this.dateRange.dateFrom && this.dateRange.dateTo ||
            !this.dateRange.dateFrom && !this.dateRange.dateTo) &&
            !this.errorMessage) {
            const newDateRange: DateRangeString = {
                dateFrom: this.dateRange.dateFrom,
                dateTo: this.dateRange.dateTo,
            };
            this.dateSelected.emit(newDateRange);
        }
    }

    showError(errorMessage?: string): void {
        this.errorMessage = errorMessage;
    }
}
