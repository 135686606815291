<div [ngSwitch]="type" class="udf-field" [class.boolean-field]="type === 'boolean'">

    <div *ngSwitchCase="type === 'string' || type === 'decimal' ? type : ''" class="user-defined-field-grid-cell">
        {{ cellValue }}
    </div>

    <cc-single-select-dropdown
        *ngSwitchCase="'choice'"
        cssClass="picklist"
        [anchorType]="'grid'"
        [matchAnchorWidth]="true"
        [searchable]="false"
        [allowNoSelection]="true"
        [autoScrollSelectedIntoView]="true"
        [options]="dropdownOptions"
        [selected]="dropdownCellValue"
        [disabled]="!canEditUdf"
        [collapseEmptyError]="true"
        (selectedChange)="updateDropdownValue($event)">
    </cc-single-select-dropdown>

    <cc-date-select-dropdown
        *ngSwitchCase="'date'"
        [format]="'us'"
        [anchorType]="'grid'"
        [anchorSize]="'large'"
        [value]="dateCellValue"
        [fuzzies]="fuzzyDates"
        [disabled]="!canEditUdf"
        (valueChange)="updateDateValue($event)">
    </cc-date-select-dropdown>

    <cc-option-toggle
        class="centered"
        *ngSwitchCase="'boolean'"
        [labels]="{ trueLabel: 'True', falseLabel: 'False' }"
        [disabled]="!canEditUdf"
        [(value)]="booleanCellValue"
        (valueChange)="updateBooleanValue($event)">
    </cc-option-toggle>
</div>
