import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { Observable } from 'rxjs';

interface CSVable { [key: string]: unknown }

export interface Schema extends CSVable {
    id: string;
    type: string;
    displayName: string;
    fields: string[];
    displayFields: string[];
    asciiOnly?: boolean;
    maxCommentLength?: number;
    createdBy: string;
    created: string;
    updated: string;
}

export const ADD_NEW_CROSSTALK_SCHEMA = 'Add new Crosstalk schema';

@Injectable()
export class CommentsService {
    private clientCode: string = '';

    constructor(
        @Inject(ApiServices.xtlk) private readonly xtlkApiExecutor: ApiExecutorService,
        private readonly currentStateService: CurrentStateService,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    fetchSchemas(): Observable<Schema[]> {
        return this.xtlkApiExecutor.invokeServiceWithParams(this.clientCode, '/schema/', undefined);
    }
}
