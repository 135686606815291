<div
        class="sidenav"
        *ngIf="clientEntitlements"
        [ngClass]="{'collapsed': !mwNavComponent?.isNavExpanded}">
    <app-mw-left-nav
        #leftNavComponent
        (transitionEnd)="onTransitionEnd()"
        (datasetDefinitionChanged)="onUpdateDatasetDefinition($event)"
        (autoAddDynamicColumnsChanged)="onUpdateAutoAddDynamicColumnsVisualizations($event)"
        [currentWidgetId]="currentWidgetId">
    </app-mw-left-nav>
</div>

<div
        class="layout-wrapper"
        id="layout-wrapper"
        [ngClass]="{'expanded': mwNavComponent?.isNavExpanded, 'collapsed': !mwNavComponent?.isNavExpanded }">

    <app-mw-dashboard
        #dashboardComponent
        *ngIf="clientEntitlements"
        [navRef]="mwNavComponent"
        [datasetDefinition]="datasetDefinition"
        [autoAddDynamicColumnsVisualizations]="autoAddDynamicColumnsVisualizations"
        (revertChanges)="onRevertChanges($event)">
    </app-mw-dashboard>

    <div #dummyDashboardDiv id="dashboardDummyDiv" class="dummy-dashboard-margin">
        <div class="margin-container"></div>
    </div>

    <div id="mw_ws" class="dashboard-container" #dashboardContainer>
        <div class="margin-container">
            <canvas id="grid-canvas" #gridcanvas></canvas>
            <app-dashboard [isManagingWidget]="true"></app-dashboard>
        </div>
    </div>

    <app-socket data-html2canvas-ignore="true"></app-socket>
</div>
