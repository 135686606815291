import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DashboardGroup } from '../models/dashboard-group';

@Injectable()
export class DashboardGroupsService {
    public readonly dashboardGroups$: Observable<DashboardGroup[]>;

    private readonly dashboardGroups: ReplaySubject<DashboardGroup[]> = new ReplaySubject<DashboardGroup[]>(1);
    private clientCode: string = '';

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
    ) {
        this.dashboardGroups$ = this.dashboardGroups.asObservable();

        this.currentStateService.clientCode$.subscribe((clientCode) => {
            this.clientCode = clientCode;
            this.getDashboardGroups().subscribe((groups) => this.dashboardGroups.next(groups));
        });
    }

    getDashboardGroups(): Observable<DashboardGroup[]> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.ddvApiService.invokeServiceWithParams<any[]>(this.clientCode, 'dashboard-groups')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .pipe(map((groupsJSON: any[]) => groupsJSON.map((groupJSON) => new DashboardGroup(groupJSON))));
    }

    addDashboardGroup(payload: DashboardGroup): Observable<DashboardGroup> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, 'dashboard-groups', 'POST', payload);
    }

    updateDashboardGroup(group: DashboardGroup): Observable<DashboardGroup> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `dashboard-groups/${group.id}`, 'PUT', group);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteDashboardGroup(id: number): Observable<any> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `dashboard-groups/${id}`, 'DELETE', null);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleDefault(id: number, isDefault: boolean): Observable<any> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `dashboard-groups/${id}/default`, 'PUT', { isDefault });
    }

    refetchDashboardGroups(): void {
        this.getDashboardGroups().subscribe((groups) => this.dashboardGroups.next(groups));
    }
}
