import { Injectable } from '@angular/core';
import { CompareColumnID, ConfigItem } from '@ddv/models';

const absSuffix = '__abs';

@Injectable()
export class ColumnTransformService {
    // Gets the field name for the destination of abs val data, assumes the column is abs val
    getAbsValFieldName(config: ConfigItem): string {
        return config.value! + absSuffix;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformAbsoluteValue(gridData: any[], columnConfigs: ConfigItem[]): void {
        columnConfigs.forEach((config: ConfigItem) => {
            if (config.useAbsVal) {
                gridData.forEach((dataRow) => {
                    let absField: string = this.getAbsValFieldName(config);

                    if (absField.indexOf(CompareColumnID.COMPARE) !== -1) {
                        absField = absField.replace(CompareColumnID.COMPARE, '') + CompareColumnID.COMPARE;
                        dataRow[absField] = Math.abs(dataRow[absField]);
                    } else if (absField.indexOf(CompareColumnID.DIFF) !== -1) {
                        absField = absField.replace(CompareColumnID.DIFF, '') + CompareColumnID.DIFF;
                        dataRow[absField] = Math.abs(dataRow[absField]);
                    } else {
                        dataRow[absField] = Math.abs(dataRow[config.value!]);
                    }
                });
            }
        });
    }
}
