import {
    getMaximumValue,
    getMinimumValue,
    getSumValue,
    getAvgValue,
    getCountValue,
    getCountValueExcludingZeros,
    getCountDistinctValue,
    getCountDistinctValueExcludingZeros,
    getSourceFieldSort,
} from '@ddv/utils';

import { LabelValuePair } from '../label-value-pair';

export type AggType =
    'sum' |
    'min' |
    'max' |
    'count' |
    'countExcludingZeros' |
    'avg' |
    'countDistinct' |
    'countDistinctExcludingZeros' |
    'first' |
    'last' |
    'zero' |
    'calcOnCountPercentage' |
    'calcOnCountFraction' |
    'calcOnCountDistinctPercentage' |
    'calcOnCountDistinctFraction' |
    'none';

export function calculateAggFunction<T extends { [key: string]: number | string }>(
    source: T[],
    valueField: string,
    formatType: AggType,
): string | number | null {
    switch (formatType) {
        case 'sum':
            return getSumValue(source, valueField);
        case 'min':
            return getMinimumValue(source, valueField);
        case 'max':
            return getMaximumValue(source, valueField);
        case 'avg':
            return getAvgValue(source, valueField);
        case 'count':
            return getCountValue(source);
        case 'countExcludingZeros':
            return getCountValueExcludingZeros(source, valueField);
        case 'countDistinct':
            return getCountDistinctValue(source, valueField);
        case 'countDistinctExcludingZeros':
            return getCountDistinctValueExcludingZeros(source, valueField);
        case 'first':
            return getSourceFieldSort(source, valueField)[0];
        case 'last':
            return getSourceFieldSort(source, valueField)[source.length - 1];
        case 'zero':
            return 0;
        case 'none':
            return null;
        default:
            return null;
    }
}

const aggPairs: { [key in AggType]: LabelValuePair<AggType> } = {
    sum: { label: 'Sum', value: 'sum' },
    min: { label: 'Min', value: 'min' },
    max: { label: 'Max', value: 'max' },
    count: { label: 'Count', value: 'count' },
    countExcludingZeros: { label: 'Count Excluding Zeros', value: 'countExcludingZeros' },
    avg: { label: 'Avg', value: 'avg' },
    countDistinct: { label: 'Count Distinct', value: 'countDistinct' },
    countDistinctExcludingZeros: { label: 'Count Distinct Excluding Zeros', value: 'countDistinctExcludingZeros' },
    first: { label: 'First', value: 'first' },
    last: { label: 'Last', value: 'last' },
    zero: { label: 'Zero', value: 'zero' },
    calcOnCountPercentage: { label: 'Calc on Count - Percentage', value: 'calcOnCountPercentage' },
    calcOnCountFraction: { label: 'Calc on Count - Fraction', value: 'calcOnCountFraction' },
    calcOnCountDistinctPercentage: { label: 'Calc on Count Distinct - Percentage', value: 'calcOnCountDistinctPercentage' },
    calcOnCountDistinctFraction: { label: 'Calc on Count Distinct - Fraction', value: 'calcOnCountDistinctFraction' },
    none: { label: 'None', value: 'none' },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AGG_TYPES_BY_SECTION_AND_DISPLAY: Record<string, Record<string, any>> = {
    tooltip: {
        value: [
            aggPairs.sum,
            aggPairs.avg,
            aggPairs.count,
            aggPairs.countExcludingZeros,
            aggPairs.countDistinct,
            aggPairs.countDistinctExcludingZeros,
            aggPairs.max,
            aggPairs.min,
        ],
        // eslint-disable-next-line id-blacklist
        string: [
            aggPairs.first,
            aggPairs.last,
            aggPairs.count,
            aggPairs.countExcludingZeros,
            aggPairs.countDistinct,
            aggPairs.countDistinctExcludingZeros,
        ],
        date: [
            aggPairs.max,
            aggPairs.min,
            aggPairs.count,
            aggPairs.countDistinct,
        ],
    },
    summary: {
        value: [
            aggPairs.sum,
            aggPairs.avg,
            aggPairs.count,
            aggPairs.countExcludingZeros,
            aggPairs.countDistinct,
            aggPairs.countDistinctExcludingZeros,
            aggPairs.max,
            aggPairs.min,
            aggPairs.calcOnCountFraction,
            aggPairs.calcOnCountPercentage,
            aggPairs.calcOnCountDistinctFraction,
            aggPairs.calcOnCountDistinctPercentage,
        ],
        // eslint-disable-next-line id-blacklist
        string: [
            aggPairs.first,
            aggPairs.last,
            aggPairs.count,
            aggPairs.countExcludingZeros,
            aggPairs.countDistinct,
            aggPairs.countDistinctExcludingZeros,
            aggPairs.calcOnCountFraction,
            aggPairs.calcOnCountPercentage,
            aggPairs.calcOnCountDistinctFraction,
            aggPairs.calcOnCountDistinctPercentage,
        ],
        date: [
            aggPairs.min,
            aggPairs.max,
        ],
    },
    grid: {
        value: [
            aggPairs.sum,
            aggPairs.avg,
            aggPairs.count,
            aggPairs.max,
            aggPairs.min,
            aggPairs.first,
            aggPairs.last,
            aggPairs.zero,
            aggPairs.none,
        ],
        // eslint-disable-next-line id-blacklist
        string: [],
        date: [],
        bar: [],
        // eslint-disable-next-line id-blacklist
        boolean: [],
    },
    circle: {
        value: [aggPairs.sum, aggPairs.avg, aggPairs.count, aggPairs.countExcludingZeros],
        // eslint-disable-next-line id-blacklist
        string: [aggPairs.count, aggPairs.countExcludingZeros, aggPairs.countDistinct, aggPairs.countDistinctExcludingZeros],
        date: [aggPairs.count, aggPairs.countDistinct],
    },
    chart: {
        value: [aggPairs.sum, aggPairs.avg, aggPairs.count, aggPairs.countExcludingZeros, aggPairs.max, aggPairs.min],
        // eslint-disable-next-line id-blacklist
        string: [aggPairs.count, aggPairs.countExcludingZeros, aggPairs.countDistinct, aggPairs.countDistinctExcludingZeros],
        date: [aggPairs.count, aggPairs.countDistinct],
    },
};
