import { Injectable } from '@angular/core';
import {
    Axis,
    ChartSettings,
    ColorMetadataService,
    ColorType,
    DrilldownKey,
    getSumValueObj,
    Margin,
    safeStringFormatter,
    Series,
    SliceManagementOtherData,
    sliceOthers,
    StackedAreaSubtotal,
    ValueAggregationService,
    ValueFormatterService,
} from '@ddv/charts';
import {
    AggType,
    ConfigItem,
    IMarginCondition,
    ValueFilterOption,
    LegendPosition,
    SliceManagement,
    SliceManagementOptions,
    TableSortType,
    VisualizationConfigs,
    VisualizationType,
    VizConfigs,
} from '@ddv/models';
import { getValidString } from '@ddv/utils';
import { Theme } from '@hs/ui-core-presentation';

const MAX_TOOLTIP_ROWS = 16;

export interface InterimData<T> {
    [key: string]: string | number | T[] | undefined;
}

@Injectable()
export class ChartsSharedService {
    constructor(
        private readonly valueFormatterService: ValueFormatterService,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private readonly valueAggregationService: ValueAggregationService<any>,
    ) {}

    computePieDonutMargin(vizModel: ChartSettings): Margin {
        return {
            top: 10,
            bottom: 10,
            left: 20,
            right: 10,
            [vizModel.legend?.docked ?? '']: vizModel.showLabels ? 50 : 30,
        };
    }

    // TODO: this needs to go
    getBarToolTip(
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
        toolTipData: any,
        axisField: string,
        tooltipsItems: ConfigItem[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataSource: any[],
        isStacked: boolean = false,
        slicerLabel?: string,
        slicerField?: string,
    ): string {
        let html = '<div class="tooltip-data"><table class="disp-table">';
        if (toolTipData) {
            const tooltipEntries: { itemLabel: string, itemValue: string | number }[] = [];
            const name = toolTipData[axisField] == null ?
                'Null' :
                (toolTipData[axisField].toString().trim() ? safeStringFormatter(toolTipData[axisField]) : 'Blanks');
            html += `<tr><td class="item-title">${name.toUpperCase()}</td></tr>`;

            let tooltipDatasource = dataSource;
            if (isStacked) {
                const safeSlicerValue = slicerLabel == null ? 'Null' : slicerLabel?.toString().trim() ? slicerLabel?.toString() : 'Blanks';

                if (name !== safeSlicerValue) {
                    html += `<tr><td class="item-title"><strong>${safeSlicerValue}</strong></td></tr>`;
                }

                tooltipDatasource = [...tooltipDatasource.filter((el) => el[slicerField ?? ''] === slicerLabel)];
            }

            tooltipsItems.forEach((tooltip) => {
                const aggFnName = tooltip.aggregationType;
                const itemLabel = `${aggFnName?.split(/(?=[A-Z])/).join(' ')} of ${tooltip.showCustomName ? tooltip.customName : tooltip.label}`;
                let itemValue = this.valueFormatterService.applyFormatter(this.valueAggregationService.aggregateValue(tooltipDatasource, tooltip.value ?? '', aggFnName), tooltip);
                itemValue = itemValue == null ? 'Null' : itemValue.toString().trim() ? itemValue : 'Blanks';
                tooltipEntries.push({ itemLabel, itemValue });
            });

            if (tooltipEntries.length) {
                tooltipEntries.forEach((entry) =>
                    html += `<tr>
                                <td class="item-label">${entry.itemLabel}</td>
                                <td class="item-value">${entry.itemValue}</td>
                             </tr>`);
            }
        }

        return `${html}</table></div>`;
    }

    getBarDataSource<T>(
        source: T[],
        filterByValue: keyof T | undefined,
        chartSeries: Series[],
        yValues: ConfigItem[],
        groupByEnabled: boolean,
        groupByOptions: SliceManagementOptions,
        sortTableBy: TableSortType | undefined,
        tableSortDirection: string | undefined,
        sortOrderSlicer?: ConfigItem,
    ): InterimData<T>[] {
        const yField = (chartSeries[0].horizontal ? chartSeries[0].xField[0] : chartSeries[0].yField[0]) as keyof T;
        const sortKey = (sortTableBy === 'SLICER' ? filterByValue : yField) ?? undefined;

        const aggregatedData = this.getAggregatedData(
            source,
            filterByValue ? [filterByValue] : [],
            [yField],
            yValues[0].aggregationType ? [yValues[0].aggregationType] : []);
        const barData = sortAggregatedData(aggregatedData, sortKey ? [sortKey as string] : [], tableSortDirection, sortOrderSlicer);

        if (groupByEnabled) {
            return getBarManagementData(barData, yField as string, groupByOptions, filterByValue as string);
        }

        return barData;
    }

    public getCircleDataSource<T>(
        source: T[],
        filterByValue: string,
        yValues: ConfigItem[],
        sortTableBy: TableSortType | undefined,
        tableSortDirection: string | undefined,
        sortOrderSlicer: ConfigItem | undefined,
    ): InterimData<T>[] {
        const sortKey = (sortTableBy === 'SLICER' ? filterByValue : yValues[0].name) ?? '';
        return sortAggregatedData(
            // throw in non-null assert on aggregationType because the API can return nulls so the type had to be adjusted
            this.getAggregatedData(source, [filterByValue as keyof T], [(yValues[0].name ?? '') as keyof T], [yValues[0].aggregationType!]),
            [sortKey],
            tableSortDirection,
            sortOrderSlicer);
    }

    public getStackedBarDataSource<T>(
        source: T[],
        slicerField: string,
        chartSeries: Series[],
        yValues: ConfigItem[],
        detailsField: string,
        sortTableBy: TableSortType | undefined,
        tableSortDirection: string | undefined,
        sortOrderSlicer?: ConfigItem,
        detailOrderSlicer?: ConfigItem,
    ): T[] {
        if (!source || source.length === 0) {
            return [];
        }

        const yField = chartSeries[0].horizontal ? chartSeries[0].xField[0] : chartSeries[0].yField[0];
        // throw in non-null assert on aggregationType because the API can return nulls so the type had to be adjusted
        const aggregatedData = this.getAggregatedData(
            source,
            [slicerField as keyof T, detailsField as keyof T],
            [yField as keyof T],
            [yValues[0].aggregationType!, 'sum']);
        let aggregated;
        let detailsSortKey: string;

        if (sortTableBy === 'SLICER') {
            detailsSortKey = detailsField;
            aggregated = sortAggregatedData(
                aggregatedData,
                [slicerField, detailsField],
                tableSortDirection,
                sortOrderSlicer);
        } else {
            // throwing in non-null assertion here.  See FieldMetadata
            detailsSortKey = yValues[0].name!;
            aggregated = this.sortStackedAggregatedData(
                aggregatedData,
                // throwing in non-null assertion here.  See FieldMetadata
                [yValues[0].name!, yValues[0].name!],
                tableSortDirection,
                slicerField);
        }

        // This is a hack. Without the sorted list of details fields there's no way for the viz to get the correct sorting
        // Stick the information in the first item, so it can be removed by datasource.service
        aggregated[0].chart_details_sort = this.getStackedBarDetails(
            source,
            detailsField,
            yField,
            detailsSortKey,
            tableSortDirection,
            detailOrderSlicer ?? sortOrderSlicer);

        return aggregated;
    }

    getStackedBarDetails<T>(
        source: T[],
        detailsField: string,
        yField: string,
        detailsSortKey: string,
        tableSortDirection: string | undefined,
        sortOrderSlicer?: ConfigItem,
    ): InterimData<T>[] {
        const sortedAggregatedData = sortAggregatedData(
            this.getAggregatedData(source, [detailsField as keyof T], [yField as keyof T], ['sum']),
            [detailsSortKey],
            tableSortDirection,
            sortOrderSlicer,
        );

        return sortedAggregatedData.map((detailItem) => {
            delete detailItem.values;
            return detailItem;
        });
    }

    getStackedAreaManagementData(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        source: any[],
        chartSeries: Series,
        groupByType: string | undefined,
        groupByValue: number,
        filterByValue: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any[] {
        const xField = chartSeries.xField[0];
        const yField = chartSeries.yField[0];

        const { total, subtotals } = getStackedAreaTotals(source, yField, filterByValue);

        const nonOtherSubtotals = groupByType === SliceManagement.PERCENTAGE ?
            getStackedAreaNonOtherSubtotals(subtotals, total, groupByValue) :
            subtotals
                .sort(sortStackedAreaSubtotals)
                .slice(0, groupByValue && groupByValue !== subtotals.length ? groupByValue - 1 : subtotals.length)
                .map((subtotal) => Object.keys(subtotal)[0]);
        const newData = [];
        const otherData: SliceManagementOtherData[] = [];

        source.forEach((datum) => {
            if (nonOtherSubtotals.includes(datum[filterByValue])) {
                newData.push(datum);
            } else {
                const otherDatum = otherData.find((d) => (d[xField] as Date).getTime() === datum[xField].getTime());
                if (otherDatum) {
                    otherDatum.children.push(datum);
                    (otherDatum[yField] as number) += datum[yField] as number;
                } else {
                    otherData.push({
                        [filterByValue]: 'others',
                        children: [datum],
                        [yField]: datum[yField],
                        [xField]: datum[xField],
                    });
                }
            }
        });

        if (otherData.length && otherData.some((otherDatum) => otherDatum[yField])) {
            newData.push(...otherData);
        }

        return newData;
    }

    getStackedAreaTooltipManagementData(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        source: any[],
        xField: string,
        filterByValue: string,
        columns: string[],
        nonOtherValues: string[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any[] {
        return source.reduce((data, datum) => {
            if (nonOtherValues.includes(datum[filterByValue])) {
                data.push(datum);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const otherDatum = data.find((d: any) => d[filterByValue] === 'others' && (d[xField] as Date).getTime() === datum[xField].getTime());

                if (otherDatum) {
                    columns.forEach((column) => {
                        if (typeof otherDatum[column] === 'number') {
                            otherDatum[column] += datum[column];
                        }
                    });
                } else {
                    const od = {
                        [filterByValue]: 'others',
                        [xField]: datum[xField],
                    };

                    columns.forEach((column) => od[column] = datum[column]);
                    data.push(od);
                }
            }

            return data;
        }, []);
    }

    getMirrorBarDataSource<T>(
        source: T[],
        filterByValue: string,
        yValues1: ConfigItem[],
        yValues2: ConfigItem[],
        tableSortDirection: string | undefined,
    ): InterimData<T>[] {
        const aggregatedData = this.getAggregatedData(
            source,
            [filterByValue as keyof T],
            // throwing in non-null assertion here.  See FieldMetadata
            [yValues1[0].name! as keyof T, yValues2[0].name! as keyof T],
            // throw in non-null assert on aggregationType because the API can returns nulls so the type had to be adjusted
            [yValues1[0].aggregationType!, yValues2[0].aggregationType!]);
        return sortAggregatedData(aggregatedData, [filterByValue], tableSortDirection);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getBaseChartModel(series: Series[], axis: Axis[][], dataSource: any[] = []): ChartSettings {
        return buildBaseChartModel(series, axis, dataSource);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortDataOnDateValue(dataSource: any[], dateColumnName: string): object[] {
        dataSource.sort((a, b) => {
            const date1 = new Date(a[dateColumnName]);
            const date2 = new Date(b[dateColumnName]);
            if (date1 > date2) {
                return 1;
            } else if (date1 < date2) {
                return -1;
            } else {
                return 0;
            }
        });
        return dataSource;
    }

    // If uniqueFields is false, colId will be used for all yValues
    getLineAndAreaDataSource(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        source: any[],
        filterByValue: string,
        chartSeries: Series,
        yValues: ConfigItem[],
        uniqueFields: boolean = true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any[] {
        const xField = chartSeries.xField[0];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hashMap = source.reduce((map: Map<string, any>, line: any): Map<string, any> => {
            if (!line[xField]) {
                // Filter out values that do not have a date/ordinal component
                return map;
            }
            // This can likely be replaced by getAggregatedData
            const hash = filterByValue ? `${line[filterByValue]}${line[xField].valueOf()}` : line[xField].valueOf();
            if (!map.has(hash)) {
                const lineSummary = { [xField]: line[xField] };
                if (filterByValue) {
                    lineSummary[filterByValue] = line[filterByValue];
                }
                yValues.forEach((yValue) => lineSummary[(uniqueFields ? yValue.value! : yValue.colId)] = [line[yValue.value!]]);
                map.set(hash, lineSummary);
            } else {
                yValues.forEach((yValue) => map.get(hash)[(uniqueFields ? yValue.value! : yValue.colId)].push(
                    line[yValue.value!]));
            }
            return map;
        }, new Map());

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = Array.from((hashMap as Map<string, any>).values());

        yValues.forEach((yValue) => {
            const id = uniqueFields ? yValue.value! : yValue.colId;
            data.forEach((line) => {
                // throw in non-null assert on aggregationType because the API can returns nulls so the type had to be adjusted
                line[id] = this.calculateAggValue(line[id], yValue.aggregationType!);
            });
        });
        return data.sort((a, b) => a[xField] - b[xField]);
    }

    getLineTooltip(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        datapoint: { [x: number]: any, hiddenValues: any },
        tooltipColors: Map<string, string>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        allTooltipData: any[],
        tooltipConfigs: ConfigItem[],
        chartSeries: Series,
        filterByValue: string,
    ): string {
        const xField = chartSeries.xField[0];
        // Ideally, we have data for the current "targetDate", otherwise
        // we want the "closest" date that occurs before the tooltip
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const targetDate = datapoint[xField as any].valueOf();

        let tooltipData = this.getLineAndStackedAreaTooltipData(allTooltipData, xField, targetDate);
        if (filterByValue !== '') {
            if (datapoint.hiddenValues.length) {
                tooltipData = tooltipData.filter((tooltipLine) => !datapoint.hiddenValues.includes(tooltipLine[filterByValue]));
            }

            tooltipColors.forEach((value, key) => addColorToTooltipDatum(key, filterByValue, value, tooltipData));
        }
        tooltipData.reverse();

        return this.lineAndStackedAreaTooltipDataToHTML(tooltipData, tooltipConfigs, filterByValue);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getLineAndStackedAreaTooltipData(allTooltipData: any[], xField: string, targetDate: number): any[] {
        const tooltipData = [];
        let closestTooltipDate: number | undefined;
        for (let i = allTooltipData.length - 1; i >= 0; i--) {
            const currentDate = allTooltipData[i][xField].valueOf();
            if (closestTooltipDate) {
                if (currentDate < closestTooltipDate) {
                    // Already found all values for the closest date
                    break;
                }
                if (currentDate === closestTooltipDate && allTooltipData[i]) {
                    tooltipData.push(allTooltipData[i]);
                }
            } else if (currentDate <= targetDate) {
                closestTooltipDate = currentDate;
                tooltipData.push(allTooltipData[i]);
            }
        }

        return tooltipData;
    }

    lineAndStackedAreaTooltipDataToHTML(tooltipData: { [key: string]: string }[], tooltips: ConfigItem[], filterByValue: string): string {
        let html = '<div class="data-in-linechart"><table class="disp-table">';
        if (tooltipData.length) {
            let rowCount = 0;
            if (filterByValue !== '') {
                tooltipData.forEach((data) => {
                    rowCount += tooltips.length + 1;
                    if (rowCount <= MAX_TOOLTIP_ROWS) {
                        const label = getLineChartAndStackedAreaColorLabel(data[filterByValue]);
                        const color = data.color;
                        html += `<tr class="bold"><td>${getTooltipColorSpan(color)}<label>${label}</label></td></tr>`;
                        html += '<tr><td colspan="3"><hr class="hr-line"></td></tr>';
                        html += this.lineTooltipLineToHTML(data, tooltips);
                    }
                });
            } else {
                html += this.lineTooltipLineToHTML(tooltipData[0], tooltips);
            }
        }
        html += '</table></div>';
        return html;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    lineTooltipLineToHTML(tooltipLine: any, tooltips: ConfigItem[]): string {
        let html = '';
        for (const tooltip of tooltips) {
            const displayName = tooltip.showCustomName ? tooltip.customName : tooltip.label;
            let displayValue = this.valueFormatterService.applyFormatter(tooltipLine[tooltip.colId], tooltip);
            displayValue = displayValue == null ? 'Null' : (displayValue.toString().trim() ? displayValue : 'Blanks');
            html += `<tr class="y-value"><td><label>${tooltip.aggregationType?.split(/(?=[A-Z])/).join(' ')} of ${displayName}</label></td>`
            + `<td style="text-align:right;"><span>${displayValue}`
            + '</span></td></tr>';
        }
        return html;
    }

    deserializeDate(date: string): Date | undefined {
        if (!date) {
            return;
        }

        // Timezone is not handled in middleware hence this is a temporary fix
        let formattedDate = date.split('Z')[0];
        if (formattedDate.indexOf('T') === -1) {
            formattedDate = `${formattedDate}T00:00:00`;
        }
        const dateObj = new Date(formattedDate);
        if (!isNaN(dateObj.valueOf())) {
            return dateObj;
        }
        return new Date(formattedDate);
    }

    sortStackedAggregatedData(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        aggregatedData: any[],
        sortKeys: string[],
        tableSortDirection: string | undefined,
        slicerField: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const orderedData: any[] = [];
        const keys: string[] = [];

        aggregatedData.forEach((data) => {
            let keyIndex = keys.indexOf(data[slicerField]);

            if (keyIndex === -1) {
                keyIndex = keys.length;
                orderedData.push({ key: data[slicerField], values: [], total: 0 });
                keys.push(data[slicerField]);
            }

            orderedData[keyIndex].values.push(data);
            orderedData[keyIndex].total += data[sortKeys[0]];
        });

        return orderedData.sort((a, b) => {
            const asc = a.total < b.total ? -1 : a.total > b.total ? 1 : 0;
            return tableSortDirection === 'ASC' ? asc : asc * -1;
        }).reduce((total, data) => {
            total.push(...data.values);
            return total;
        }, []);
    }

    getDrillKeys(slicerList: ConfigItem[], selectedSlicer: Partial<ConfigItem>): DrilldownKey[] {
        const index = slicerList.findIndex((item) => item.value === selectedSlicer.value);
        return slicerList.slice(index, slicerList.length).concat(slicerList.slice(0, index));
    }

    getColorRange(
        preferences: VisualizationConfigs,
        colorService: ColorMetadataService,
        slicerValue: string,
        theme: Theme = Theme.light,
    ): string[] {
        const selectedSlicer = this.getSelectedSlicer(preferences.configs, slicerValue);

        if (selectedSlicer?.colorType && selectedSlicer.colorName) {
            if (preferences.visualizationType === 'LINE_CHART') {
                return this.getLineChartColorRange(selectedSlicer, colorService);
            }
            if (isStackedAreaTransparentColor(preferences.visualizationType, selectedSlicer.colorName)) {
                return this.getStackedAreaChartTransparentColorRange(selectedSlicer, colorService);
            }
            return this.getRegularChartColorRange(selectedSlicer, colorService, theme);
        }
        return [];
    }

    getValueColorName(colorName: string, index: number): string {
        return ColorMetadataService.mapOutdatedLineChartValueColorName(colorName, index);
    }

    getAttributeCustomColors(
        preferences: VisualizationConfigs,
        slicerValue: string,
        theme: Theme = Theme.light,
    ): { [key: string]: string }[] {
        const selectedSlicer = this.getSelectedSlicer(preferences.configs, slicerValue);

        if (selectedSlicer?.configCustomStyles) {
            if (preferences.visualizationType === 'LINE_CHART' && preferences.configs?.slicers.length) {
                selectedSlicer.configCustomStyles = selectedSlicer.configCustomStyles.filter((attribute) => {
                    return !ColorMetadataService.isLineChartConfigCustomStyleOutdated(attribute.attributeColorName);
                });
            } else if (isStackedAreaTransparentColor(preferences.visualizationType, selectedSlicer.colorName ?? '')) {
                selectedSlicer.configCustomStyles.forEach((colorConfig) => {
                    if (colorConfig.attributeColorName && !colorConfig.attributeColorName.includes('rgba')) {
                        colorConfig.attributeColorName = ColorMetadataService.makeColorSemiTransparent(colorConfig.attributeColorName);
                    }
                });
            }

            return selectedSlicer.configCustomStyles
                .map((attribute) => ({
                    [attribute.attributeValue]: ColorMetadataService.mapThemeCustomColorValue(
                        ColorMetadataService.mapOutdatedCustomColorValue(attribute.attributeColorName), theme),
                }));
        }
        return [];
    }

    getFilteredColorRange(vizModel: ChartSettings): string[] {
        const customColors: string[] = vizModel.attributeCustomColors?.reduce((acc, customColor) => {
            return acc.concat(Object.keys(customColor).map((key) => customColor[key]));
        }, [] as string[]) ?? [];

        return vizModel.colorRange?.filter((color) => !customColors.find((customColor) => customColor === color)) ?? [];
    }

    getMarginCondition(
        legendPosition: string | undefined,
        yAxisPosition: string,
        enableYAxis: boolean,
        enableAxisLabel: boolean,
    ): IMarginCondition {
        return buildMarginCondition(legendPosition, yAxisPosition, enableYAxis, enableAxisLabel);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    calculateAggValue(source: any[], formatType: AggType): string | number | undefined {
        switch (formatType) {
            case 'sum':
                return this.getSum(source);
            case 'min':
                return this.getMin(source);
            case 'max':
                return this.getMax(source);
            case 'avg':
                return (this.getSum(source) / source.length);
            case 'count':
                return source.length;
            case 'countExcludingZeros':
                return source.filter((column) => column !== 'N/A' && column !== 0).length;
            case 'countDistinct':
                return new Set(source).size;
            case 'countDistinctExcludingZeros':
                return new Set(source.filter((column) => column !== 'N/A' && column !== 0)).size;
            case 'first':
                return this.getMin(source);
            case 'last':
                return this.getMin(source);
            case 'zero':
                return 0;
            default: return;
        }
    }

    getMin(source: (string | number)[]): string | number {
        return source.reduce((min, val) => val < min ? val : min, source[0]);
    }

    getMax(source: (string | number)[]): string | number {
        return source.reduce((max, val) => val > max ? val : max, source[0]);
    }

    getSum(source: number[]): number {
        return source.reduce((sum, val) => sum + val, 0);
    }

    private getAggregatedData<T>(
        source: T[] | undefined,
        slicers: (keyof T)[],
        values: (keyof T)[],
        aggTypes: AggType[],
    ): InterimData<T>[] {
        if (!source?.length) {
            return [];
        }

        const groupedChildren = getDataGroupedBySlicers(source, slicers);
        return groupedChildren.map((children) => this.mapGroupedChildren(children, slicers, values, aggTypes));
    }

    private mapGroupedChildren<T>(
        children: T[],
        slicers: (keyof T)[],
        values: (keyof T)[],
        aggTypes: AggType[],
    ): InterimData<T> {
        const item: InterimData<T> = { values: children };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        slicers.forEach((slicer) => item[slicer as string] = children[0][slicer] as any);
        values.forEach((value, i) => {
            item[value as string] = this.valueAggregationService.aggregateValue(children, value, (aggTypes[i] || aggTypes[0]));
        });
        return item;
    }

    private getLineChartColorRange(selectedSlicer: ConfigItem, colorService: ColorMetadataService): string[] {
        selectedSlicer.colorType = ColorMetadataService.mapOutdatedLineChartColorType();
        selectedSlicer.colorName = ColorMetadataService.mapOutdatedLineChartColorName();
        const colorTypeConfig = colorService.getLineChartColorOptions();
        const colorNameConfig = colorTypeConfig.find((color) => color.label === selectedSlicer.colorName);
        return colorNameConfig ? colorNameConfig.values : [];
    }

    private getStackedAreaChartTransparentColorRange(selectedSlicer: ConfigItem, colorService: ColorMetadataService): string[] {
        const colorName = ColorMetadataService.normalizeStackedAreaTransparentColorName(selectedSlicer.colorName ?? '');
        const colorTypeConfig = colorService.getColorConfig().filter((cc) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return cc.type === (ColorType[selectedSlicer?.colorType as any] as any);
        });
        const colorNameConfig = colorTypeConfig.find((color) => color.label === colorName);
        return colorNameConfig ? ColorMetadataService.makeStackedAreaColorsSemiTransparent(colorNameConfig.values) : [];
    }

    private getRegularChartColorRange(selectedSlicer: ConfigItem, colorService: ColorMetadataService, theme: Theme): string[] {
        selectedSlicer.colorName = ColorMetadataService.mapOutdatedSolidColorName(
            ColorMetadataService.mapOutdatedMultiColorName(selectedSlicer.colorName ?? ''),
        );
        const colorTypeConfig = (selectedSlicer.colorType === ColorType[ColorType.SOLID] ?
            colorService.getSolidColorConfig(theme) :
            colorService.getColorConfig().filter((config) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return config.type === (ColorType[selectedSlicer?.colorType as any] as any);
            }));
        const colorNameConfig = colorTypeConfig.find((color) => color.label === selectedSlicer.colorName);

        return colorNameConfig ? colorNameConfig.values : [];
    }

    private getSelectedSlicer(configs: VizConfigs | undefined, slicerValue: string): ConfigItem | undefined {
        return configs?.slicers.length ? configs.slicers.find((slicer) => slicer.value === slicerValue) : configs?.values[0];
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addColorToTooltipDatum(key: string, filterByValue: string, color: string, tooltipData: any[]): void {
    const tooltipDatum = tooltipData.find((datum) => datum[filterByValue] === key);
    if (tooltipDatum) {
        tooltipDatum.color = color;
    }
}

export function getLineChartAndStackedAreaColorLabel(filterValue: string | null): string {
    if (filterValue == null) {
        return 'Null';
    }
    return filterValue.toString().trim() ? filterValue : 'Blanks';
}

export function getTooltipColorSpan(color: string): string {
    return `<span class="tooltip-color" style="background-color: ${color}"></span> `;
}

export function getStackedAreaTotals(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    source: any[],
    yField: string,
    filterByValue: string,
): { total: number, subtotals: StackedAreaSubtotal[] } {
    const stackedAreaTotals: { total: number, subtotals: StackedAreaSubtotal[] } = { total: 0, subtotals: [] };

    source.forEach((column) => {
        const slicerValue = column[filterByValue];
        const currentSubtotal = stackedAreaTotals.subtotals.find((subtotal) => Object.keys(subtotal)[0] === slicerValue);

        if (currentSubtotal) {
            currentSubtotal[slicerValue] += column[yField];
        } else {
            stackedAreaTotals.subtotals.push({ [slicerValue]: column[yField] });
        }

        stackedAreaTotals.total += column[yField];
    });

    return stackedAreaTotals;
}

export function hashFromKeys<T>(item: T, slicers: (keyof T)[]): string {
    return slicers.map((slicer) => item[slicer]).join(',');
}

export function getDataGroupedBySlicers<T>(source: T[], slicers: (keyof T)[]): T[][] {
    const aggregated: { [key: string]: T[] } = {};
    source.forEach((item) => {
        const hash = hashFromKeys(item, slicers);
        if (!aggregated[hash]) {
            aggregated[hash] = [];
        }
        aggregated[hash].push(item);
    });
    return Object.values(aggregated);
}

export function sortAggregatedData<T>(
    aggregatedData: InterimData<T>[],
    sortKeys: string[],
    tableSortDirection: string | undefined,
    sortOrderSlicer?: ConfigItem,
): InterimData<T>[] {
    if (tableSortDirection === 'CUSTOM' && sortOrderSlicer?.configCustomSortOrder) {
        const orderedValues = typeof sortOrderSlicer.configCustomSortOrder === 'string' ?
            JSON.parse(getValidString(sortOrderSlicer.configCustomSortOrder)) :
            sortOrderSlicer.configCustomSortOrder;
        return sortDataByCustomSortOrder(aggregatedData, sortOrderSlicer.value!, orderedValues);
    } else {
        const sortDirectionBoolean = tableSortDirection === 'ASC' || false;
        const sort = (a: InterimData<T>, b: InterimData<T>, keyIdx: number): number => {
            if (a[sortKeys[keyIdx]] === b[sortKeys[keyIdx]]) {
                return keyIdx < sortKeys.length ? sort(a, b, keyIdx + 1) : 0;
            }
            return (a[sortKeys[keyIdx]]! < b[sortKeys[keyIdx]]!) !== sortDirectionBoolean ? 1 : -1;
        };

        return aggregatedData.sort((a, b) => sort(a, b, 0));
    }
}

function sortDataByCustomSortOrder<T>(
    aggregatedData: InterimData<T>[],
    fieldName: string,
    orderedValues: ValueFilterOption[],
): InterimData<T>[] {
    const unsortedData = aggregatedData.filter((data) => !orderedValues.some((item) => item.value === data[fieldName]));
    const sortedData = aggregatedData.filter((data) => orderedValues.some((item) => item.value === data[fieldName]));
    unsortedData.sort((a, b) => a[fieldName]! < b[fieldName]! ? -1 : a[fieldName]! > b[fieldName]! ? 1 : 0);
    sortedData.sort((a, b) =>
        orderedValues.findIndex((item) => item.value === a[fieldName]) -
        orderedValues.findIndex((item) => item.value === b[fieldName]));
    sortedData.push(...unsortedData);
    return sortedData;
}

export function getBarManagementData<T>(
    source: InterimData<T>[],
    yField: string,
    groupByOptions: SliceManagementOptions,
    filterByValue: string | undefined,
): InterimData<T>[] {
    const { groupByType, groupByValue, showOthers } = groupByOptions;
    const newData = [];
    const total = getSumValueObj(source, yField);
    const otherData: SliceManagementOtherData = {
        [filterByValue ?? '']: 'others',
        children: [],
        [yField]: 0,
    };

    const pushOthers = (d: InterimData<T>): void => {
        otherData.children.push(d);
        (otherData[yField] as number) += (d[yField] as number);
    };

    const isGroupedByPercentage = groupByType === SliceManagement.PERCENTAGE;
    if (isGroupedByPercentage) {
        source.forEach((d) => {
            d.percentage = Number(d[yField]) / total;
            if (Math.abs(d.percentage) > groupByValue / 100) {
                newData.push(d);
            } else {
                pushOthers(d);
            }
        });
    } else {
        source.forEach((d, i: number) => {
            d.id = i;
            d.percentage = Number(d[yField]) / total;
        });
        const { newData: data, others } = sliceOthers<InterimData<T>>(source, groupByValue);
        newData.push(...data);
        others.forEach(pushOthers);
    }

    if (shouldAddOthersCategory(showOthers, otherData[yField] as number, isGroupedByPercentage, otherData.children)) {
        newData.push(otherData.children.length === 1 ? otherData.children[0] : otherData);
        return newData;
    }

    if (otherData.children.length === 1) {
        newData.push(otherData.children[0]);
    }

    return newData;
}

export function getStackedAreaNonOtherSubtotals(
    subtotals: { [filterByValue: string]: number }[],
    total: number,
    groupByValue: number,
): string[] {
    return subtotals.reduce((result, subtotal) => {
        const key = Object.keys(subtotal)[0];
        const percentage = subtotal[key] / total;

        if (Math.abs(percentage) > groupByValue / 100) {
            result.push(key);
        }

        return result;
    }, [] as string[]);
}

export function sortStackedAreaSubtotals(subA: { [filterByValue: string]: number }, subB: { [filterByValue: string]: number }): number {
    const aKey = Object.keys(subA)[0];
    const bKey = Object.keys(subB)[0];
    return Math.abs(subA[aKey]) > Math.abs(subB[bKey]) ? -1 : 1;
}

function isStackedAreaTransparentColor(visualizationType: VisualizationType, colorName: string): boolean {
    return visualizationType === 'STACKED_AREA_CHART' && colorName.includes('Transparent');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function shouldAddOthersCategory(showOthers: boolean, yFieldValue: number, isGroupedByPercentage: boolean, otherFields: any[]): boolean {
    return showOthers && (!!yFieldValue || isGroupedByPercentage && yFieldValue >= 0 && !!otherFields.length);
}

export function buildBaseChartModel(series: Series[], axis: Axis[][], dataSource: unknown[] = []): ChartSettings {
    return {
        series,
        axis,
        legend: {
            type: 'circle',
            docked: LegendPosition.RIGHT,
            inline: false,
            showCustom: true,
        },
        margin: { bottom: 60, left: 0, top: 0, right: 0 },
        enableDrilldown: false,
        highlightSlice: false,
        showLabels: false,
        dataSource,
        selector: '',
    };
}

export function buildMarginCondition(
    legendPosition: string | undefined,
    yAxisPosition: string,
    enableYAxis: boolean,
    enableAxisLabel: boolean,
): IMarginCondition {
    return {
        legendPosition: legendPosition ?? '',
        rightSideAxisFlag: yAxisPosition === 'left' || !enableYAxis || !enableAxisLabel,
        leftSideAxisFlag: yAxisPosition === 'right' || !enableYAxis || !enableAxisLabel,
    };
}
