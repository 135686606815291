<span *ngIf="criteriaPosition === 'side'" class="side-criteria">
	<ng-container *ngTemplateOutlet="criteriaListBox"></ng-container>
</span>

<div class="list-browser" [ngClass]="{'side-browser': criteriaPosition === 'side'}">
    <ng-container [ngSwitch]="headerLayout">
        <ng-container *ngSwitchCase="'no-criteria'">
            <ng-container *ngTemplateOutlet="no_criteria"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'top-criteria'">
            <ng-container *ngTemplateOutlet="top_criteria"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'show-selected'">
            <ng-container *ngTemplateOutlet="show_selected"></ng-container>
        </ng-container>

        <ng-container *ngSwitchDefault></ng-container>
    </ng-container>

    <ng-container *ngTemplateOutlet="searchBox"></ng-container>

    <ng-container *ngTemplateOutlet="searchList"></ng-container>
</div>

<ng-template #searchList>
    <div class="no-data error" *ngIf="noFiltersAvailable">
        <span>NO FILTERS AVAILABLE</span>
    </div>

    <ul class="search-list" *ngIf="!noFiltersAvailable">
        <li class="header-row" *ngIf="headers">
            <span class="header" *ngFor="let header of headers">{{ header }}</span>
        </li>
        <ng-container>
            <cdk-virtual-scroll-viewport [itemSize]="itemSize" [minBufferPx]="300" [maxBufferPx]="1300" [style.height.px]="virtualScrollViewportHeight">
                <ng-container *cdkVirtualFor="let option of getValueOptions(showOnlySelected) | listBrowserFilter : searchValue; templateCacheSize: 0">
                    <ng-container *ngTemplateOutlet="item; context: { ctx: option }"></ng-container>
                </ng-container>
            </cdk-virtual-scroll-viewport>
        </ng-container>
    </ul>
</ng-template>

<ng-template #searchBox>
    <div class="search-box">
        <input
            type="search"
            name="searchValue"
            placeholder="{{placeholder}}"
            autocomplete="off"
            [(ngModel)]="searchValue"
            (ngModelChange)="onSearchValueChanged()"/>

        <span class="icon-search1"></span>

        <button class="fill-btn" type="button" (click)="addValues()">
            {{buttonLabel}}
        </button>
    </div>
</ng-template>

<ng-template #criteriaListBox>
    <app-dropdown
        id="include-exclude"
        [options]="criteriaOptions"
        [(selected)]="selectedCriteria"
        (selectedChange)="updateSelectedCriteria($event)">
    </app-dropdown>
</ng-template>

<ng-template #no_criteria>
    <ul class="top-menu no-criteria">
        <li>
            <span>Selected ({{ selectedCount }} OF {{ options.length }})</span>
        </li>

        <li>
            <button type="button" (click)="toggleValues(true)" class="empty-btn">Select All</button>
        </li>

        <li>
            <button type="button" (click)="toggleValues(false)" class="empty-btn">Deselect All</button>
        </li>
    </ul>
</ng-template>

<ng-template #top_criteria>
    <ul class="top-menu">
        <li class="pos-rel">
            <ng-container *ngTemplateOutlet="criteriaListBox"></ng-container>
        </li>

        <li>
            <span class="selected-count">{{ selectedCount }} OF {{ options.length }}</span>
        </li>

        <li>
            <button type="button" (click)="toggleValues(true)" class="empty-btn">Select All</button>
        </li>

        <li>
            <button type="button" (click)="toggleValues(false)" class="empty-btn">Deselect All</button>
        </li>
    </ul>
</ng-template>

<ng-template #show_selected>
    <ul class="top-menu no-criteria">
        <li>
            <cc-checkbox
                id="show-only-selected"
                [label]="'Show selected'"
                [(value)]="showOnlySelected"
                (click)="onShowOnlySelected()">
            </cc-checkbox>

            <span>({{ selectedCount }} OF {{ options.length }})</span>
        </li>

        <li>
            <button type="button" (click)="toggleValues(true)" class="empty-btn">Select All</button>
        </li>

        <li>
            <button type="button" (click)="toggleValues(false)" class="empty-btn">Deselect All</button>
        </li>
    </ul>
</ng-template>

<ng-template #item let-option="ctx">
    <li
            class="checkbox"
            [ngClass]="{'selected': option.checked}">
        <cc-checkbox
                [label]="!searchValue ? option.texts[0] : ''"
                [(value)]="!!option.checked"
                (valueChange)="onCheckboxChanged()">
            <span
                class="highlight"
                innerHTML="{{ searchValue && option.texts[0] | highlight : searchValue }}">
            </span>
        </cc-checkbox>

        <span
            *ngFor="let text of option.texts.slice(1)"
            innerHTML="{{ text | highlight : searchValue }}">
        </span>
    </li>
</ng-template>
