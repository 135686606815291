import { NgModule } from '@angular/core';
import { HttpModule } from '@ddv/http';

import { NamedQueriesService } from './services/named-queries.service';

@NgModule({
    imports: [HttpModule],
    providers: [NamedQueriesService],
    declarations: [],
    exports: [],
})
export class NamedQueriesModule { }
