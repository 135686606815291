import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface DashboardModeAndId {
    mode: string | null;
    id: number | null;
}

@Injectable({ providedIn: 'root' })
export class CurrentStateService {
    public readonly isMultiClient$: Observable<boolean>;
    public readonly clientCode$: Observable<string>;
    public readonly dashboardModeAndId$: Observable<DashboardModeAndId>;
    public readonly currentWidgetId$: Observable<number | null>;
    public readonly currentDatasetDefinitionId$: Observable<number | null>;

    private readonly currentDatasetDefinitionId = new BehaviorSubject<number | null>(null);
    private readonly currentWidgetId = new BehaviorSubject<number | null>(null);
    private readonly clientCode = new ReplaySubject<string>(1);
    private readonly isMultiClient = new ReplaySubject<boolean>(1);
    private readonly dashboardModeAndId = new BehaviorSubject<DashboardModeAndId>({ id: null, mode: null });
    private currentClientCode: string | null = null;
    private readonly routeSubscriptions: Subscription[] = [];

    constructor(private readonly route: ActivatedRoute, private readonly router: Router) {
        this.isMultiClient$ = this.isMultiClient.asObservable();
        this.clientCode$ = this.clientCode.asObservable();
        this.dashboardModeAndId$ = this.dashboardModeAndId.asObservable();
        this.currentWidgetId$ = this.currentWidgetId.asObservable();
        this.currentDatasetDefinitionId$ = this.currentDatasetDefinitionId.asObservable();

        // the routing tree seems to be rebuilt on certain router events, so we'll just re-subscribe every time we navigate
        this.router.events
            .pipe(filter((event: Event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.routeSubscriptions.forEach((s) => s.unsubscribe());
                this.routeSubscriptions.splice(0);  // empty the array
                this.subscribe(this.route.root);
            });
    }

    private static emitIfChanged(
        params: Params,
        paramsProp: string,
        subject: BehaviorSubject<number | null>,
        convertToNumber: boolean = true,
    ): boolean | undefined {
        if (!hasProperty(params, paramsProp)) {
            return;
        }

        let newValue;
        if (convertToNumber) {
            newValue = params[paramsProp] === 'none' ? null : Number(params[paramsProp]);
        } else {
            newValue = params[paramsProp];
        }

        if (newValue !== subject.getValue()) {
            subject.next(newValue);
            return true;
        }

        return false;
    }

    private subscribe(route: ActivatedRoute): void {
        this.routeSubscriptions.push(route.params.subscribe(this.updateState.bind(this)));
        route.children.forEach((r) => this.subscribe(r));
    }

    private updateState(params: Params): void {
        if (hasProperty(params, 'clientCode')) {
            const newClientCode = params.clientCode;

            // the whole apps assume it should completely reinit itself when "the client code changes"
            // therefore, we can only emit a clientCode when its actually changed
            if (newClientCode !== this.currentClientCode) {
                this.isMultiClient.next(newClientCode.includes('MLTI') || newClientCode.includes('UT58UAT'));
                this.currentClientCode = newClientCode;
                this.clientCode.next(newClientCode);
            }
        }

        if (hasProperty(params, 'dashboardId')) {
            const newId = params.dashboardId === 'none' ? null : Number(params.dashboardId);
            const newMode = params.mode;  // if it's got dashboardId, it's got mode so no need to check it also
            const currentValue = this.dashboardModeAndId.getValue();

            if (newId !== currentValue.id || newMode !== currentValue.mode) {
                this.dashboardModeAndId.next({
                    mode: newMode,
                    id: newId,
                });
            }
        }

        CurrentStateService.emitIfChanged(params, 'coreWidgetId', this.currentWidgetId);
        CurrentStateService.emitIfChanged(params, 'datasetDefinitionId', this.currentDatasetDefinitionId);
    }
}

function hasProperty(obj: unknown, prop: string): boolean {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}
