import { NgModule } from '@angular/core';
import { CrosstalkModule } from '@ddv/crosstalk';
import { DatasetDefinitionsModule } from '@ddv/dataset-definitions';
import { EntitlementsModule } from '@ddv/entitlements';
import { FiltersModule } from '@ddv/filters';
import { HttpModule } from '@ddv/http';
import { LayoutModule } from '@ddv/layout';
import { ReferenceDataModule } from '@ddv/reference-data';
import { UsageTrackingModule } from '@ddv/usage-tracking';

import { CompareModeService } from './services/compare-mode.service';
import { DataFastnessService } from './services/data-fastness.service';
import { DatasetManagerService } from './services/dataset-manager.service';
import { MetadataService } from './services/metadata.service';
import { WidgetDataSourceService } from './services/widget-datasource.service';

@NgModule({
    imports: [
        CrosstalkModule,
        DatasetDefinitionsModule,
        EntitlementsModule,
        FiltersModule,
        HttpModule,
        LayoutModule,
        ReferenceDataModule,
        UsageTrackingModule,
    ],
    providers: [
        CompareModeService,
        DataFastnessService,
        DatasetManagerService,
        MetadataService,
        WidgetDataSourceService,
    ],
})
export class DatasetsModule {}
