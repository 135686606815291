import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, TemplateRef } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ConversationHighlight, CrosstalkService } from '@ddv/crosstalk';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { Entitlements, UserEntitlements, UserEntitlementService, UserService } from '@ddv/entitlements';
import { ClientDatasetFilterService, QueryParamsService } from '@ddv/filters';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { LayoutService, ManagerService } from '@ddv/layout';
import {
    LinkConfiguration,
    BoardWidgetDefinitionIds,
    Client,
    DATASET_KEY,
    FLAG_NOT_SET,
    GRAPHQL_ERROR_MESSAGE,
    HI_DATA_CLIENT_ERROR_MESSAGE,
    HI_DATA_USER_ERROR_MESSAGE,
    MANAGE_WIDGET_ID,
    MANAGE_WIDGET_WS_ID,
    MODE,
    NO_ENTITLEMENTS,
    WIDGET_KEY,
    WIDGET_LIFECYCLE_EVENT,
    crosstalkCommentFields,
    CrosstalkFields,
    CompareMode,
    DashboardPreference,
    toDefaultQueryParams,
    toPrioritizedFilterParams,
    WidgetData,
    DatasetFetchRuntimeParams,
    Query,
    QueryTypeName,
    DdvDate,
    UserPreferences,
    FilterPreference,
    FilterQueryParam,
    Fund,
    FuzzyDates,
    AppWidgetState,
    WidgetFilterParams,
    TrebekConversationFields,
    UserDefinedField,
    isTradeFileDetails,
    crosstalkColumns,
    HSColumnDefinition,
    MetadataLookup,
} from '@ddv/models';
import { ClientsService, FundsService, FuzzyDatesService } from '@ddv/reference-data';
import { UsageTracker, UsageTrackingService } from '@ddv/usage-tracking';
import { getUTCDate, deepCompare } from '@ddv/utils';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, throwError, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, takeWhile, withLatestFrom } from 'rxjs/operators';

import { IDataLoad } from '../models/data-load';
import { CompareModeService } from './compare-mode.service';
import { DataFastnessService } from './data-fastness.service';
import { MetadataService, splitEndpointIntoClientAndRoute } from './metadata.service';
import { WidgetDataSourceService } from './widget-datasource.service';

interface Links {
    links?: { [key: string]: LinkConfiguration };
}

interface TrebekQueryResponseRow extends Links {
    selectedColumnData: WidgetData;
}

interface TrebekTracingInfo {
    upstream?: string;
    routedTo?: string;
    upstreamQuery?: {
        apiRouteUsed: string;
        method: string;
        body: Record<string, unknown> | null;
    }[];
}

export interface TrebekQueryResponse extends Links {
    data: TrebekQueryResponseRow[];
    tracingInfo?: TrebekTracingInfo;
    error?: string;
    queryId?: string;
}

export type PublicApiResponseRow = (WidgetData & Links);

export type DatedPublicApiResponseRow = (PublicApiResponseRow & { date?: string });

export interface PublicAPIQueryResponse {
    success: boolean;
    data: PublicApiResponseRow[];
    dataSourceName?: string;
    retryAfter?: number;
    error?: unknown;
}

export function postProcessPublicApiResponse(data: TrebekQueryResponseRow[]): PublicApiResponseRow[] {
    return data.map((row) => {
        const processed: PublicApiResponseRow = {
            ...row.selectedColumnData,
            links: row.links ?? {},
        } as PublicApiResponseRow;

        if (Object.prototype.hasOwnProperty.call(processed, 'date') && !Object.prototype.hasOwnProperty.call(processed, 'Date')) {
            processed.Date = processed.date;
        }

        return processed;
    });
}

@Injectable()
export class DatasetManagerService {
    // TODO: public state
    dataLoadedTime: Map<string, string> = new Map();
    crosstalkSchemaFields: Map<number, { name: string, displayName: string }[]> = new Map();
    sourceDataCopy: PublicApiResponseRow[] | undefined;

    private dashboardPref: DashboardPreference = {};
    private readonly fetchSubjectsByWidgetId: Map<number, BehaviorSubject<IDataLoad>> = new Map();
    private readonly fetchedDataWithParams: Map<string, WidgetData[]> = new Map();
    private readonly widgetIdUniqueKeyMap: Map<number, string> = new Map();
    private dashboardQueryParam: FilterQueryParam = {};
    private widgetQueryParam: FilterPreference | undefined;
    private readonly widgetFilterParam: Map<number, FilterQueryParam> = new Map();
    private dashboardSubscription: Subscription | undefined;
    private widgetSubscription: Subscription | undefined;
    private readonly datasetLoading: Map<string, boolean> = new Map();
    private fundOptions: Fund[] = [];
    private clientOptions: Client[] = [];
    private readonly datasetErrors: Map<string, string> = new Map();
    private fetchDatasetSubscription: Record<string, Subscription> = {};
    private fetchDataSubscription: Record<string, Subscription> = {};
    private readonly datasetCancelled: Map<string, boolean> = new Map();
    private userPreference: UserPreferences | undefined;
    private fastnessCheckDatasetIds: string[] = [];
    private refreshTimerSubscriptions: { [key: number]: Subscription } = {};
    private onRealtimeUpdate = false;
    private isMultiClient: boolean = false;
    private fullClientsList: string = '';
    private isDetailWidgetOpened = false;
    private haveCrosstalkCommentImport: boolean = false;
    private readonly widgetData: Map<string, PublicApiResponseRow[]> = new Map();
    private readonly widgetCompareData: Map<string, PublicApiResponseRow[]> = new Map();
    private udfMetadata: HSColumnDefinition[] = [];

    constructor(
        private readonly currentStateService: CurrentStateService,
        private readonly managerService: ManagerService,
        private readonly layoutService: LayoutService,
        private readonly fundsService: FundsService,
        private readonly clientsService: ClientsService,
        private readonly userService: UserService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly dataFastnessService: DataFastnessService,
        private readonly queryParamsService: QueryParamsService,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly usageTracking: UsageTrackingService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly crosstalk: CrosstalkService,
        private readonly userEntitlementsService: UserEntitlementService,
        private readonly metadataService: MetadataService,
        private readonly compareModeService: CompareModeService,
        private readonly clientDatasetFilterService: ClientDatasetFilterService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
    ) {
        combineLatest([this.widgetDataSourceService.dataSource$, this.compareModeService.currentWidgetInCompareMode]).subscribe({
            next: ([res, currentWidgetId]) => {
                if (res.lastChangedDataset) {
                    const datasource = res.datasources.find((dw) => dw.uniqueKey === res.lastChangedDataset);
                    if (!datasource) {
                        return;
                    }

                    this.widgetIdUniqueKeyMap.forEach((uniqueKey: string, widgetId: number) => {
                        if (uniqueKey === res.lastChangedDataset) {
                            if (widgetId === currentWidgetId) {
                                this.compareModeService.updateCompareDataByWidgetId(widgetId, datasource.compareData);
                            }

                            this.fetchSubjectsByWidgetId.get(widgetId)?.next({
                                isLoaded: true,
                                data: datasource?.data,
                                compareData: this.compareModeService.compareDataMap.get(widgetId),
                                compareMode: datasource?.compareMode,
                            });

                            this.fetchedDataWithParams.set(uniqueKey, datasource?.data);
                        }
                    });
                }
            },
        });

        this.userService.userPreferences$.subscribe({
            next: (userPreferences) => {
                this.userPreference = userPreferences;
            },
        });

        this.currentStateService.isMultiClient$
            .pipe(switchMap((isMultiClient) => {
                this.isMultiClient = isMultiClient;

                if (this.isMultiClient) {
                    return this.clientsService.clients();
                }

                return this.fundsService.funds();
            }))
            .subscribe({
                next: (data: Fund[] | string[]) => {
                    if (data.some((datum) => datum instanceof Fund)) {
                        this.fundOptions = data as Fund[];
                    } else {
                        this.fullClientsList = data.join(',');
                        this.clientOptions = (data as string[]).map((clientCode) => {
                            return { clientId: clientCode, clientName: clientCode };
                        });
                    }
                },
            });

        this.managerService.isDetailWidgetOpened.subscribe({
            next: (isOpened) => {
                this.isDetailWidgetOpened = isOpened;
            },
        });

        this.userEntitlementsService.entitlementsForClientCode$.subscribe({
            next: (entitlements: UserEntitlements) => {
                this.haveCrosstalkCommentImport = entitlements.haveCrosstalkCommentImport;
            },
        });

        this.metadataService.metadataState.subscribe((metadataState) => {
            if (metadataState?.metadata.size) {
                const metadata: MetadataLookup | undefined = metadataState.metadata.get(metadataState.lastChangedWidgetId!);
                if (metadata) {
                    this.udfMetadata = Object.values(metadata).filter((metadataItem) => metadataItem.name.startsWith('udf_'));
                }
            }
        });
    }

    getQueryParam(queryParam: DashboardPreference | FilterPreference | undefined): FilterQueryParam {
        return {
            startDate: queryParam?.startDate,
            endDate: queryParam?.endDate,
            activeDate: queryParam?.activeDate,
            funds: queryParam?.funds,
            comparing: queryParam?.comparing,
            isComparing: queryParam?.isComparing,
            compareDates: queryParam?.compareDates,
            isPreferenceChangedOnRefresh: (queryParam as DashboardPreference)?.isPreferenceChangedOnRefresh, // only on DashboardPreference
            timestamp: (queryParam as FilterPreference)?.timestamp, // only on FilterPreference
            clients: queryParam?.clients,
            acknowledged: (queryParam as DashboardPreference)?.acknowledged, // only on DashboardPreference
        };
    }

    addCrosstalkFieldsToData(
        data: (WidgetData & { links: { [key: string]: LinkConfiguration } }),
        clientCode: string,
        highlight: ConversationHighlight,
        columnId: CrosstalkFields.ClientComment | CrosstalkFields.HSComment,
        isUserEntitledToUploadAttachments: boolean,
        isUserEntitledToUploadBulkAttachments: boolean,
    ): void {
        const comment = highlight[columnId];
        const colId = `conversation${columnId[0].toUpperCase()}${columnId.substring(1)}`;

        data[colId] = comment ? comment.message : undefined;
        data[`${colId}Author`] = comment ? comment.createdBy : undefined;
        data[`${colId}Created`] = comment ? comment.created : '';
        data[`${colId}_settings`] = highlight.settings;
        data.conversationId = highlight.id;

        data[CrosstalkFields.Attachments] = highlight.lastAttachments?.length ? 'Attachments' : 'No Attachments';

        this.addCrosstalkLinksToData(clientCode, data);
        this.addCrosstalkAttachmentsToData(
            data,
            clientCode,
            highlight,
            isUserEntitledToUploadAttachments,
            isUserEntitledToUploadBulkAttachments,
        );
    }

    addUDFsToData(
        udfs: UserDefinedField[],
        highlight: ConversationHighlight,
        data: (WidgetData & { links: { [key: string]: LinkConfiguration } }),
    ): void {
        if (highlight.userDefinedFields?.length) {
            highlight.userDefinedFields.forEach((udf) => {
                udf.conversationId = highlight.id;
                if ((udf.type === 'string' || udf.type === 'choice') && udf.value == null) {
                    udf.value = '';
                }
                data[`udf_${udf.name}`] = udf;
            });
        } else {
            udfs.forEach((udf) => {
                data[`udf_${udf.name}`] = {
                    conversationId: highlight.id,
                    name: udf.name,
                    type: udf.type,
                    valueEditableBy: udf.valueEditableBy,
                    value: (udf.type === 'string' || udf.type === 'choice') ? '' : undefined,
                };
            });
        }
    }

    fetchUpdatedConversations(
        widgetPreferences: AppWidgetState,
        data: (WidgetData & { links: { [key: string]: LinkConfiguration } })[],
    ): Observable<WidgetData[]> {
        const clientCode = widgetPreferences.clientCode;
        const datasetDefinition = widgetPreferences.datasetDefinition;
        const conversableType = datasetDefinition?.conversableType;

        if (!datasetDefinition || !clientCode || !conversableType) {
            return of([]); // this should probably throw
        }

        return this.crosstalk.getSchema(clientCode, conversableType)
            .pipe(mergeMap((schema) => {
                if (this.haveCrosstalkCommentImport) {
                    const crosstalkFields: { name: string, displayName: string }[] = [];
                    schema.fields.forEach((field) => {
                        crosstalkFields.push({
                            name: `${field}_crosstalk_key`,
                            displayName: field,
                        });
                    });
                    this.crosstalkSchemaFields.set(datasetDefinition.id!, crosstalkFields);
                }

                return this.crosstalk.fetchUpdatedConversations(clientCode, conversableType, data.map((datum) => schema.liftKeys(datum)))
                    .pipe(
                        withLatestFrom(this.userEntitlementsService.entitlementsForClientCode$),
                        map(([highlights, entitlements]) => {
                            const isUserEntitledToUploadAttachments = entitlements.hasPermission(Entitlements.ATTACHMENT_EDIT) ||
                                entitlements.hasPermission(Entitlements.CROSSTALK_RESTRICTED_ATTACHMENT);
                            const isUserEntitledToUploadBulkAttachments = entitlements.hasPermission(Entitlements.ATTACHMENT_EDIT) ||
                                entitlements.hasPermission(Entitlements.CROSSTALK_RESTRICTED_ATTACHMENT);

                            highlights.forEach((highlight, index) => {
                                crosstalkCommentFields.forEach(() => {
                                    this.addCrosstalkAttachmentsToData(
                                        data[index],
                                        clientCode,
                                        highlight,
                                        isUserEntitledToUploadAttachments,
                                        isUserEntitledToUploadBulkAttachments);
                                });
                            });

                            return data;
                        }));
            }));
    }

    // Only called by ApplicationBaseWidget.fetchDataset
    getDataSet(widgetPreferences: AppWidgetState, fastnessCheckTemplate: TemplateRef<string> | undefined): Observable<IDataLoad> {
        const dashboardId = this.managerService.getCurrentDashboardId();
        const widgetId = widgetPreferences.id;

        if (!widgetId || !dashboardId) {
            return throwError(() => new Error('cannot get dataset when dashboardId or widgetId are undefined'));
        }

        const fetchSubject = this.getFetchSubject(widgetId);

        // this and the below should NOT be here.  something else should be watching for
        // filter changes and then just calling this service to request new data
        if (!this.dashboardSubscription) {
            this.subscribeDashboardQueryParams();
        }

        if (!this.widgetSubscription) {
            this.subscribeWidgetQueryParams();
        }

        const uniqueKey = this.getUniqueKey(widgetPreferences);
        this.widgetIdUniqueKeyMap.set(widgetId, uniqueKey);  // for subscribed board widgets, this is always the dsd

        // this is used for realtime updates
        // it will trigger reload every 20 seconds if the widget is with polling on
        // or only once if it is not, and it will change 'on the fly' if the toggle is changed
        // it will skip a reload if the previous hasn't finished yet,
        // and it will complete if the widget is not on the board
        const { isDetailWidget, realtimeUpdates } = widgetPreferences;
        const realtimeUpdatesInterval = 20000; // ms
        const refreshTimer = timer(0, realtimeUpdatesInterval)
            .pipe(filter((numEmitted) => numEmitted === 0 || !!realtimeUpdates))
            .pipe(filter(() => (!this.isDetailWidgetOpened && !this.datasetLoading.get(uniqueKey)) || !!isDetailWidget))
            .pipe(takeWhile(() => this.widgetIdUniqueKeyMap.has(widgetId)));

        if (this.refreshTimerSubscriptions[widgetId]) {
            this.refreshTimerSubscriptions[widgetId].unsubscribe();
        }

        this.refreshTimerSubscriptions[widgetId] = refreshTimer.subscribe({
            next: (): void => {
                if (widgetPreferences.isSubscribedToDashboardFilters) {
                    const cachedData = this.fetchedDataWithParams.get(uniqueKey);
                    if (cachedData && !widgetPreferences.realtimeUpdates) {
                        if (this.datasetErrors.get(uniqueKey)) {
                            this.sendMessage(
                                widgetId,
                                {
                                    action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                                    exception: { message: GRAPHQL_ERROR_MESSAGE, time: this.datasetErrors.get(uniqueKey) },
                                });
                        }
                        setTimeout(() => fetchSubject.next({ isLoaded: !this.datasetLoading.get(uniqueKey), data: cachedData }), 100);
                    } else {
                        if (!this.fetchDatasetSubscription[uniqueKey] || widgetPreferences.realtimeUpdates) {
                            this.datasetLoading.set(uniqueKey, true);
                            if (Object.keys(this.dashboardPref).length) {  // I have no idea what this does.
                                const { clientCode, datasetDefinition, isSubscribedToDashboardFilters } = widgetPreferences;
                                if (!clientCode) {
                                    return console.error(`no client code on widget preferences ${widgetPreferences.id}`);
                                }

                                const ids = new BoardWidgetDefinitionIds(dashboardId, widgetId, datasetDefinition?.id);
                                if (datasetDefinition?.queryType?.name === QueryTypeName.RECON) {
                                    if (!this.fastnessCheckDatasetIds.includes(uniqueKey)) {
                                        this.fastnessCheckDatasetIds.push(uniqueKey);
                                        // we don't care about client and funds here
                                        const hsDefaults = toDefaultQueryParams(
                                            [],
                                            [],
                                            // this is a clue to the proper type for getExtraParametersForWorkspace
                                            this.managerService.getExtraParametersForWorkspace());
                                        const startDate = this.dashboardPref.startDate ?? hsDefaults.startDate;
                                        const endDate = this.dashboardPref.endDate ?? hsDefaults.endDate;
                                        this.dataFastnessService.checkDataFastness(startDate ?? '', endDate ?? '', fastnessCheckTemplate).subscribe({
                                            next: (fastnessDataPromise: Promise<boolean>) => {
                                                fastnessDataPromise
                                                    .then((result) => {
                                                        if (result) {
                                                            this.fetchData(ids, isSubscribedToDashboardFilters, uniqueKey, clientCode);
                                                        } else {
                                                            setTimeout(() => {
                                                                const dataWrapper = { uniqueKey, data: [], compareMode: undefined };
                                                                this.widgetDataSourceService.addDataSource(dataWrapper);
                                                            }, 0);
                                                        }
                                                    })
                                                    .catch((error) => console.error(error));

                                                this.removeFastnessCheckDatasetId(uniqueKey);
                                            },
                                        });
                                    }
                                } else {
                                    this.fetchData(ids, isSubscribedToDashboardFilters, uniqueKey, clientCode);
                                }
                            }
                        }
                    }

                    if (this.datasetCancelled.get(uniqueKey)) {
                        this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.DATA_LOADING_CANCELLED);
                    } else if (this.datasetLoading.get(uniqueKey)) {
                        if (!widgetPreferences.hideLoaderAfterFirstDataLoad) {
                            this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);
                        }
                    }
                } else {
                    // this seems way out of place.  why would 'getDataset' do this?
                    this.onRealtimeUpdate = true;
                    if (widgetPreferences.id) {
                        this.queryParamsService.addWidgetQueryParam(widgetPreferences.id, this.getWidgetFilterParams(widgetPreferences));
                    }
                }
            },
        });

        return fetchSubject.asObservable();
    }

    updateViewWidgetQueryParams(widgetPrefs: AppWidgetState): void {
        if (!widgetPrefs.id || !widgetPrefs.widgetFilters) {
            return;
        }

        this.onRealtimeUpdate = false;
        this.queryParamsService.addWidgetQueryParam(widgetPrefs.id, widgetPrefs.widgetFilters);
    }

    areParamsTheSame(oldParams: FilterQueryParam, newParams: FilterQueryParam, fuzzyDates: FuzzyDates): boolean {
        if (deepCompare(oldParams, newParams)) { // completely the same
            return true;
        }

        // filters object stripped of the dates
        const oldParameters = this.getParamsObject(oldParams);
        const newParameters = this.getParamsObject(newParams);

        if (!deepCompare(oldParameters, newParameters)) { // there is a change in the non-date filters
            return false;
        }

        // we need this because in Edit mode dates are null, but in View mode they are already overridden by user preferences
        const oldParamsStartDate = oldParams.startDate ?? this.userPreference?.startDate;
        const oldParamsEndDate = oldParams.endDate ?? this.userPreference?.endDate;
        const newParamsStartDate = newParams.startDate ?? this.userPreference?.startDate;
        const newParamsEndDate = newParams.endDate ?? this.userPreference?.endDate;

        let oldStartDate: Date | undefined;
        if (oldParamsStartDate && DdvDate.isStringValidDate(oldParamsStartDate)) {
            oldStartDate = getUTCDate(oldParamsStartDate);
        } else {
            const fuzzyDate = fuzzyDates.from.findByName(oldParamsStartDate);
            oldStartDate = fuzzyDate?.actualDate;
        }

        let oldEndDate: Date | undefined;
        if (oldParamsEndDate && DdvDate.isStringValidDate(oldParamsEndDate)) {
            oldEndDate = getUTCDate(oldParamsEndDate);
        } else {
            const fuzzyDate = fuzzyDates.to.findByName(oldParamsEndDate);
            oldEndDate = fuzzyDate?.actualDate;
        }

        let newStartDate: Date | undefined;
        if (newParamsStartDate && DdvDate.isStringValidDate(newParamsStartDate)) {
            newStartDate = getUTCDate(newParamsStartDate);
        } else {
            const fuzzyDate = fuzzyDates.from.findByName(newParamsStartDate);
            newStartDate = fuzzyDate?.actualDate;
        }

        let newEndDate: Date | undefined;
        if (newParamsEndDate && DdvDate.isStringValidDate(newParamsEndDate)) {
            newEndDate = getUTCDate(newParamsEndDate);
        } else {
            const fuzzyDate = fuzzyDates.to.findByName(newParamsEndDate);
            newEndDate = fuzzyDate?.actualDate;
        }

        if (!oldStartDate || !oldEndDate || !newStartDate || !newEndDate) {
            return false;
        }

        return newStartDate.getTime() === oldStartDate.getTime() && newEndDate.getTime() === oldEndDate.getTime();
    }

    subscribeDashboardQueryParams(): void {
        combineLatest([
            this.fuzzyDatesService.fuzzyDates().pipe(take(1)),
            this.currentStateService.clientCode$,
        ]).subscribe({
            next: ([fuzzyDates, clientCode]) => {
                this.dashboardSubscription = this.queryParamsService.dashboardQueryParams.subscribe({
                    next: (dashboardPref: DashboardPreference) => {
                        this.dashboardPref = dashboardPref;
                        const dashboardId = this.managerService.getCurrentDashboardId();
                        const currentQueryParam = this.getQueryParam(dashboardPref);

                        if (currentQueryParam.comparing === CompareMode.BOTH ||
                            !this.areParamsTheSame(this.dashboardQueryParam, currentQueryParam, fuzzyDates)
                        ) {
                            delete this.dashboardPref.isPreferenceChangedOnRefresh;
                            this.dashboardQueryParam = this.getQueryParam(this.dashboardPref);

                            this.sendDataLoadingMessage();

                            const uniqueKeys = new Set(this.widgetIdUniqueKeyMap.values());
                            const subscribedKeys = Array.from(uniqueKeys).filter((uniqueKey) => uniqueKey.indexOf(DATASET_KEY) === 0);
                            subscribedKeys.forEach((uniqueKey) => {
                                const datasetId = Number(uniqueKey.slice(DATASET_KEY.length, uniqueKey.length));
                                this.datasetLoading.set(uniqueKey, true);
                                this.fetchData(
                                    new BoardWidgetDefinitionIds(dashboardId, undefined, datasetId),
                                    true,
                                    uniqueKey,
                                    clientCode);
                            });
                        } else {
                            if (!deepCompare(this.dashboardQueryParam, currentQueryParam)) {
                                this.dashboardQueryParam = this.getQueryParam(this.dashboardPref);
                                this.sendDataUpdateMessage(dashboardPref);
                            }
                        }
                    },
                });
            },
        });
    }

    subscribeWidgetQueryParams(): void {
        this.currentStateService.clientCode$.subscribe({
            next: (clientCode) => {
                this.widgetSubscription = this.queryParamsService.widgetQueryParams.subscribe({
                    next: (params: WidgetFilterParams | undefined) => {
                        if (params?.lastChangedWidgetId) {
                            this.widgetQueryParam = params.widgetFilters.get(params.lastChangedWidgetId);
                            const currentQueryParam = this.getQueryParam(this.widgetQueryParam);
                            const widgetPreferences = this.managerService.getWidgetPreferences(params.lastChangedWidgetId);
                            if (!widgetPreferences?.id) {
                                return;
                            }

                            if (
                                (
                                    currentQueryParam.isComparing && currentQueryParam.comparing === CompareMode.BOTH
                                ) ||
                                (
                                    widgetPreferences?.datasetDefinition?.id &&
                                    !deepCompare(this.widgetFilterParam.get(params.lastChangedWidgetId), currentQueryParam)
                                ) ||
                                (
                                    widgetPreferences?.realtimeUpdates && this.onRealtimeUpdate
                                )
                            ) {
                                const uniqueKey = this.getUniqueKey(widgetPreferences);
                                if (widgetPreferences.realtimeUpdates) {
                                    this.datasetLoading.set(uniqueKey, true);
                                }
                                const dashboardId = this.managerService.getCurrentDashboardId();
                                this.sendMessage(widgetPreferences.id, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);
                                this.fetchData(
                                    new BoardWidgetDefinitionIds(
                                        dashboardId,
                                        widgetPreferences.id,
                                        widgetPreferences.datasetDefinition?.id,
                                    ),
                                    false,
                                    uniqueKey,
                                    widgetPreferences.clientCode ?? clientCode);
                                this.widgetFilterParam.set(params.lastChangedWidgetId, currentQueryParam);
                            }
                        }
                    },
                });
            },
        });
    }

    getUniqueKey(widgetPreferences?: AppWidgetState): string {
        return widgetPreferences?.isSubscribedToDashboardFilters ?
            `${DATASET_KEY}${widgetPreferences?.datasetDefinition?.id}` :
            `${WIDGET_KEY}${widgetPreferences?.id}`;
    }

    removeDataset(widgetPrefs: AppWidgetState): void {
        if (widgetPrefs?.id) {
            this.fetchSubjectsByWidgetId.delete(widgetPrefs.id);
            this.widgetIdUniqueKeyMap.delete(widgetPrefs.id);
            const uniqueKeys = new Set(this.widgetIdUniqueKeyMap.values());
            this.widgetFilterParam.delete(widgetPrefs.id);
            this.queryParamsService.removeWidgetQueryParam(widgetPrefs.id);
            const uniqueKey = this.getUniqueKey(widgetPrefs);
            if (!uniqueKeys.has(uniqueKey)) {
                this.cancelRequest(uniqueKey);
                this.datasetErrors.delete(uniqueKey);
                this.datasetCancelled.delete(uniqueKey);
                this.fetchedDataWithParams.delete(uniqueKey);
                this.dataLoadedTime.delete(uniqueKey);
                this.widgetDataSourceService.removeDataSource(uniqueKey);
            }
        }
    }

    clearDashboardPreference(): void {
        this.dashboardPref = {};
        this.dashboardQueryParam = {};
    }

    cancelRequest(uniqueKey: string, widgetId?: number): void {
        this.dataLoadedTime.delete(uniqueKey);
        if (uniqueKey.indexOf(DATASET_KEY) === 0 && widgetId) {
            this.datasetCancelled.set(uniqueKey, true);
            this.widgetIdUniqueKeyMap.forEach((uKey: string, wId: number) => {
                if (uniqueKey === uKey && widgetId !== wId) {
                    this.sendMessage(wId, WIDGET_LIFECYCLE_EVENT.DATA_LOADING_CANCELLED);
                }
            });
        }

        if (this.fetchDatasetSubscription[uniqueKey]) {
            this.fetchDatasetSubscription[uniqueKey].unsubscribe();
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this.fetchDatasetSubscription[uniqueKey];
        }

        if (this.fetchDataSubscription[uniqueKey]) {
            this.fetchDataSubscription[uniqueKey].unsubscribe();
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this.fetchDataSubscription[uniqueKey];
            this.datasetLoading.delete(uniqueKey);
        }
    }

    sendMessage(widgetId: number, message: unknown): void {
        this.managerService.sendMessageToExistingWidget(widgetId, message);
    }

    private getWidgetFilterParams(widgetPrefs: AppWidgetState): FilterPreference | FilterQueryParam | undefined {
        let widgetFilters: FilterPreference | FilterQueryParam | undefined;
        if (widgetPrefs.id !== MANAGE_WIDGET_ID) {
            const widgetSetting = widgetPrefs.widgetSettings?.find((widSetting) => {
                return widSetting.mode === this.layoutService.getWorkspaceMode();
            });

            if (this.layoutService.getWorkspaceMode() === MODE.VIEW) {
                widgetFilters = widgetPrefs.widgetFilters ??
                    toPrioritizedFilterParams(
                        this.fundOptions,
                        this.clientOptions,
                        this.managerService.getExtraParametersForWorkspace(),
                        this.userPreference);
            } else {
                widgetFilters = widgetPrefs.widgetFilters ?? widgetSetting?.widgetFilters;
                widgetPrefs.widgetFilters = widgetFilters;
            }

            this.managerService.setWidgetExtraPreferences(widgetPrefs.id, widgetPrefs);
        } else {
            widgetFilters = widgetPrefs.widgetFilters;
        }

        return widgetFilters;
    }

    /*
        Called by:
            - getDataSet (which is public and used by widgets directly)
            - The functions that subscribe to board and widget queryParam changes
     */
    private fetchData(
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        isSubscribed: boolean,
        uniqueKey: string,
        clientCode: string,
    ): void {
        const requestParamsArr: { queryParams: DatasetFetchRuntimeParams, compareMode: string | undefined }[] = [];

        const widgetComparing = this.widgetQueryParam?.comparing;
        const dashboardComparing = this.dashboardQueryParam.comparing;
        const mode = !isSubscribed ?
            this.widgetQueryParam?.comparing :
            (isNaN(boardWidgetDefinitionIds.dashboardId as number) ? widgetComparing : dashboardComparing);

        requestParamsArr.push({
            queryParams: this.getQueryParams(isSubscribed, uniqueKey, mode),
            compareMode: mode === CompareMode.BOTH ? CompareMode.ORIGINAL : mode,
        });
        const modeParam = this.widgetQueryParam?.isComparing ?? this.dashboardQueryParam?.isComparing;
        if (mode === CompareMode.BOTH && modeParam) {
            requestParamsArr.push({
                queryParams: this.getQueryParams(false, uniqueKey, CompareMode.COMPARED),
                compareMode: CompareMode.COMPARED,
            });
        }

        this.cancelRequest(uniqueKey);
        const dsdId = boardWidgetDefinitionIds.definitionId;
        if (!dsdId) {
            return console.error('cannot fetchData when bwd has no definitionId');
        }

        this.fetchDatasetSubscription[uniqueKey] = this.datasetDefinitionsService.fetchDatasetDefinitionDetails(dsdId).subscribe({
            next: (datasetDefinition) => {
                requestParamsArr.forEach((requestParam) => {
                    try {
                        const isQueryTemplateStacked = datasetDefinition.isQueryTemplateStacked;
                        const queryTypeName = datasetDefinition.queryType.name;
                        const queryEndpoint = datasetDefinition.queryEndpoint(clientCode);
                        const startTime = new Date().getTime();
                        const dashboardId = this.managerService.getCurrentDashboardId();

                        if (isQueryTemplateStacked) {
                            const dataQueries = datasetDefinition.populateStackedQueryTemplate(requestParam.queryParams, this.fundOptions);

                            if (isTradeFileDetails(queryTypeName) && !this.dashboardQueryParam.acknowledged) {
                                dataQueries.forEach((dataQuery) => dataQuery.acknowledged = this.dashboardQueryParam.acknowledged);
                            }

                            if (datasetDefinition.conversableType) {
                                dataQueries.forEach((dataQuery) => {
                                    // some datasets, like adhoc, don't have the "selectedColumns" property
                                    // and for those Trebek is automatically adding the crosstalk columns to the response
                                    // without us requesting them, we just need to add the "conversationType"
                                    dataQuery.selectedColumns?.push(...crosstalkColumns, { columnId: 'conversationId' });
                                    dataQuery.conversationType = datasetDefinition.conversableType;
                                });
                            }

                            if (this.isMultiClient && dashboardId === MANAGE_WIDGET_WS_ID) {
                                this.widgetIdUniqueKeyMap.forEach((key: string, widgetId: number) => {
                                    if (key === uniqueKey) {
                                        this.metadataService
                                            .fetchMetadataAllMetadata([new BoardWidgetDefinitionIds(undefined, widgetId, dsdId)])
                                            .subscribe();
                                    }
                                });
                            }

                            this.sendStackedDataQuery(
                                boardWidgetDefinitionIds,
                                dataQueries,
                                queryEndpoint,
                                requestParam,
                                startTime,
                                uniqueKey);
                        } else {
                            const dataQuery = datasetDefinition.populateQueryTemplate(requestParam.queryParams, this.fundOptions);

                            if (
                                dataQuery.timeseriesRange &&
                                (
                                    dataQuery.timeseriesRange.startDate !== requestParam.queryParams.dateRange.from ||
                                    dataQuery.timeseriesRange.endDate !== requestParam.queryParams.dateRange.to
                                )
                            ) {
                                dataQuery.timeseriesRange.startDate = requestParam.queryParams.dateRange.from;
                                dataQuery.timeseriesRange.endDate = requestParam.queryParams.dateRange.to;
                            }
                            if (isTradeFileDetails(queryTypeName) && !this.dashboardQueryParam.acknowledged) {
                                dataQuery.acknowledged = this.dashboardQueryParam.acknowledged;
                            }

                            if (this.isMultiClient && dashboardId === MANAGE_WIDGET_WS_ID) {
                                this.widgetIdUniqueKeyMap.forEach((key: string, widgetId: number) => {
                                    if (key === uniqueKey) {
                                        this.metadataService
                                            .fetchMetadataAllMetadata([new BoardWidgetDefinitionIds(undefined, widgetId, dsdId)])
                                            .subscribe();
                                    }
                                });
                            }

                            if (datasetDefinition.conversableType) {
                                // some datasets, like adhoc, don't have the "selectedColumns" property
                                // and for those Trebek is automatically adding the crosstalk columns to the response
                                // without us requesting them, we just need to add the "conversationType"
                                dataQuery.selectedColumns?.push(...crosstalkColumns, { columnId: 'conversationId' });
                                dataQuery.conversationType = datasetDefinition.conversableType;
                            }

                            this.sendDataQuery(
                                boardWidgetDefinitionIds,
                                dataQuery,
                                queryEndpoint,
                                requestParam,
                                startTime,
                                uniqueKey,
                                clientCode);
                        }
                    } catch (error) {
                        const message = {
                            action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                            exception: { message: (error as Error).message },
                        };
                        if (boardWidgetDefinitionIds.widgetId) {
                            this.sendMessage(boardWidgetDefinitionIds.widgetId, message);
                        } else {
                            this.widgetIdUniqueKeyMap.forEach((key, widgetId) => {
                                if (key === uniqueKey) {
                                    this.sendMessage(widgetId, message);
                                }
                            });
                        }
                    }
                });
            },
        });
    }

    private sendDataQuery(
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        dataQuery: Query,
        queryEndpoint: string,
        requestParam: { queryParams: { uniqueId: string, clientCodeList: Client[] }, compareMode?: string },
        startTime: number,
        uniqueKey: string,
        clientCode: string,
    ): void {
        const { queryParams: { uniqueId, clientCodeList }, compareMode } = requestParam;
        this.fetchDataSubscription[uniqueKey] =
            this.invokePublicAPI(boardWidgetDefinitionIds, dataQuery, queryEndpoint, uniqueId, clientCodeList).subscribe({
                next: (response) => {
                    if (!response.success && response.retryAfter) {
                        setTimeout(() => {
                            this.sendDataQuery(
                                boardWidgetDefinitionIds,
                                dataQuery,
                                queryEndpoint,
                                requestParam,
                                startTime,
                                uniqueKey,
                                clientCode);
                        }, response.retryAfter);
                        return;
                    }

                    response.data.forEach((datum) => {
                        this.addCrosstalkLinksToData(clientCode, datum);
                    });

                    this.sendHideCancelMessage(uniqueId);

                    if (compareMode === CompareMode.COMPARED) {
                        this.widgetCompareData.set(uniqueKey, response.data);
                    } else {
                        this.widgetData.set(uniqueKey, response.data);
                    }

                    const dataSourceName = response.dataSourceName;

                    setTimeout(() => {
                        this.updateDatasource(uniqueId, compareMode, boardWidgetDefinitionIds, dataSourceName, uniqueKey);
                    }, 0);
                },
            });
    }

    private sendStackedDataQuery(
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        dataQueries: Query[],
        queryEndpoint: string,
        requestParam: { queryParams: { uniqueId: string, clientCodeList: Client[] }, compareMode?: string },
        startTime: number,
        uniqueKey: string,
    ): void {
        const { queryParams: { uniqueId, clientCodeList }, compareMode } = requestParam;
        const dataQueryObs: Observable<PublicAPIQueryResponse>[] = [];
        dataQueries.forEach((dataQuery) => {
            dataQueryObs.push(this.invokePublicAPI(boardWidgetDefinitionIds, dataQuery, queryEndpoint, uniqueId, clientCodeList));
        });

        this.fetchDataSubscription[uniqueKey] = combineLatest(dataQueryObs).subscribe({
            next: (res) => {
                if (res.some((response) => !response.success && response.retryAfter)) {
                    const timeout = res.find((response) => !response.success && response.retryAfter)?.retryAfter;
                    setTimeout(() => {
                        this.sendStackedDataQuery(boardWidgetDefinitionIds, dataQueries, queryEndpoint, requestParam, startTime, uniqueKey);
                    }, timeout);
                    return;
                }

                this.sendHideCancelMessage(uniqueId);

                let stackedData: PublicApiResponseRow[] = [];
                res.forEach((response) => stackedData.push(...response.data));
                const columnsToConsider = dataQueries[0].selectedColumns.map((column) => column.columnId);
                stackedData = this.clientDatasetFilterService.removeDuplicateRows(stackedData, columnsToConsider);
                const dataSourceName = res[0].dataSourceName;
                this.widgetData.set(uniqueKey, stackedData);

                setTimeout(() => {
                    this.updateDatasource(uniqueId, compareMode, boardWidgetDefinitionIds, dataSourceName, uniqueKey);
                }, 0);
            },
        });
    }

    private updateDatasource(
        uniqueId: string,
        compareMode: string | undefined,
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        datasourceName: string | undefined,
        uniqueKey: string,
    ): void {
        const dataWrapper = {
            uniqueKey: uniqueId,
            data: this.widgetData.get(uniqueKey) ?? [],
            compareData: this.widgetCompareData.get(uniqueKey) ?? [],
            compareMode,
            dataLoaded: false,
        };
        this.sourceDataCopy = this.widgetData.get(uniqueKey);
        this.widgetDataSourceService.addDataSource(dataWrapper);
        if (boardWidgetDefinitionIds.definitionId && datasourceName) {
            this.widgetDataSourceService.updateDataSourcesNames({
                [boardWidgetDefinitionIds.definitionId]: datasourceName,
            });
        }
        this.dataLoadedTime.set(uniqueId, new Date().toISOString());
        if (uniqueId.indexOf(DATASET_KEY) === 0 || uniqueId.indexOf(WIDGET_KEY) === 0) {
            this.datasetLoading.delete(uniqueKey);
            this.datasetCancelled.delete(uniqueKey);
        }
    }

    private invokePublicAPI(
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        dataQuery: Query,
        queryEndpoint: string,
        uniqueId: string,
        clientCodeList: Client[] = [],
    ): Observable<PublicAPIQueryResponse> {
        const usage: UsageTracker = this.usageTracking.startTracking('DDV.Dataset.Data', boardWidgetDefinitionIds.toRecord());
        const clientCodeAndRoute = splitEndpointIntoClientAndRoute(queryEndpoint);

        if (this.isMultiClient) {
            const clients = clientCodeList.map((el) => el.clientId).join(',');
            clientCodeAndRoute.route += `?clients=${clients.length ? clients : this.fullClientsList}`;
        }

        const call = this.trebekApiExecutor.invokeServiceWithBodyAndReturnFullResponse<TrebekQueryResponse>(
            clientCodeAndRoute.clientCode,
            clientCodeAndRoute.route,
            'POST',
            dataQuery,
            { usageTracker: usage });
        return call.pipe(
            map((response: HttpResponse<TrebekQueryResponse>): PublicAPIQueryResponse => {
                if (response?.body?.error) {
                    return this.handleQueryError((response)?.body, usage, uniqueId);
                }

                if (response?.headers?.get('x-hedgeserv-retry-after')) {
                    return {
                        success: false,
                        data: [],
                        retryAfter: response.headers.get('x-hedgeserv-retry-after') ? Number(response.headers.get('x-hedgeserv-retry-after')) : undefined,
                    };
                }

                usage.succeeded({ numRows: response.body?.data.length });

                return {
                    success: true,
                    data: postProcessPublicApiResponse(response.body?.data ?? []),
                    dataSourceName: response.body?.tracingInfo?.upstream,
                };
            }),
            catchError((error) => of(this.handleQueryError(error, usage, uniqueId))));
    }

    private handleQueryError(
        response: TrebekQueryResponse | HttpErrorResponse,
        usage: UsageTracker,
        uniqueId: string,
    ): PublicAPIQueryResponse {
        usage.failed((response instanceof HttpErrorResponse) ? response.error || response.message : response.error);
        console.error(response);

        setTimeout(() => {
            this.sendErrorMessage(uniqueId, new Date().toISOString(), response);
        }, 0);

        return {
            success: false,
            error: (response instanceof HttpErrorResponse) ? response.error || response.message : response.error,
            data: [],
        };
    }

    private sendHideCancelMessage(requestId: string): void {
        if (requestId.indexOf(DATASET_KEY) === 0) {
            this.widgetIdUniqueKeyMap.forEach((uniqueKey: string, widgetId: number) => {
                if (uniqueKey === requestId) {
                    this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.HIDE_CANCEL);
                }
            });
        } else {
            const widgetId = Number(requestId.slice(WIDGET_KEY.length, requestId.length));
            this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.HIDE_CANCEL);
        }
    }

    private sendErrorMessage(
        requestId: string,
        time: string,
        response: TrebekQueryResponse | HttpErrorResponse,
    ): void {
        if (requestId.indexOf(DATASET_KEY) === 0) {
            this.widgetIdUniqueKeyMap.forEach((uniqueKey: string, widgetId: number) => {
                if (uniqueKey === requestId) {
                    this.datasetErrors.set(requestId, time);
                    if (this.checkForHIError(response, NO_ENTITLEMENTS) || this.checkForHIError(response, FLAG_NOT_SET)) {
                        this.sendMessage(
                            widgetId,
                            {
                                action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                                exception: {
                                    message: this.checkForHIError(response, NO_ENTITLEMENTS) ?
                                        HI_DATA_USER_ERROR_MESSAGE :
                                        HI_DATA_CLIENT_ERROR_MESSAGE,
                                },
                            });
                    } else {
                        this.sendMessage(
                            widgetId,
                            { action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED, exception: { message: GRAPHQL_ERROR_MESSAGE, time } });
                    }
                }
            });
        } else {
            const widgetId = Number(requestId.slice(WIDGET_KEY.length, requestId.length));
            if (this.checkForHIError(response, NO_ENTITLEMENTS) || this.checkForHIError(response, FLAG_NOT_SET)) {
                this.sendMessage(
                    widgetId,
                    {
                        action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                        exception: {
                            message: this.checkForHIError(response, NO_ENTITLEMENTS) ?
                                HI_DATA_USER_ERROR_MESSAGE :
                                HI_DATA_CLIENT_ERROR_MESSAGE,
                        },
                    });
            } else {
                this.sendMessage(
                    widgetId,
                    { action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED, exception: { message: GRAPHQL_ERROR_MESSAGE, time } });
            }
        }
    }

    private checkForHIError(response: TrebekQueryResponse | HttpErrorResponse, error: { CODE: string, TEXT: string }): boolean {
        return response.error?.errorCode === error.CODE && response.error.errorMessage === error.TEXT;
    }

    private sendDataLoadingMessage(): void {
        this.widgetIdUniqueKeyMap.forEach((uniqueKey: string, widgetId: number) => {
            if (uniqueKey.indexOf(DATASET_KEY) === 0) {
                this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);
            }
        });
    }

    private sendDataUpdateMessage(queryParams: FilterQueryParam): void {
        this.widgetIdUniqueKeyMap.forEach((uniqueKey: string, widgetId: number) => {
            if (uniqueKey.indexOf(DATASET_KEY) === 0) {
                const widget = this.managerService.getWidgetById(widgetId);
                if (widget) {
                    queryParams.isComparing = this.widgetQueryParam?.isComparing;
                    widget.lifeCycleCallBack?.(WIDGET_LIFECYCLE_EVENT.DATA_UPDATE,
                        { data: this.fetchedDataWithParams.get(uniqueKey), filters: queryParams, compareMode: queryParams.comparing });
                }
            }
        });
    }

    private getQueryParams(isSubscribed: boolean, uniqueId: string, mode?: string): DatasetFetchRuntimeParams {
        const preference = isSubscribed ? this.dashboardPref : this.widgetQueryParam;

        const hsDefaults = toPrioritizedFilterParams(
            this.fundOptions,
            this.clientOptions,
            this.managerService.getExtraParametersForWorkspace(),
            this.userPreference);
        const fundCodes = (preference?.funds?.length ? preference : hsDefaults)?.funds?.map((fund) => fund.fundId) ?? [];

        const dateFrom = mode && mode === CompareMode.COMPARED && preference?.compareDates ?
            preference?.compareDates.dateFrom :
            preference?.startDate ?? hsDefaults.startDate;

        const dateTo = mode && mode === CompareMode.COMPARED && preference?.compareDates ?
            preference.compareDates.dateTo :
            preference?.endDate ?? hsDefaults.endDate;

        const dateRange = {
            from: dateFrom ?? '',
            to: dateTo ?? '',
        };

        if (this.isMultiClient) {
            const clientCodeList = preference?.clients;
            return new DatasetFetchRuntimeParams(uniqueId, 'daily', dateRange, fundCodes, true, true, clientCodeList);
        }

        return new DatasetFetchRuntimeParams(uniqueId, 'daily', dateRange, fundCodes, true);
    }

    private removeFastnessCheckDatasetId(uniqueKey: string): void {
        this.fastnessCheckDatasetIds = this.fastnessCheckDatasetIds.filter((id) => id !== uniqueKey);
    }

    private getParamsObject(params: FilterQueryParam): FilterQueryParam {
        return {
            funds: params.funds,
            isComparing: params.isComparing,
            comparing: params.isComparing ? params.comparing : undefined, // we don't care about that if we aren't comparing
            compareDates: params.compareDates,
            isPreferenceChangedOnRefresh: params.isPreferenceChangedOnRefresh,
            clients: params.clients,
            acknowledged: params.acknowledged,
        };
    }

    private getFetchSubject(widgetId: number): BehaviorSubject<IDataLoad> {
        let fetchSubject = this.fetchSubjectsByWidgetId.get(widgetId);
        if (!fetchSubject) {
            fetchSubject = new BehaviorSubject<IDataLoad>({ isLoaded: false });
            this.fetchSubjectsByWidgetId.set(widgetId, fetchSubject);
        }

        return fetchSubject;
    }

    private addCrosstalkLinksToData(
        clientCode: string,
        data: PublicApiResponseRow | (WidgetData & { links: { [key: string]: LinkConfiguration } }),
    ): void {
        data.links = {
            ...data.links,
            [TrebekConversationFields.ClientComment]: {
                label: 'Add Comment',
                uri: `/two-way/#/${clientCode}/conversation/${data.conversationId}`,
                launchType: 'modal',
                size: {
                    width: 620,
                    height: 600,
                },
            },

            [TrebekConversationFields.HSComment]: {
                label: 'Add Comment',
                uri: `/two-way/#/${clientCode}/conversation/${data.conversationId}`,
                launchType: 'modal',
                size: {
                    width: 620,
                    height: 600,
                },
            },
        };
    }

    private addCrosstalkAttachmentsToData(
        data: (WidgetData & { links: { [key: string]: LinkConfiguration } }),
        clientCode: string,
        highlight: ConversationHighlight,
        isUserEntitledToUploadAttachments: boolean,
        isUserEntitledToUploadBulkAttachments: boolean,
    ): void {
        data[CrosstalkFields.Attachments] = highlight.lastAttachments?.length ? 'Attachments' : 'No Attachments';

        data.links = {
            ...data.links,
            [CrosstalkFields.Attachments]: {
                label: 'Add Comment',
                uri: `/two-way/#/${clientCode}/conversation/${highlight.id}`,
                launchType: 'modal',
                size: {
                    width: 620,
                    height: 600,
                },
                conversationId: highlight.id,
                clientCode,
                isUserEntitledToUploadAttachments,
                isUserEntitledToUploadBulkAttachments,
                lastAttachments: highlight.lastAttachments,
            },
        };
    }
}
