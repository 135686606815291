import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CurrentStateService, DirtyFlagService } from '@ddv/behaviors';
import { appWidgetStateToConfig } from '@ddv/dashboards';
import { UserEntitlements, UserEntitlementService } from '@ddv/entitlements';
import { AppLayoutState, createWorkspaceObj, Workspace, LayoutService, TopLevelWorkspaceComponent, ManagerService } from '@ddv/layout';
import {
    DEVICE_MODE,
    MANAGE_WIDGET_WS_ID,
    MANAGE_WIDGET_WS_KEY,
    MODE,
    WIDGET_LIFECYCLE_EVENT,
    DashboardModel,
    DatasetDefinition,
    AppWidgetState,
} from '@ddv/models';
import { deepClone } from '@ddv/utils';
import { WidgetsService } from '@ddv/widgets';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { WS_DEFINITION } from '../../models/workspace.configuration';
import { MWLeftNavComponent } from '../left-nav/manage-widgets-left-nav/manage-widgets-left-nav.component';
import { MWDashboardComponent } from '../manage-widgets-dashboard/manage-widgets-dashboard.component';

@Component({
    templateUrl: 'manage-widgets.component.html',
    styleUrls: ['manage-widgets.component.scss'],
})
export class ManageWidgetsComponent implements OnInit, OnDestroy, TopLevelWorkspaceComponent {
    doesMWExist = false;
    currentDashboard: Workspace | undefined;
    isDashboardLoading = true;
    clientEntitlements: UserEntitlements | undefined;
    currentWidgetId: number | undefined;
    datasetDefinition: DatasetDefinition | undefined;
    autoAddDynamicColumnsVisualizations: Map<string, boolean> | undefined;

    @ViewChild('dashboardComponent', { static: false }) private readonly manageWidgetDashboardRef: MWDashboardComponent | undefined;
    @ViewChild('leftNavComponent', { static: false }) mwNavComponent: MWLeftNavComponent | undefined;

    @ViewChild('gridcanvas', { static: true }) gridcanvas: ElementRef | undefined;
    @ViewChild('dashboardContainer', { static: true }) dashboardContainer: ElementRef | undefined;
    @ViewChild('dummyDashboardDiv', { static: true }) dummyDashboardDiv: ElementRef | undefined;

    private subscriptions: Subscription[] = [];
    private isMultiClient = false;

    constructor(
        private readonly manager: ManagerService,
        private readonly layoutService: LayoutService,
        private readonly dirtyFlagService: DirtyFlagService,
        private readonly currentStateService: CurrentStateService,
        private readonly widgetsService: WidgetsService,
        private readonly userEntitlementService: UserEntitlementService,
        private readonly cdr: ChangeDetectorRef,
    ) {
    }

    ngOnDestroy(): void {
        this.manager.removeWorkspace();

        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    ngOnInit(): void {
        this.layoutService.setTopLevelWorkspaceComponent(this);
        this.layoutService.initializeLayoutManager();
        this.manager.registerWorkspaceStateChangeHandler(this.onWorkspaceChangeEvent.bind(this));

        forkJoin([
            this.currentStateService.currentWidgetId$.pipe(take(1)),
            this.currentStateService.isMultiClient$.pipe(take(1)),
            this.userEntitlementService.entitlementsForClientCode$.pipe(take(1)),
        ]).subscribe({
            next: ([_, isMultiClient, userEntitlements]) => {
                this.isMultiClient = isMultiClient;
                this.setUserEntitlements(userEntitlements);

                const currentWidgetId = this.currentStateService.currentWidgetId$.subscribe({
                    next: (widgetId) => this.respondToWidgetIdChange(widgetId),
                });
                this.subscriptions.push(currentWidgetId);
            },
        });
    }

    onRevertChanges(widgetId: number): void {
        this.fetchWidgetInfo(widgetId);
    }

    onUpdateDatasetDefinition(datasetDefinition: DatasetDefinition): void {
        this.datasetDefinition = datasetDefinition;
    }

    onUpdateAutoAddDynamicColumnsVisualizations(visualizations: Map<string, boolean>): void {
        // We need a new map every time in order to trigger the change detection in the child component since:
        // The default change-detection algorithm looks for differences
        // by comparing bound-property values by reference across change detection runs
        // https://angular.io/api/core/DoCheck
        this.autoAddDynamicColumnsVisualizations = deepClone(visualizations);
    }

    private respondToWidgetIdChange(widgetId: number | null): void {
        if (widgetId == null) {
            return this.initializeForWidget({} as AppWidgetState);
        }

        this.currentWidgetId = widgetId;
        this.fetchWidgetInfo(widgetId);
    }

    private fetchWidgetInfo(widgetId: number): void {
        if (this.isMultiClient) {
            this.subscriptions.push(this.widgetsService.fetchWidgetInfoForMultiClient(widgetId)
                .subscribe((widgetInfo: AppWidgetState) => {
                    this.initializeForWidget(widgetInfo);
                }));
        } else {
            this.subscriptions.push(this.widgetsService.fetchWidgetInfo(widgetId)
                .subscribe((widgetInfo: AppWidgetState) => {
                    widgetInfo.widgetFilters?.filters?.forEach((filter) => {
                        if (filter.filterValuesType === 'number') {
                            filter.values = filter.values?.map((value) => Number(value));
                        }

                        if (filter.filterValuesType === 'boolean') {
                            filter.values = filter.values?.map((value) => {
                                if (value != null) {
                                    return value === 'true';
                                } else {
                                    return value;
                                }
                            });
                        }
                    });
                    this.initializeForWidget(widgetInfo);
                }));
        }
    }

    private initializeForWidget(widgetInfo: AppWidgetState): void {
        if (widgetInfo.id) {
            this.mwNavComponent?.onEditBaseWidget(widgetInfo);
        }
        this.registerAppModel(widgetInfo);
    }

    private setUserEntitlements(userEntitlements: UserEntitlements): void {
        if (this.clientEntitlements) {
            return;
        }

        if (userEntitlements.hasNoPermissions()) {
            throw new Error('You are not entitled to anything');
        }

        this.clientEntitlements = userEntitlements;

        this.cdr.detectChanges();

        if (this.mwNavComponent) {
            this.manageWidgetDashboardRef?.setNavRef(this.mwNavComponent);
        }
    }

    private showWorkspaceById(wsId: string, wsKey: string): void {
        if (this.manager.getWorkspace()?.id !== wsId) {
            this.addWorkspaceAndSwitch(wsKey);
        } else {
            this.doesMWExist = true;
        }
        this.updateCurrentDashboard(wsId);
        this.layoutService.toggleTabletMode(DEVICE_MODE.DESKTOP);
    }

    private updateCurrentDashboard(wsId: string): void {
        this.currentDashboard = wsId ? this.manager.getWorkspace() : undefined;
    }

    private addWorkspaceAndSwitch(id: string): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dashboard = createWorkspaceObj((WS_DEFINITION as any)[id]);
        this.manager.addWorkspace(dashboard);
        const dashboardModel = dashboard.getExtraParameters();
        this.manager.createWidgets(appWidgetStateToConfig(dashboardModel?.widgets, this.layoutService.getWorkspaceMode()));
    }

    onWorkspaceChangeEvent(appLayoutState: AppLayoutState, isWorkspaceRendered: boolean, eventName: string): void {
        if (this.isDirtyOnWorkspaceChange(isWorkspaceRendered, eventName)) {
            this.dirtyFlagService.enterDirtyState(this.manager.getCurrentDashboardId() ?? '');
        }
    }

    private isDirtyOnWorkspaceChange(isWorkspaceRendered: boolean, eventName: string): boolean {
        return isWorkspaceRendered &&
            eventName !== WIDGET_LIFECYCLE_EVENT.INIT_WIDGET &&
            eventName !== WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE &&
            eventName !== WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE;
    }

    onTransitionEnd(): void {
        if (this.manager.getWorkspace()) {
            this.updateGridSize();
        }
    }

    private registerAppModel(widgetInfo: AppWidgetState): void {
        // Note, widgetInfo is not being used here, the default partial/fake dashboardModel.widgets
        // is actually registered in the shared service.
        // widgetInfo comes directly from the MW API and contains actual ID (not 9999) and some other
        // things that may be necessary to tell the application "this is a live demo" but some information
        // is lost by not registering the original widgetInfo
        if (this.manager.getWorkspace()?.id !== MANAGE_WIDGET_WS_ID) {
            WS_DEFINITION.MANAGE_WIDGET.widgets[0].enableCompareMode = !!widgetInfo.enableCompareMode;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const dashboard = createWorkspaceObj(WS_DEFINITION.MANAGE_WIDGET as any);
            this.manager.addWorkspace(dashboard);
            const dashboardModel: DashboardModel = dashboard.getExtraParameters();
            this.manager.createWidgets(appWidgetStateToConfig(dashboardModel.widgets, this.layoutService.getWorkspaceMode()));
        } else {
            this.doesMWExist = true;
            this.layoutService.updateLayout();
        }
        this.currentDashboard = this.manager.getWorkspace();

        this.layoutService.toggleTabletMode(DEVICE_MODE.DESKTOP);
        if (this.doesMWExist) {
            this.layoutService.updateWidgetBehaviour(true);
        }
        this.isDashboardLoading = false;

        this.showWorkspaceById(MANAGE_WIDGET_WS_ID, MANAGE_WIDGET_WS_KEY);
        this.layoutService.changeWorkspaceMode(MODE.EDIT_WORKSPACE);
        this.updateGridSize();
    }

    private updateGridSize(): void {
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        setTimeout(() => layoutHandler?.onWindowResize(undefined, true), 500);
    }
}
