<h2 class="title-modal" *ngIf="filterTitle">{{ filterTitle }}</h2>

<div class="dashboard-funds">
    <ul class="list-grid">
        <li
                *ngIf="isMultiClient && isSingleClientPicker !== undefined"
                [@enterLeaveAnimation]
                class="first-grid funds"
                [ngClass]="{'single-select-dropdown': showSingleClientPicker()}">
            <div *ngIf="!showSingleClientPicker()">
                <div class="grid-text">{{ selectedClientsCount }} selected</div>

                <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showClientsDashboardForm = false">
                    <div
                        class="icon-filterarrow-close"
                        [class.icon-filterarrow-open]="showClientsDashboardForm"
                        (click)="toggleClientsDashboardForm()">
                    </div>

                    <div class="dropdown right-position client-drop-wrap" *ngIf="showClientsDashboardForm">
                        <app-clients-form
                            [selectedClients]="queryParams.clients ?? []"
                            (clientsApply)="applySelectedClients($event)">
                        </app-clients-form>
                    </div>
                </div>

                <p>Clients</p>
            </div>

            <cc-single-select-dropdown
                *ngIf="showSingleClientPicker()"
                label="Client Code"
                class="clients-dropdown"
                anchorType="filter"
                type="filter"
                size="xsmall"
                [searchable]="true"
                cssClass="client-list"
                searchPlaceholderText="Quick search..."
                [collapseEmptyError]="true"
                [autoScrollSelectedIntoView]="true"
                [options]="clientOptions"
                [selected]="selectedClient"
                (selectedChange)="applySelectedClients($event)">
            </cc-single-select-dropdown>
        </li>

        <li *ngIf="!isMultiClient" [@enterLeaveAnimation] class="first-grid funds">
            <div class="grid-text">{{ selectedFundsCount }} selected</div>

            <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showFundsDashboardForm = false">
                <span
                    class="icon-filterarrow-close"
                    [class.icon-filterarrow-open]="showFundsDashboardForm"
                    (click)="toggleFundsDashboardForm()">
                </span>

                <div class="dropdown right-position fund-drop-wrap" *ngIf="showFundsDashboardForm">
                    <app-funds-form
                        [selectedFunds]="queryParams.funds ?? []"
                        (fundsApply)="applySelectedFunds($event)">
                    </app-funds-form>
                </div>
            </div>

            <p>Funds</p>
        </li>

        <li
                [@enterLeaveAnimation]
                class="second-mini-grid date-range-picker"
                [ngClass]="{'grey-text': isCompareDateChecked}"
                appMousedownOutside
                (mousedownOutside)="closeRangeDatepicker()"
                *ngIf="isCalendarVisible">
            <div class="grid__item" *ngIf="isDateRangeSupported">
                <span
                    class="grid-text"
                    title="{{validateDate(queryParams.startDate) ? (queryParams.startDate | date: 'MMM dd yyyy') : queryParams.startDate || 'Use Defaults'}}">
                    {{ validateDate(queryParams.startDate) ? (queryParams.startDate | date: 'MMM dd yyyy') : queryParams.startDate ? (getDateFromFuzzy('from', queryParams.startDate) | date: 'MMM dd yyyy') : 'Use Defaults' }}
                </span>

                <p>{{ validateDate(queryParams.startDate) ? 'From Date' : 'Dynamic From' }}</p>
            </div>

            <div class="grid__item">
                <span
                    class="grid-text"
                    title="{{validateDate(queryParams.endDate) ? (queryParams.endDate |  date: 'MMM dd yyyy') : queryParams.endDate || 'Use Defaults'}}">
                    {{ validateDate(queryParams.endDate) ? (queryParams.endDate | date: 'MMM dd yyyy') : queryParams.endDate ? (getDateFromFuzzy('to', queryParams.endDate) | date: 'MMM dd yyyy') : 'Use Defaults'}}
                </span>

                <p *ngIf="!isDateRangeSupported">{{ validateDate(queryParams.endDate) ? 'Date' : 'Dynamic Date' }}</p>
                <p *ngIf="isDateRangeSupported">{{ validateDate(queryParams.endDate) ? 'To Date' : 'Dynamic To' }}</p>
            </div>

            <div class="dropdown-wrapper">
                <span
                    class="icon-filterarrow-close"
                    [ngClass]="{'icon-filterarrow-open':showRangeDatepicker}"
                    (click)="toggleRangeDatepickers()">
                </span>
            </div>

            <div class="datepickerdialog" *ngIf="showRangeDatepicker">
                <app-single-date-picker-dialog
                    *ngIf="!isDateRangeSupported"
                    [date]="queryParams.endDate"
                    [fuzzyDates]="fuzzyDates.to"
                    [isHIDataAvailable]="isHIDataAvailable"
                    (dateSelected)="checkPickerSelectedDates($event)">
                </app-single-date-picker-dialog>

                <app-date-range-picker-dialog
                    *ngIf="isDateRangeSupported"
                    [dateRange]="initPickerDates"
                    [fuzzyDates]="fuzzyDates"
                    [isHIDataAvailable]="isHIDataAvailable"
                    (dateSelected)="checkPickerSelectedDates($event)">
                </app-date-range-picker-dialog>
            </div>
        </li>

        <li
                [@enterLeaveAnimation]
                *ngIf="isActiveDateSupported"
                class="third-grid active-date"
                [ngClass]="{'grey-text': isCompareDateChecked, 'no-border': !showAdditionalFilters}"
                appMousedownOutside
                (mousedownOutside)="showActiveDatePickerDialog = false">
            <div
                    class="grid-text"
                    [title]="getActiveDateRange()">
                {{ getActiveDateRange() }}
            </div>

            <div class="dropdown-wrapper">
                <span
                    class="icon-filterarrow-close"
                    [ngClass]="{'icon-filterarrow-open': showActiveDatePickerDialog}"
                    (click)="toggleActiveDatePickerDialog()">
                </span>
            </div>

            <p>{{ getActiveDateRange() === 'RANGE' || validateDate(queryParams.activeDate!) ? 'Active Date': 'Dynamic Active' }}</p>

            <div class="datepickerdialog" *ngIf="showActiveDatePickerDialog">
                <app-active-date-picker-dialog
                    [isActiveDate]="isActiveDate"
                    [date]="queryParams.activeDate || ''"
                    [fuzzyDates]="fuzzyDates.from"
                    (dateSelected)="applyActiveDate($event)"
                    (isActiveDateChanged)="updateIsActiveDate($event)">
                </app-active-date-picker-dialog>
            </div>
        </li>

        <li
                [@enterLeaveAnimation]
                *ngIf="showAdditionalFilters"
                class="third-mini-grid additional-filters"
                [ngClass]="{'no-border': !showHighlight}">
            <div class="grid-text">{{ queryParams.filters?.length }} selected</div>

            <div class="dropdown-wrapper">
                <span
                    class="icon-filterarrow-close"
                    (click)="openFilterDialog()">
                </span>
            </div>

            <p>Additional Filters</p>
        </li>

        <li class="fifth-grid" *ngIf="filterType !== 'coreWidget'">
            <cc-icon *ngIf="showRestore" id="refresh-dashboard" [icon]="'refresh'" [size]="'medium'" title="Refresh" (iconClicked)="refreshCurrentDashboard()"></cc-icon>

            <span class="show-restore-modal-wrapper" appMousedownOutside (mousedownOutside)="showRestoreModal = false" *ngIf="showRestore">
                <cc-icon [icon]="'chevron-down2'" [size]="'medium'" (iconClicked)="showRestoreModal = !showRestoreModal"></cc-icon>
                <div class="restore-wrapper" *ngIf="showRestoreModal">
                    <span (click)="restoreCurrentDashboard()">Restore Default View</span>
                </div>
            </span>
        </li>
    </ul>
</div>

<ng-template #fastnessCheckTemplate>
    <div class="fastness-title">Date Not Cached.</div>
    <div class="fastness-message">Data for this date is not cached. Do you wish to continue?</div>
</ng-template>
