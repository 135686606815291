import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { FeatureFlagService } from '@ddv/entitlements';
import { LayoutHandlerDetail, ManagerService } from '@ddv/layout';
import { WIDGET_LIFECYCLE_EVENT, WorkspaceSize, Widget, WorkspaceBoundingRectangle, WidgetRectangle } from '@ddv/models';
import { Subscription } from 'rxjs';

import { WidgetOnBoardComponent } from '../widget-on-board/widget-on-board.component';

// Dynamically created ONLY by DashboardComponent, so you won't see the selector referenced in source
@Component({
    selector: 'app-workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements AfterContentInit, OnInit, OnDestroy {
    private static readonly windowResizeTimeoutDuration = 100;

    @Input() widgets: Widget[] = [];
    @Input() isGlobal = false;
    @Input() isManagingWidget = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styleAttributes: any = {};
    private widgetInitCount = 0;
    @ViewChildren(WidgetOnBoardComponent) private readonly widgetComponents: QueryList<WidgetOnBoardComponent> | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly layoutHandlersObserver: any | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private windowResizeTimeoutId: any | undefined;

    mode: string = '';
    dashboardId: number = 0;
    private readonly currentStateObserver: Subscription;
    protected useNewLegend = false;
    private newLegendFlagSubscription: Subscription | undefined;

    constructor(
        private readonly workspaceContainer: ElementRef,
        private readonly manager: ManagerService,
        private readonly currentState: CurrentStateService,
        private readonly cdr: ChangeDetectorRef,
        private readonly featureFlagsService: FeatureFlagService,
    ) {
        this.layoutHandlersObserver = this.manager.layoutHandlers.subscribe((layoutHandlerDetail: LayoutHandlerDetail) => {
            this.styleAttributes.height = `${layoutHandlerDetail.workspaceSize.height}px`;
            this.styleAttributes.width = `${layoutHandlerDetail.workspaceSize.width}px`;
        });

        this.currentStateObserver = this.currentState.dashboardModeAndId$.subscribe((dashboardModeAndId) => {
            this.mode = dashboardModeAndId.mode ?? '';
            this.dashboardId = dashboardModeAndId.id ?? 0;
        });

        window.addEventListener('resize', this.resizeWorkspace.bind(this));
    }

    ngOnInit(): void {
        this.setInitialWorkspaceSize();

        this.newLegendFlagSubscription = this.featureFlagsService.isFlagEnabled('ddv-new-legend').subscribe({
            next: (useNewLegend) => {
                this.useNewLegend = useNewLegend;

                // this moronicness is because of all of the dynamic components
                this.widgetComponents?.forEach((wob) => {
                    wob.useNewLegend = this.useNewLegend;
                });
                this.cdr.detectChanges();
            },
        });
    }

    ngAfterContentInit(): void {
        this.cdr.detectChanges();
    }

    private setInitialWorkspaceSize(): void {
        const workspaceSize = this.getWorkspaceSize();
        this.manager.setInitialWorkspaceSize(workspaceSize.width, workspaceSize.height);
    }

    resizeWorkspace(): void {
        clearTimeout(this.windowResizeTimeoutId);
        const currentWorkspaceId = this.manager.getCurrentDashboardId();
        if (currentWorkspaceId) {
            const layoutHandler = this.manager.getWorkspaceLayoutHandler();
            this.windowResizeTimeoutId = setTimeout(() => {
                const workspaceSize: WorkspaceSize = this.getWorkspaceSize();
                const workspaceResized = layoutHandler?.onWindowResize(workspaceSize, true);
                if (!workspaceResized) {
                    this.manager.resizeWorkspace(workspaceSize.width, workspaceSize.height);
                }
            }, WorkspaceComponent.windowResizeTimeoutDuration);

            layoutHandler?.onWindowResize(this.getWorkspaceSize(), false);
        }
    }

    private getWorkspaceSize(): WorkspaceSize {
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        if (!layoutHandler) {
            return { width: window.innerWidth, height: window.innerHeight };
        }

        const workspaceBoundingRectangle: WorkspaceBoundingRectangle = this.workspaceContainer.nativeElement.getBoundingClientRect();

        return { width: workspaceBoundingRectangle?.width ?? 0, height: workspaceBoundingRectangle?.height ?? 0 };
    }

    ngOnDestroy(): void {
        this.layoutHandlersObserver.unsubscribe();
        this.currentStateObserver?.unsubscribe();
        this.newLegendFlagSubscription?.unsubscribe();
    }

    unselectAllWidgets(): void {
        if (this.widgetComponents) {
            this.widgetComponents.forEach((widgetComponent) => {
                widgetComponent.unselectWidget();
            });
        }
    }

    // all this does is trick the function below (onWorkspaceStateChange) so that as an upstream component (ViewEdit for example)
    // is removing and re-adding widgets, things that shouldn't happen until all of the widgets are initialized, won't
    prepareToReinitializeWidgets(): void {
        this.widgetInitCount = 0;
        const workspace = this.manager.getWorkspace();
        if (workspace) {
            workspace.isRendered = false;
        }
    }

    onWorkspaceStateChange(eventName: WIDGET_LIFECYCLE_EVENT): void {
        if (eventName === WIDGET_LIFECYCLE_EVENT.INIT_WIDGET) {
            this.widgetInitCount += 1;
            const workspace = this.manager.getWorkspace();
            const layoutHandler = this.manager.getWorkspaceLayoutHandler();
            if (workspace && layoutHandler && this.widgetInitCount === this.widgets.length) {
                workspace.isRendered = true;
                layoutHandler.configureWorkspaceLayout();
            }
        }
        const workspaceStateChangeHandler = this.manager.getWorkspaceStateChangeHandler();
        if (typeof workspaceStateChangeHandler === 'function') {
            const applicationLayoutState = this.manager.getApplicationLayoutState();
            const isWorkspaceRendered = !!this.manager.getWorkspace()?.isRendered;
            workspaceStateChangeHandler(applicationLayoutState, isWorkspaceRendered, eventName);
        }
    }

    onDragOver(widgetDragDetail: { widgetRectangle: WidgetRectangle, widgetId: string }): void {
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        if (layoutHandler?.isCustomDragHandlingSupported) {
            layoutHandler.onDragOver(widgetDragDetail.widgetRectangle, Number(widgetDragDetail.widgetId));
        }
    }
}
