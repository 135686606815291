// eslint-disable-next-line import/no-extraneous-dependencies
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { HeaderComp, IHeaderParams } from '@ag-grid-community/core/dist/cjs/es5/headerRendering/cells/column/headerComp';
import { UserDefinedFieldType } from '@ddv/models';

export interface CustomColDef extends ColDef {
    isUserDefinedField?: boolean;
    userDefinedFieldType?: UserDefinedFieldType;
}

export interface CustomColGroupDef extends ColGroupDef {
    customWidthWhenGroup?: number;
}

export class HTMLRenderer extends HeaderComp {
    override init(params: IHeaderParams): void {
        super.init(params);
        const element = this.getGui().querySelector('[ref="eText"]');
        if (element) {
            element.innerHTML = params.displayName;
        }
    }
}

export class DefaultColumnDefinition implements ColDef {
    headerName = '';
    headerComponent = HTMLRenderer;
    field = '';
    width = 50;
    minWidth = 30;
    sortable = true;
    suppressMenu = false;
    hide = false;
    pinned = null;
    suppressSizeToFit = false;
    suppressMovable = false;
    resizable = true;
    suppressNavigable = false;
    suppressAutoSize = false;
    editable = false;
    unSortIcon = false;
    rowGroup = false;
    enableRowGroup = false;
    enablePivot = false;
    enableValue = false;
    enableCellChangeFlash = false;
    suppressColumnsToolPanel = false;
}
